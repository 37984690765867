import { Link, useNavigate } from "react-router-dom";

import AvatarDetails from "../../../../../avatar-details";
import Card from "../../../../../../models/card";
import GoogleIcon from "../../../../../google-icon";
import { PaymentsType } from "../../../../../../modules/dashboard/payments-new/hooks/state/send-money/payments.constants";
import TooltipIcons from "../../../../../tooltip";
import getInitials from "../../../../../../helpers/get-initials";
import titleCase from "../../../../../../hooks/titleCase";

interface CardsSearchCardProps {
    index: number;
    card: Card;
    onClick: () => void;
}

export function CardsSearchCard({ card, onClick }: CardsSearchCardProps): JSX.Element {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5" data-type="transaction">
                <div className="flex w-full items-center justify-start space-x-2" data-type="transaction">
                    <AvatarDetails
                        initials={getInitials(card.name)}
                        title={
                            <div className="flex w-full flex-row items-center justify-start space-x-2">
                                <span className="w-max whitespace-nowrap text-xs font-medium">{titleCase(card.name)}</span>
                                <div className="flex w-full items-center justify-start space-x-2 divide-x-1">
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        ••••{titleCase(card.maskedPan).slice(-4)}
                                    </p>
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap pl-2 text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {card.walletTypeName}
                                    </p>
                                </div>
                            </div>
                        }
                        dataType="transaction"
                        size="xs"
                    />
                </div>
                <div className="pl-6" data-type="transaction">
                    <Link
                        to={{
                            pathname: `/cards`,
                            search: `?query=${card.id}`,
                        }}
                        state={{ cardId: card.id }}
                        onClick={onClick}
                        tabIndex={-1}
                        data-type="transaction"
                    >
                        <TooltipIcons
                            icon={<GoogleIcon icon="add_card" size="xs" />}
                            size="sm"
                            message="Fund Card"
                            placement="right"
                            data-type="transaction"
                            onClick={() => {
                                navigate(
                                    {
                                        pathname: "/payments/make/single",
                                        search: `?from=${card.id}`,
                                    },
                                    {
                                        state: {
                                            typeOfTransfer: PaymentsType.FUND_CARD,
                                            to: card.id,
                                            temp: true,
                                        },
                                    }
                                );
                                onClick();
                            }}
                        />
                    </Link>
                </div>
            </div>
        </>
    );
}

// import "yup-phone";

import * as Yup from "yup";

import { InviteTeamMemberForm, InviteTeamMemberRequest, TeamMemberAccountSettings, TeamMemberItem } from "../team-members.types";
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { NotificationChannel, NotificationType, UserRole } from "../../../../../../models/userAccount.constants";
import { NotificationChannelOptions, NotificationTypeOptions, RoleOptions } from "../team-members.constants";
import { useDispatch, useSelector } from "react-redux";

import Currency from "../../../../../../models/currency";
import { DropdownItem } from "../../../../../../helpers/types";
import { FormikProps } from "formik";
import { IRootState } from "../../../../../../redux/rootReducer";
import { MainInitState } from "../../../../../../redux/init/slice/initSlice.types";
import { ObjectSchema } from "yup";
import UserAccount from "../../../../../../models/userAccount";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { inviteTeamMember } from "../team-members.api";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import useStateRef from "../../../../../../hooks/useStateRef";

export enum InviteStage {
    TEAM_MEMBER_DETAILS = 1,
    TEAM_MEMBER_RULES = 2,
}

export interface useInviteTeamMemberResponse {
    initialFormState: InviteTeamMemberForm;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValidation: ObjectSchema<any>;
    formikRef: MutableRefObject<FormikProps<InviteTeamMemberForm>>;
    options: {
        role: Array<DropdownItem<UserRole>>;
        account: Array<DropdownItem<string>>;
        notificationType: Array<DropdownItem<NotificationType>>;
        notificationChannel: Array<DropdownItem<NotificationChannel>>;
    };
    error: string | null;
    currency: Currency | null | undefined;
    isSubmitting: boolean;
    isFormValid: boolean;
    settingsForm: TeamMemberAccountSettings;
    inviteTeamMemberStage: InviteStage;
    isSettingApprovalLevel: boolean;

    setRole: (newValue: UserRole | undefined) => void;
    setUserAccountIds: (newValue: string[]) => void;
    setNotificationType: (newValue: NotificationType | undefined) => void;
    setMaxApprovalAmount: (newValue: string) => void;
    setNotificationChannels: (newValue: NotificationChannel[]) => void;

    handleFormSubmit: (values: InviteTeamMemberForm) => void;
    handleToggleApprovalLevel: () => void;
    handleSetStageToTeamMemberRules: () => void;
    handleSetStageToTeamMemberDetails: () => void;
}

function useInviteTeamMember(onComplete: (item: TeamMemberItem, message: string) => void, active: boolean): useInviteTeamMemberResponse {
    const dispatch = useDispatch();

    const main = useSelector<IRootState, MainInitState | null>((state) => state.init.main);
    const currency = useSelector<IRootState, Currency | null | undefined>((state) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);
    // const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);

    const [error, setError] = useState<string | null>(null);
    const [accountOptions, setAccountOptions] = useState<Array<DropdownItem<string>>>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSettingApprovalLevel, setIsSettingApprovalLevel] = useState<boolean>(false);
    const [inviteTeamMemberStage, setInviteTeamMemberStage] = useState<InviteStage>(InviteStage.TEAM_MEMBER_DETAILS);
    const [isFormValid, setIsFormValid, isFormValidRef] = useStateRef<boolean>(false);
    const [settingsForm, setSettingsForm, settingsFormRef] = useStateRef<TeamMemberAccountSettings>({
        role: undefined,
        userAccountIds: [],
        minApprovalAmount: null,
        maxApprovalAmount: null,
        notificationType: undefined,
        notificationChannels: [],
    });

    const formikRef = useRef<FormikProps<InviteTeamMemberForm>>();

    const initialFormState: InviteTeamMemberForm = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    };

    const formValidation = Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        phone: Yup.string()
            // .phone(isZambia ? "ZM" : "NG", false, "Invalid Phone Number")
            .required("Required"),
        amount: Yup.string(),
    });

    useEffect(() => {
        if (!active) {
            setError(null);
            formikRef.current?.resetForm();
            setSettingsForm({
                role: undefined,
                notificationType: undefined,
                notificationChannels: [],
                userAccountIds: [],
                maxApprovalAmount: null,
                minApprovalAmount: null,
            });
            setInviteTeamMemberStage(InviteStage.TEAM_MEMBER_DETAILS);
            setIsSettingApprovalLevel(false);
        }
    }, [active]);

    const handleInviteTeamMember = useCallback(
        async (data: InviteTeamMemberRequest) => {
            setError(null);
            setIsSubmitting(true);
            try {
                const res = await inviteTeamMember(data);
                onComplete(res, `An invite has been sent to ${res.teamMember.name}`);
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                setError(errorMessage);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleFormSubmit = useCallback(
        (values: InviteTeamMemberForm) => {
            if (
                !isFormValidRef.current ||
                isNullOrUndefined(settingsFormRef.current.role) ||
                isNullOrUndefined(settingsFormRef.current.notificationType)
            ) {
                return setError("Fill all required fields");
            }
            void handleInviteTeamMember({
                ...values,
                ...settingsFormRef.current,
                role: settingsFormRef.current.role,
                notificationType: settingsFormRef.current.notificationType,
                minApprovalAmount: null,
            });
        },
        [settingsFormRef]
    );

    const setUserAccountIds = useCallback(
        (newValue: string[]) => {
            setSettingsForm({
                ...settingsFormRef.current,
                userAccountIds: newValue,
            });
        },
        [settingsFormRef]
    );

    const setRole = useCallback(
        (newValue: UserRole | undefined) => {
            setSettingsForm({
                ...settingsFormRef.current,
                role: newValue,
            });
        },
        [settingsFormRef]
    );

    const setNotificationType = useCallback(
        (newValue: NotificationType | undefined) => {
            setSettingsForm({
                ...settingsFormRef.current,
                notificationType: newValue,
            });
        },
        [settingsFormRef]
    );

    const setNotificationChannels = useCallback(
        (newValue: NotificationChannel[]) => {
            setSettingsForm({
                ...settingsFormRef.current,
                notificationChannels: newValue,
            });
        },
        [settingsFormRef]
    );

    const setMaxApprovalAmount = useCallback(
        (newValue: string) => {
            setSettingsForm({
                ...settingsFormRef.current,
                maxApprovalAmount: newValue,
            });
        },
        [settingsFormRef]
    );

    const handleSetStageToTeamMemberDetails = useCallback(() => {
        setSettingsForm({
            role: undefined,
            notificationType: undefined,
            notificationChannels: [],
            userAccountIds: [],
            maxApprovalAmount: null,
            minApprovalAmount: null,
        });
        setError(null);
        setIsSettingApprovalLevel(false);
        setInviteTeamMemberStage(InviteStage.TEAM_MEMBER_DETAILS);
    }, []);

    const handleSetStageToTeamMemberRules = useCallback(() => {
        setInviteTeamMemberStage(InviteStage.TEAM_MEMBER_RULES);
    }, []);

    const handleToggleApprovalLevel = useCallback(() => {
        setIsSettingApprovalLevel((prev) => !prev);
    }, []);

    // check if the form is valid
    useEffect(() => {
        if (settingsForm.notificationType === NotificationType.NONE) {
            setIsFormValid(
                !!(
                    formikRef.current &&
                    formikRef.current.isValid &&
                    settingsForm.userAccountIds.length > 0 &&
                    !isNullOrUndefined(settingsForm.role) &&
                    settingsForm.notificationChannels.length < 1
                )
            );
        } else if (settingsForm.role === UserRole.NOTIFICATION_ONLY) {
            setIsFormValid(
                !!(
                    formikRef.current &&
                    formikRef.current.isValid &&
                    settingsForm.userAccountIds.length > 0 &&
                    !isNullOrUndefined(settingsForm.notificationType) &&
                    settingsForm.notificationType === NotificationType.TRUNCATED &&
                    settingsForm.notificationChannels.length > 0
                )
            );
        } else {
            setIsFormValid(
                !!formikRef.current &&
                    formikRef.current.isValid &&
                    settingsForm.userAccountIds.length > 0 &&
                    settingsForm.notificationChannels.length > 0 &&
                    !isNullOrUndefined(settingsForm.role) &&
                    !isNullOrUndefined(settingsForm.notificationType)
            );
        }
    }, [formikRef.current, formikRef.current?.values, settingsForm]);

    // set AccountOptions from state.init.main
    useEffect(() => {
        const accounts: UserAccount[] = main?.companyDetails?.accounts || [];
        setAccountOptions(
            accounts.map((account) => ({
                value: account.id,
                text: account.lencoNameMin || account.bankAccount?.accountName || "",
                subtext: account.accountName || undefined,
            }))
        );
    }, [main]);

    return {
        error,
        currency,
        formikRef: formikRef as MutableRefObject<FormikProps<InviteTeamMemberForm>>,
        isFormValid,
        isSubmitting,
        settingsForm,
        formValidation,
        initialFormState,
        inviteTeamMemberStage,
        isSettingApprovalLevel,
        options: {
            role: RoleOptions,
            account: accountOptions,
            notificationType: NotificationTypeOptions,
            notificationChannel: NotificationChannelOptions,
        },

        setRole,
        handleFormSubmit,
        setUserAccountIds,
        setNotificationType,
        setMaxApprovalAmount,
        setNotificationChannels,
        handleToggleApprovalLevel,
        handleSetStageToTeamMemberRules,
        handleSetStageToTeamMemberDetails,
    };
}

export default useInviteTeamMember;

import ButtonComp from "../../../../../components/button/ButtonComp";
import { ReactComponent as CandleIcon } from "../../../../../assets/svg/CandleIcon.svg";
import { PeopleStageType } from "../../../../../redux/people/slice/peopleSlice.types";
import { setPeopleStage } from "../../../../../redux/people/slice/peopleSlice";
import { useDispatch } from "react-redux";
import useDimension from "../../../../../hooks/useDimension";

function ManageCategoriesButton(): JSX.Element {
    const dispatch = useDispatch();
    const deviceWidth = useDimension().width;

    return (
        <>
            <ButtonComp
                color="grey"
                type="button"
                buttonType="secondary"
                size="md"
                paddingSize="sm"
                func={() => dispatch(setPeopleStage(PeopleStageType.CATEGORY_LIST))}
                fullWidth={deviceWidth < 640}
            >
                <div className="flex items-center justify-center space-x-2">
                    <CandleIcon /> <span className="text-black-tertiary">Manage Groups</span>{" "}
                </div>
            </ButtonComp>
        </>
    );
}

export default ManageCategoriesButton;

import { BillPurchaseCategoriesType, BillPurchaseStatusType } from "../../../../../../models/billPurchase.constant";

import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import { DropdownItem } from "../../../../../../helpers/types";
import store from "../../../../../../redux/store";

export const AirtimeSections: {
    [value in BillPaymentStage]: DropdownItem<BillPaymentStage>;
} = {
    [BillPaymentStage.INITIAL]: {
        value: BillPaymentStage.INITIAL,
        text: "",
        subtext: "",
    },
    [BillPaymentStage.PAYMENT_DETAILS]: {
        value: BillPaymentStage.PAYMENT_DETAILS,
        text: "Buy airtime",
        subtext: "Purchase airtime with your Lenco account",
    },
    [BillPaymentStage.REVIEW_PAYMENT]: {
        value: BillPaymentStage.REVIEW_PAYMENT,
        text: "Review your Purchase",
        subtext: "Confirm the transaction details",
    },
    [BillPaymentStage.MAKE_PAYMENT]: {
        value: BillPaymentStage.MAKE_PAYMENT,
        text: "Authorize Purchase",
        subtext: "",
    },
    [BillPaymentStage.PROCESS_PAYMENT]: {
        value: BillPaymentStage.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const DataSections: {
    [value in BillPaymentStage]: DropdownItem<BillPaymentStage>;
} = {
    [BillPaymentStage.INITIAL]: {
        value: BillPaymentStage.INITIAL,
        text: "",
        subtext: "",
    },
    [BillPaymentStage.PAYMENT_DETAILS]: {
        value: BillPaymentStage.PAYMENT_DETAILS,
        text: "Buy Data",
        subtext: "Purchase data with your Lenco account",
    },
    [BillPaymentStage.REVIEW_PAYMENT]: {
        value: BillPaymentStage.REVIEW_PAYMENT,
        text: "Review your Purchase",
        subtext: "Confirm the transaction details",
    },
    [BillPaymentStage.MAKE_PAYMENT]: {
        value: BillPaymentStage.MAKE_PAYMENT,
        text: "Authorize Purchase",
        subtext: "",
    },
    [BillPaymentStage.PROCESS_PAYMENT]: {
        value: BillPaymentStage.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const CableTvSections: {
    [value in BillPaymentStage]: DropdownItem<BillPaymentStage>;
} = {
    [BillPaymentStage.INITIAL]: {
        value: BillPaymentStage.INITIAL,
        text: "",
        subtext: "",
    },
    [BillPaymentStage.PAYMENT_DETAILS]: {
        value: BillPaymentStage.PAYMENT_DETAILS,
        text: "Buy cable TV subscriptions",
        subtext: "Purchase cable TV subscriptions with your Lenco account",
    },
    [BillPaymentStage.REVIEW_PAYMENT]: {
        value: BillPaymentStage.REVIEW_PAYMENT,
        text: "Review your Purchase",
        subtext: "Confirm the transaction details",
    },
    [BillPaymentStage.MAKE_PAYMENT]: {
        value: BillPaymentStage.MAKE_PAYMENT,
        text: "Authorize Purchase",
        subtext: "",
    },
    [BillPaymentStage.PROCESS_PAYMENT]: {
        value: BillPaymentStage.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const ElectricitySections: {
    [value in BillPaymentStage]: DropdownItem<BillPaymentStage>;
} = {
    [BillPaymentStage.INITIAL]: {
        value: BillPaymentStage.INITIAL,
        text: "",
        subtext: "",
    },
    [BillPaymentStage.PAYMENT_DETAILS]: {
        value: BillPaymentStage.PAYMENT_DETAILS,
        text: "Buy Electricity",
        subtext: "Purchase electricity with your Lenco account",
    },
    [BillPaymentStage.REVIEW_PAYMENT]: {
        value: BillPaymentStage.REVIEW_PAYMENT,
        text: "Review your Purchase",
        subtext: "Confirm the transaction details",
    },
    [BillPaymentStage.MAKE_PAYMENT]: {
        value: BillPaymentStage.MAKE_PAYMENT,
        text: "Authorize Purchase",
        subtext: "",
    },
    [BillPaymentStage.PROCESS_PAYMENT]: {
        value: BillPaymentStage.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

//status
export const BillPaymentHistoryStatusArray = [BillPurchaseStatusType.FAILED, BillPurchaseStatusType.SUCCESS, BillPurchaseStatusType.PENDING];

export const getBillPaymentHistoryStatusName = (_status: BillPurchaseStatusType): string => {
    switch (_status) {
        case BillPurchaseStatusType.FAILED:
            return "Failed";
        case BillPurchaseStatusType.SUCCESS:
            return "Success";
        case BillPurchaseStatusType.PENDING:
            return "Pending";
        default:
            return "";
    }
};

export const BillPaymentHistoryStatusOptions = BillPaymentHistoryStatusArray.map((_status) => ({
    text: getBillPaymentHistoryStatusName(_status),
    value: _status,
}));

//user account ids
export const BillPaymentHistoryAccountIdsArray = store.getState().init.main?.companyDetails?.accounts || [];
export const BillPaymentHistoryAccountIdsOptions = BillPaymentHistoryAccountIdsArray.map((_userAccount) => ({
    text: _userAccount.accountName,
    value: _userAccount.id,
}));

//vendors
export const BillPaymentHistoryVendorsArray = store.getState().billPayment.vendorsList || [];
export const BillPaymentHistoryVendorsOptions = BillPaymentHistoryVendorsArray.map((_vendor) => ({
    text: _vendor.name || "",
    value: _vendor.id,
}));

//category
export const BillPaymentHistoryCategoriesTypeArray = [
    BillPurchaseCategoriesType.AIRTIME,
    BillPurchaseCategoriesType.AIRTIME_PIN,
    BillPurchaseCategoriesType.CABLE_TV,
    BillPurchaseCategoriesType.ELECTRICITY,
    BillPurchaseCategoriesType.INTERNET,
    BillPurchaseCategoriesType.MOBILE_COMBO,
    BillPurchaseCategoriesType.MOBILE_DATA,
    BillPurchaseCategoriesType.OTHER_BILLS,
];

export const BillPaymentHistoryCategoriesTypeText = (_count: BillPurchaseCategoriesType): string => {
    switch (_count) {
        case BillPurchaseCategoriesType.AIRTIME:
            return "Airtime";
        case BillPurchaseCategoriesType.AIRTIME_PIN:
            return "Airtime Pin";
        case BillPurchaseCategoriesType.CABLE_TV:
            return "Cable TV";
        case BillPurchaseCategoriesType.ELECTRICITY:
            return "Electricity";
        case BillPurchaseCategoriesType.INTERNET:
            return "Internet";
        case BillPurchaseCategoriesType.MOBILE_COMBO:
            return "Mobile Combo";
        case BillPurchaseCategoriesType.MOBILE_DATA:
            return "Mobile Data";
        case BillPurchaseCategoriesType.OTHER_BILLS:
            return "Other Bills";
    }
};

export const BillPaymentHistoryCategoryOptions = BillPaymentHistoryCategoriesTypeArray.map((_count) => ({
    text: BillPaymentHistoryCategoriesTypeText(_count),
    value: _count,
}));

//Final
export enum BillPaymentHistoryFilters {
    DATE = "Date",
    STATUS = "Status",
    VENDORS = "Vendors",
    ACCOUNTS = "Accounts",
    CATEGORIES = "Categories",
}

const BillPaymentHistoryFilterIcon = {
    [BillPaymentHistoryFilters.DATE]: "calendar_month",
    [BillPaymentHistoryFilters.ACCOUNTS]: "account_balance",
    [BillPaymentHistoryFilters.VENDORS]: "person",
    [BillPaymentHistoryFilters.CATEGORIES]: "category",
    [BillPaymentHistoryFilters.STATUS]: "cached",
};

export const BillPaymentHistoryFiltersData = Object.values(BillPaymentHistoryFilters).map((_filter) => ({
    text: _filter,
    value: _filter,
    icon: BillPaymentHistoryFilterIcon[_filter],
}));

import { Form, Formik } from "formik";
import { SIGN_UP_FORM_VALIDATION, SignUpStages } from "../hooks/sign-up.constant";
import { SignUpForm, SignUpInviteParams } from "../services/sign-up-api.types";
import { useEffect, useLayoutEffect, useState } from "react";

import { BusinessTypes } from "../../business-type/service/business-type.constant";
import ButtonComp from "../../../../components/button/ButtonComp";
import EmailOtp from "../components/email-otp";
import EmailVerified from "../../email-verification/components/email-verified";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import GoogleIcon from "../../../../components/google-icon";
import { IRootState } from "../../../../redux/rootReducer";
import { LencoActiveCountries } from "../../../../redux/init/slice/initSlice.types";
import LencoFormInput from "../../../../components/inputs/FormInput";
import LencoSpinner from "../../../../components/spinner";
import LoginFooter from "../../../../components/layouts/components/landing/login-footer";
import Parsers from "../../../../utils/parsers";
import PasswordHints from "../../../../utils/password-hints/password-hints";
import formikHasError from "../../../../helpers/formikHasError";
import useClickOutside from "../../../../hooks/useClickOutside";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useSignUpByInvite from "../hooks/use-sign-up-by-invite";
import useSignUpUser from "../hooks/use-sign-up-user";
import useVerifySignUpInvite from "../hooks/use-verify-sign-up-invite";

const homeUrl = process.env.REACT_APP_HOME_URL || "https://lenco.co";

function SignUp(): JSX.Element {
    const queryParams = new URLSearchParams(location.search);
    const countryParam = queryParams.get("country");
    const signUpURLParams = useParams<SignUpInviteParams>();
    const selectedCountryFromParam: LencoActiveCountries | undefined = countryParam
        ? Parsers.nullableEnum(countryParam, LencoActiveCountries) || undefined
        : undefined;
    const businessTypeParam = queryParams.get("type");
    const selectedBusinessType: BusinessTypes | undefined = businessTypeParam
        ? Parsers.nullableNumberEnum(Number(businessTypeParam || 0), BusinessTypes) || undefined
        : undefined;

    const landingCountry = useSelector((state: IRootState) => state.landing.country);

    const [username, setUsername] = useState("");
    const [signUpStage, setSignUpStage] = useState<SignUpStages>(SignUpStages.INITIAL);
    const [isRepeatTouched, setIsRepeatTouched] = useState(false);

    const {
        data: verifySignUpInviteData,
        error: verifySignUpInviteError,
        mutate: verifySignUpInviteMutation,
        isPending: isVerifySignUpInviteLoading,
    } = useVerifySignUpInvite();

    const {
        data: signUpUserData,
        error: signUpUserError,
        mutate: signUpUserMutation,
        isPending: isSignUpUserLoading,
    } = useSignUpUser({
        onComplete: () => setSignUpStage(SignUpStages.EMAIL_OTP),
    });

    const {
        data: signUpByInviteData,
        error: signUpByInviteError,
        mutate: signUpByInviteMutation,
        isPending: isSignUpByInviteLoading,
    } = useSignUpByInvite({
        onComplete: () => setSignUpStage(SignUpStages.EMAIL_OTP),
    });

    const INITIAL_FORM_STATE: SignUpForm = {
        email: verifySignUpInviteData?.email || "",
        phone: verifySignUpInviteData?.phone || "",
        isInvite: !!verifySignUpInviteData,
        password: "",
        lastName: verifySignUpInviteData?.lastName || "",
        firstName: verifySignUpInviteData?.firstName || "",
        businessName: verifySignUpInviteData?.businessName || "",
        repeatPassword: "",
    };

    useLayoutEffect(() => {
        if (Object.keys(signUpURLParams).length > 0) {
            verifySignUpInviteMutation({
                a: signUpURLParams.a || "",
                b: signUpURLParams.b || "",
                type: signUpURLParams.type || "",
                auth: "signup",
            });
        }
    }, [signUpURLParams]);

    useEffect(() => {
        return () => {
            setUsername("");
            setSignUpStage(SignUpStages.INITIAL);
        };
    }, []);

    const domNode = useClickOutside(
        () => {
            setIsRepeatTouched(false);
        },
        undefined,
        ["mousedown", "keydown"]
    );

    return (
        <>
            {isVerifySignUpInviteLoading && (
                <div className="flex h-full w-full items-center justify-center">
                    <LencoSpinner size="lg" />
                </div>
            )}
            {verifySignUpInviteError && (
                <div className="flex items-center justify-center">
                    <ErrorToast error={verifySignUpInviteError} />
                </div>
            )}

            {!(isVerifySignUpInviteLoading || verifySignUpInviteError) && (
                <>
                    {signUpStage === SignUpStages.INITIAL && (
                        <Formik
                            initialValues={INITIAL_FORM_STATE}
                            validationSchema={SIGN_UP_FORM_VALIDATION}
                            onSubmit={(values) => {
                                values.isInvite
                                    ? void signUpByInviteMutation({
                                          a: signUpURLParams.a || "",
                                          b: signUpURLParams.b || "",
                                          type: signUpURLParams.type || "",
                                          phone: values.phone,
                                          password: values.password,
                                          lastName: values.lastName,
                                          firstName: values.firstName,
                                          repeatPassword: values.repeatPassword,
                                          canUseEmailVerificationOtp: true,
                                      })
                                    : void signUpUserMutation({
                                          phone: values.phone,
                                          email: values.email,
                                          country: selectedCountryFromParam ?? landingCountry,
                                          password: values.password,
                                          lastName: values.lastName,
                                          firstName: values.firstName,
                                          isRegistered: selectedBusinessType ? selectedBusinessType === BusinessTypes.REGISTERED : undefined,
                                          businessName: values.businessName,
                                          repeatPassword: values.repeatPassword,
                                          canUseEmailVerificationOtp: true,
                                      });
                            }}
                            enableReinitialize
                            validateOnChange
                            validateOnMount
                        >
                            {(formik) => {
                                return (
                                    <>
                                        {(signUpUserError || signUpByInviteError) && (
                                            <div className="flex items-center justify-center pb-4">
                                                <ErrorToast error={signUpUserError || signUpByInviteError} />
                                            </div>
                                        )}
                                        <div className="relative flex w-full max-w-lg flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                                            <div className="flex w-full flex-col items-center justify-start space-y-2">
                                                {formik.values.isInvite ? (
                                                    <>
                                                        <h3 className="break-words text-center text-2xl font-medium text-black">
                                                            You have been invited to join{" "}
                                                            <span className="font-medium">{verifySignUpInviteData?.businessName} </span>
                                                            on Lenco
                                                        </h3>
                                                        <p className="break-words text-center text-base font-normal text-black-tertiary">
                                                            Signing up on Lenco is fast and free - no commitments or long-term contracts
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3 className="break-words text-center text-2xl font-medium text-black">
                                                            Open a Better Business account
                                                        </h3>
                                                        <p className="break-words text-center text-base font-normal text-black-tertiary">
                                                            Sign up to Lenco in minutes
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            <Form className="flex w-full flex-col items-center justify-center space-y-4" autoComplete="disable">
                                                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <LencoFormInput label="First Name" name="firstName" />
                                                    <LencoFormInput label="Last Name" name="lastName" />
                                                </div>

                                                <LencoFormInput
                                                    label={`${selectedBusinessType !== BusinessTypes.UNREGISTERED ? "Legal " : ""}Business Name`}
                                                    name="businessName"
                                                    isDisabled={formik.values.isInvite}
                                                />

                                                <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                    <LencoFormInput label="Your Email Address" name="email" />
                                                    <LencoFormInput type="tel" label="Your Phone Number" name="phone" />
                                                </div>

                                                <div className="flex w-full flex-col space-y-4">
                                                    <LencoFormInput label="Choose a Password" name="password" type="password" />
                                                    <PasswordHints
                                                        password={formik.values.password}
                                                        className="flex items-center space-x-2 pb-1 text-xs"
                                                        show={formik.values.password.length > 0}
                                                    />
                                                    {!formik.errors.password && (
                                                        <div className="w-full">
                                                            <div className="w-full" onClick={() => setIsRepeatTouched(true)} ref={domNode}>
                                                                <LencoFormInput label="Re-enter Password" name="repeatPassword" type="password" />
                                                            </div>

                                                            {((formik.values.repeatPassword.length >= formik.values.password.length &&
                                                                formik.values.repeatPassword !== formik.values.password) ||
                                                                (formik.values.repeatPassword.length > 0 &&
                                                                    !isRepeatTouched &&
                                                                    formik.values.repeatPassword !== formik.values.password)) && (
                                                                <div className="pt-2">
                                                                    <li className="flex items-center space-x-1 pb-2 text-xs">
                                                                        <GoogleIcon icon="close" className="text-error" />
                                                                        <span className="text-error">Password does not match</span>
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="w-full pt-4">
                                                    <ButtonComp
                                                        size="xl"
                                                        type="submit"
                                                        color="black"
                                                        buttonType="primary"
                                                        disable={formikHasError(formik.errors)}
                                                        isLoading={isSignUpUserLoading || isSignUpByInviteLoading}
                                                        fullWidth
                                                        isText
                                                    >
                                                        Create Account
                                                    </ButtonComp>
                                                </div>
                                                <p className="px-6 pt-2 text-center text-xs text-black-secondary">
                                                    By clicking &apos;Create Account&apos;, I agree to Lenco&apos;s{" "}
                                                    <a href={`${homeUrl}/terms`} className="font-bold" target="_blank" rel="noreferrer">
                                                        Terms of Service,
                                                    </a>{" "}
                                                    <a href={`${homeUrl}/privacy`} className="font-bold" target="_blank" rel="noreferrer">
                                                        Privacy Policy,
                                                    </a>{" "}
                                                    and <span className="font-bold">WhatsApp</span> alert information service.
                                                </p>
                                            </Form>
                                        </div>
                                        <LoginFooter isLogin />
                                    </>
                                );
                            }}
                        </Formik>
                    )}
                    {signUpUserData && signUpUserData?.emailVerification.key && signUpStage === SignUpStages.EMAIL_OTP && (
                        <EmailOtp
                            userKey={signUpUserData?.emailVerification.key || signUpByInviteData?.emailVerification.key || ""}
                            email={signUpUserData?.emailVerification.email || signUpByInviteData?.emailVerification.email || ""}
                            onComplete={(_username) => {
                                setUsername(_username);
                                setSignUpStage(SignUpStages.SUCCESSFUL_VERIFICATION);
                            }}
                        />
                    )}
                    {signUpStage === SignUpStages.SUCCESSFUL_VERIFICATION && (
                        <EmailVerified
                            email={signUpUserData?.emailVerification?.email || signUpByInviteData?.emailVerification.email || ""}
                            username={username || ""}
                            justSetPassword={false}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default SignUp;

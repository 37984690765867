import React, { useState } from "react";

// import { ReactComponent as AddUserIcon } from "../../../../../../assets/svg/TeamMember/AddUserIcon.svg";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";
import InviteTeamMemberModal from "./InviteTeamMemberModal";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";

interface InviteTeamMemberProps {
    onComplete: (item: TeamMemberItem) => void;
}

function InviteTeamMember({ onComplete }: InviteTeamMemberProps): JSX.Element {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <div>
                <InviteTeamMemberModal active={showModal} onClose={() => setShowModal(false)} onComplete={onComplete} />

                <ButtonComp buttonType="secondary" color="grey" type="button" size="md" func={() => setShowModal(true)}>
                    <div className="flex items-center justify-center space-x-2">
                        <GoogleIcon icon="person_add" className="text-blue" />
                        <span className="text-sm">Invite a Team Member</span>
                    </div>
                </ButtonComp>
            </div>
        </>
    );
}

export default InviteTeamMember;

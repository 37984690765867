import { Dispatch, SetStateAction, useEffect } from "react";

import FullPageTray from "../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayFooter from "../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import FullPageTrayHeader from "../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import NewTransactionIcon from "../../../../../components/new-transaction-icon";
import Transaction from "../../../../../models/transaction";
import TransactionDetailCardActions from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardActions";
import TransactionDetailCardBy from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardBy";
import TransactionDetailCardCategories from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardCategories";
import TransactionDetailCardDetails from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardDetails";
import TransactionDetailCardExpenseManagement from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardExpenseManagement";
import TransactionDetailCardHead from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardHead";
import TransactionDetailCardPendingActivation from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardPendingActivation";
import TransactionDetailCardStatus from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardStatus";
import TransactionDetailCardUpload from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardUpload";
import TransactionDetailCardUserGroups from "../Cards/TransactionDetailCard/Elements/TransactionDetailCardUserGroups";
import TransactionDetailCommentsList from "../Cards/TransactionDetailCard/Elements/TransactionDetailCommentsList";
import TransactionsDetailCardCancelApprovalActions from "../Cards/TransactionDetailCard/Elements/TransactionsDetailCardCancelApprovalActions";
import TransactionsDetailCardPendingApprovalActions from "../Cards/TransactionDetailCard/Elements/TransactionsDetailCardPendingApprovalActions";
import UserAccount from "../../../../../models/userAccount";
import { setIsTriggerUploadFunc } from "../../../../../redux/transactions/slice/transactionsSlice";
import { useDispatch } from "react-redux";

interface IProps {
    isBigger: boolean;
    transaction: Transaction | undefined;
    isBackTogglerIcon: boolean;
    isUploadingReceipt: boolean;
    canShowTransaction: boolean;
    isPreviewDownloading: boolean;
    globalActiveDataType: string;
    canApproveTransaction: boolean;

    setAccountToActivate: Dispatch<SetStateAction<UserAccount | null>>;
    setShowTagRecipientModal: Dispatch<SetStateAction<boolean>>;
    handleTransactionDeclined: () => void;
    setShowActivateAccountModal: Dispatch<SetStateAction<boolean>>;
    handleUploadAccountingReceipt: (transaction: Transaction, file: File) => Promise<void>;
    handlePreviewAccountingReceipt: (transaction: Transaction) => Promise<void>;
    handleCloseTransactionDetailCard: () => void;
    handleTogglePendingApprovalShowModal: () => void;
}

function TransactionDetailsTray({
    isBigger,
    transaction,
    isBackTogglerIcon,
    canShowTransaction,
    isUploadingReceipt,
    isPreviewDownloading,
    globalActiveDataType,
    canApproveTransaction,
    setAccountToActivate,
    setShowTagRecipientModal,
    handleTransactionDeclined,
    setShowActivateAccountModal,
    handleUploadAccountingReceipt,
    handlePreviewAccountingReceipt,
    handleCloseTransactionDetailCard,
    handleTogglePendingApprovalShowModal,
}: IProps): JSX.Element {
    const dispatch = useDispatch();

    useEffect(() => {
        if (canShowTransaction) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [canShowTransaction]);

    return (
        <FullPageTray active={canShowTransaction} dataType={globalActiveDataType || "transaction"}>
            <FullPageTrayHeader
                size="md"
                toggler={handleCloseTransactionDetailCard}
                dataType={globalActiveDataType || "transaction"}
                menu={isBackTogglerIcon ? <></> : undefined}
            >
                Transaction Details
            </FullPageTrayHeader>
            <FullPageTrayBody dataType={globalActiveDataType || "transaction"}>
                {transaction && (
                    <div className="w-full space-y-6 pb-7">
                        <TransactionDetailCardHead transaction={transaction} dataType={globalActiveDataType || "transaction"} />

                        <TransactionDetailCardDetails transaction={transaction} dataType={globalActiveDataType || "transaction"} />

                        {transaction.destination?.card && (transaction.initiatedBy || transaction.approvedBy) && (
                            <TransactionDetailCardBy transaction={transaction} dataType={globalActiveDataType || "transaction"} />
                        )}

                        <TransactionDetailCardStatus transaction={transaction} dataType={globalActiveDataType || "transaction"} />
                        <TransactionDetailCardExpenseManagement
                            transaction={transaction}
                            showTransactionDetailCard={canShowTransaction}
                            dataType={globalActiveDataType || "transaction"}
                        />
                        <TransactionDetailCommentsList transaction={transaction} dataType={globalActiveDataType || "transaction"} />

                        <TransactionDetailCardUpload
                            transactionDetail={transaction}
                            dataType={globalActiveDataType || "transaction"}
                            isBigger={isBigger}
                            isPreviewDownloading={isPreviewDownloading}
                            isUploadingReceipt={isUploadingReceipt}
                            handleUploadAccountingReceipt={handleUploadAccountingReceipt}
                            handlePreviewAccountingReceipt={handlePreviewAccountingReceipt}
                        />
                        <TransactionDetailCardCategories
                            dataType={globalActiveDataType}
                            transaction={transaction}
                            showTransactionDetailCard={canShowTransaction}
                        />
                        <TransactionDetailCardUserGroups
                            dataType={globalActiveDataType}
                            transaction={transaction}
                            showTransactionDetailCard={canShowTransaction}
                        />
                    </div>
                )}
            </FullPageTrayBody>
            {transaction && !transaction.isCardPayment && (
                <>
                    <TransactionDetailCardActions
                        dataType={globalActiveDataType || "transaction"}
                        transaction={transaction}
                        handleShowTagTransactionModal={() => setShowTagRecipientModal(true)}
                        handleAttachFile={() => dispatch(setIsTriggerUploadFunc(true))}
                        isUploadingReceipt={isUploadingReceipt}
                        handleUploadAccountingReceipt={handleUploadAccountingReceipt}
                        handlePreviewAccountingReceipt={handlePreviewAccountingReceipt}
                    />
                    <TransactionsDetailCardPendingApprovalActions
                        dataType={globalActiveDataType || "transaction"}
                        transaction={transaction}
                        isAdmin={canApproveTransaction}
                        handleUpdateDeclinedTransaction={handleTransactionDeclined}
                        handleTogglePendingApprovalShowModal={handleTogglePendingApprovalShowModal}
                    />
                    <TransactionsDetailCardCancelApprovalActions dataType={globalActiveDataType || "transaction"} transaction={transaction} />
                    <TransactionDetailCardPendingActivation
                        dataType={globalActiveDataType || "transaction"}
                        transaction={transaction}
                        handleShowActiveAccountModal={() => setShowActivateAccountModal(true)}
                        handleSetAccountToActivate={setAccountToActivate}
                    />
                </>
            )}
            {transaction && transaction.isCardPayment && (
                <FullPageTrayFooter dataType={globalActiveDataType || "transaction"} size="md">
                    <NewTransactionIcon
                        icon="attach_file"
                        text="Attach Receipt"
                        dataType={globalActiveDataType || "transaction"}
                        onClick={() => dispatch(setIsTriggerUploadFunc(true))}
                    />
                </FullPageTrayFooter>
            )}
        </FullPageTray>
    );
}

export default TransactionDetailsTray;

import React, { useCallback, useState } from "react";

import { ReactComponent as ActivateCardIcon } from "../../../../../../assets/svg/Cards/ActivateCardIcon.svg";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import ExpiryInput from "../../../../../../components/inputs/expiry-input";
import GoogleIcon from "../../../../../../components/google-icon";
import { IActivateCard } from "../../../Services/cardsApi.types";
import Input from "../../../../../../components/inputs/Input";
import TooltipIcons from "../../../../../../components/tooltip";
import titleCase from "../../../../../../hooks/titleCase";

function ActivateCard({ isActivateCardLoading, onCancel, onCardActivation }: IActivateCard): JSX.Element {
    const [expiryDate, setExpiryDate] = useState("");
    const [lastSixDigit, setLastSixDigit] = useState("");

    const onLastDigitChange = useCallback((e: string) => {
        if (/^\d{0,6}$/.test(e)) {
            setLastSixDigit(e);
        }
    }, []);

    const onExpiryDateChange = useCallback((e: string) => {
        setExpiryDate(e);
    }, []);

    const onActivateCardSubmit = useCallback(() => {
        const splitExpiryDate = expiryDate?.split("/");
        return onCardActivation({
            last6Digits: lastSixDigit,
            expiryMonth: splitExpiryDate[0],
            expiryYear: splitExpiryDate[1],
        });
    }, [lastSixDigit, expiryDate, onCardActivation]);

    return (
        <>
            <div className="mt-4 w-full space-y-8">
                <div className="flex flex-col space-y-3">
                    <div className="flex items-center justify-start space-x-2">
                        <ActivateCardIcon className="h-5 w-5" />
                        <h3 className="text-base font-medium text-black">Activate Card</h3>
                    </div>
                    <p className="text-sm font-normal text-black-secondary">Please follow the activation instructions included with your card.</p>
                </div>
                <div className="flex w-full flex-col space-y-12">
                    <div className="flex w-full flex-col space-y-4">
                        <div className="relative w-full">
                            <Input placeholder="Last Six Digits" value={titleCase(lastSixDigit)} onChange={onLastDigitChange} fullWidth />
                            <div className="absolute right-3 top-0 z-10 flex h-full items-center justify-center">
                                <TooltipIcons
                                    bg="none"
                                    icon={<GoogleIcon icon="info" className="text-black-quat" />}
                                    message="The last 6 digits found at the front of your card"
                                    dataType="lastSixDigits"
                                    placement="top"
                                />
                            </div>
                        </div>
                        <div className="relative w-full">
                            <ExpiryInput hidePrefixIcon placeholder="Expiry Date" value={expiryDate} onChange={onExpiryDateChange} />
                            <div className="absolute right-3 top-0 z-10 flex h-full items-center justify-center">
                                <TooltipIcons
                                    bg="none"
                                    icon={<GoogleIcon icon="info" className="text-black-quat" />}
                                    message="The expiry date found at the front of your card"
                                    dataType="expiryDate"
                                    placement="top"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row items-center justify-center space-x-4">
                        <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={onCancel} color={"grey"}>
                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                        </ButtonComp>

                        <ButtonComp
                            fullWidth
                            type="button"
                            size="lg"
                            buttonType="primary"
                            disable={!lastSixDigit || !expiryDate || lastSixDigit?.length !== 6 || expiryDate.length !== 5}
                            isLoading={isActivateCardLoading}
                            onClick={onActivateCardSubmit}
                            color={"black"}
                        >
                            <span className="w-full text-center">Activate Card</span>
                        </ButtonComp>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ActivateCard;

import { ReactComponent as FadedTrashIcon } from "../../../../assets/svg/Terminal/faded-trash.svg";
import React from "react";
import TooltipIcons from "../../../../components/tooltip";
import { ReactComponent as TrashIcon } from "../../../../assets/svg/Terminal/trash.svg";

interface Props {
    name: string;
    canManage?: boolean;
    isManager?: boolean;
    isAdmin?: boolean;
    onDelete?: () => void;
}

export default function TeamAccessAdminButton({ name, canManage = false, isManager = false, isAdmin = false, onDelete }: Props) {
    const textSplit = name?.split(" ");
    return (
        <div className="flex w-full items-center gap-x-2 rounded-md border border-grey px-2 py-2">
            <div className="relative flex h-8 w-8 items-center justify-center rounded-lg bg-grey-tertiary text-xs uppercase text-black-tertiary">{`${textSplit?.[0]?.[0]}${textSplit.length > 1 ? textSplit?.[1]?.[0] : ""}`}</div>
            <div className="flex flex-1 flex-col items-start gap-y-1 text-sm font-medium capitalize leading-none text-black-secondary">
                <div className="space-x-1">
                    <span> {name}</span>
                    {isAdmin ? (
                        <span className="h-3 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-2xs font-medium capitalize leading-none text-success">
                            Admin
                        </span>
                    ) : null}
                </div>
                {isManager ? <div className="text-xs font-normal leading-none text-black-tertiary">Manage</div> : null}
                {!isManager ? <div className="text-xs font-normal leading-none text-black-tertiary">View Only</div> : null}
            </div>
            <div
                onClick={canManage ? onDelete : undefined}
                className="relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg text-xs uppercase text-black-tertiary"
            >
                <TooltipIcons
                    bg="none"
                    icon={(!canManage && <FadedTrashIcon />) || (canManage && <TrashIcon />) || null}
                    message="Remove access"
                    placement="right"
                />
            </div>
        </div>
    );
}

import ButtonComp from "../../button/ButtonComp";
import { FilterDateTypes } from "../filter.constant";
import GoogleIcon from "../../google-icon";
import { formatDate } from "../../../utils/formatDate";

interface Props {
    endDate: string | Date;
    startDate: string | Date;
    selectedFilterDate: FilterDateTypes;

    handleCancelSelection: () => void;
}

function DateTag(props: Props): JSX.Element {
    return (
        <>
            {props.selectedFilterDate !== FilterDateTypes.ALL_TIME ? (
                <div className="w-max pr-2 pt-2">
                    <div className="flex h-10 max-w-max items-center justify-center space-x-1 rounded-lg bg-grey px-3">
                        <p className="select-none text-xs text-black">
                            {props.selectedFilterDate !== FilterDateTypes.CUSTOM
                                ? `Date - ${props.selectedFilterDate}`
                                : `Date: ${formatDate(new Date(props.startDate))} - ${formatDate(new Date(props.endDate))}`}
                        </p>
                        <ButtonComp
                            size="md"
                            color="black"
                            className="-mr-2 !p-1 text-black hover:text-error-tertiary"
                            buttonType="tertiary"
                            onClick={props.handleCancelSelection}
                        >
                            <GoogleIcon icon="close" size="sm" />
                        </ButtonComp>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default DateTag;

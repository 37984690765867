import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaymentStageType, PaymentType, SendMoneyState } from "./sendMoneySlice.types";

const initialState: SendMoneyState = {
    temp: false,
    payTo: "",
    payFrom: "",
    pageFrom: "",
    payAmount: "",
    payReason: "",
    paymentStage: PaymentStageType.INITIAL,
    paymentType: PaymentType.SINGLE_TRANSFER,
};

export const sendMoneySlice = createSlice({
    name: "sendMoney",
    initialState,
    reducers: {
        setPaymentStage: (state: SendMoneyState, action: PayloadAction<PaymentStageType>) => {
            state.paymentStage = action.payload;
        },
        setPaymentType: (state: SendMoneyState, action: PayloadAction<PaymentType>) => {
            state.paymentType = action.payload;
        },
        setPayFrom: (state: SendMoneyState, action: PayloadAction<string>) => {
            state.payFrom = action.payload;
        },
        setPayTo: (state: SendMoneyState, action: PayloadAction<string>) => {
            state.payTo = action.payload;
        },
        setPayAmount: (state: SendMoneyState, action: PayloadAction<string>) => {
            state.payAmount = action.payload;
        },
        setPayReason: (state: SendMoneyState, action: PayloadAction<string>) => {
            state.payReason = action.payload;
        },
        setPageFrom: (state: SendMoneyState, action: PayloadAction<string>) => {
            state.pageFrom = action.payload;
        },
        setTempTrue: (state: SendMoneyState) => {
            state.temp = true;
        },
        resetAllTempSendMoneyData: (state: SendMoneyState) => {
            state.payFrom = "";
            state.payTo = "";
            state.pageFrom = "";
            state.payAmount = "";
            state.payReason = "";
            state.temp = false;
        },
        resetAllSendMoneyData: (state: SendMoneyState) => {
            state.paymentStage = PaymentStageType.INITIAL;
            state.paymentType = PaymentType.SINGLE_TRANSFER;
            state.payFrom = "";
            state.payTo = "";
            state.payAmount = "";
            state.payReason = "";
            state.pageFrom = "";
            state.temp = false;
        },
    },
});

export const {
    setPaymentStage,
    setPaymentType,
    setPayFrom,
    setPayTo,
    setPageFrom,
    setTempTrue,
    setPayAmount,
    setPayReason,
    resetAllTempSendMoneyData,
    resetAllSendMoneyData,
} = sendMoneySlice.actions;

export default sendMoneySlice.reducer;

import React from "react";
import Skeleton from "../../../../../../../components/skeleton";
import TeamMemberDetailsAccountRow from "../TeamMemberDetailsAccountRow";
import TeamMemberDetailsSkeleton from "../../../Skeleton/TeamMembers/TeamMemberDetailsSkeleton";
import { TeamMemberItem } from "../../../../Services/team-members/team-members.types";
import TeamMembersCard from "../TeamMembersCards";
import TeamMembersCardContainer from "../TeamMembersCardsContainer";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";

interface Props {
    isSelf: boolean;
    userAccountNames: Map<string, string>;
    selectedTeamMember: TeamMemberItem | null;

    onRemoveFromTeam: (memberId: string) => void;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    removeAccountMetaFromTeamMember: (accountMetaToRemove: UserAccountMeta) => void;
}

function TeamMemberAccountDetails({
    isSelf,
    userAccountNames,
    selectedTeamMember,

    onRemoveFromTeam,
    updateAccountMeta,
    removeAccountMetaFromTeamMember,
}: Props): JSX.Element {
    return (
        <div className="max-h-fit-available mt-5 flex h-full w-full flex-col items-start justify-start">
            {/* show information */}
            {!selectedTeamMember ? (
                <div className="h-full w-full">
                    <Skeleton box />
                </div>
            ) : (
                <div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
                    <div className="relative flex h-full w-full flex-1">
                        {/* <div className="absolute flex h-full max-h-full w-full flex-1 overflow-auto"> */}
                        <div className="flex h-full max-h-full w-full flex-1">
                            <div className="left-0 top-0 h-fit w-full rounded-custom border border-grey">
                                <table className="hidden w-full xl:table">
                                    <thead className="sticky z-10 overflow-x-scroll border-b border-grey-secondary text-left text-sm font-medium text-black-secondary">
                                        <tr className="relative">
                                            <th className="sticky top-0 overflow-ellipsis whitespace-nowrap bg-grey-tertiary p-3" scope="col">
                                                Acount
                                            </th>
                                            <th className="sticky top-0 whitespace-nowrap bg-grey-tertiary px-4 pr-4 text-left" scope="col">
                                                Role
                                            </th>
                                            <th className="sticky top-0 whitespace-nowrap bg-grey-tertiary px-4 text-left" scope="col">
                                                Notification Rule
                                            </th>
                                            <th className="sticky top-0 bg-grey-tertiary px-3 text-right font-normal" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {selectedTeamMember &&
                                            selectedTeamMember.accountsMeta.map((accountMeta) => (
                                                <TeamMemberDetailsAccountRow
                                                    key={accountMeta.userAccountId}
                                                    accountMeta={accountMeta}
                                                    onRemoveFromTeam={onRemoveFromTeam}
                                                    updateAccountMeta={updateAccountMeta}
                                                    removeAccountMetaFromTeamMember={removeAccountMetaFromTeamMember}
                                                    userAccountNames={userAccountNames}
                                                    isSelf={isSelf}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                                {selectedTeamMember && (
                                    <div className="block w-full xl:hidden">
                                        <TeamMembersCardContainer>
                                            {selectedTeamMember.accountsMeta.map((accountMeta) => {
                                                return (
                                                    <TeamMembersCard
                                                        key={accountMeta.userAccountId}
                                                        accountMeta={accountMeta}
                                                        onRemoveFromTeam={onRemoveFromTeam}
                                                        updateAccountMeta={updateAccountMeta}
                                                        removeAccountMetaFromTeamMember={removeAccountMetaFromTeamMember}
                                                        userAccountNames={userAccountNames}
                                                        isSelf={isSelf}
                                                    />
                                                );
                                            })}
                                        </TeamMembersCardContainer>
                                    </div>
                                )}
                                {!selectedTeamMember && (
                                    <>
                                        <TeamMemberDetailsSkeleton />
                                        <TeamMemberDetailsSkeleton />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TeamMemberAccountDetails;

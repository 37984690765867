import React, { useState } from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import GoogleIcon from "../../google-icon";
import Tooltip from "../../tooltip";

interface Props {
    text: string;
    children?: React.ReactNode;
    copyIconSize?: "xs" | "sm" | "md" | "lg";
    withCopyIcon?: boolean;
    onClickedText?: string;
    copyIconClassName?: string;
}

function OldLencoClipboard(props: Props): JSX.Element {
    const { withCopyIcon = true } = props;

    const [isCopied, setIsCopied] = useState<boolean>(false);

    const onCopyText = () => {
        const timeOut = setTimeout(() => setIsCopied(false), 1000);
        clearTimeout(timeOut);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
    };
    console.log(isCopied);

    return (
        <>
            <CopyToClipboard text={props.text} onCopy={onCopyText}>
                <div className="relative">
                    <div className="flex cursor-pointer flex-row items-center justify-start">
                        {props.children && props.children}
                        {withCopyIcon && (
                            <Tooltip
                                icon={
                                    <GoogleIcon
                                        icon="content_copy"
                                        className={props.copyIconClassName}
                                        size={props.copyIconSize || "md"}
                                        isOutlined
                                    />
                                }
                                bg="none"
                                size="fit"
                                message="Copy"
                            />
                        )}
                    </div>
                    <div
                        className={
                            `absolute -right-12 -top-4 rounded-lg pr-10`
                            // +
                            // `${isCopied ? "animate__animated animate__fadeOutUp animate__infinite infinite block" : "hidden"} `
                        }
                    >
                        <div className="rounded-lg bg-grey-backdrop px-2">
                            <p className="text-xs font-normal text-info-text">{props.onClickedText ? props.onClickedText : "Copied!"}</p>
                        </div>
                    </div>
                </div>
            </CopyToClipboard>
        </>
    );
}

export default OldLencoClipboard;

import React, { useEffect } from "react";
import {
    resetAllTerminalStageToSpecificTerminal,
    resetAllTerminalTransactionsData,
    setSpecificTerminalStage,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import { SpecificTerminalStageType } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import SpecificTerminalTransactions from "../SpecificTerminalTransactions";
import TabButton from "../../../../../components/TabButton";
import TerminalSettings from "./Settings";
import TerminalTransactionSummary from "../../Elements/Card/TerminalSummary/TerminalTransactionSummary";

function SpecificTerminal(): JSX.Element {
    const dispatch = useDispatch();

    const specificTerminalStage = useSelector((state: IRootState) => state.terminals.specificTerminalStage);

    const handleGoToSpecificTerminalSettings = () => {
        dispatch(setSpecificTerminalStage(SpecificTerminalStageType.SETTINGS));
    };

    const handleGoToSpecificTerminalTransactions = () => {
        dispatch(setSpecificTerminalStage(SpecificTerminalStageType.TERMINAL_TRANSACTIONS));
    };

    useEffect(() => {
        return () => {
            dispatch(resetAllTerminalStageToSpecificTerminal());
            dispatch(resetAllTerminalTransactionsData());
        };
    }, []);

    return (
        <div className="w-full space-y-4">
            <TerminalTransactionSummary />
            {specificTerminalStage === SpecificTerminalStageType.SETTINGS && (
                <div className="flex w-full items-center justify-between pb-2">
                    <TabButton
                        buttons={[
                            {
                                text: "Transactions",
                                onClick: handleGoToSpecificTerminalTransactions,
                                isActive: false,
                            },
                            {
                                text: "Settings",
                                onClick: handleGoToSpecificTerminalSettings,
                                isActive: true,
                            },
                        ]}
                    />
                </div>
            )}

            {specificTerminalStage === SpecificTerminalStageType.TERMINAL_TRANSACTIONS && <SpecificTerminalTransactions />}
            {specificTerminalStage === SpecificTerminalStageType.SETTINGS && <TerminalSettings />}
        </div>
    );
}

export default SpecificTerminal;

import { ReactComponent as EmptyList } from "../../../../../../assets/svg/EmptyList.svg";
import { Link } from "react-router-dom";
import SkeletonElement from "../../../../../../components/skeleton";
import Transaction from "../../../../../../models/transaction";
import { TransactionType } from "../../../../../../models/transaction.constants";
import TransactionsListCardItem from "./components/transactions-list-card-item";

interface Props {
    type: "inflow" | "payout" | "pending-transfer";
    isLoaded: boolean;
    accountId?: string;
    transactions: Transaction[];
}

function TransactionsListCard({ type, accountId, isLoaded, transactions }: Props): JSX.Element {
    return (
        <div className="flex min-h-72 flex-col rounded-xl bg-white p-4 shadow">
            {isLoaded ? (
                <>
                    {type === "pending-transfer" ? (
                        <div className="flex flex-row items-center justify-between font-medium text-black-secondary">
                            <p>Recent Pending Approval</p>
                            {/* {transactions.length > 4 && ( */}
                            {/* <Link to="/payments/pending"> */}

                            <Link to="/payments/pending">
                                <p className="text-blue">View all</p>
                            </Link>
                            {/* )} */}
                        </div>
                    ) : (
                        <div className="flex flex-row items-center justify-between font-medium text-black-secondary">
                            <p>Recent {type === "inflow" ? "Inflows" : "Payout"}</p>
                            <Link
                                to={{
                                    pathname: "/transactions",
                                    search: accountId
                                        ? type === "inflow"
                                            ? `?type=${TransactionType.CREDIT}&account=${accountId}`
                                            : `?type=${TransactionType.DEBIT}&account=${accountId}`
                                        : undefined,
                                }}
                            >
                                {/* <p className={`${transactions.length > 4 ? "block" : "hidden"} text-blue`}>View all</p> */}
                                <p className="text-blue">View all</p>
                            </Link>
                        </div>
                    )}
                </>
            ) : (
                <div className="mb-2 h-8 w-52">
                    <SkeletonElement box />{" "}
                </div>
            )}
            <div
                className={
                    "flex h-full flex-col items-center text-xs text-black [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary " +
                    `${transactions.length > 0 ? "justify-start pt-4" : "justify-center"}`
                }
            >
                {isLoaded ? (
                    transactions.length > 0 ? (
                        transactions
                            .slice(0, 4)
                            .map((_transaction, _index) => <TransactionsListCardItem key={_index} type={type} transaction={_transaction} />)
                    ) : (
                        <div className="flex h-full w-full flex-col items-center justify-center space-y-3 border-black-tertiary text-center text-xs text-black-secondary">
                            <EmptyList />
                            {type === "pending-transfer" ? (
                                <p>No transfers pending approval in the last 30 days</p>
                            ) : (
                                <p>No transactions in the last 30 days</p>
                            )}
                        </div>
                    )
                ) : (
                    <>
                        <div className="flex max-h-full w-full flex-col items-center justify-between space-y-3">
                            <div className="h-9 w-full">
                                <SkeletonElement box />
                            </div>
                            <div className="h-9 w-full">
                                <SkeletonElement box />
                            </div>
                            <div className="h-9 w-full">
                                <SkeletonElement box />
                            </div>
                            <div className="h-9 w-full">
                                <SkeletonElement box />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default TransactionsListCard;

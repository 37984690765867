import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaPayoutsData,
    setIsExportZambiaPayoutsTrayOpen,
    setIsZambiaPayoutsDetailCardOpen,
    setSelectedZambiaPayoutsDetails,
    setSelectedZambiaPayoutsFilterStateInstant,
} from "../../../../redux/zambia/payouts/zambiaPayoutsSlice";

import ButtonComp from "../../../../components/button/ButtonComp";
import ExportZambiaPayoutsTray from "../Components/Payouts/Tray/ExportZambiaPayoutsTray";
import GoogleIcon from "../../../../components/google-icon";
import PageLayout from "../../../../components/layouts/page-layout";
import { SubTextType } from "../../../../components/Table/Type/SubTextType";
import Table from "../../../../components/Table";
import { TableColumnType } from "../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../components/Table/Type/TextType";
import ZambiaLencoPayPayoutsTray from "../Components/Payouts/Tray/ZambiaLencoPayPayoutsTray";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaPayouts from "../hookss/payoutss/useZambiaPayouts";

export type ZambiaCollectionSettlementsParams = {
    query?: string;
};

export const PAYOUT_TRANSACTION_DATATYPE = "PAYOUT_TRANSACTION_DATATYPE";
const allPayoutsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Payout Account", pos: 2, headType: TableColumnType.TEXT },
    { text: "Recipient", pos: 3, headType: TableColumnType.TEXT },
    { text: "Payout Amount", pos: 4, headType: TableColumnType.AMOUNT },
];

function ZambiaPayouts(): JSX.Element {
    const { query } = useParams<ZambiaCollectionSettlementsParams>();
    const dispatch = useDispatch();
    const {
        total,
        offset,
        groupSize,
        filterOption,
        zambiaPayoutsList,
        selectedFilterCount,
        isZambiaPayoutsPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaPayouts,
    } = useZambiaPayouts();

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaPayoutsTrayOpen(true));
    }, []);

    useEffect(() => {
        if (query) {
            dispatch(setSelectedZambiaPayoutsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaPayouts({ offset: 0, query: query || "" });
    }, [query]);

    useEffect(() => {
        return () => {
            dispatch(resetAllZambiaPayoutsData());
        };
    }, []);

    return (
        <>
            <ExportZambiaPayoutsTray />
            <ZambiaLencoPayPayoutsTray />

            <PageLayout pageTitle="Payouts" subTitle="List of your payouts" isFullPageScroll>
                <Table
                    //filter
                    filterOptions={filterOption}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    totalSelectedItems={selectedFilterCount}
                    //pagination
                    total={total}
                    offset={offset}
                    groupSize={groupSize}
                    paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                    isPaginateLoading={isZambiaPayoutsPaginationLoading}
                    //table
                    isSticky
                    heads={allPayoutsHeader}
                    dataType={PAYOUT_TRANSACTION_DATATYPE}
                    isLoading={!!isZambiaPayoutsPaginationLoading}
                    rows={
                        zambiaPayoutsList?.map((obj) => ({
                            onRecordClick: () => {
                                dispatch(setIsZambiaPayoutsDetailCardOpen(true));
                                dispatch(setSelectedZambiaPayoutsDetails(obj.id));
                            },
                            record: [
                                {
                                    key: "Date",
                                    text: obj?.singleDatetime,
                                    textType: TextType.DATE,
                                    subText: obj?.singleDatetime,
                                    subTextType: SubTextType.TIME,
                                },
                                {
                                    key: "Payout Account",
                                    text: obj?.origination?.account?.accountName || "-",
                                },
                                {
                                    key: "Recipient",
                                    text: obj?.destination?.account?.bankAccount?.accountName || "-",
                                },
                                {
                                    key: "Payout Amount",
                                    text: obj?.amount,
                                    textType: TextType.BALANCE,
                                    currency: obj?.currency || undefined,
                                },
                            ],
                        })) || []
                    }
                    extraButton={
                        <ButtonComp size="md" paddingSize="xs" color="grey" buttonType="secondary" func={onExport}>
                            <GoogleIcon icon="download" size="sm" className="-ml-1 text-black-tertiary" />
                            <span className="ml-2 text-sm font-normal text-black-tertiary">Export</span>
                        </ButtonComp>
                    }
                />
            </PageLayout>
        </>
    );
}

export default ZambiaPayouts;

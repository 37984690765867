import {
    GrantUserLencoPayAccessRequest,
    SignleUserLencoPayAccessRequest as SingleUserLencoPayAccessRequest,
} from "../../Services/DefaultSetup/lencoPayDefaultSetupApi.types";
import {
    addLencoPayUserManagerAccess,
    addLencoPayUserViewAccess,
    removeLencoPaySingleUserManageAccess,
    removeLencoPaySingleUserViewerAccess,
} from "../../Services/DefaultSetup/lencoPayDefaultSetupApi";
import {
    addManagersWithCurrentViewersList,
    addViewersWithCurrentManagersList,
    updateManagerAccessList,
    updateViewerAccessList,
} from "../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { useCallback, useState } from "react";

import { AppToastType } from "../../../../../redux/app-toast/app-toast-slice.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

const useLencoPayAccess = () => {
    const dispatch = useDispatch();
    const [AddManagerAccessError, setAddManagerAccessError] = useState<string | null>(null);
    const [isAddManagerAccessDone, setIsAddManagerAccessDone] = useState<boolean>(false);
    const [isAddManagerAccessLoading, setIsAddManagerAccessLoading] = useState<boolean>(false);
    const [AddViewerAccessError, setAddViewerAccessError] = useState<string | null>(null);
    const [isAddViewerAccessDone, setIsAddViewerAccessDone] = useState<boolean>(false);
    const [isAddViewerAccessLoading, setIsAddViewerAccessLoading] = useState<boolean>(false);
    const [removeViewerAccessError, setRemoveViewerAccessError] = useState<string | null>(null);
    const [isRemoveViewerAccessDone, setIsRemoveViewerAccessDone] = useState<boolean>(false);
    const [isRemoveViewerAccessLoading, setIsRemoveViewerAccessLoading] = useState<boolean>(false);
    const [removeManageAccessError, setRemoveManageAccessError] = useState<string | null>(null);
    const [isRemoveManageAccessDone, setIsRemoveManageAccessDone] = useState<boolean>(false);
    const [isRemoveManageAccessLoading, setIsRemoveManageAccessLoading] = useState<boolean>(false);

    const handleAddManagerAccess = useCallback(
        async (_data: GrantUserLencoPayAccessRequest) => {
            try {
                setIsAddManagerAccessDone(false);
                setAddManagerAccessError(null);
                setIsAddManagerAccessLoading(true);
                const res = await addLencoPayUserManagerAccess(_data);
                setIsAddManagerAccessDone(true);
                dispatch(updateManagerAccessList(res.manageAccess || []));
                dispatch(addManagersWithCurrentViewersList(res.manageAccess));
                // dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted Manager Access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddManagerAccessError(errorMessage);
            } finally {
                setIsAddManagerAccessLoading(false);
            }
        },
        [dispatch]
    );

    const handleAddViewerAccess = useCallback(
        async (_data: GrantUserLencoPayAccessRequest) => {
            try {
                setIsAddViewerAccessDone(false);
                setAddViewerAccessError(null);
                setIsAddViewerAccessLoading(true);
                const res = await addLencoPayUserViewAccess(_data);
                setIsAddViewerAccessDone(true);
                dispatch(updateViewerAccessList(res?.viewAccess || []));
                dispatch(addViewersWithCurrentManagersList(res.viewAccess));
                dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted View Access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddViewerAccessError(errorMessage);
            } finally {
                setIsAddViewerAccessLoading(false);
            }
        },
        [dispatch]
    );

    const handleResetAccessData = useCallback(() => {
        setAddManagerAccessError("");
        setIsAddManagerAccessDone(false);
        setIsAddManagerAccessLoading(false);
        setAddViewerAccessError("");
        setIsAddViewerAccessDone(false);
        setIsAddViewerAccessLoading(false);
    }, []);

    const handleRemoveViewerAccess = useCallback(
        async (_data: SingleUserLencoPayAccessRequest) => {
            try {
                setIsRemoveViewerAccessDone(false);
                setRemoveViewerAccessError(null);
                setIsRemoveViewerAccessLoading(true);
                const res = await removeLencoPaySingleUserViewerAccess(_data);
                dispatch(updateViewerAccessList(res.viewAccess));
                dispatch(messageTrue({ message: `Team member's View Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveViewerAccessError(errorMessage);
            } finally {
                setIsRemoveViewerAccessLoading(false);
                setIsRemoveViewerAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleRemoveManageAccess = useCallback(
        async (_data: SingleUserLencoPayAccessRequest) => {
            try {
                setIsRemoveManageAccessDone(false);
                setRemoveManageAccessError(null);
                setIsRemoveManageAccessLoading(true);
                const res = await removeLencoPaySingleUserManageAccess(_data);
                dispatch(updateManagerAccessList(res.manageAccess));
                // dispatch(messageTrue({ message: `Team member's Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveManageAccessError(errorMessage);
            } finally {
                setIsRemoveManageAccessLoading(false);
                setIsRemoveManageAccessDone(true);
            }
        },
        [dispatch]
    );

    return {
        removeViewerAccessError,
        isRemoveViewerAccessDone,
        isRemoveViewerAccessLoading,
        removeManageAccessError,
        isRemoveManageAccessDone,
        isRemoveManageAccessLoading,
        AddManagerAccessError,
        isAddManagerAccessDone,
        isAddManagerAccessLoading,
        AddViewerAccessError,
        isAddViewerAccessDone,
        isAddViewerAccessLoading,
        handleAddManagerAccess,
        handleAddViewerAccess,
        handleResetAccessData,
        handleRemoveViewerAccess,
        handleRemoveManageAccess,
    };
};

export default useLencoPayAccess;

import { FilterDateType, FilterDateTypes } from "../filter.constant";
import { useEffect, useState } from "react";

import NewDropdownItem from "../../new-dropdown-item";
import RadioButton from "../../radio-button";
import moment from "moment";
import { useAppSelector } from "../../../redux/hooks";

interface Props {
    dataType?: string;
    filterDate?: FilterDateTypes;
    handleFilterDate?: () => void;
    handleOpenDateModal: () => void;
    handleSetFilterDate: (data: FilterDateType) => void;
}

function DateRows(props: Props): JSX.Element {
    const data = useAppSelector((state) => state.init.main?.companyDetails?.company?.accountCreatedOn || null);
    const isResetFilter = useAppSelector((state) => state.filter.isReset);

    const [options, setOptions] = useState<FilterDateType[]>([]);
    const [currentDate, setCurrentDate] = useState<string | Date>("");
    const [firstDate, setFirstDate] = useState<Date | null>(null);
    const [last30Days, setLast30Days] = useState<Date | string>("");
    const [firstDayOfMonth, setFirstDayOfMonth] = useState<Date | string>("");
    const [lastDayOfMonth, setLastDayOfMonth] = useState<Date | string>("");
    const [firstDayOfLastMonth, setFirstDayOfLastMonth] = useState<Date | string>("");
    const [lastDayOfLastMonth, setLastDayOfLastMonth] = useState<Date | string>("");
    const [firstDayOfYear, setFirstDayOfYear] = useState<Date | string>("");
    const [last7Days, setLast7Days] = useState<Date | string>("");

    const [selectedDateType, setSelectedDateType] = useState<FilterDateTypes | null>(FilterDateTypes.ALL_TIME);

    //set first day a filter was made
    useEffect(() => {
        setFirstDate(data);
    }, [data]);

    useEffect(() => {
        setSelectedDateType(props.filterDate || FilterDateTypes.ALL_TIME);
    }, [props.filterDate]);

    //Global rest for filters that are not controlled
    useEffect(() => {
        if (!isResetFilter || props.filterDate) return;
        setSelectedDateType(FilterDateTypes.ALL_TIME);
    }, [isResetFilter]);

    useEffect(() => {
        //current date
        const cDate = new Date();
        setCurrentDate(cDate.toISOString().slice(0, 10));
        // first 30 days
        const l30 = new Date();
        l30.setDate(l30.getDate() - 30);
        setLast30Days(l30.toISOString().slice(0, 10));
        //get last day of last month
        const lDLMonth = new Date();
        lDLMonth.setMonth(lDLMonth.getMonth(), 0);
        setLastDayOfLastMonth(lDLMonth.toISOString().slice(0, 10));
        //get first day of last month
        const fDLMonth = new Date();
        fDLMonth.setMonth(fDLMonth.getMonth() - 1, 1);
        setFirstDayOfLastMonth(fDLMonth.toISOString().slice(0, 10));
        //get first day of this month
        const fDMonth = new Date();
        fDMonth.setDate(1);
        setFirstDayOfMonth(fDMonth.toISOString().slice(0, 10));
        //get last day of this month
        const lDMonth = new Date();
        lDMonth.setMonth(lDMonth.getMonth() + 1, 0);
        setLastDayOfMonth(lDMonth.toISOString().slice(0, 10));
        //get first day of year
        const fDYear = new Date(new Date().getFullYear(), 0, 1, 1);
        setFirstDayOfYear(fDYear.toISOString().slice(0, 10));
        //get last 7 days
        const l7 = new Date();
        l7.setDate(l7.getDate() - 7);
        setLast7Days(l7.toISOString().slice(0, 10));
    }, []);

    //set options and selected option
    useEffect(() => {
        //set options
        setOptions([
            {
                name: FilterDateTypes.ALL_TIME,
                date: { begin: firstDate, end: currentDate },
            },
            {
                name: FilterDateTypes.TODAY,
                date: { begin: currentDate, end: currentDate },
            },
            {
                name: FilterDateTypes.LAST_7_DAYS,
                date: { begin: last7Days, end: currentDate },
            },
            {
                name: FilterDateTypes.LAST_30_DAYS,
                date: { begin: last30Days, end: currentDate },
            },
            {
                name: FilterDateTypes.CUSTOM,
                date: { begin: "", end: currentDate },
            },
        ]);
    }, [currentDate, firstDate, firstDayOfLastMonth, firstDayOfMonth, firstDayOfYear, last30Days, lastDayOfLastMonth, lastDayOfMonth]);

    moment.locale("de");

    return (
        <>
            <div
                className="h-fit border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary"
                data-type={props.dataType || ""}
            >
                {options &&
                    options.map((option, index) => {
                        return (
                            <NewDropdownItem
                                key={index}
                                data-type={props.dataType || ""}
                                onClick={() => {
                                    !props.filterDate && setSelectedDateType(option.name);
                                    option.name === FilterDateTypes.CUSTOM ? props.handleOpenDateModal() : props.handleSetFilterDate(option);
                                }}
                                size="lg"
                                isReadOnly={!!(selectedDateType !== FilterDateTypes.CUSTOM && selectedDateType === option.name)}
                            >
                                <div className="flex h-full w-full flex-row items-center justify-start text-sm" data-type={props.dataType}>
                                    <div className="pointer-events-none w-fit max-w-full" data-type={props.dataType}>
                                        <RadioButton
                                            id={option.name || ""}
                                            checked={!!selectedDateType && selectedDateType.includes(option.name)}
                                            readOnly
                                            size="sm"
                                            data-type={props.dataType || ""}
                                            text={
                                                <div className="ml-1">
                                                    <p
                                                        className={
                                                            "text-sm " +
                                                            `${selectedDateType !== FilterDateTypes.CUSTOM && selectedDateType === option.name ? "font-medium text-black" : "text-black-secondary"}`
                                                        }
                                                    >
                                                        {option.name}
                                                    </p>
                                                    <p
                                                        className={
                                                            "text-xs " +
                                                            `${selectedDateType !== FilterDateTypes.CUSTOM && selectedDateType === option.name ? "text-black-secondary" : " text-black-tertiary"}`
                                                        }
                                                    >
                                                        {option.date.begin === option.date.end
                                                            ? moment(option.date.begin).format("MMMM D")
                                                            : option.date.begin && option.date.end && option.name !== FilterDateTypes.ALL_TIME
                                                              ? `${moment(option.date.begin).format("MMMM D")} - ${moment(option.date.end).format("MMMM D")}`
                                                              : ""}
                                                    </p>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            </NewDropdownItem>
                        );
                    })}
            </div>
        </>
    );
}

export default DateRows;

import GoogleIcon from "../google-icon";
import { useState } from "react";

interface Data {
    header: string;
    body: string;
}

interface Props {
    items: Data[];
}

function Accordion({ items }: Props): JSX.Element {
    const [openAccordion, setOpenAccordion] = useState(0);

    return (
        <div className="w-full pb-6 [&>div:not(:last-child)]:mb-2">
            {items?.map((item, idx) => (
                <div
                    key={idx}
                    className="flex w-full cursor-pointer flex-col rounded-[10px] bg-grey-backdrop p-4"
                    onClick={() => setOpenAccordion(idx)}
                >
                    <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium text-black">{item.header}</h3>
                        {/* <ChevronDown className={`${idx === openAccordion && "rotate-180"}`} /> */}
                        <div className={`${idx === openAccordion ? "rotate-0" : "rotate-180"} ` + "!mr-[-6px] w-max duration-150"}>
                            <GoogleIcon icon="keyboard_arrow_up" />
                        </div>
                    </div>
                    <p
                        className={
                            "w-full cursor-text overflow-hidden text-xs font-normal text-black-secondary transition-all " +
                            `${openAccordion === idx ? "max-h-max pt-3" : "max-h-0"}`
                        }
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        // style={{ height: openAccordion === idx ? "max-content" : "0px" }}
                    >
                        {item.body}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default Accordion;

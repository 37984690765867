import CurrencyCode from "../../../../../../../components/currency-code";
import Transaction from "../../../../../../../models/transaction";
import { commaSeparator } from "../../../../../../../utils/formatNumber";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardCharges({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            {transaction && transaction.totalCharges > 0 && (
                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                    <span className="text-sm text-black-tertiary" data-type={dataType}>
                        Fee
                    </span>
                    <p className="text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                        <CurrencyCode data-type={dataType} />
                        {commaSeparator(transaction.totalCharges)}
                    </p>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardCharges;

import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../../../../../../models/card";
import { CardWalletType } from "../../../../../../../models/card.constants";
import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import { setFundCardRecipient } from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";

interface useFundCardRecipientInterface {
    suggestedCards: Card[] | null;
    handleSelectCard: (_cardId: string) => void;
}

function useFundCardRecipient(): useFundCardRecipientInterface {
    const dispatch = useDispatch();

    const originatingAccountId = useSelector((state: IRootState) => state.fundCard.fundCardDetails.originatingAccountId);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts) || null;
    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
    const cards = useSelector((state: IRootState) => state.init.main?.companyDetails.cards.filter((_) => _.isActive));

    useEffect(() => {
        if (!payTo || !cards) return;
        if (cards.length === 1) {
            handleSelectCard(cards[0].id);
        } else {
            const payToCard = cards.find((_card) => _card.id === payTo);
            if (!isNullOrUndefined(payToCard)) {
                handleSelectCard(payToCard.id);
            }
        }
    }, [payTo, cards]);

    const handleSelectCard = useCallback(
        (_cardId: string) => {
            if (!cards) return;
            const doesCardExist = cards.some((el) => el.id === _cardId);
            if (doesCardExist) {
                const existingCard = cards.find((el) => el.id === _cardId) as Card;
                dispatch(setFundCardRecipient(existingCard));
            }
        },
        [cards]
    );

    const getPayToOptions = useMemo((): Array<Card> => {
        if (!accounts || !cards) return [];
        const doesAccountExist = accounts.some((el) => el.id === originatingAccountId);
        if (doesAccountExist) {
            const userAccount = accounts.find((el) => el.id === originatingAccountId) as UserAccount;
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts)
                return [...userAccount.accountRestrictions.cards.filter((el) => el.walletType !== CardWalletType.LINKED_TO_ACCOUNT && el.isActive)];
        }
        return [...cards];
    }, [cards, accounts, originatingAccountId]);
    return {
        suggestedCards: getPayToOptions,
        handleSelectCard,
    };
}

export default useFundCardRecipient;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardMain from "../../../../../../models/cardMain";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../components/message-toast";
import { ReassignCardDTO } from "../../../Services/cardsApi.types";
import SingleSelectDropdown from "../../../../../../components/dropdown/single-select/single-select";
import { removeFromActiveOpenedReassignCardForms } from "../../../../../../redux/cards/slice/cardsSlice";
import useCardAssign from "../../../Hooks/State/useCardAssign";

interface IReassignCard {
    cardData: CardMain;
    assignedTo?: string;
    isSelectedCardDetailsOpen: boolean;
    handleBack: () => void;
}

function ReassignCard({ isSelectedCardDetailsOpen, cardData, handleBack }: IReassignCard): JSX.Element {
    const dispatch = useDispatch();

    const { onCloseEditAssignCardToForm, handleReassignCard, isIsReassignCardLoading } = useCardAssign();

    const { users } = useSelector((state: IRootState) => state.cards);
    const [assignDTO, setAssignDTO] = useState<ReassignCardDTO | null>(null);

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        divRef.current?.scrollIntoView();
        return () => {
            dispatch(removeFromActiveOpenedReassignCardForms(cardData.createRequest.id || ""));
        };
    }, []);

    return (
        <div className="mt-4 w-full space-y-8" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"} ref={divRef}>
            <div className="flex flex-col space-y-2">
                <h3 className="text-base font-medium leading-none text-black">Reassign Card</h3>
                <p className="text-sm text-black-tertiary">Select a member of your team</p>
            </div>
            <div className="flex w-full flex-col space-y-12">
                <div className="flex flex-col items-start space-y-4">
                    <SingleSelectDropdown
                        size="lg"
                        placeholder="Select Team Member"
                        options={users?.map((el) => {
                            return {
                                text: el.name,
                                subtext: el.isAdmin || el?.hasManageCardAccess ? "Initiator and Approver" : "Initiator",
                                value: el.id,
                            };
                        })}
                        onChange={(value) => {
                            setAssignDTO({
                                createRequestId: cardData?.createRequest?.id,
                                assignedToCorporateAccountMemberUid: value || "",
                            });
                        }}
                        isDisabled={users.length < 2}
                        active={true}
                    />
                    <MessageToast
                        message={
                            users.length < 2
                                ? "Add more users to your team under settings"
                                : "Your card will be restricted for 24 hours after reassigning a card"
                        }
                        type="info"
                        fullWidth
                    />
                </div>
                <div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4">
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="grey"
                        func={() => {
                            handleBack();
                            onCloseEditAssignCardToForm(cardData.createRequest.id);
                        }}
                        fullWidth
                        size="lg"
                    >
                        <span className="w-full text-center">Cancel</span>
                    </ButtonComp>
                    <ButtonComp
                        type="button"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        disable={!assignDTO?.createRequestId || !assignDTO.assignedToCorporateAccountMemberUid}
                        isLoading={isIsReassignCardLoading}
                        func={() => {
                            if (assignDTO) handleReassignCard(assignDTO);
                        }}
                        fullWidth
                        size="lg"
                    >
                        <span className="w-full text-center">Reassign Card</span>
                    </ButtonComp>
                </div>
            </div>
        </div>
    );
}
export default ReassignCard;

import "../../../Style/cardTransaction.css";

import React, { useEffect, useRef } from "react";
import { setTransactionCardBackTogglerIcon, showTransactionDetails } from "../../../../../../redux/transaction/slice/transactionSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { CARD_DETAILS_DATATYPE } from "../CardDetails";
import LencoSpinner from "../../../../../../components/spinner";
import { Link } from "react-router-dom";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import { ToastType } from "../../../../../../helpers/AppConstants";
import TransactionsListCardItem from "../../../../Home copy/Components/Cards/transactions-list-card/components/transactions-list-card-item";
import { setGlobalActiveDataType } from "../../../../../../redux/init/slice/initSlice";
import { useDispatch } from "react-redux";
import useRecentCardTransactions from "../../../Hooks/State/useRecentCardTransactions";

interface IRecentTransactions {
    cardId: string;
    isSelectedCardDetailsOpen: boolean;
}

function RecentTransactions({ cardId, isSelectedCardDetailsOpen }: IRecentTransactions): JSX.Element {
    const dispatch = useDispatch();

    const { recentCardTransactions, isRecentCardTransactionLoading, recentCardTransactionsError, handleReset, handleGetTransactions } =
        useRecentCardTransactions();

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (cardId) {
            handleGetTransactions({
                cardIds: [cardId],
            });
        }
        return () => {
            handleReset();
        };
    }, [cardId]);

    return (
        <div className="mt-4 w-full" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
            <div className="max-w-md" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                <div className="flex items-center justify-between py-3" ref={divRef} data-type="card">
                    <h3 className="text-base font-medium leading-none text-black" data-type="card">
                        Recent Transactions
                    </h3>
                </div>
            </div>
            <div className="mt-3 flex h-full w-full items-center justify-center 2xs:relative" data-type="card">
                {isRecentCardTransactionLoading && (
                    <div className="flex h-24 items-center justify-center" data-type="card">
                        <LencoSpinner />
                    </div>
                )}
                {recentCardTransactionsError && !isRecentCardTransactionLoading && (
                    <div className="flex h-full w-full items-center justify-center" data-type="card">
                        <div className="w-max" data-type="card">
                            <MessageToasts toastMessage={recentCardTransactionsError || ""} toastType={ToastType.ERROR} data-type="card" />
                        </div>
                    </div>
                )}
                {!isRecentCardTransactionLoading && recentCardTransactions && !recentCardTransactionsError && (
                    <>
                        {recentCardTransactions.transactions.length > 0 ? (
                            <div className="w-full" data-type="card">
                                <div className="flex h-full flex-col items-center justify-start text-xs text-black [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                                    {recentCardTransactions.transactions.slice(0, 5).map((_transaction, index) => (
                                        <TransactionsListCardItem
                                            transaction={_transaction}
                                            type={_transaction.isDebit() ? "payout" : _transaction.isCredit() ? "inflow" : "pending-transfer"}
                                            onClick={() => {
                                                dispatch(setGlobalActiveDataType(CARD_DETAILS_DATATYPE));
                                                dispatch(setTransactionCardBackTogglerIcon());
                                                dispatch(showTransactionDetails(_transaction.id));
                                            }}
                                            key={index}
                                        />
                                    ))}
                                </div>
                                <div className="flex w-full justify-center pt-8" data-type="card">
                                    <Link to={`/transactions/cards/${cardId}`} data-type="card">
                                        <ButtonComp color="blue" size="sm" buttonType="tertiary" dataType="card">
                                            View All Transactions
                                        </ButtonComp>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="pb-2 text-center" data-type="card">
                                <p className="text text-sm text-black-tertiary" data-type="card">
                                    Your recent transactions will show here
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
export default RecentTransactions;

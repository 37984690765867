import AvatarDetails from "../../../../../../../components/avatar-details";
import Money from "../../../../../../../components/money";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import { formatDate } from "../../../../../../../utils/formatDate";
import { showTransactionDetails } from "../../../../../../../redux/transaction/slice/transactionSlice";
import { useDispatch } from "react-redux";

interface Props {
    type: "inflow" | "payout" | "pending-transfer";
    transaction: Transaction;
    onClick?: () => void;
}

function TransactionsListCardItem(props: Props) {
    const dispatch = useDispatch();

    return (
        <>
            <div
                className={`flex w-full cursor-pointer flex-row items-center justify-between space-x-2.5 py-3 duration-150 hover:bg-grey-backdrop`}
                data-type="transaction"
                onClick={props.onClick ? props.onClick : () => dispatch(showTransactionDetails(props.transaction.id))}
            >
                <AvatarDetails
                    icon={props.transaction.isFailed || props.transaction.isCancelled ? "cancel" : props.type === "inflow" ? "inflow" : "outflow"}
                    size="xs"
                    title={
                        props.transaction.source
                        // props.type === "inflow"
                        //     ? (props.transaction.origination &&
                        //           props.transaction.origination.userAccount &&
                        //           props.transaction.origination.userAccount.isMain &&
                        //           "MAIN") ||
                        //           (props.transaction.origination &&
                        //               props.transaction.origination.userAccount &&
                        //               !props.transaction.origination.userAccount.isMain &&
                        //               props.transaction.origination.userAccount.subAccountShortName) ||
                        //           (props.transaction.origination &&
                        //               props.transaction.origination.customerAccount &&
                        //               props.transaction.origination.customerAccount.bankAccount &&
                        //               props.transaction.origination.customerAccount.bankAccount.accountName) ||
                        //           (props.transaction.origination && props.transaction.origination.card
                        // && props.transaction.origination.card.name) ||
                        //           (props.transaction.origination && props.transaction.origination.narration) ||
                        //           ""
                        //     : (props.transaction.destination &&
                        //           props.transaction.destination.userAccount &&
                        //           props.transaction.destination.userAccount.isMain &&
                        //           "MAIN") ||
                        //           (props.transaction.destination &&
                        //               props.transaction.destination.userAccount &&
                        //               !props.transaction.destination.userAccount.isMain &&
                        //               props.transaction.destination.userAccount.subAccountShortName) ||
                        //           (props.transaction.destination &&
                        //               props.transaction.destination.customerAccount &&
                        //               props.transaction.destination.customerAccount.bankAccount &&
                        //               props.transaction.destination.customerAccount.bankAccount.accountName) ||
                        //           (props.transaction.destination && props.transaction.destination.card
                        // && props.transaction.destination.card.name) ||
                        //           (props.transaction.destination && props.transaction.destination.narration) ||
                        //           ""
                    }
                    dataType="transaction"
                    subtitle={props.transaction.createdOn ? formatDate(props.transaction.createdOn) : ""}
                    fullWidth
                />
                <div className="w-full text-right" data-type="transaction">
                    <p className={`text-sm font-medium ${props.type === "inflow" ? "text-success" : "text-black"} `} data-type="transaction">
                        <Money
                            amount={props.transaction.amount}
                            positive={props.type === "inflow"}
                            negative={props.type !== "inflow"}
                            currency={props.transaction.currency}
                            dataType="transaction"
                        />
                    </p>
                    {props.type === "pending-transfer" && (
                        <p className="text-2xs font-medium text-warning" data-type="transaction">
                            Pending Approval
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default TransactionsListCardItem;

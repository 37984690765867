import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import FundCardHeaders from "./FundCardHeaders";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import MoneyInput from "../../../../../../components/inputs/money-input";
import NewAllAccountDropdown from "../../../../../../components/all-account-dropdown";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { resetAllFundCardData } from "../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import { useCallback } from "react";
import useFundCardAmount from "../../../hooks/state/send-money/FundCard/useFundCardAmount";
import useFundCardPayFrom from "../../../hooks/state/send-money/FundCard/useFundCardPayFrom";
import useFundCardPurpose from "../../../hooks/state/send-money/FundCard/useFundCardPurpose";
import useFundCardRecipient from "../../../hooks/state/send-money/FundCard/useFundCardRecipient";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";
import { useNavigate } from "react-router";

function PaymentDetails(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
    const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
    const fundCardDetails = useSelector((state: IRootState) => state.fundCard.fundCardDetails);
    const selectedUserAccount = useSelector((state: IRootState) => state.fundCard.selectedUserAccount);
    const recipientCardDetails = useSelector((state: IRootState) => state.fundCard.recipientCardDetails);

    const { handleAmountChange } = useFundCardAmount();
    const { handlePurposeChange } = useFundCardPurpose();
    const { suggestedCards, handleSelectCard } = useFundCardRecipient();
    const { selectedAccountId, handleSelectAccount, accounts } = useFundCardPayFrom();

    const handleBack = useCallback(() => {
        if (pageFrom && payTo) {
            navigate(
                {
                    pathname: pageFrom,
                },
                {
                    state: {
                        cardId: payTo,
                    },
                }
            );
        }
        dispatch(setPaymentStage(PaymentStageType.INITIAL));
        dispatch(resetAllFundCardData());
    }, [pageFrom, payTo]);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <FundCardHeaders />

                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Select Account</p>
                        <NewUserAccountDropdown
                            value={selectedAccountId || ""}
                            options={accounts || null}
                            onClick={handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                            dropdownSize="xl"
                            showBalance
                            initiatorCanSelect
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                        <MoneyInput
                            label="Amount"
                            value={fundCardDetails.amount || ""}
                            onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                            inputSize="xl"
                        />
                        <Input placeholder="Purpose" value={fundCardDetails.purpose} onChange={handlePurposeChange} inputSize="xl" fullWidth />
                    </div>

                    {useMoneyToNumber(fundCardDetails.amount) > (selectedUserAccount?.balance as number) && (
                        <MessageToast
                            message={
                                <div>
                                    You do not have enough funds. Your account balance is <Money amount={selectedUserAccount?.balance || 0} />
                                </div>
                            }
                            type="error"
                            fullWidth
                        />
                    )}
                    {selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
                        <MessageToast message="You can only send to specific recipients" type="info" fullWidth />
                    )}

                    {selectedUserAccount?.accountRestrictions.cannotSendMoney ? (
                        <MessageToast message="You can not send money from this account" type="error" fullWidth />
                    ) : (
                        <div className="flex w-full flex-col space-y-4">
                            <p className="text-sm font-medium text-black-secondary">Pay To</p>
                            <NewAllAccountDropdown
                                value={recipientCardDetails || null}
                                options={suggestedCards || null}
                                onClick={handleSelectCard}
                                isCard
                                showBalance
                            />
                        </div>
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        size="xl"
                        color="black"
                        buttonType="primary"
                        disable={
                            !!(
                                !selectedAccountId ||
                                !fundCardDetails?.purpose ||
                                !recipientCardDetails?.name ||
                                !useMoneyToNumber(fundCardDetails.amount) ||
                                useMoneyToNumber(fundCardDetails.amount) > (selectedUserAccount?.balance as number)
                            )
                        }
                        func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;

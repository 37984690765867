import { FilterDropdownItem, FilterValueType } from "../../Table/table-filter.types";

import ButtonComp from "../../button/ButtonComp";
import GoogleIcon from "../../google-icon";
import NewDropdownHead from "../../new-dropdown";
import NewDropdownItem from "../../new-dropdown-item";
import React from "react";
import titleCase from "../../../hooks/titleCase";

interface Props<T extends FilterValueType> {
    title: string;
    values: Array<T>;
    options?: Array<FilterDropdownItem<T>>;
    onCancel: () => void;
    onIndividualCancel?: (_value: T) => void;
}

function GenericFilterTag<T extends FilterValueType>(props: Props<T>): JSX.Element {
    return (
        <>
            {props.values.length > 0 ? (
                <div className="w-max pr-2 pt-2">
                    {props.values.length === 1 && (
                        <div className="flex h-10 max-w-max items-center justify-center space-x-1 rounded-lg bg-grey px-3">
                            <span className="select-none text-xs text-black">
                                {props.title}
                                {" - "}
                                {titleCase(
                                    (props.options
                                        ? props.options.find((_option) => _option.value === props.values[0])?.text
                                        : typeof props.values[0] === "string"
                                          ? props.values[0]
                                          : "") || ""
                                )}
                            </span>
                            <ButtonComp
                                size="md"
                                color="black"
                                className="-mr-2 !p-1 text-black hover:text-error-tertiary"
                                buttonType="tertiary"
                                onClick={() => props.onCancel()}
                            >
                                <GoogleIcon icon="close" size="sm" />
                            </ButtonComp>
                        </div>
                    )}
                    {props.values.length > 1 && (
                        <NewDropdownHead
                            content={
                                <div className="flex items-center justify-start space-x-2">
                                    <span className="text-sm !font-normal">{props.title}</span>
                                    <span className="p-1 text-black duration-150 hover:text-error-tertiary" onClick={() => props.onCancel()}>
                                        <GoogleIcon icon="close" size="sm" />
                                    </span>
                                </div>
                            }
                            size="md"
                            color="dark-grey"
                            buttonType="flat"
                            paddingSize="xs"
                            dropdownSize="sm"
                            dropdownWidth="max"
                            optionsLength={props.values.length}
                        >
                            {props.values.map((_accountId, index) => (
                                <NewDropdownItem size="md" padding="md" key={index}>
                                    <div className="flex w-full flex-row items-center justify-between">
                                        <span className="text-left text-sm text-black-tertiary">
                                            {titleCase((props.options && props.options.find((_option) => _option.value === _accountId)?.text) || "")}
                                        </span>
                                        <button
                                            className="-mr-2 cursor-pointer p-2 outline-none duration-150 hover:text-error-tertiary focus:outline-none"
                                            onClick={() => {
                                                props.onIndividualCancel && props.onIndividualCancel(_accountId);
                                            }}
                                        >
                                            <GoogleIcon icon="close" size="sm" />
                                        </button>
                                    </div>
                                </NewDropdownItem>
                            ))}
                        </NewDropdownHead>
                    )}
                </div>
            ) : null}
        </>
    );
}

export default GenericFilterTag;

import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import FormInput from "../../../../../components/inputs/FormInput";
import MessageToast from "../../../../../components/message-toast";
// import LencoInput from "../../../../../components/inputs/lenco-input";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Transaction from "../../../../../models/transaction";
import useTransactionDetailsTagRecipient from "../Cards/TransactionDetailCard/Hooks/useTransactionDetailsTagRecipient";

interface TagTransactionRecipientInterface {
    showModal: boolean;
    transaction: Transaction;
    toggler(): void;
}

function TagTransactionRecipient(props: TagTransactionRecipientInterface): JSX.Element {
    const { isSent, errorMessage, isSubmitting, handleTagRecipient } = useTransactionDetailsTagRecipient();

    const initialFormState: {
        recipient: string;
    } = {
        recipient: "",
    };

    const formikRef = useRef<FormikProps<{
        recipient: string;
    }> | null>(null);

    const formValidation = Yup.object().shape({
        recipient: Yup.string().required("Required"),
    });

    useEffect(() => {
        if (props.showModal) return;
        formikRef.current?.setValues({ recipient: "" });
    }, [props.showModal]);

    useEffect(() => {
        if (!isSent) return;
        props.toggler();
    }, [isSent]);

    return (
        <>
            <Modal size="md" active={props.showModal} toggler={() => props.toggler()}>
                <ModalHeader onClose={() => props.toggler()} subTitle="The payment receipt will be sent to the recipient">
                    Share Receipt
                </ModalHeader>
                <Formik
                    initialValues={initialFormState}
                    innerRef={formikRef}
                    validationSchema={formValidation}
                    onSubmit={(values) => void handleTagRecipient(props.transaction, values.recipient)}
                    enableReinitialize={true}
                    validateOnChange
                    validateOnMount
                >
                    {({ isValid }) => {
                        return (
                            <Form className="w-full">
                                <ModalBody>
                                    {errorMessage && (
                                        <div className="flex w-full items-center justify-center pb-4">
                                            <MessageToast message={errorMessage} type="error" />
                                        </div>
                                    )}
                                    <FormInput type="text" label="Enter Email or Phone number" name="recipient" />
                                </ModalBody>
                                <ModalFooter>
                                    <ButtonComp size="lg" color="grey" buttonType="secondary" func={() => props.toggler()}>
                                        Cancel
                                    </ButtonComp>
                                    <ButtonComp
                                        size="lg"
                                        type="submit"
                                        color="black"
                                        buttonType="primary"
                                        isLoading={isSubmitting}
                                        disable={!isValid}
                                    >
                                        Share Receipt
                                    </ButtonComp>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default TagTransactionRecipient;

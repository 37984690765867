import {
    TransactionFilterTypes,
    transactionStatusList,
    transactionStatusText,
    transactionTypesList,
    transactionTypesText,
} from "../../../../../redux/transactions/slice/transactionsSlice.types";
import {
    resetTransactionDateFilterState,
    setIsExportZambiaTransactionsTrayOpen,
    setUpdatedSelectedTransactionFilter,
} from "../../../../../redux/transactions/slice/transactionsSlice";

import ButtonComp from "../../../../../components/button/ButtonComp";
import DateTag from "../../../../../components/filter/Tags/DateTag";
import ExportDropdown from "../DropDowns/ExportDropdown";
import FilterDropdown from "../DropDowns/FilterDropDown";
import GenericFilterTag from "../../../../../components/filter/Tags/GenericTag";
import GoogleIcon from "../../../../../components/google-icon";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface Props {
    handleFilteredDetails: (_data: TransactionFilterTypes) => void;
    handleClearAll: () => void;
}

function TransactionFilterSection(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const cards = useAppSelector((state) => state.init.main?.companyDetails.cards || []);
    const account = useAppSelector((state) => state.init.main?.companyDetails?.accounts);
    const categories = useAppSelector((state) => state.init.main?.companyDetails.transactionCategories || []);
    const isZambia = useAppSelector((state) => state.init.main?.companyDetails?.company.isZambia);
    const userGroupsData = useAppSelector((state) => state.init.main?.companyDetails.userGroups || []);
    const selectedTransactionDate = useAppSelector((state) => state.transactions.selectedTransactionDate);
    const selectedTransactionFilterState = useAppSelector((state) => state.transactions.selectedTransactionFilterState);

    const userGroups = userGroupsData.filter((_userGroups) => !_userGroups.isDeleted) || [];

    return (
        <>
            <div className="-mt-2 flex w-full flex-col md:flex-row md:justify-between">
                {/* Filter dropdown */}
                <div className="flex flex-col flex-wrap 4xs:flex-row">
                    <div className="w-max pr-2 pt-2">
                        <FilterDropdown handleFilteredDetails={props.handleFilteredDetails} handleClearAll={props.handleClearAll} />
                    </div>
                    <div className="relative">
                        <div className="flex w-full flex-row flex-wrap">
                            {selectedTransactionFilterState.query.length > 0 && (
                                <GenericFilterTag
                                    title="Search"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ query: "" }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, query: "" });
                                    }}
                                    values={[selectedTransactionFilterState.query]}
                                />
                            )}
                            {selectedTransactionFilterState.date &&
                                selectedTransactionFilterState.date.begin &&
                                selectedTransactionFilterState.date.end && (
                                    <DateTag
                                        endDate={selectedTransactionFilterState.date.end || ""}
                                        startDate={selectedTransactionFilterState.date.begin || ""}
                                        handleCancelSelection={() => {
                                            const updatedFilter = { ...selectedTransactionFilterState, date: { begin: "", end: "" } };
                                            dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                            dispatch(resetTransactionDateFilterState());
                                            props.handleFilteredDetails(updatedFilter);
                                        }}
                                        selectedFilterDate={selectedTransactionDate}
                                    />
                                )}
                            {(selectedTransactionFilterState.userAccountIds || []).length > 0 && (
                                <GenericFilterTag
                                    title="Account"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ userAccountIds: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, userAccountIds: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            userAccountIds: selectedTransactionFilterState?.userAccountIds?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={account?.map((_userAccount) => ({
                                        text: _userAccount.lencoNameMin,
                                        value: _userAccount.id,
                                    }))}
                                    values={selectedTransactionFilterState.userAccountIds || []}
                                />
                            )}
                            {(selectedTransactionFilterState.userGroupIds || []).length > 0 && (
                                <GenericFilterTag
                                    title="Recipients"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ userGroupIds: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, userGroupIds: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            userGroupIds: selectedTransactionFilterState?.userGroupIds?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={userGroups.map((_userAccount) => ({
                                        text: _userAccount.name,
                                        value: _userAccount.id,
                                    }))}
                                    values={selectedTransactionFilterState.userGroupIds || []}
                                />
                            )}
                            {(selectedTransactionFilterState.categoryIds || []).length > 0 && (
                                <GenericFilterTag
                                    title="Categories"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ categoryIds: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, categoryIds: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            categoryIds: selectedTransactionFilterState?.categoryIds?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={categories.map((_userAccount) => ({
                                        text: _userAccount.name,
                                        value: _userAccount.id,
                                    }))}
                                    values={selectedTransactionFilterState.categoryIds || []}
                                />
                            )}
                            {(selectedTransactionFilterState.transactionTypes || []).length > 0 && (
                                <GenericFilterTag
                                    title="Type"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ transactionTypes: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, transactionTypes: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            transactionTypes: selectedTransactionFilterState?.transactionTypes?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={transactionTypesList.map((_) => ({
                                        text: transactionTypesText[_],
                                        value: _,
                                    }))}
                                    values={selectedTransactionFilterState.transactionTypes || []}
                                />
                            )}
                            {(selectedTransactionFilterState.transactionStatuses || []).length > 0 && (
                                <GenericFilterTag
                                    title="Status"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ transactionStatuses: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, transactionStatuses: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            transactionStatuses:
                                                selectedTransactionFilterState?.transactionStatuses?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={transactionStatusList.map((_) => ({
                                        text: transactionStatusText[_],
                                        value: _,
                                    }))}
                                    values={selectedTransactionFilterState.transactionStatuses || []}
                                />
                            )}
                            {(selectedTransactionFilterState.cardIds || []).length > 0 && (
                                <GenericFilterTag
                                    title="Cards"
                                    onCancel={() => {
                                        dispatch(setUpdatedSelectedTransactionFilter({ cardIds: [] }));
                                        props.handleFilteredDetails({ ...selectedTransactionFilterState, cardIds: [] });
                                    }}
                                    onIndividualCancel={(_value) => {
                                        const updatedFilter = {
                                            ...selectedTransactionFilterState,
                                            cardIds: selectedTransactionFilterState?.cardIds?.filter((_el) => _el !== _value) || [],
                                        };
                                        dispatch(setUpdatedSelectedTransactionFilter(updatedFilter));
                                        props.handleFilteredDetails(updatedFilter);
                                    }}
                                    options={cards.map((_userAccount) => ({
                                        text: _userAccount.name,
                                        value: _userAccount.id,
                                    }))}
                                    values={selectedTransactionFilterState.cardIds || []}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {isZambia ? (
                    <div className="relative h-fit max-w-max pt-2">
                        <ButtonComp
                            size="md"
                            color="grey"
                            buttonType="secondary"
                            paddingSize="xs"
                            func={() => dispatch(setIsExportZambiaTransactionsTrayOpen(true))}
                        >
                            <GoogleIcon icon="download" size="sm" className="-ml-1 text-black-tertiary" />
                            <span className="ml-2 text-sm font-normal text-black-tertiary">Export</span>
                        </ButtonComp>
                    </div>
                ) : (
                    <div className="relative h-fit max-w-max cursor-pointer pt-2">
                        <ExportDropdown />
                    </div>
                )}
            </div>
        </>
    );
}

export default TransactionFilterSection;

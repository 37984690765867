import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaCollectionsSettlementsData,
    setIsExportZambiaCollectionsSettlementsTrayOpen,
    setIsZambiaCollectionsSettlementsDetailCardOpen,
    setSelectedZambiaCollectionsSettlementsDetails,
    setSelectedZambiaCollectionsSettlementsFilterStateInstant,
} from "../../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CollectionSettlementTray from "./Tray/CollectionSettlementTray";
import ExportZambiaCollectionsSettlementsTray from "./Tray/ExportZambiaCollectionsSettlementsTray";
import GoogleIcon from "../../../../../../components/google-icon";
// import FilterSection from "./FilterSection/FilterSection";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { ZambiaCollectionsStageType } from "../../../../../../redux/zambia/collections/collectionsSlice.types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaCollectionsSettlements from "../../../hookss/Collections/useZambiaCollectionsSettlement";

export const COLLECTION_SETTLEMENT_DATATYPE = "zambia-collections-settlement";

const allCollectionSettlementsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Channel", pos: 3, headType: TableColumnType.TEXT },
    { text: "Channel Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Transaction Amount", pos: 5, headType: TableColumnType.AMOUNT },
    { text: "Settlement Amount", pos: 6, headType: TableColumnType.AMOUNT },
];

export type ZambiaCollectionSettlementsParams = {
    type: string;
    query: string;
};

function ZambiaCollectionSettlements(): JSX.Element {
    const dispatch = useDispatch();
    const { type, query } = useParams<ZambiaCollectionSettlementsParams>();

    const {
        total,
        offset,
        groupSize,
        filterOption,
        selectedFilterCount,
        zambiaCollectionsSettlementsList,
        isZambiaCollectionsSettlementsPaginationLoading,

        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsSettlements,
    } = useZambiaCollectionsSettlements();

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaCollectionsSettlementsTrayOpen(true));
    }, []);

    useEffect(() => {
        if (!type || type !== ZambiaCollectionsStageType.SETTLEMENTS) return;

        if (query) {
            dispatch(setSelectedZambiaCollectionsSettlementsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaCollectionsSettlements({ offset: 0, query: query || "" });
    }, [query]);

    useEffect(() => {
        void handleFilteredZambiaCollectionsSettlements({ offset: 0, query: "" });
        return () => {
            dispatch(resetAllZambiaCollectionsSettlementsData());
        };
    }, []);

    return (
        <>
            <CollectionSettlementTray />
            <ExportZambiaCollectionsSettlementsTray />

            <div className="relative mt-4 flex h-full w-full flex-col items-start justify-start">
                <Table
                    //filter
                    filterOptions={filterOption}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    totalSelectedItems={selectedFilterCount}
                    //pagination
                    total={total}
                    offset={offset}
                    groupSize={groupSize}
                    paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                    isPaginateLoading={isZambiaCollectionsSettlementsPaginationLoading}
                    //table
                    isSticky
                    heads={allCollectionSettlementsHeader}
                    dataType={COLLECTION_SETTLEMENT_DATATYPE}
                    isLoading={!!isZambiaCollectionsSettlementsPaginationLoading}
                    rows={
                        zambiaCollectionsSettlementsList?.map((obj) => ({
                            onRecordClick: () => {
                                dispatch(setIsZambiaCollectionsSettlementsDetailCardOpen(true));
                                dispatch(setSelectedZambiaCollectionsSettlementsDetails(obj.id));
                            },
                            record: [
                                {
                                    key: "Date",
                                    text: "45",
                                    textType: TextType.DATE,
                                    subText: obj?.createdAt,
                                    subTextType: SubTextType.TIME,
                                },
                                {
                                    key: "From",
                                    text: obj.mobileMoneyCollection?.debitAccountName ?? (obj.cardPaymentCollection?.cardDetails?.name || "-"),
                                },
                                {
                                    key: "Channel",
                                    text: obj?.channelText,
                                },
                                {
                                    key: "Channel Type",
                                    text: obj.mobileMoneyCollection?.serviceProviderText || obj?.cardPaymentCollection?.cardDetails?.cardType || "-",
                                },
                                {
                                    key: "Transaction Amount",
                                    text: obj.collectionRequest?.amount,
                                    textType: TextType.BALANCE,
                                    currency: obj.collectionRequest?.currency,
                                },
                                {
                                    key: "Settlement Amount",
                                    text: obj.settlementAmount,
                                    textType: TextType.BALANCE,
                                    currency: obj.currency,
                                },
                            ],
                        })) || []
                    }
                    extraButton={
                        <ButtonComp size="md" paddingSize="xs" color="grey" buttonType="secondary" func={onExport}>
                            <GoogleIcon icon="download" size="sm" className="-ml-1 text-black-tertiary" />
                            <span className="ml-2 text-sm font-normal text-black-tertiary">Export</span>
                        </ButtonComp>
                    }
                />
            </div>
        </>
    );
}

export default ZambiaCollectionSettlements;

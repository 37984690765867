import { BulkTransferHeader, BulkTransferRow } from "../../../hooks/state/send-money/payments.constants";
import {
    removeTransferDetailToTransferList,
    resetAllBulkTransferData,
    setBulkTransfersAmountTotal,
    setCanVerifyRecipientAccountDetails,
    setIsUpdatingTransferDetail,
    setNewTransferDetail,
    setTransferDetail,
} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddRecipients from "../../modals/AddRecipients";
import BulkPaymentHeaders from "./BulkPaymentHeaders";
// import BulkTransferDetailRow from "../../TableRows/BulkTransferDetailRow";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { ERROR } from "../../../../../../helpers/AppConstants";
import EditPaymentTray from "./components/edit-payment-tray";
// import { ReactComponent as EmptyX } from "../../../../../../assets/images/empty/empty-x.svg";
import { FileDrop } from "react-file-drop";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import LencoSpinner from "../../../../../../components/spinner";
import Money from "../../../../../../components/money";
import { PaymentStageType } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import Table from "../../../../../../components/Table";
import { TransferDetail } from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import UploadModal from "../../modals/UploadModal";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { setPaymentStage } from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useBulkTransferEditDetail from "../../../hooks/state/send-money/BulkTransfer/useBulkTransferEditDetail";
import useBulkTransferUploadModal from "../../../hooks/state/send-money/BulkTransfer/useBulkTransferUploadModal";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";

enum PaymentDetailStage {
    INITIAL = "initial",
    PAGE_SUMMARY = "page_summary",
    TRANSFER_DETAIL = "transfer_detail",
}

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();

    const { isEditDetailTrayActive, handleIsEditDetailTrayActive } = useBulkTransferEditDetail();

    const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
    const bulkTransfersAmountTotal = useSelector((state: IRootState) => state.bulkTransfer.bulkTransfersAmountTotal);

    const [isHover, setIsHover] = useState(false);
    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);
    const [isFromPageSummary, setIsFromPageSummary] = useState(false);
    const [paymentDetailStage, setPaymentDetailStage] = useState<PaymentDetailStage>(PaymentDetailStage.INITIAL);

    const { handleBulkUpload, handleCloseShowUploadModal, handleDownloadTemplate, isBulkUploadLoading, isDownloadTemplateLoading, showUploadModal } =
        useBulkTransferUploadModal({
            onComplete: () => {
                setPaymentDetailStage(PaymentDetailStage.PAGE_SUMMARY);
            },
        });

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const formData = new FormData();

    const onFileChange = useCallback(
        (files: File) => {
            formData.append("file", files);
            void handleBulkUpload(formData);
        },
        [handleBulkUpload]
    );
    const onTargetClick = () => {
        // console.log("first clicked");
        fileInputRef.current && fileInputRef.current.click();
    };

    useLayoutEffect(() => {
        if (transferDetailArray.length < 1) return;
        setPaymentDetailStage(PaymentDetailStage.PAGE_SUMMARY);
    }, []);

    useEffect(() => {
        if (transferDetailArray.length < 1) return;
        dispatch(
            setBulkTransfersAmountTotal(
                transferDetailArray.reduce((acc, cur) => {
                    if (cur.amount === ERROR) return acc;
                    return acc + useMoneyToNumber(cur.amount);
                }, 0)
            )
        );
    }, [transferDetailArray]);

    const handleEditTransfer = useCallback((_data: TransferDetail) => {
        dispatch(setTransferDetail(_data));
        dispatch(setCanVerifyRecipientAccountDetails(false));
        dispatch(setIsUpdatingTransferDetail(true));
        handleIsEditDetailTrayActive(true);
    }, []);

    const handleRemoveTransfer = useCallback((_dataIndex: number) => {
        dispatch(removeTransferDetailToTransferList(_dataIndex));
        dispatch(messageTrue("Transfer Deleted"));
    }, []);

    const handleBack = () => {
        dispatch(resetAllBulkTransferData());
        setIsFromPageSummary(false);
        if (paymentDetailStage === PaymentDetailStage.INITIAL) {
            dispatch(setPaymentStage(PaymentStageType.INITIAL));
        } else {
            setPaymentDetailStage(PaymentDetailStage.INITIAL);
        }
    };

    return (
        <>
            <UploadModal
                active={showUploadModal}
                toggler={handleCloseShowUploadModal}
                uploadFunc={handleBulkUpload}
                isDownloadTemplateLoading={isDownloadTemplateLoading}
                handleDownloadTemplate={() => void handleDownloadTemplate()}
                isLoading={isBulkUploadLoading}
            />
            <EditPaymentTray active={isEditDetailTrayActive} toggler={() => handleIsEditDetailTrayActive(false)} />

            <div className="flex h-full max-h-full w-full max-w-full flex-col">
                <div className="relative flex h-full w-full flex-col">
                    {/* <div className="absolute left-0 top-0 flex h-full max-h-full w-full flex-col overflow-y-auto"> */}
                    <div
                        className={
                            "mx-auto flex w-full flex-1 flex-col items-start justify-center space-y-6 " +
                            `${paymentDetailStage === PaymentDetailStage.PAGE_SUMMARY ? "" : "max-w-xl"}`
                        }
                    >
                        <BulkPaymentHeaders />
                        {paymentDetailStage === PaymentDetailStage.INITIAL && (
                            <div className="flex w-full flex-col space-y-8 rounded-lg border border-grey bg-white p-6">
                                <div className="flex w-full flex-col space-y-4">
                                    <h3 className="text-lg font-medium">Upload Payment File</h3>
                                    <div className="flex w-full flex-col space-y-2">
                                        <div
                                            className={`flex h-56 w-full max-w-xl cursor-pointer flex-col items-center justify-start space-y-2 rounded-lg border border-dashed hover:bg-blue-senary [&>div>div]:h-full [&>div>div]:w-full [&>div]:h-full [&>div]:w-full ${
                                                isHover ? "bg-blue-senary" : ""
                                            }`}
                                        >
                                            <FileDrop
                                                // onTargetClick={onTargetClick}
                                                onFrameDragEnter={() => setIsHover(true)}
                                                onFrameDragLeave={() => setIsHover(false)}
                                                onDragOver={() => setIsHover(true)}
                                                onDragLeave={() => setIsHover(false)}
                                                onDrop={(files) => files && files[0] && onFileChange(files && files[0])}
                                            >
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    className="hidden"
                                                    onChange={(event) => {
                                                        if (event.target.files && event.target.files[0].size > 5242880) {
                                                            return setIsFileSizeExceeded(true);
                                                        }
                                                        setIsFileSizeExceeded(false);
                                                        event.target.files &&
                                                            event.target.files[0] &&
                                                            onFileChange(event.target.files && event.target.files[0]);
                                                    }}
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                />
                                                <div className="flex h-full w-full flex-col items-center justify-center space-y-1">
                                                    {isBulkUploadLoading ? (
                                                        <LencoSpinner size="sm" />
                                                    ) : (
                                                        <>
                                                            <GoogleIcon icon="upload" />
                                                            <div className="flex items-center justify-center space-x-1 text-black-tertiary">
                                                                <p>Drag and Drop or</p>
                                                                <ButtonComp
                                                                    color="blue"
                                                                    buttonType="tertiary"
                                                                    func={onTargetClick}
                                                                    isLoading={isBulkUploadLoading}
                                                                    size="sm"
                                                                >
                                                                    Browse
                                                                </ButtonComp>
                                                            </div>
                                                            {isFileSizeExceeded && <p className="text-xs text-error">*File exceeded 5MB</p>}
                                                        </>
                                                    )}
                                                </div>
                                            </FileDrop>
                                        </div>
                                        <ButtonComp
                                            color="black"
                                            buttonType="tertiary"
                                            isLoading={isDownloadTemplateLoading}
                                            func={() => void handleDownloadTemplate()}
                                            size="sm"
                                        >
                                            <p className="underline">Download Template</p>
                                        </ButtonComp>
                                    </div>
                                </div>
                                <div className="flex w-full items-center justify-center space-x-6">
                                    <hr className="w-full border-grey" />
                                    <span className="text-sm text-black-tertiary">OR</span>
                                    <hr className="w-full border-grey" />
                                </div>
                                <ButtonComp
                                    size="lg"
                                    color="blue"
                                    buttonType="flat"
                                    func={() => {
                                        setPaymentDetailStage(PaymentDetailStage.TRANSFER_DETAIL);
                                        dispatch(setNewTransferDetail());
                                    }}
                                    fullWidth
                                >
                                    <GoogleIcon icon="add_circle" />
                                    <p className="ml-2">Add Payment Manually</p>
                                </ButtonComp>
                            </div>
                        )}
                        {paymentDetailStage === PaymentDetailStage.TRANSFER_DETAIL && <AddRecipients />}
                        {paymentDetailStage === PaymentDetailStage.PAGE_SUMMARY && (
                            <>
                                <div className="flex h-full w-full flex-col">
                                    <div className="flex w-full items-center justify-between">
                                        <p className="text-base">
                                            Total:{" "}
                                            <span className="font-medium">
                                                <Money amount={bulkTransfersAmountTotal} />
                                            </span>
                                        </p>

                                        <ButtonComp
                                            type="button"
                                            ripple="light"
                                            buttonType="secondary"
                                            borderSmall
                                            color="grey"
                                            size="sm"
                                            func={() => {
                                                setPaymentDetailStage(PaymentDetailStage.TRANSFER_DETAIL);
                                                setIsFromPageSummary(true);
                                                dispatch(setNewTransferDetail());
                                            }}
                                        >
                                            <div className="flex flex-row items-center space-x-2 text-black">
                                                <GoogleIcon icon="upload" />
                                                <span>Add Transfer</span>
                                            </div>
                                        </ButtonComp>
                                    </div>

                                    <div className="mt-4 h-full w-full">
                                        <Table
                                            heads={BulkTransferHeader}
                                            dataType="bill-purchase"
                                            isPaginateLoading={false}
                                            rows={transferDetailArray.map((_transfer, _index) => ({
                                                record: BulkTransferRow(_index, _transfer, handleEditTransfer, handleRemoveTransfer),
                                            }))}
                                            isMaxHeight
                                        />
                                    </div>
                                    {/* <div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
                                        <div className="relative flex h-full w-full flex-1">
                                            <div className="flex h-fit w-full flex-1 overflow-x-auto">
                                                <div className="left-0 top-0 h-full w-full">
                                                    <table className="tableT w-full">
                                                        <thead className="tableheader relative z-10 rounded-bl-2xl border-grey-backdrop
                                                         text-left text-sm text-black">
                                                            <tr className="relative">
                                                                <th className="tableTh1 px-6 py-3 font-normal">FROM</th>
                                                                <th className="px-6 py-3 font-normal">TO</th>
                                                                <th className="px-6 py-3 font-normal">PURPOSE</th>
                                                                <th className="px-6 py-3 font-normal">AMOUNT</th>
                                                                <th className="tableTh2 px-6 py-3 font-normal"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="w-full">
                                                            {transferDetailArray &&
                                                                transferDetailArray.map((el, index) => (
                                                                    <BulkTransferDetailRow
                                                                        editFunc={handleEditTransfer}
                                                                        removeFunc={handleRemoveTransfer}
                                                                        data={el}
                                                                        key={index}
                                                                        reviewMode
                                                                    />
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                    {transferDetailArray.length < 1 && (
                                                        <div className="flex h-40 w-full flex-col items-center justify-center">
                                                            <EmptyX />
                                                            <p className="text-sm text-black-secondary">You have no Payment</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </>
                        )}
                        <div className="flex w-full flex-row items-center justify-center space-x-4">
                            {!isFromPageSummary && (
                                <ButtonComp buttonType="secondary" color="grey" func={handleBack}>
                                    {paymentDetailStage === PaymentDetailStage.INITIAL ? "Back" : "Cancel"}
                                </ButtonComp>
                            )}
                            <ButtonComp
                                color="black"
                                buttonType="primary"
                                func={() => {
                                    paymentDetailStage === PaymentDetailStage.TRANSFER_DETAIL ? setIsFromPageSummary(false) : undefined;
                                    paymentDetailStage === PaymentDetailStage.PAGE_SUMMARY
                                        ? dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))
                                        : setPaymentDetailStage(PaymentDetailStage.PAGE_SUMMARY);
                                }}
                                disable={
                                    !!(
                                        transferDetailArray.length < 1 ||
                                        (paymentDetailStage === PaymentDetailStage.PAGE_SUMMARY &&
                                            (transferDetailArray.some((el) => el.originatingAccountId === ERROR) ||
                                                transferDetailArray.some((el) => el.purpose === ERROR) ||
                                                transferDetailArray.some((el) => el.amount === ERROR) ||
                                                transferDetailArray.some((el) => isNullOrUndefined(el.recipientBankAccount))))
                                    )
                                }
                            >
                                {paymentDetailStage === PaymentDetailStage.PAGE_SUMMARY
                                    ? "Review Payments"
                                    : isFromPageSummary
                                      ? "Back to Payment Summary"
                                      : "Payment Summary"}
                            </ButtonComp>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;

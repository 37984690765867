import CreateAccountRequest from "../../../../../models/createAccountRequest";
import { ReactComponent as PendingAccountIcon } from "../../../../../assets/svg/Cards/Pending-account-icon.svg";
import React from "react";
import titleCase from "../../../../../hooks/titleCase";
import useAccountCreationStatus from "../../Hooks/State/useAccountCreationStatus";

interface AccountsProcessingCardInterface {
    data: CreateAccountRequest;
}

function AccountsProcessingCard({ data }: AccountsProcessingCardInterface): JSX.Element {
    useAccountCreationStatus(data);
    // const date = new Date();
    return (
        <div className="relative box-border flex min-h-[296px] w-full flex-col items-center justify-start rounded-lg bg-white shadow">
            {data && (
                <>
                    <div className="flex h-full w-full flex-col items-center justify-center space-y-3 px-5 py-6">
                        <PendingAccountIcon />
                        <div className="flex w-full flex-col items-center justify-start space-y-2">
                            <p className="text-black-black w-full break-words text-center text-base font-medium">
                                {titleCase(data?.fullAccountName || "-")}
                            </p>
                            <p className="text-center text-xs font-normal text-black-tertiary">Sub-account is being created</p>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-end pb-8 pr-4">
                        <span className="text-sm font-medium text-warning-secondary">Pending Activation</span>
                    </div>
                </>
            )}
        </div>
    );
}

export default AccountsProcessingCard;

import * as Yup from "yup";

import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { CancelCardOptionsEnum } from "../../../Services/cardsApi.types";
import { CancelCardOptionsList } from "../../../Services/cards.constants";
import CardMain from "../../../../../../models/cardMain";
import FormInput from "../../../../../../components/inputs/FormInput";
import MessageToast from "../../../../../../components/message-toast";
import RadioButton from "../../../../../../components/radio-button";
import useCardTray from "../../../Hooks/State/useCardTray";
import useDeactivateCard from "../../../Hooks/State/useDeactivateCard";

interface ICancelCard {
    isSelectedCardDetailsOpen: boolean;
    cardData: CardMain;
}

function CancelCard({ isSelectedCardDetailsOpen, cardData }: ICancelCard): JSX.Element {
    const { onOpenDefaultCardSection } = useCardTray();
    const { handleDeactivateCard, isDeactivateCardLoading } = useDeactivateCard();

    const initialFormState: { other_reason: string } = {
        other_reason: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        other_reason: Yup.string().required(),
    });

    const divRef = useRef<HTMLDivElement | null>(null);

    const [cancelCardReason, setCancelCardReason] = useState<CancelCardOptionsEnum | null>(null);

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    const handleCancelCardRequest = useCallback(async () => {
        if (!cancelCardReason) return;
        await handleDeactivateCard({
            reason: cancelCardReason,
            createRequestId: cardData.createRequest.id,
            otherReasonText: undefined,
        });
        onOpenDefaultCardSection();
    }, [cancelCardReason]);

    return (
        <div
            className="w-full space-y-8 border-t border-grey-tertiary pb-4 pt-6"
            data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}
            ref={divRef}
        >
            <div className="flex flex-col space-y-2">
                <h3 className="text-base font-medium leading-none text-black">Cancel Card</h3>
                <p className="text-sm text-black-tertiary">
                    This card would be completely stopped from carrying out any transaction. This action is irreversible
                </p>
            </div>
            <Formik
                initialValues={initialFormState}
                validationSchema={FORM_VALIDATION}
                onSubmit={async (value) => {
                    if (cancelCardReason !== CancelCardOptionsEnum.OTHER) return;
                    await handleDeactivateCard({
                        reason: cancelCardReason,
                        otherReasonText: value.other_reason,
                        createRequestId: cardData.createRequest.id,
                    });
                    onOpenDefaultCardSection();
                }}
                enableReinitialize
                validateOnChange
            >
                {(formik) => (
                    <Form className="flex w-full flex-col space-y-12">
                        <div className="flex w-full flex-col space-y-5">
                            <p className="text-base font-medium text-black">Choose your reason</p>
                            <div className="w-full space-y-5">
                                {CancelCardOptionsList?.map((_option, _index) => (
                                    <RadioButton
                                        id="cancel-card"
                                        key={_index}
                                        size="sm"
                                        text={_option.text}
                                        checked={cancelCardReason === _option.value}
                                        func={() => setCancelCardReason(_option.value)}
                                    />
                                ))}
                                {cancelCardReason === CancelCardOptionsEnum.OTHER ? (
                                    <FormInput name="other_reason" type="textarea" placeholder="Description" />
                                ) : null}
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-6">
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp fullWidth size="lg" buttonType="secondary" color="grey" func={onOpenDefaultCardSection}>
                                    No, Keep Card
                                </ButtonComp>
                                <ButtonComp
                                    fullWidth
                                    type={cancelCardReason === CancelCardOptionsEnum.OTHER ? "submit" : "button"}
                                    size="lg"
                                    color="red"
                                    disable={
                                        cancelCardReason === CancelCardOptionsEnum.OTHER ? formik.values.other_reason.length < 1 : !cancelCardReason
                                    }
                                    isLoading={isDeactivateCardLoading}
                                    buttonType="primary"
                                    func={handleCancelCardRequest}
                                >
                                    Cancel Card
                                </ButtonComp>
                            </div>
                            <MessageToast
                                type="info"
                                message="Freeze the card instead of canceling if you would still like to use the card in the future"
                                fullWidth
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
export default CancelCard;

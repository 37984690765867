import React, { useEffect, useRef, useState } from "react";

import ColorCircle from "./Components/ColorCircle";
import { ReactComponent as PlusCircle } from "../../../../../../../../../../../assets/svg/LencoPay/plusCircle.svg";
import { colors } from "../../../../../../../../hookss/ApiAccessKeySettings/apiAccessKeySettings.constant";

interface Props {
    isDisabled?: boolean;
    selectedColorCode: string;
    handleSelectColor: (_color: string) => void;
}

function ColorSelector(props: Props): JSX.Element {
    const colorPickerRef = useRef<HTMLInputElement | null>(null);
    const [isCustomColor, setIsCustomColor] = useState(false);

    useEffect(() => {
        if (!props.selectedColorCode) return;
        setIsCustomColor(!colors.some((_color) => _color.code === props.selectedColorCode));
    }, [props.selectedColorCode]);

    return (
        <div className="grid-cols-widget grid w-full place-content-between gap-2">
            {colors.map((_color, _index) => (
                <ColorCircle
                    key={_index}
                    color={_color.code}
                    isDisabled={props.isDisabled}
                    isSelected={props.selectedColorCode === _color.code}
                    handleSelectColor={(_code) => {
                        setIsCustomColor(false);
                        props.handleSelectColor(_code);
                    }}
                />
            ))}

            <div className="relative flex items-center justify-center">
                <input
                    ref={colorPickerRef}
                    type="color"
                    disabled={props.isDisabled}
                    className="pointer-events-none h-0 w-0 opacity-0"
                    onChange={(event) => {
                        setIsCustomColor(true);
                        props.handleSelectColor(event.target.value);
                    }}
                />
                <button
                    className={props.isDisabled ? "cursor-default" : "cursor-pointer"}
                    onClick={() => colorPickerRef.current && colorPickerRef.current.click()}
                    disabled={props.isDisabled}
                >
                    <PlusCircle />
                </button>
            </div>
            {isCustomColor ? (
                <div className={`w-fit duration-200 ${!isCustomColor ? "pointer-events-none opacity-0" : ""}`}>
                    <ColorCircle color={props.selectedColorCode} isSelected isDisabled={props.isDisabled} />
                </div>
            ) : null}
        </div>
    );
}

export default ColorSelector;

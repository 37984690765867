import { AllTerminalsStageType, TerminalsStageType } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import { setAllTerminalStageToInitial, setTerminalStage } from "../../../../../redux/terminals/slice/terminalsSlice";

import TabButton from "../../../../../components/TabButton";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useTerminals from "../../Hooks/State/useTerminals";

interface Props {
    children: React.ReactNode;
}

function TerminalsLayout(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { handleTerminalList } = useTerminals();
    const terminalStage = useAppSelector((state) => state.terminals.terminalStage);
    const allTerminalStage = useAppSelector((state) => state.terminals.allTerminalStage);
    const selectedTerminalNameInTerminalStage = useAppSelector((state) => state.terminals.selectedTerminalNameInTerminalStage);

    const handleGoToAllTerminals = () => {
        dispatch(setTerminalStage(TerminalsStageType.ALL_TERMINALS));
    };

    const handleGoToTerminalRequest = () => {
        dispatch(setTerminalStage(TerminalsStageType.TERMINAL_REQUEST));
    };

    const handleGoToTerminalTransactions = () => {
        dispatch(setTerminalStage(TerminalsStageType.TERMINAL_TRANSACTION));
    };

    const handleGoBack = async () => {
        dispatch(setAllTerminalStageToInitial());
        await handleTerminalList({ query: "" });
    };

    return (
        <>
            {/* <div className="flex h-full max-h-full w-full min-w-[500px] flex-col"> */}
            <div className="flex h-full max-h-full w-full flex-col space-y-10">
                <TabButton
                    buttons={[
                        {
                            text: "All Terminals",
                            onClick: handleGoToAllTerminals,
                            isActive: terminalStage === TerminalsStageType.ALL_TERMINALS,
                            subTab: {
                                text: `${selectedTerminalNameInTerminalStage}`,
                                onClose: handleGoBack,
                            },
                            showSubTab:
                                terminalStage === TerminalsStageType.ALL_TERMINALS &&
                                allTerminalStage === AllTerminalsStageType.SPECIFIC_TERMINAL_TRANSACTION,
                        },
                        {
                            text: "Transactions",
                            onClick: handleGoToTerminalTransactions,
                            isActive: terminalStage === TerminalsStageType.TERMINAL_TRANSACTION,
                        },
                        {
                            text: "Requested Terminals",
                            onClick: handleGoToTerminalRequest,
                            isActive: terminalStage === TerminalsStageType.TERMINAL_REQUEST,
                        },
                    ]}
                />
                <div className="relative flex w-full flex-grow flex-col">{props.children}</div>
            </div>
        </>
    );
}

export default TerminalsLayout;

import ChartCanvas from "../../../../../../../components/chart";
import Currency from "../../../../../../../models/currency";
import CurrencyCode from "../../../../../../../components/currency-code";
import { DailyAccountBalanceItem } from "../../../../Services/homeApi.types";
import GoogleIcon from "../../../../../../../components/google-icon";
import { LencoActiveCountries } from "../../../../../../../redux/init/slice/initSlice.types";
import Money from "../../../../../../../components/money";
import NigeriaFlag from "../../../../../../../assets/images/countries/nigeria.png";
import ZambiaFlag from "../../../../../../../assets/images/countries/zambia.png";

interface Props {
    country: LencoActiveCountries | undefined;
    currency: Currency;
    chartData: DailyAccountBalanceItem[];
    showAmount: boolean;
    totalInflow: number;
    totalBalance: number;
    totalOutflow: number;
    handleShowAmount: () => void;
}

function Insight(props: Props): JSX.Element {
    return (
        <>
            <div className="mb-6 flex h-20 w-full items-start justify-between">
                <div className="w-full space-y-4">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <p className="text-sm text-black-tertiary">Total Balance</p>
                            <div
                                className="flex cursor-pointer items-center justify-center rounded bg-grey-tertiary p-1"
                                onClick={props.handleShowAmount}
                            >
                                {props.showAmount ? (
                                    <GoogleIcon icon="visibility_off" className="text-black-secondary" size="xs" isOutlined />
                                ) : (
                                    <GoogleIcon icon="visibility" className="text-black-secondary" size="xs" isOutlined />
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-center space-x-2 rounded bg-grey-tertiary p-2">
                            <img
                                className="rounded-full border border-black-quat"
                                src={props.country === LencoActiveCountries.NIGERIA ? NigeriaFlag : ZambiaFlag}
                                alt="Country flag"
                                width="20px"
                                height="20px"
                            />
                            <p className="text-sm text-black-secondary">
                                <CurrencyCode currency={props.currency} />
                            </p>
                        </div>
                    </div>

                    <div className="flex h-8 items-center justify-start">
                        {props.showAmount ? (
                            <div className="text-3xl font-bold text-black-secondary">
                                <Money amount={props.totalBalance} currency={props.currency} />
                            </div>
                        ) : (
                            <div className="flex flex-row items-center justify-center pt-2">
                                <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                <div className="mx-1 flex h-2 w-2 rounded-full bg-black-secondary" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="space-y-3">
                <p className="text-lg font-medium text-black-secondary">Insight for the last 30 days</p>
                <div className="flex h-12 items-center justify-between">
                    <div>
                        <div className="flex items-center justify-start space-x-1">
                            <p className="text-sm font-normal text-black-tertiary">Inflow</p>
                            <GoogleIcon icon="trending_down" className="text-success" size="sm" />
                        </div>
                        <div className="flex h-6 items-center justify-start text-lg font-medium text-black-secondary">
                            {props.showAmount ? (
                                <Money amount={props.totalInflow} currency={props.currency} />
                            ) : (
                                <div className="flex h-2 flex-row items-center justify-center">
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="md:text-right">
                        <div className="flex items-center justify-start space-x-1 md:justify-end">
                            <p className="text-sm font-normal text-black-tertiary">Payout</p>
                            <GoogleIcon icon="trending_up" className="text-error" size="sm" />
                        </div>
                        <div className="flex h-6 items-center justify-start text-lg font-medium text-black-secondary">
                            {props.showAmount ? (
                                <Money amount={props.totalOutflow} currency={props.currency} />
                            ) : (
                                <div className="flex h-2 flex-row items-center justify-center">
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />{" "}
                                    <div className="mx-1 flex h-1.5 w-1.5 rounded-full bg-black-secondary" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex h-full max-h-72 w-full items-center justify-center">
                <ChartCanvas
                    data={props.chartData.map((point) => {
                        return { date: point.date, balance: point.balance as number };
                    })}
                    numPoints={30}
                />
            </div>
        </>
    );
}

export default Insight;

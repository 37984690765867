import { Form, Formik, FormikProps } from "formik";
import React, { MutableRefObject, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { ChangePasswordRequest } from "../../../Services/security/change-password/change-password.types";
import FormInput from "../../../../../../components/inputs/FormInput";
import GoogleIcon from "../../../../../../components/google-icon";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import PasswordHints from "../../../../../../utils/password-hints/password-hints";
// import PasswordHints from "./password-hints/PasswordHints";
import { ToastType } from "../../../../../../helpers/AppConstants";
import { UseChangePasswordResponse } from "../../../Services/security/change-password/useChangePassword";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import useDimension from "../../../../../../hooks/useDimension";

interface ChangePasswordFormProps {
    initialValues: ChangePasswordRequest;
    show: boolean;
    formikRef: MutableRefObject<FormikProps<ChangePasswordRequest>>;
    useChangePasswordData: UseChangePasswordResponse;
    doneHandler: () => void;
    setValues: React.Dispatch<React.SetStateAction<ChangePasswordRequest | undefined>>;
}

function ChangePasswordForm({ initialValues, show, formikRef, useChangePasswordData, doneHandler, setValues }: ChangePasswordFormProps): JSX.Element {
    const [isRepeatTouched, setIsRepeatTouched] = useState(false);
    const domNode = useClickOutside(
        () => {
            setIsRepeatTouched(false);
        },
        undefined,
        ["mousedown", "keydown"]
    );

    const deviceWidth = useDimension().width;

    return (
        <>
            <Modal size="sm" active={show} toggler={doneHandler}>
                <ModalHeader
                    onClose={() => {
                        doneHandler();
                    }}
                >
                    Change Password
                </ModalHeader>
                <Formik
                    initialValues={initialValues}
                    innerRef={formikRef}
                    validationSchema={useChangePasswordData.formValidation}
                    onSubmit={(values) => {
                        void useChangePasswordData.handleChangePassword(values);
                        setValues(values);
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="flex w-full flex-col" autoComplete="off">
                                <ModalBody>
                                    {useChangePasswordData.error && (
                                        <div className="pb-4">
                                            <MessageToasts
                                                toastType={ToastType.ERROR}
                                                toastMessage={useChangePasswordData.error}
                                                onClick={useChangePasswordData.clearError}
                                            />
                                        </div>
                                    )}
                                    <div className="space-y-4">
                                        <FormInput placeholder="Current Password" name="currentPassword" type="password" passwordCustomStyle />
                                        <FormInput placeholder="New Password" name="newPassword" type="password" passwordCustomStyle />
                                        <PasswordHints
                                            password={formik.values.newPassword}
                                            className="flex items-center space-x-2 pb-1 text-xs"
                                            show={!!formik.touched.newPassword || formik.values.newPassword.length > 0}
                                        />

                                        {!formik.errors.newPassword && (
                                            <div className="w-full">
                                                <div className="w-full" onClick={() => setIsRepeatTouched(true)} ref={domNode}>
                                                    <FormInput label="Repeat New Password" name="repeatPassword" type="password" />
                                                </div>

                                                {((formik.values.repeatNewPassword.length >= formik.values.newPassword.length &&
                                                    formik.values.repeatNewPassword !== formik.values.newPassword) ||
                                                    (formik.values.repeatNewPassword.length > 0 &&
                                                        !isRepeatTouched &&
                                                        formik.values.repeatNewPassword !== formik.values.newPassword)) && (
                                                    <div className="pt-2">
                                                        <li className="flex items-center space-x-1 pb-2 text-xs">
                                                            <GoogleIcon icon="close" className="text-error" />
                                                            <span className="text-error">Password does not match</span>
                                                        </li>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="flex h-fit w-full flex-col-reverse items-center justify-end gap-y-4 space-x-0 pt-6 sm:flex-row sm:gap-y-0 sm:space-x-4">
                                        <ButtonComp
                                            type="button"
                                            ripple="light"
                                            buttonType="secondary"
                                            color="grey"
                                            func={() => {
                                                doneHandler();
                                            }}
                                            fullWidth={deviceWidth < 640}
                                        >
                                            <span className="">Cancel</span>
                                        </ButtonComp>
                                        <ButtonComp
                                            type="submit"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            disable={
                                                !!formik.errors.currentPassword ||
                                                !!formik.errors.newPassword ||
                                                !!formik.errors.repeatNewPassword ||
                                                formik.values.repeatNewPassword !== formik.values.newPassword ||
                                                formik.values.newPassword.length < 8
                                            }
                                            isLoading={useChangePasswordData.isSubmitting}
                                            fullWidth={deviceWidth < 640}
                                        >
                                            Change Password
                                        </ButtonComp>
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}

export default ChangePasswordForm;

// import { HintType, ListStyle } from "../../../../../applicationn/components/element/hint/hint.constant";
import {
    resetAllZambiaSingleTransferData,
    resetZambiaSingleTransferRecipient,
    setZambiaPaymentMethod,
} from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useDispatch, useSelector } from "react-redux";

import AccountNumberInput from "../../../../../../../components/account-number-input";
import AccountNumberSelect from "../../../selects/account-number-select";
import { BandFees } from "../../../../../../../helpers/zambia-fee-calculator";
import BankAccount from "../../../../../../../models/bankAccount";
import BankSelect from "../../../selects/bank-select";
import ButtonComp from "../../../../../../../components/button/ButtonComp";
import Card from "../../../../../../../models/card";
// import { CompanyHint } from "../../../../../applicationn/services/nigeria-application/registered/company.types";
import CustomSelect from "../../../../../../../components/dropdown/custom-select";
import CustomerAccount from "../../../../../../../models/customerAccount";
import ErrorCard from "../../../Cards/send-money-error-card";
// import Hint from "../.s./../../../applicationn/components/element/hint";
import { IRootState } from "../../../../../../../redux/rootReducer";
import Input from "../../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../../components/message-toast";
import MoneyInput from "../../../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../../../components/user-account-dropdown";
import { PaymentStageType } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import { PaymentsType } from "../../../../hooks/state/send-money/payments.constants";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import UserAccount from "../../../../../../../models/userAccount";
import VerifiedAccountCard from "../../../Cards/verified-account-card";
import ZambiaFee from "../../../../../../../components/fees/ZambiaFee";
import { ZambiaPaymentMethod } from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import { setPaymentStage } from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../../hooks/titleCase";
import { useMoneyToNumber } from "../../../../../../../hooks/useMoneyToNumber";
import { useNavigate } from "react-router";
import useSingleTransferAccountNumberAutocomplete from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferAccountNumberAutocomplete";
import useSingleTransferAmount from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferAmount";
import useSingleTransferPayFrom from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferPayFrom";
import useSingleTransferPurpose from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferPurpose";
import useSingleTransferRecipientBankCode from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferRecipientBankCode";
import useSingleTransferVerifyRecipientAccountDetails from "../../../../hooks/state/send-money/Zambia/SingleTransfer/useSingleTransferVerifyRecipientAccountDetails";
import { zambiaPaymentMethods } from "../../../../hooks/state/send-money/Zambia/zambia-single-transfer.constant";

// const TransferHints: CompanyHint[] = [
//     {
//         index: 0,
//         span: <p>Lenco Business is a way for you to send money to other Lenco Business
// account holders and they&apos;ll receive funds instantly.</p>,
//     },
// ];

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedUserAccount = useSelector((state: IRootState) => state.zambiaSingleTransfer.selectedUserAccount);
    const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);

    const bankAccount = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.bankAccount);
    const mobileMoney = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.mobileMoney);
    const lencoMerchant = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.lencoMerchant);

    const { handleAmountChange } = useSingleTransferAmount();
    const { handlePurposeChange } = useSingleTransferPurpose();
    const { handleSelectBank } = useSingleTransferRecipientBankCode();
    const { accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount } = useSingleTransferPayFrom();
    const { verifyAccountError, verifiedAccount, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount } =
        useSingleTransferVerifyRecipientAccountDetails();
    const { telcoImg, isRecipientAutocompleteLoading, suggestedRecipients, handleSelectRecipient, handleAccountNumberChange } =
        useSingleTransferAccountNumberAutocomplete({ handleSelectBank, handleSetVerifiedAccount, handleResetVerifiedAccount });

    const accountBalance = selectedUserAccount?.balance;
    const amountWithFee =
        singleTransferDetails.paymentMethod && singleTransferDetails.amount.length > 0
            ? BandFees(singleTransferDetails.paymentMethod, useMoneyToNumber(singleTransferDetails.amount)).allFees +
              (useMoneyToNumber(singleTransferDetails.amount) || 0)
            : null;
    const maxAmount =
        singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER
            ? bankAccount?.maxAmount
            : singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS
              ? lencoMerchant?.maxAmount
              : singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY
                ? mobileMoney?.maxAmount
                : null;
    const minAmount =
        singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER
            ? bankAccount?.minAmount
            : singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS
              ? lencoMerchant?.minAmount
              : singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY
                ? mobileMoney?.minAmount
                : null;

    const isInsufficientFunds =
        selectedUserAccount && !isNullOrUndefined(accountBalance)
            ? useMoneyToNumber(singleTransferDetails.amount) > accountBalance || (amountWithFee && amountWithFee > accountBalance)
            : false;
    // const selectedZambiaMobileMoneyOperator =
    // Object.values(ZambiaMobileMoneyOperator).find((val) => val === singleTransferDetails.recipient.bankCode) || undefined;

    return (
        <>
            <div className="flex w-full flex-row items-center justify-center lg:items-start lg:justify-between lg:space-x-8">
                <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                    <SinglePaymentHeaders />
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <div className="flex w-full flex-col space-y-4">
                            <p className="text-sm font-medium text-black-secondary">Select Account</p>
                            <NewUserAccountDropdown
                                value={selectedAccountId || ""}
                                options={accounts || null}
                                onClick={handleSelectAccount}
                                isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                                dropdownSize="xl"
                                showBalance
                                initiatorCanSelect
                            />
                            <CustomSelect
                                label="Payment Method"
                                value={singleTransferDetails.paymentMethod}
                                options={zambiaPaymentMethods.map((item) => ({
                                    text: item.name,
                                    value: item.id,
                                    // icon: item.icon,
                                }))}
                                onSelect={(value) => {
                                    if (value) {
                                        dispatch(resetZambiaSingleTransferRecipient());
                                        dispatch(setZambiaPaymentMethod(value));
                                        handleResetVerifiedAccount();
                                        if (value === (ZambiaPaymentMethod.LENCO_BUSINESS as number)) {
                                            handleSelectBank("lenco-till");
                                        }
                                    }
                                }}
                            />
                            <MessageToast
                                message="Lenco Business is a way for you to send money to other Lenco Business account holders and they'll receive funds instantly."
                                type="info"
                                size="sm"
                                fullWidth
                            />
                        </div>

                        <div className="flex w-full flex-col space-y-4">
                            <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                            <MoneyInput
                                label="Amount"
                                value={singleTransferDetails.amount || ""}
                                onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                                fee={
                                    selectedUserAccount?.bankCode !== singleTransferDetails.recipient?.bankCode
                                        ? singleTransferDetails.paymentMethod && (
                                              <ZambiaFee
                                                  value={singleTransferDetails?.amount || ""}
                                                  paymentMethod={singleTransferDetails.paymentMethod}
                                                  isWithTax
                                              />
                                          )
                                        : undefined
                                }
                                inputSize="xl"
                            />
                            <Input label="Purpose" value={singleTransferDetails.purpose} onChange={handlePurposeChange} inputSize="xl" fullWidth />
                        </div>

                        {selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
                            <ErrorCard canSendMoneyToSpecificAccounts fullWidth />
                        )}
                        {useMoneyToNumber(singleTransferDetails.amount) > 0 &&
                            singleTransferDetails.paymentMethod !== ZambiaPaymentMethod.LENCO_BUSINESS &&
                            minAmount &&
                            useMoneyToNumber(singleTransferDetails.amount) < minAmount && (
                                <ErrorCard isZambiaMinAmount zambiaMinAmount={minAmount} fullWidth />
                            )}
                        {maxAmount && useMoneyToNumber(singleTransferDetails.amount) > maxAmount && (
                            <ErrorCard isZambiaMaxAmount zambiaMaxAmount={maxAmount} fullWidth />
                        )}
                        {isInsufficientFunds && selectedUserAccount && !isNullOrUndefined(accountBalance) && (
                            <ErrorCard balance={accountBalance} isInsufficientFunds fullWidth />
                        )}
                        {currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
                            <ErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount || 0} isApprovalError fullWidth />
                        )}

                        {selectedUserAccount?.accountRestrictions.cannotSendMoney ? (
                            <ErrorCard canNotSendFromAccount fullWidth />
                        ) : (
                            <div className="flex w-full flex-col space-y-4">
                                <p className="text-sm font-medium text-black-secondary">Recipient Details</p>
                                {selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                                    <>
                                        <AccountNumberSelect
                                            onClick={(e) => {
                                                if (e instanceof UserAccount || e instanceof CustomerAccount) {
                                                    handleSelectRecipient(e.bankAccount as BankAccount);
                                                }
                                                if (e instanceof Card) {
                                                    navigate(
                                                        {
                                                            pathname: "/payments/make/single",
                                                            search: `?to=${e.id}`,
                                                        },
                                                        {
                                                            state: {
                                                                typeOfTransfer: PaymentsType.FUND_CARD,
                                                                from: selectedAccountId,
                                                                to: e.id,
                                                            },
                                                        }
                                                    );
                                                }
                                            }}
                                            options={suggestedRecipients}
                                            value={singleTransferDetails.recipient.accountNumber}
                                        />
                                        {!verifyAccountError && verifiedAccount && (
                                            <VerifiedAccountCard value={titleCase(verifiedAccount.accountName)} />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY ? (
                                            <Input
                                                label="Mobile Number"
                                                value={titleCase(singleTransferDetails.recipient.accountNumber)}
                                                onChange={(value: string) => handleAccountNumberChange(value)}
                                                inputSize="xl"
                                                appendIcon={
                                                    telcoImg ? (
                                                        <div className="flex h-12 w-16 items-center justify-center pr-4">
                                                            <img className="h-9 rounded-full border border-black-quin" src={telcoImg} alt="telco" />
                                                        </div>
                                                    ) : undefined
                                                }
                                                fullWidth
                                            />
                                        ) : (
                                            <>
                                                <AccountNumberInput
                                                    data={suggestedRecipients as UserAccount[] | null}
                                                    label="Account Number or Name"
                                                    onChange={handleAccountNumberChange}
                                                    isLoading={isRecipientAutocompleteLoading}
                                                    inputValue={singleTransferDetails.recipient.accountNumber}
                                                    onClick={(e) => {
                                                        if (e instanceof UserAccount || e instanceof CustomerAccount) {
                                                            handleSelectRecipient(e.bankAccount as BankAccount);
                                                        }
                                                        if (e instanceof Card) {
                                                            navigate(
                                                                {
                                                                    pathname: "/payments/make/single",
                                                                    search: `?to=${e.id}`,
                                                                },
                                                                {
                                                                    state: {
                                                                        typeOfTransfer: PaymentsType.FUND_CARD,
                                                                        from: selectedAccountId,
                                                                        temp: true,
                                                                        to: e.id,
                                                                    },
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                                {singleTransferDetails.paymentMethod !== ZambiaPaymentMethod.LENCO_BUSINESS && (
                                                    <BankSelect
                                                        bankCode={singleTransferDetails.recipient.bankCode}
                                                        // isDisabled={singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS}
                                                        accountNumber={singleTransferDetails.recipient.accountNumber}
                                                        onClick={handleSelectBank}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {verifyAccountError && !verifiedAccount && (
                                            <MessageToast message={verifyAccountError.message} type="error" fullWidth />
                                        )}
                                        {!verifyAccountError && verifiedAccount && (
                                            <VerifiedAccountCard value={titleCase(verifiedAccount.accountName)} />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex w-full flex-row items-center justify-center space-x-4">
                        <ButtonComp
                            size="xl"
                            color="grey"
                            ripple="light"
                            buttonType="secondary"
                            func={() => {
                                dispatch(setPaymentStage(PaymentStageType.INITIAL));
                                dispatch(resetAllZambiaSingleTransferData());
                            }}
                        >
                            Back
                        </ButtonComp>

                        <ButtonComp
                            size="xl"
                            color="black"
                            isLoading={isVerifyAccountLoading}
                            buttonType="primary"
                            disable={
                                !!(
                                    !selectedUserAccount ||
                                    !singleTransferDetails.paymentMethod ||
                                    verifyAccountError ||
                                    !verifiedAccount ||
                                    selectedUserAccount.accountRestrictions.cannotSendMoney ||
                                    !singleTransferDetails.purpose ||
                                    !singleTransferDetails.amount ||
                                    useMoneyToNumber(singleTransferDetails.amount) === 0 ||
                                    isInsufficientFunds ||
                                    (singleTransferDetails.paymentMethod !== ZambiaPaymentMethod.LENCO_BUSINESS &&
                                        minAmount &&
                                        useMoneyToNumber(singleTransferDetails.amount) < minAmount) ||
                                    !!(maxAmount && useMoneyToNumber(singleTransferDetails.amount) > maxAmount)
                                )
                            }
                            func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
                        >
                            Review Payment
                        </ButtonComp>
                    </div>
                </div>

                {/* <div className="-mt-2 flex items-center justify-start pt-24">
                    <div className="w-fit">
                        <Hint infoText="New update" hintType={HintType.GUIDE} listStyle={ListStyle.PLAIN} firstList={TransferHints} fullWidth />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default PaymentDetails;

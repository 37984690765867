import React, { useEffect } from "react";
import {
    resetAllApiAccessKeysData,
    setIsZambiaCreateApiAccessKeyModalOpen,
} from "../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch, useSelector } from "react-redux";

import { ApiAccessKeysStage } from "../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice.types";
import ApiList from "../Components/ApiAccessKeys/ApiList";
import ApiSettings from "../Components/ApiAccessKeys/ApiSettings";
import { ReactComponent as BlueCirclePlus } from "../../../../assets/svg/Button/blue-circled-plus.svg";
import ButtonComp from "../../../../components/button/ButtonComp";
import CreateApiKeyModal from "../Components/ApiAccessKeys/ApiList/Components/Modals/CreateApiKeyModal";
import DeactivateKeyModal from "../Components/ApiAccessKeys/ApiList/Components/Modals/DeactivateKeyModal";
import GoogleIcon from "../../../../components/google-icon";
import { IRootState } from "../../../../redux/rootReducer";
import PageLayout from "../../../../components/layouts/page-layout";
import RegenerateKeyModal from "../Components/ApiAccessKeys/ApiList/Components/Modals/RegenerateKeyModal";
import useLencoPayDefaultSetupInit from "../hookss/DefaultSetup/useLencoPayDefaultSetupInit";

function ApiAccessKeys(): JSX.Element {
    const dispatch = useDispatch();

    const { handleLencoPayDefaultSetupInit } = useLencoPayDefaultSetupInit();
    const apiAccessKeysStage = useSelector((state: IRootState) => state.zambiaApiAccessKeys.apiAccessKeysStage);

    useEffect(() => {
        handleLencoPayDefaultSetupInit();
        return () => {
            dispatch(resetAllApiAccessKeysData());
        };
    }, []);

    return (
        <>
            <CreateApiKeyModal />
            <RegenerateKeyModal />
            <DeactivateKeyModal />

            <PageLayout
                isFullPageScroll
                pageTitle={"APIs"}
                subTitle={"Create and manage your API access"}
                headerButtons={
                    apiAccessKeysStage === ApiAccessKeysStage.LIST ? (
                        <>
                            <div className="flex w-full flex-col items-center justify-start space-y-4 2xs:max-w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
                                <div className="w-full 2xs:w-max">
                                    <a target="_blank" rel="noopener noreferrer" href="https://lenco-api.readme.io/v2.0/reference/introduction">
                                        <ButtonComp color="grey" type="button" buttonType="secondary" size="md">
                                            <div className="flex items-center justify-center space-x-1">
                                                <GoogleIcon icon="data_object" /> <span>View Documentation</span>
                                            </div>
                                        </ButtonComp>
                                    </a>
                                </div>
                                <div className="w-full 2xs:w-max">
                                    <ButtonComp
                                        color="grey"
                                        type="button"
                                        buttonType="secondary"
                                        size="md"
                                        func={() => dispatch(setIsZambiaCreateApiAccessKeyModalOpen(true))}
                                    >
                                        <div className="flex items-center justify-center space-x-1">
                                            <BlueCirclePlus /> <span>New API Key</span>
                                        </div>
                                    </ButtonComp>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                }
            >
                {apiAccessKeysStage === ApiAccessKeysStage.SETTINGS && <ApiSettings />}
                {apiAccessKeysStage === ApiAccessKeysStage.LIST && <ApiList />}
            </PageLayout>
        </>
    );
}

export default ApiAccessKeys;

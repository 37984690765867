export enum TransactionFilters {
    DATE = "Date",
    ACCOUNTS = "Accounts",
    RECIPIENTS = "Recipients",
    CATEGORIES = "Categories",
    TYPE = "Type",
    STATUS = "Status",
    CARDS = "Cards",
}

export const TransactionFiltersArray = [
    TransactionFilters.DATE,
    TransactionFilters.ACCOUNTS,
    TransactionFilters.CATEGORIES,
    TransactionFilters.TYPE,
    TransactionFilters.STATUS,
    TransactionFilters.RECIPIENTS,
    TransactionFilters.CARDS,
];

const TransactionFilterIcon = {
    [TransactionFilters.DATE]: "calendar_month",
    [TransactionFilters.ACCOUNTS]: "account_balance",
    [TransactionFilters.RECIPIENTS]: "person",
    [TransactionFilters.CATEGORIES]: "category",
    [TransactionFilters.TYPE]: "checklist_rtl",
    [TransactionFilters.STATUS]: "cached",
    [TransactionFilters.CARDS]: "credit_card",
};

export const TransactionFiltersData = Object.values(TransactionFilters).map((_filter) => ({
    text: _filter,
    value: _filter,
    icon: TransactionFilterIcon[_filter],
}));

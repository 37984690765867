import React from "react";

interface Props {
    hasSelectedAccount: boolean;
    isActive: boolean;
    text: string;
    handleMenuItemClick: () => void;
}

function AccountSettingsSideMenuItem({ hasSelectedAccount, isActive, text, handleMenuItemClick }: Props): JSX.Element {
    return (
        <div
            className={`flex w-full items-center rounded-md px-2 py-4 text-center font-medium hover:text-black focus:bg-white md:h-10 md:px-4 md:py-0 md:text-left ${hasSelectedAccount ? "cursor-pointer" : "pointer-events-none"} ${isActive ? "bg-white" : ""}`}
            onClick={handleMenuItemClick}
        >
            <p className={`text-sm duration-150 hover:text-black sm:text-base ${isActive ? "text-black" : "text-black-tertiary"}`}>{text}</p>
        </div>
    );
}

export default AccountSettingsSideMenuItem;

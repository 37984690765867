import { CardWalletType, CreateCardRequestBankingAppStatus } from "../../../../../../models/card.constants";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardDeclinedIcon from "../../../../../../assets/svg/Cards/declined.svg";
import CardDeliveredIcon from "../../../../../../assets/images/Card/CardDelivered.png";
import CardShippedIcon from "../../../../../../assets/images/Card/CardShipped.png";
import CreditCardIcon from "../../../../../../assets/images/Card/CreditCard.png";
import GoogleIcon from "../../../../../../components/google-icon";
import OkIcon from "../../../../../../assets/images/Card/Ok.png";
import React from "react";
import RocketIcon from "../../../../../../assets/images/Card/Rocket.png";
import { ReactComponent as TabActiveIndicatorIcon } from "../../../../../../assets/svg/Cards/TabActiveIndicator.svg";
import { ReactComponent as TabInactiveIndicatorIcon } from "../../../../../../assets/svg/Cards/TabInactiveIndicator.svg";
import TimeGlassIcon from "../../../../../../assets/images/Card/hourglass.png";

interface Props {
    walletType: CardWalletType;
    deliveryPhone: string;
    deliveryAddress: string;
    cardRequestStatus: CreateCardRequestBankingAppStatus | null;

    handleActivateCard: () => void;
}

function CardRequestStatus(props: Props): JSX.Element {
    return (
        <>
            {props.cardRequestStatus && (
                <div className="w-full space-y-4">
                    <div className="flex items-center justify-start space-x-2 capitalize">
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.DECLINED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={CardDeclinedIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Declined</span>
                            </>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.REQUESTED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={TimeGlassIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Requested</span>
                            </>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.APPROVED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={OkIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Approved</span>
                            </>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.PRINTED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={CreditCardIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Printed</span>
                            </>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.SHIPPED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={CardShippedIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Shipped</span>
                            </>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.DELIVERED ? (
                            <>
                                <img className="h-5 overflow-hidden rounded-full" src={CardDeliveredIcon} alt="" />
                                <span className="text-base font-medium text-black">Card Delivered</span>
                            </>
                        ) : null}
                    </div>
                    {props.cardRequestStatus !== CreateCardRequestBankingAppStatus.DECLINED ? (
                        <div className="flex justify-start space-x-2">
                            {/* REQUEST */}
                            <TabActiveIndicatorIcon className="fill-current text-current" />
                            {props.cardRequestStatus >= CreateCardRequestBankingAppStatus.APPROVED ? (
                                <TabActiveIndicatorIcon className="fill-current text-current" />
                            ) : (
                                <TabInactiveIndicatorIcon className="fill-current text-current" />
                            )}
                            {props.cardRequestStatus >= CreateCardRequestBankingAppStatus.PRINTED ? (
                                <TabActiveIndicatorIcon className="fill-current text-current" />
                            ) : (
                                <TabInactiveIndicatorIcon className="fill-current text-current" />
                            )}
                            {props.cardRequestStatus >= CreateCardRequestBankingAppStatus.SHIPPED ? (
                                <TabActiveIndicatorIcon className="fill-current text-current" />
                            ) : (
                                <TabInactiveIndicatorIcon className="fill-current text-current" />
                            )}
                            {props.cardRequestStatus >= CreateCardRequestBankingAppStatus.DELIVERED ? (
                                <TabActiveIndicatorIcon className="fill-current text-current" />
                            ) : (
                                <TabInactiveIndicatorIcon className="fill-current text-current" />
                            )}
                        </div>
                    ) : null}
                    <div>
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.REQUESTED ? (
                            <p className="text-sm font-normal text-black-secondary">
                                Your request has been received and is now under review. We&apos;ll keep you updated on its status.
                            </p>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.DECLINED ? (
                            <p className="text-sm font-normal text-black-secondary">Your request has been declined.</p>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.APPROVED ? (
                            <p className="text-sm font-normal text-black-secondary">
                                Great news! Your card request has been approved. We&apos;re now preparing your business{" "}
                                {props.walletType === CardWalletType.INDEPENDENT_WALLET ? "prepaid" : "debit"} card.
                            </p>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.PRINTED ? (
                            <p className="text-sm font-normal text-black-secondary">
                                Your Lenco {props.walletType === CardWalletType.INDEPENDENT_WALLET ? "prepaid" : "debit"} card is in the final stages
                                of production. We&apos;re printing and personalizing it with your information.
                            </p>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.SHIPPED ? (
                            <p className="text-sm font-normal text-black-secondary">
                                Exciting news! Your business {props.walletType === CardWalletType.INDEPENDENT_WALLET ? "prepaid" : "debit"} card has
                                been shipped and is on its way to you.
                            </p>
                        ) : null}
                        {props.cardRequestStatus === CreateCardRequestBankingAppStatus.DELIVERED ? (
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <p className="text-sm font-normal text-black-secondary">
                                        Congratulations! Your business {props.walletType === CardWalletType.INDEPENDENT_WALLET ? "prepaid" : "debit"}{" "}
                                        card has been delivered to the address you provided.
                                    </p>
                                    <div className="flex items-center justify-start space-x-2 rounded-lg border border-grey bg-grey-backdrop p-2.5 text-xs font-normal text-black-tertiary">
                                        <GoogleIcon icon="location_on" size="sm" isOutlined />
                                        <p>{props.deliveryAddress}</p>
                                        {/* <div>
                                            <p>{props.deliveryPhone}</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="border-t border-grey-secondary"></div>
                                <div className="space-y-2">
                                    <div className="flex items-center justify-start space-x-2">
                                        <img className="h-5" src={RocketIcon} alt="rocket" />
                                        <h3 className="text-base font-medium text-black">Get Started</h3>
                                    </div>
                                    <p className="text-sm font-normal text-black-secondary">
                                        To get started, please follow the activation instructions included with your card.
                                    </p>
                                    <div className="flex justify-center pt-2">
                                        <ButtonComp size="lg" buttonType="primary" fullWidth color="black" onClick={props.handleActivateCard}>
                                            <span className="text-sm">Activate Card</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
}

export default CardRequestStatus;

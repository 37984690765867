import React from "react";

interface Props {
    children: React.ReactNode;
    pageTitle: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    isLoading?: boolean;
    headerButtons?: React.ReactNode;
    isFullPageScroll?: boolean;
    isWithShadowContent?: boolean;
}

function PageLayout(props: Props): JSX.Element {
    return (
        <>
            <div className="flex h-full max-h-full w-full flex-col">
                <div
                    className={
                        "flex flex-col items-start justify-start space-y-4 border-b border-grey pb-3 pt-4 xs:flex-row xs:items-center xs:justify-between xs:space-x-4 xs:space-y-0"
                    }
                >
                    <div className="flex flex-col items-start justify-start space-y-3">
                        {typeof props.pageTitle === "string" ? (
                            <span className="-mt-1 text-base font-medium !leading-[100%] text-black md:text-3xl">{props.pageTitle}</span>
                        ) : (
                            <div className="-mt-1 text-base font-medium !leading-[100%] text-black md:text-3xl">{props.pageTitle}</div>
                        )}
                        {typeof props.subTitle === "string" ? (
                            <span className="text-xs font-normal !leading-[100%] text-black-tertiary md:text-base">{props.subTitle}</span>
                        ) : (
                            <div className="text-xs font-normal !leading-[100%] text-black-tertiary md:text-base">{props.subTitle}</div>
                        )}
                    </div>
                    {props.headerButtons}
                </div>
                <div
                    className={
                        "relative flex w-full flex-grow flex-col py-10 " +
                        `${!props.isFullPageScroll ? "overflow-y-auto scrollbar-hide" : ""} ` +
                        `${props.isWithShadowContent ? "px-1" : ""}`
                    }
                >
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default PageLayout;

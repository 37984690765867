import React from "react";
import ZambiaCollectionRequest from "../../../../../../../models/zambia/zambiaCollectionRequest";

interface Props {
    collection: ZambiaCollectionRequest | null;
}

export default function StatusTag({ collection }: Props) {
    return (
        <div>
            {collection?.isSuccessful && (
                <span className="h-6 rounded border border-success-quin bg-success-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-success">
                    Success
                </span>
            )}
            {collection?.isPending && (
                <span className="h-6 rounded border border-warning-quin bg-warning-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                    Pending
                </span>
            )}{" "}
            {collection?.isExpired && (
                <span className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error">
                    Expired
                </span>
            )}{" "}
            {collection?.isCancelled && (
                <span className="h-6 rounded border border-black-quin bg-grey-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-black">
                    Cancelled
                </span>
            )}{" "}
            {collection?.isFailed && (
                <span className="h-6 rounded border border-error-quin bg-error-backdrop px-3 py-1 text-xs font-medium capitalize leading-none text-error">
                    Failed
                </span>
            )}
        </div>
    );
}

import {
    setElectricityPurchaseDebitAccountId,
    setElectricityPurchaseSelectedUserAccount,
} from "../../../../../../../redux/payments/electricityPurchase/slice/electricityPurchaseSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";

interface UseElectricityPurchasePayFromInterface {
    selectedAccountId: string;
    accounts: UserAccount[] | undefined;
    handleSelectAccount: (_accountId: string) => void;
}

function useElectricityPurchasePayFrom(): UseElectricityPurchasePayFromInterface {
    const originatingAccountId = useSelector((state: IRootState) => state.electricityPurchase.electricityPurchaseDetails.debitAccountId);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const payFrom = useSelector((state: IRootState) => state.billPayment.payFrom);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!accounts) return;
        if (payFrom) {
            handleSelectAccount(payFrom);
        } else {
            const accessAccount = accounts.find((_) => doesUserHaveAccessToAccount(_.id));
            accessAccount && handleSelectAccount(accessAccount.id);
        }
    }, [payFrom, accounts]);

    const handleSelectAccount = useCallback(
        (_accountId: string) => {
            const doesAccountExist = accounts?.some((el) => el.id === _accountId);
            if (doesAccountExist) {
                const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
                dispatch(setElectricityPurchaseDebitAccountId(_accountId));
                dispatch(setElectricityPurchaseSelectedUserAccount(userAccount));
                // if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetSingleTransferRecipient());s
            }
        },
        [accounts]
    );

    return {
        accounts,
        selectedAccountId: originatingAccountId,
        handleSelectAccount,
    };
}

export default useElectricityPurchasePayFrom;

import React, { useLayoutEffect } from "react";
import {
    setIsZambiaCreateApiAccessKeyModalOpen,
    setIsZambiaDeactivateApiAccessKeyModalOpen,
    setIsZambiaRegenerateApiAccessKeyModalOpen,
    setSelectedZambiaApiAccessKey,
    setTempSelectedZambiaApiAccessKey,
} from "../../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CreateApiKeyModal from "./Components/Modals/CreateApiKeyModal";
import GoogleIcon from "../../../../../../components/google-icon";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { useDispatch } from "react-redux";
import usePauseZambiaApiAccessKey from "../../../hookss/ApiAccessKeys/usePauseZambiaApiAccessKey";
import useRestoreZambiaApiAccessKey from "../../../hookss/ApiAccessKeys/useRestoreZambiaApiAccessKey";
import useZambiaApiAccessKeys from "../../../hookss/ApiAccessKeys/useZambiaApiAccessKeys";

const DATA_TYPE = "data-type-api-listt";
const allApiListHeader: TableHead = [
    { text: "API Name", pos: 1, headType: TableColumnType.TEXT },
    { text: "Last Used", pos: 2, headType: TableColumnType.TEXT },
    { text: "Date Created", pos: 3, headType: TableColumnType.TEXT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
    { text: "Action", pos: 5, headType: TableColumnType.ACTION },
];

function ApiList(): JSX.Element {
    const dispatch = useDispatch();
    const {
        apiAccessKeysList,
        apiAccessKeysListTotal,
        apiAccessKeysListGroupSize,
        apiAccessKeysPaginationOffset,
        isApiAccessKeysPaginationLoading,
        handleFilteredZambiaApiAccessKeys,
        handlePaginationRequest,
    } = useZambiaApiAccessKeys();

    const { handlePauseZambiaApiAccessKey } = usePauseZambiaApiAccessKey();
    const { handleRestoreZambiaApiAccessKey } = useRestoreZambiaApiAccessKey();

    useLayoutEffect(() => {
        void handleFilteredZambiaApiAccessKeys({
            offset: 0,
            query: "",
        });
    }, []);

    return (
        <div className="relative flex h-full w-full flex-col items-start justify-start">
            <CreateApiKeyModal />
            <Table
                isSticky
                dataType={DATA_TYPE}
                heads={allApiListHeader}
                isPaginateLoading={isApiAccessKeysPaginationLoading}
                groupSize={apiAccessKeysListGroupSize}
                offset={apiAccessKeysPaginationOffset}
                total={apiAccessKeysListTotal}
                isLoading={!!isApiAccessKeysPaginationLoading}
                paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                noRecordText={
                    <div className="flex flex-col items-center space-y-2">
                        <p className="text-xl font-medium text-black">Create your first API</p>
                        <p className="text-xs font-normal text-black-tertiary">Create and manage your API access</p>
                        <div className="pt-2">
                            <ButtonComp
                                type="button"
                                color="black"
                                buttonType="primary"
                                size="md"
                                onClick={() => dispatch(setIsZambiaCreateApiAccessKeyModalOpen(true))}
                            >
                                <span className="px-2">New API Key</span>
                            </ButtonComp>
                        </div>
                    </div>
                }
                rows={
                    apiAccessKeysList?.map((obj) => ({
                        onRecordClick: () => {
                            dispatch(setSelectedZambiaApiAccessKey(obj));
                        },
                        record: [
                            {
                                key: "API Name",
                                text: obj?.name,
                            },
                            {
                                key: "Last Used",
                                text: obj?.lastUsedAt,
                                textType: TextType.DATE_TIME,
                            },
                            {
                                key: "Date Created",
                                text: obj?.createdAt,
                                textType: TextType.DATE_TIME,
                            },
                            {
                                key: "Status",
                                text: obj?.statusText,
                                textType: obj.getTableStatus(),
                            },
                            {
                                key: "Action",
                                text: "drop-down",
                                textType: TextType.MEAT_BALL_MENU,
                                dropDownOptions: [
                                    {
                                        icon: <GoogleIcon icon="play_circle" data-type={DATA_TYPE} />,
                                        text: "Go Live",
                                        func: () => handleRestoreZambiaApiAccessKey({ id: obj.id }),
                                        show: !!obj?.isPaused,
                                    },
                                    {
                                        icon: <GoogleIcon icon="pause_circle" data-type={DATA_TYPE} />,
                                        text: " Pause",
                                        func: () => handlePauseZambiaApiAccessKey({ id: obj.id }),
                                        show: !!obj?.isActive,
                                    },
                                    {
                                        icon: <GoogleIcon icon="refresh" data-type={DATA_TYPE} />,
                                        text: "Regenerate Keys",
                                        func: () => {
                                            dispatch(setTempSelectedZambiaApiAccessKey(obj));
                                            dispatch(setIsZambiaRegenerateApiAccessKeyModalOpen(true));
                                        },
                                        show: true,
                                    },
                                    {
                                        icon: <GoogleIcon icon="block" data-type={DATA_TYPE} />,
                                        text: "Deactivate",
                                        isDanger: true,
                                        func: () => () => {
                                            dispatch(setTempSelectedZambiaApiAccessKey(obj));
                                            dispatch(setIsZambiaDeactivateApiAccessKeyModalOpen(true));
                                        },
                                        show: true,
                                    },
                                ],
                            },
                        ],
                    })) || []
                }
            />
        </div>
    );
}

export default ApiList;

import InviteTeamMember from "../Components/team-members/invite/InviteTeamMember";
import { MobileTeamMemberListPageState } from "../Services/team-members/team-members.constants";
import PageLayout from "../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../helpers/AppConstants";
import React from "react";
import TeamMemberDetails from "../Components/team-members/details/TeamMemberDetails";
import TeamMemberList from "../Components/team-members/list/TeamMemberList";
import TeamMemberListMobileBack from "../Components/team-members/list/TeamMemberListMobileBack";
import useTeamMemberList from "../Services/team-members/hooks/useTeamMemberList";

function TeamMembers(): JSX.Element {
    const {
        filterTerm,
        isMobileLayout,
        selectedTeamMember,
        filteredTeamMembers,
        isLoadingTeamMembers,
        mobileLayoutPageState,
        isSelectedTeamMemberLoading,

        addNewTeamMember,
        removeTeamMember,
        updateAccountMeta,
        handleSetFilterTerm,
        backToMobileListPage,
        handleSelectTeamMember,
        handleAddNewAccounts,
        removeAccountMetaFromTeamMember,

        userAccountNames,
        user,
    } = useTeamMemberList();

    document.title = PageTitle.TEAM_MEMBERS_SETTINGS_PAGE;

    return (
        <>
            <PageLayout
                pageTitle="Settings"
                subTitle="Add instructions, and set restrictions to your Lenco bank accounts"
                headerButtons={<InviteTeamMember onComplete={addNewTeamMember} />}
                isFullPageScroll
            >
                <div className="flex w-full flex-col items-center justify-start -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available">
                    <TeamMemberListMobileBack
                        isMobileLayout={isMobileLayout}
                        mobileLayoutPageState={mobileLayoutPageState}
                        goBack={backToMobileListPage}
                    />

                    <div className="relative flex w-full flex-col items-start justify-start -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available xl:flex-row">
                        <div
                            className={
                                "w-full overflow-hidden -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available xl:max-w-17 " +
                                `${isMobileLayout && mobileLayoutPageState === MobileTeamMemberListPageState.DETAILS ? "hidden" : "flex flex-col"} `
                            }
                        >
                            <TeamMemberList
                                filterTerm={filterTerm}
                                handleSetFilterTerm={handleSetFilterTerm}
                                isLoadingTeamMembers={isLoadingTeamMembers}
                                filteredTeamMembers={filteredTeamMembers}
                                selectedTeamMember={selectedTeamMember}
                                handleSelectTeamMember={(id) => {
                                    handleSelectTeamMember(id);
                                }}
                            />
                        </div>
                        <TeamMemberDetails
                            goBack={backToMobileListPage}
                            isMobileLayout={isMobileLayout}
                            selectedTeamMember={selectedTeamMember}
                            mobileLayoutPageState={mobileLayoutPageState}
                            isSelectedTeamMemberLoading={isSelectedTeamMemberLoading}
                            onRemoveFromTeam={removeTeamMember}
                            handleAddNewAccounts={handleAddNewAccounts}
                            updateAccountMeta={updateAccountMeta}
                            removeAccountMetaFromTeamMember={removeAccountMetaFromTeamMember}
                            userAccountNames={userAccountNames}
                            isSelf={user?.id === selectedTeamMember?.teamMember.id}
                        />
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default TeamMembers;

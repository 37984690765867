import * as Yup from "yup";

import { Form, Formik } from "formik";

import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import Input from "../../../../../../../../components/inputs/Input";
import LencoSpinner from "../../../../../../../../components/spinner";
import Text from "../../../../../../../../components/general/Text";
import useRemoveZambiaApiAccessKeyWebhookUrl from "../../../../../hookss/ApiAccessKeySettings/useRemoveZambiaApiAccessKeyWebhookUrl";
import { useSelector } from "react-redux";
import useUpdateZambiaApiAccessKeyWebhookUrl from "../../../../../hookss/ApiAccessKeySettings/useUpdateZambiaApiAccessKeyWebhookUrl";

function Webhook(): JSX.Element {
    const { isUpdateZambiaApiAccessKeyWebhookUrlLoading, handleUpdateZambiaApiAccessKeyWebhookUrl } = useUpdateZambiaApiAccessKeyWebhookUrl();
    const { isRemoveZambiaApiAccessKeyWebhookUrlLoading, handleRemoveZambiaApiAccessKeyWebhookUrl } = useRemoveZambiaApiAccessKeyWebhookUrl();

    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);

    const INITIAL_FORM_STATE = {
        id: selectedApiAccessKey?.id || "",
        webhookUrl: selectedApiAccessKey?.webhookUrl || "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        id: Yup.string().required("Required"),
        webhookUrl: Yup.string().required("Required"),
    });

    return (
        <>
            {selectedApiAccessKey && (
                <div className="w-full space-y-6">
                    <div className="">
                        <p className="text-base font-medium">Webhooks Configuration</p>
                        <div className="text-xs text-black-tertiary">Authenticate and secure your API requests with unique credentials</div>
                    </div>

                    <Formik
                        initialValues={{
                            ...INITIAL_FORM_STATE,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => {
                            handleUpdateZambiaApiAccessKeyWebhookUrl(values);
                        }}
                        enableReinitialize
                        validateOnMount
                        validateOnBlur
                    >
                        {(formik) => {
                            return (
                                <Form className="w-full">
                                    <div className="flex w-full flex-col items-start justify-start space-y-12">
                                        <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg bg-white px-4 py-4">
                                            <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                                <p className="min-w-10 text-base text-black-secondary">Webhook URL</p>
                                                <Input
                                                    bg=" !bg-grey-backdrop"
                                                    label=""
                                                    value={formik.values.webhookUrl}
                                                    disabled={!selectedApiAccessKey.isAccessible}
                                                    inputSize="lg"
                                                    isLoading={isRemoveZambiaApiAccessKeyWebhookUrlLoading}
                                                    isDisabled={!selectedApiAccessKey.isAccessible}
                                                    inputPlaceholder="https://"
                                                    iconRightClassName={
                                                        selectedApiAccessKey?.webhookUrl ? "text-black-tertiary hover:text-error-secondary " : ""
                                                    }
                                                    onChange={(_value) => formik.getFieldHelpers("webhookUrl").setValue(_value)}
                                                    onClickRightIcon={
                                                        selectedApiAccessKey?.webhookUrl
                                                            ? () => handleRemoveZambiaApiAccessKeyWebhookUrl({ id: selectedApiAccessKey.id })
                                                            : undefined
                                                    }
                                                    iconRight={
                                                        selectedApiAccessKey?.webhookUrl ? (
                                                            <div className="flex w-6 items-center justify-center">
                                                                {isRemoveZambiaApiAccessKeyWebhookUrlLoading ? (
                                                                    <LencoSpinner size="sm" />
                                                                ) : (
                                                                    <GoogleIcon icon="delete" />
                                                                )}
                                                            </div>
                                                        ) : undefined
                                                    }
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="w-full border-b border-grey-secondary"></div>

                                            <div className="flex w-full flex-col justify-between space-y-4 2xs:flex-row 2xs:items-center 2xs:space-x-6 2xs:space-y-0">
                                                <p className="min-w-10 text-base text-black-secondary">Signature key</p>
                                                <Text
                                                    fullWidth
                                                    text={selectedApiAccessKey.webhookSignatureKey}
                                                    isDisabled={!selectedApiAccessKey.isAccessible}
                                                    withClipboard={selectedApiAccessKey.isAccessible}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex w-full 2xs:space-x-6">
                                            <div className="flex w-full items-center justify-center">
                                                <ButtonComp
                                                    type="submit"
                                                    color="black"
                                                    buttonType="primary"
                                                    disable={!formik.isValid || !formik.dirty || !selectedApiAccessKey.isAccessible}
                                                    isLoading={isUpdateZambiaApiAccessKeyWebhookUrlLoading}
                                                >
                                                    Save Changes
                                                </ButtonComp>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        </>
    );
}

export default Webhook;

import { createSlice } from "@reduxjs/toolkit";

export interface FilterState {
    isReset: boolean;
    isDateReset: boolean;
}

const initialState: FilterState = {
    isReset: false,
    isDateReset: false,
};

export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        resetFilter: (state: FilterState) => {
            state.isReset = true;
        },
        dateResetFilter: (state: FilterState) => {
            state.isDateReset = true;
        },
        restDateResetFilter: (state: FilterState) => {
            state.isDateReset = false;
        },
        resetAllFilterData: (state: FilterState) => {
            state.isReset = false;
            state.isDateReset = false;
        },
    },
});

export const { resetFilter, dateResetFilter, restDateResetFilter, resetAllFilterData } = filterSlice.actions;

export default filterSlice.reducer;

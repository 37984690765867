import { FilterDateObj, FilterDateType, FilterDateTypes, getFilterCount } from "../../../../../components/filter/filter.constant";
import { FilterOptionSelectType, FilterOptions } from "../../../../../components/Table/table-filter.types";
import { setIsZambiaPayoutsPaginationLoading, setZambiaPayoutsList } from "../../../../../redux/zambia/payouts/zambiaPayoutsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";

import Parsers from "../../../../../utils/parsers";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { TransactionStatus } from "../../../../../models/transaction.constants";
import { ZambiaPayoutsRequest } from "../../Services/Payouts/zambiaPayoutsApi.types";
import { ZambiaPayoutsStatusOptions } from "./zambia-payouts.constants";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { zambiaPayouts } from "../../Services/Payouts/zambiaPayoutsApi";

function useZambiaPayouts() {
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [groupSize, setGroupSize] = useState(0);

    const [filter, setFilter] = useState<ZambiaPayoutsRequest>({});
    const [dateType, setDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilter, setSelectedFilter] = useState<ZambiaPayoutsRequest>({});
    const [selectedDateType, setSelectedDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const accounts = useAppSelector((state) => state?.init.main?.companyDetails?.accounts || []);
    const zambiaPayoutsList = useAppSelector((state) => state?.zambiaPayouts?.zambiaPayoutsList);
    const isZambiaPayoutsPaginationLoading = useAppSelector((state) => state?.zambiaPayouts?.isZambiaPayoutsPaginationLoading);

    useEffect(() => {
        setSelectedFilterCount(getFilterCount(filter));
    }, [filter]);

    const handleFilteredZambiaPayouts = useCallback(async (_data: ZambiaPayoutsRequest) => {
        try {
            dispatch(setIsZambiaPayoutsPaginationLoading(true));
            const res = await zambiaPayouts(_data);
            dispatch(setZambiaPayoutsList(res.transactions));
            setTotal(res.total);
            setGroupSize(res.groupSize);
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            dispatch(setIsZambiaPayoutsPaginationLoading(false));
        }
    }, []);

    const handleApplyFilter = async () => {
        setSelectedFilter(filter);
        setSelectedDateType(dateType);
        await handleFilteredZambiaPayouts(filter);
    };

    const handlePaginationRequest = async (_offset: number) => {
        setOffset(_offset);
        await handleFilteredZambiaPayouts({ offset });
    };

    const handleClearFilter = useCallback(async () => {
        setOffset(0);
        setFilter({});
        setSelectedFilter({});
        setDateType(FilterDateTypes.ALL_TIME);
        setSelectedDateType(FilterDateTypes.ALL_TIME);
        await handleFilteredZambiaPayouts({});
    }, []);

    const filterOption = useMemo(
        (): FilterOptions => ({
            filterOptions: [
                {
                    text: "Date",
                    value:
                        filter.date !== "" && !isNullOrUndefined(filter.date) ? ({ name: dateType, date: filter.date } as FilterDateType) : undefined,
                    selectType: FilterOptionSelectType.DATE_OPTION,
                    selectedValue:
                        selectedFilter.date !== "" || isNullOrUndefined(selectedFilter.date)
                            ? ({ name: selectedDateType, date: selectedFilter.date } as FilterDateType)
                            : undefined,
                    onClick: (_value) => {
                        if (!(typeof _value === "object" && "date" in _value && "name" in _value)) return;
                        setFilter((prev) => ({ ...prev, date: _value.date as FilterDateObj }));
                        setDateType(_value.name as FilterDateTypes);
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, date: "" };
                        handleFilteredZambiaPayouts(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Status",
                    value: filter.statuses,
                    subList: ZambiaPayoutsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.statuses,
                    onClick: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, TransactionStatus);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            statuses: prev.statuses
                                ? prev.statuses?.includes(filterValue)
                                    ? prev.statuses.filter((_) => _ !== filterValue)
                                    : [...prev.statuses, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, statuses: [] };
                        handleFilteredZambiaPayouts(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, TransactionStatus);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            statuses: filter?.statuses?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleFilteredZambiaPayouts(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Account",
                    value: filter.userAccountIds,
                    subList: accounts.map((_) => ({ text: _.lencoNameMin, value: _.id })),
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.userAccountIds,
                    onClick: (_value) => {
                        const filterValue = Parsers.string(_value);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            userAccountIds: prev.userAccountIds
                                ? prev.userAccountIds?.includes(filterValue)
                                    ? prev.userAccountIds.filter((_) => _ !== filterValue)
                                    : [...prev.userAccountIds, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, userAccountIds: [] };
                        handleFilteredZambiaPayouts(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.string(_value);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            userAccountIds: filter?.userAccountIds?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleFilteredZambiaPayouts(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
            ],
        }),
        [filter, accounts, selectedFilter, selectedDateType]
    );

    return {
        total,
        offset,
        groupSize,
        filterOption,
        zambiaPayoutsList,
        selectedFilterCount,
        isZambiaPayoutsPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaPayouts,
    };
}

export default useZambiaPayouts;

import { SetStateAction, useEffect, useState } from "react";

import { AccountRestrictionType } from "../../../../../models/accountRestriction.constants";
import ButtonComp from "../../../../../components/button/ButtonComp";

interface Props {
    canCancel?: boolean;
    formLength?: number;
    initialType?: AccountRestrictionType;
    isFormValid?: boolean;
    isSubmitting: boolean;
    isDisableCancel?: boolean;
    handleCancel?: () => void;
    handleSubmit?: () => void;
    handleInitialType?: (value: SetStateAction<AccountRestrictionType>) => void;
}

function AccountSettingsActionButtons(props: Props): JSX.Element {
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (props.isFormValid === false) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [props.isFormValid]);

    return (
        <div className="flex w-full flex-col items-center justify-center space-x-0 space-y-4 5xs:flex-row 5xs:items-center 5xs:space-x-4 5xs:space-y-0">
            <div className={props.formLength === 2 && !props.isSubmitting && props.isDisableCancel ? "invisible w-1/2" : "block w-1/2"}>
                <ButtonComp
                    type="button"
                    ripple="light"
                    size="lg"
                    buttonType="secondary"
                    color="grey"
                    disable={props.isSubmitting || props.isDisableCancel}
                    func={props.handleCancel}
                    fullWidth
                >
                    Back
                </ButtonComp>
            </div>
            <div className="w-1/2">
                <ButtonComp
                    type="submit"
                    color="black"
                    ripple="light"
                    size="lg"
                    buttonType="primary"
                    isLoading={props.isSubmitting}
                    // disable={props.isFormValid === false}
                    disable={isDisabled}
                    func={props.handleSubmit}
                    fullWidth
                >
                    Save Changes
                </ButtonComp>
            </div>
        </div>
    );
}

export default AccountSettingsActionButtons;

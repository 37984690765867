import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardMain from "../../../../../../models/cardMain";
import GoogleIcon from "../../../../../../components/google-icon";
import React from "react";
import titleCase from "../../../../../../hooks/titleCase";
import { useNavigate } from "react-router";

interface ICardLinkedTo {
    isSelectedCardDetailsOpen: boolean;
    cardData: CardMain;
}

function CardLinkedTo({ isSelectedCardDetailsOpen, cardData }: ICardLinkedTo): JSX.Element {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full border-b border-grey-secondary pb-6 pt-6" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                <div className="max-w-md space-y-4" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}>
                    <div className="flex items-center justify-between">
                        <h3 className="text-base font-medium text-black">Card Linked To</h3>
                        <ButtonComp color="blue" buttonType="tertiary" func={() => navigate(`/accounts/${cardData.card?.linkedAccount?.id || "#"}`)}>
                            <GoogleIcon icon="business_center" size="xs" className="text-sm" />
                            <span className="ml-2 text-sm capitalize">View account</span>
                        </ButtonComp>
                    </div>
                    <div
                        className="w-max rounded-full bg-grey-tertiary pb-1 pl-2 pr-2 pt-1 text-sm font-normal text-black-secondary"
                        data-type="card"
                    >
                        <span className="text-sm font-normal text-black-secondary" data-type="card">
                            {titleCase(
                                (cardData.card?.linkedAccount?.isMain
                                    ? cardData.card?.linkedAccount?.name
                                    : cardData.card?.linkedAccount?.lencoNameMin) || ""
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CardLinkedTo;

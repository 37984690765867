import { FilterDateObj } from "../filter/filter.constant";
import { GenericObject } from "../../helpers/types";

export type FilterValueType = number | string | FilterDateObj | GenericObject;

export interface FilterDropdownItem<T extends FilterValueType> {
    text: string;
    icon?: string;
    value: T;
    subtext?: string;
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
}

export enum FilterOptionSelectType {
    DATE_OPTION = "date",
    RADIO_OPTION = "radio",
    CHECKBOX_OPTION = "checkbox",
}

export type FilterOptionSubList = {
    text?: string;
    value?: FilterValueType;
    subText?: string;
    onClick?: (data: FilterValueType) => void;
};

export type TableFilterOption<T extends FilterValueType, U extends FilterOptionSubList> = {
    text: string;
    icon?: string;
    value?: T[] | T;
    subList?: U[];
    selectType?: FilterOptionSelectType;
    selectedValue?: Array<T> | T;
    onClick?: (data: FilterValueType) => void;
    onCancel?: () => void;
    onIndividualCancel?: (_value: FilterValueType) => void;
};

export type TableFilterProps<T extends FilterValueType, U extends FilterOptionSubList> = {
    options: TableFilterOption<T, U>[];
    isFilterLeft?: boolean;
    totalSelectedItems: number;
    handleApplyFilter?: () => void;
    handleClearFilter: () => void;
};

//outside filter types
export type FilterOption = {
    text: string;
    icon?: string;
    value?: Array<FilterValueType> | FilterValueType;
    subList?: { text?: string; value?: FilterValueType; subText?: string; onClick?: (data: FilterValueType) => void }[];
    selectType?: FilterOptionSelectType;
    selectedValue?: Array<FilterValueType> | FilterValueType;
    onClick?: (data: FilterValueType) => void;
    onCancel?: () => void;
    onIndividualCancel?: (_value: FilterValueType) => void;
};

export type FilterOptions = {
    filterOptions: FilterOption[];
    handleApplyFilter?: () => void;
};

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange, Range, RangeKeyDict } from "react-date-range";
import { FilterDateObj, FilterDateType, FilterDateTypes } from "../filter/filter.constant";
import { useCallback, useEffect, useState } from "react";

import ButtonComp from "../button/ButtonComp";
import { DateParse } from "../../utils/dateParsers";
import MessageToast from "../message-toast";
import Modal from "./Modal";
import ModalBody from "./modal-body";
import ModalFooter from "./modal-footer";
import ModalHeader from "./modal-header";
import moment from "moment";
import useDimension from "../../hooks/useDimension";

interface Props {
    isActive: boolean;
    dataType: string;
    is3MonthsMax?: boolean;
    handleSelectDate: (_date: FilterDateType) => void;
    handleToggleModal: () => void;
}

function DateModal(props: Props): JSX.Element {
    const { width } = useDimension();

    const [date, setDate] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    //reset filled
    useEffect(() => {
        if (!props.isActive) return;
        setDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
        ]);
    }, [props.isActive]);

    const handleCloseCustomDatePicker = useCallback(() => {
        props.handleToggleModal();
    }, []);

    const handleDateRange = useCallback((_data: FilterDateObj) => {
        props.handleSelectDate({
            name: FilterDateTypes.CUSTOM,
            date: { begin: _data.begin as string, end: _data.end as string },
        });
    }, []);

    return (
        <>
            <Modal size={width < 476 ? "fill" : "lg"} active={props.isActive} toggler={handleCloseCustomDatePicker} dataType={props.dataType}>
                <ModalHeader dataType={props.dataType} onClose={handleCloseCustomDatePicker}>
                    Choose a Date Range
                </ModalHeader>
                <ModalBody dataType={props.dataType}>
                    <div className="relative flex w-full items-center justify-center [&>div]:w-full" data-type={props.dataType}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={(rangesByKey: RangeKeyDict) => {
                                setDate([
                                    (
                                        rangesByKey as unknown as {
                                            selection: {
                                                startDate: Date;
                                                endDate: Date;
                                                key: string;
                                            };
                                        }
                                    ).selection,
                                ]);
                            }}
                            // showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={width > 768 ? 2 : undefined}
                            maxDate={
                                props.is3MonthsMax
                                    ? date[0].startDate &&
                                      (DateParse.isToday(date[0].startDate)
                                          ? new Date()
                                          : DateParse.isFuture(DateParse.addDays(date[0].startDate, 90))
                                            ? new Date()
                                            : DateParse.addDays(date[0].startDate, 90))
                                    : new Date()
                            }
                            ranges={date}
                            direction="horizontal"
                            data-type={props.dataType}
                        />
                    </div>
                    <div className="mx-auto w-max" data-type={props.dataType}>
                        <MessageToast message="You can only select dates within a Three(3) months window" dataType={props.dataType} type="warning" />
                    </div>
                </ModalBody>

                <ModalFooter dataType={props.dataType}>
                    <div className="flex w-full flex-col space-y-4 2xs:w-max 2xs:flex-row 2xs:space-x-4 2xs:space-y-0" data-type={props.dataType}>
                        <div className="w-full 2xs:w-max" data-type={props.dataType}>
                            <ButtonComp
                                type="button"
                                buttonType="secondary"
                                color="grey"
                                ripple="light"
                                fullWidth
                                func={handleCloseCustomDatePicker}
                                dataType={props.dataType}
                            >
                                <span data-type={props.dataType}>Cancel</span>
                            </ButtonComp>
                        </div>
                        <div className="w-full 2xs:w-max" data-type={props.dataType}>
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                fullWidth
                                dataType={props.dataType}
                                disable={date[0].startDate && DateParse.differenceInDays(date[0].startDate, date[0].endDate) > 90}
                                func={() => {
                                    handleCloseCustomDatePicker();
                                    const { startDate, endDate } = date[0];
                                    handleDateRange({
                                        begin: moment(startDate).format().slice(0, 10),
                                        end: moment(endDate).format().slice(0, 10),
                                    });
                                }}
                            >
                                <span data-type={props.dataType}>Select Date</span>
                            </ButtonComp>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default DateModal;

import { addAccountToNewUserGroup, addAccountToUserGroup, removeAccountFromUserGroup } from "../../../../../People/servicess/peopleApi";
import { useCallback, useState } from "react";

import CustomerAccount from "../../../../../../../models/customerAccount";
import { MainInitCompanyDetailsInterface } from "../../../../../../../redux/init/slice/initSlice.types";
import UserGroup from "../../../../../../../models/userGroup";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { mainUpdateCompanyDetails } from "../../../../../../../redux/init/slice/initSlice";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { setCustomerAccount } from "../../../../../../../redux/customer-account/customerAccountSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

export interface UseTransactionDetailsUserGroupsResponse {
    userGroupOptions: UserGroup[];
    isSubmitting: boolean;
    isError: boolean;
    handleAddAccountToUserGroup: (account: CustomerAccount, userGroupId: string) => Promise<void>;
    handleCreateAndAddAccountToUserGroup: (account: CustomerAccount, name: string) => Promise<void>;
    handleRemoveAccountFromUserGroup: (account: CustomerAccount) => Promise<void>;
}

function useTransactionDetailsUserGroups(): UseTransactionDetailsUserGroupsResponse {
    const dispatch = useDispatch();

    const userGroupsData = useAppSelector((state) => state.init.main?.companyDetails.userGroups || []);

    const userGroupOptions = userGroupsData.filter((_userGroups) => !_userGroups.isDeleted) || [];

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const handleAddAccountToUserGroup = useCallback(
        async (account: CustomerAccount, userGroupId: string): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const updatedCustomerAccount = await addAccountToUserGroup({ account: account.toPaymentRecipient(), userGroupId });
                dispatch(setCustomerAccount(updatedCustomerAccount));
                dispatch(messageTrue({ message: "Recipient Added Successfully" }));
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleCreateAndAddAccountToUserGroup = useCallback(
        async (account: CustomerAccount, name: string): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const res = await addAccountToNewUserGroup({ account: account.toPaymentRecipient(), name });
                dispatch(setCustomerAccount(res.account));
                dispatch(
                    mainUpdateCompanyDetails({
                        callback: (companyDetails: MainInitCompanyDetailsInterface) => {
                            companyDetails.userGroups.push(res.userGroup);
                        },
                    })
                );
                dispatch(messageTrue({ message: "Recipient Added Successfully" }));
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    const handleRemoveAccountFromUserGroup = useCallback(
        async (account: CustomerAccount): Promise<void> => {
            try {
                setIsError(false);
                setIsSubmitting(true);
                const updatedCustomerAccount = await removeAccountFromUserGroup(account.id);
                dispatch(setCustomerAccount(updatedCustomerAccount));
                dispatch(messageTrue({ message: "Recipient Removed Successfully" }));
            } catch (err) {
                setIsError(true);
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return {
        isError,
        userGroupOptions,
        isSubmitting,
        handleAddAccountToUserGroup,
        handleCreateAndAddAccountToUserGroup,
        handleRemoveAccountFromUserGroup,
    };
}

export default useTransactionDetailsUserGroups;

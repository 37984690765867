import { openAboutMyAccountModal, resetIndividualAccount, resetSubAccountData } from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AboutMyAccountTray from "../../Components/Trays/AboutMyAccount";
import { AccountModalStageType } from "../../../../../redux/accounts/slice/accountsSlice.types";
import CreateSubAccountTray from "../../Components/Trays/CreateSubAccountTray";
import EditAccountDescription from "../../Components/Modals/EditAccountDescriptionModal";
import { IRootState } from "../../../../../redux/rootReducer";
import IndividualAccountChartCard from "../../Components/Cards/IndividualAccountChartCard";
import IndividualAccountsDetailsCard from "../../Components/Cards/IndividualAccountsDetailsCard";
import InfoTextButton from "../../../../../components/button/Info-text-button";
import PageLayout from "../../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../../helpers/AppConstants";
import Skeleton from "../../../../../components/skeleton";
import TabButton from "../../../../../components/TabButton";
import TransactionsListCard from "../../../Home copy/Components/Cards/transactions-list-card/transactions-list-card";
import { abortIndividualAccountTransfer } from "../../Services/accounts-api";
import titleCase from "../../../../../hooks/titleCase";
import useIndividualAccount from "../../Hooks/State/useIndividualAccount";
import { useNavigate } from "react-router-dom";

function IndividualAccountDetails(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { inflow, outflow, pending, showAmount, handleToggleShowAmount } = useIndividualAccount();

    const [isLoading, setIsLoading] = useState(true);

    const main = useSelector((state: IRootState) => state.init.main);
    const accountSummary = useSelector((state: IRootState) => state.account.individualAccountSummary);
    const individualAccount = useSelector((state: IRootState) => state.account.individualAccount);
    const subAccountModalStage = useSelector((state: IRootState) => state.account.subAccountModalStage);

    const handleCloseCreateSubAccountModal = useCallback(() => {
        dispatch(resetSubAccountData());
    }, []);

    useEffect(() => {
        return () => {
            abortIndividualAccountTransfer();
            dispatch(resetIndividualAccount());
            dispatch(resetSubAccountData());
        };
    }, []);

    useEffect(() => {
        if (!individualAccount) {
            document.title = PageTitle.INDIVIDUAL_ACCOUNT_PAGE;
            return;
        }
        document.title = `${titleCase(individualAccount.accountName)} ${PageTitle.INDIVIDUAL_ACCOUNT_PAGE}`;
        setIsLoading(!(accountSummary && individualAccount));
    }, [accountSummary, individualAccount]);

    const handleOpenAboutMyAccountModal = useCallback(() => {
        dispatch(openAboutMyAccountModal());
    }, []);

    const handleGoBack = useCallback(() => {
        navigate("/accounts");
    }, []);

    return (
        <>
            <AboutMyAccountTray />

            <EditAccountDescription />

            <CreateSubAccountTray active={!!(subAccountModalStage === AccountModalStageType.DETAILS)} toggler={handleCloseCreateSubAccountModal} />

            <PageLayout
                pageTitle={
                    <div>
                        {!individualAccount || !accountSummary ? (
                            <div className="h-[30px] w-28">
                                <Skeleton box />
                            </div>
                        ) : (
                            <span>{individualAccount?.bankAccount?.formattedLencoAccountName || ""}</span>
                        )}
                    </div>
                }
                subTitle={
                    <div>
                        {!individualAccount || !accountSummary ? (
                            <div className="h-4 w-44">
                                <Skeleton box />
                            </div>
                        ) : (
                            <span>{individualAccount?.description || ""}</span>
                        )}
                    </div>
                }
                headerButtons={
                    <InfoTextButton
                        isLoading={!individualAccount || !accountSummary}
                        onClick={handleOpenAboutMyAccountModal}
                        content={<span className="font-normal text-black">About My Account</span>}
                    />
                }
                isWithShadowContent
            >
                <div className="flex w-full flex-col space-y-10 pb-5">
                    {!(main?.companyDetails.company.isTypeStarter && main.companyDetails.accounts.length < 2) && (
                        <TabButton
                            isLoading={!individualAccount || !accountSummary}
                            buttons={[
                                {
                                    text: "Accounts",
                                    showSubTab: true,
                                    isActive: true,
                                    onClick: handleGoBack,
                                    subTab: {
                                        text: individualAccount?.bankAccount?.formattedLencoAccountName || "",
                                        onClose: handleGoBack,
                                    },
                                },
                            ]}
                        />
                    )}

                    <div className="space-y-5">
                        <div className="grid grid-cols-1 items-stretch gap-5 xl:grid-cols-2">
                            <IndividualAccountsDetailsCard isLoading={isLoading} />
                            <IndividualAccountChartCard
                                isLoading={isLoading}
                                showAmount={showAmount}
                                accountSummary={accountSummary}
                                individualAccount={individualAccount}
                                handleToggleShowAmount={handleToggleShowAmount}
                            />
                        </div>
                        <div className="grid grid-cols-1 space-y-5 xl:grid-cols-3 xl:space-x-5 xl:space-y-0">
                            <TransactionsListCard isLoaded={!isLoading} transactions={inflow} type="inflow" accountId={individualAccount?.id} />
                            <TransactionsListCard isLoaded={!isLoading} transactions={outflow} type="payout" accountId={individualAccount?.id} />
                            <TransactionsListCard isLoaded={!isLoading} transactions={pending} type="pending-transfer" />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default IndividualAccountDetails;

import GoogleIcon from "../../google-icon";
import React from "react";

interface Props {
    menu?: React.ReactNode;
    size?: "md" | "lg";
    dataType: string;
    children: React.ReactNode;
    subTitle?: React.ReactNode;
    toggler: () => void;
}
function FullPageTrayHeader(props: Props): JSX.Element {
    return (
        <div
            className={
                "relative flex w-full items-center justify-start px-7 " +
                `${props.size === "md" ? "py-5" : ""}` +
                `${!props.size || props.size === "lg" ? "py-6" : ""}`
            }
            data-type={props.dataType}
        >
            <div className="relative flex w-full items-center justify-start" data-type={props.dataType}>
                {props.menu ? (
                    <div className="flex w-full flex-col items-center justify-start" data-type={props.dataType}>
                        <div className="flex w-full flex-row items-center justify-between" data-type={props.dataType}>
                            <div className="flex w-full flex-row items-center justify-start space-x-2" data-type={props.dataType}>
                                <button
                                    className={
                                        "flex h-10 w-10 items-center justify-center rounded-md bg-grey-tertiary outline-none transition-all focus:outline-none " +
                                        `${props.size === "md" ? "h-9 w-9" : ""}` +
                                        `${!props.size || props.size === "lg" ? "h-10 w-10" : ""}`
                                    }
                                    onClick={props.toggler}
                                    data-type={props.dataType}
                                >
                                    <GoogleIcon icon="arrow_left_alt" size="lg" dataType={props.dataType} />
                                </button>
                                <div className="max-w-sm pl-2 text-xl font-normal capitalize text-black" data-type={props.dataType}>
                                    {props.children}
                                </div>
                            </div>
                            {props.menu && props.menu}
                        </div>
                        {props.subTitle && (
                            <div className="ml-20 flex w-full flex-col items-start justify-start" data-type={props.dataType}>
                                <div className="max-w-sm text-sm text-black-tertiary" data-type={props.dataType}>
                                    {props.subTitle}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex w-full flex-col items-center justify-start" data-type={props.dataType}>
                        <div className="flex w-full flex-row items-center justify-between" data-type={props.dataType}>
                            {typeof props.children === "string" ? (
                                <p className="max-w-sm text-xl font-normal capitalize text-black" data-type={props.dataType}>
                                    {props.children}
                                </p>
                            ) : (
                                <div className="max-w-sm text-xl font-normal capitalize text-black" data-type={props.dataType}>
                                    {props.children}
                                </div>
                            )}
                            <button
                                className={
                                    "flex h-10 w-10 items-center justify-center rounded-md bg-grey-tertiary outline-none transition-all focus:outline-none " +
                                    `${props.size === "md" ? "h-9 w-9" : ""}` +
                                    `${!props.size || props.size === "lg" ? "h-10 w-10" : ""}`
                                }
                                onClick={props.toggler}
                                data-type={props.dataType}
                            >
                                <GoogleIcon icon="close" size={props.size === "md" ? "md" : "lg"} dataType={props.dataType} />
                            </button>
                        </div>
                        {props.subTitle && (
                            <div className="flex w-full flex-col items-start justify-start" data-type={props.dataType}>
                                <p className="max-w-xs text-sm text-black-tertiary" data-type={props.dataType}>
                                    {props.subTitle}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FullPageTrayHeader;

import { DataColumn } from "../Type/DataRow";
import Money from "../../money";
import { ReactNode } from "react";
import { SubTextType } from "../Type/SubTextType";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDate";
import { formatTime } from "../../../utils/formatDate";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function TransactionAmountWithSubtext({ dataColumn, dataType }: Props) {
    return (
        <>
            {dataColumn.textType === TextType.ERROR ? (
                <p className="text-error">{typeof dataColumn.text === "string" ? dataColumn.text : ""}</p>
            ) : (
                <span data-type={dataType} className="flex flex-col items-start gap-x-2 gap-y-1 leading-none">
                    {dataColumn.textType === TextType.FINAL_GAIN && (
                        <span data-type={dataType} className="text-base font-normal text-success">
                            <span>+</span>
                            {(typeof dataColumn.text === "string" || typeof dataColumn.text === "number") && (
                                <Money currency={dataColumn.currency} amount={dataColumn.text} />
                            )}
                        </span>
                    )}
                    {dataColumn.textType === TextType.FINAL_LOSS && (
                        <span data-type={dataType} className="text-base font-normal capitalize text-black-secondary">
                            <span>-</span>
                            {(typeof dataColumn.text === "string" || typeof dataColumn.text === "number") && (
                                <Money currency={dataColumn.currency} amount={dataColumn.text} />
                            )}
                        </span>
                    )}
                    {dataColumn.textType === TextType.PROCESSING && (
                        <span
                            data-type={dataType}
                            className="rounded-lg border border-warning-quin bg-warning-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-warning"
                        >
                            Processing
                        </span>
                    )}
                    {dataColumn.textType === TextType.FAILED && (
                        <span
                            data-type={dataType}
                            className="rounded-lg border border-error-quin bg-error-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-error"
                        >
                            Failed
                        </span>
                    )}
                    {dataColumn.textType === TextType.CANCELLED && (
                        <span
                            data-type={dataType}
                            className="rounded-lg border border-black-quin bg-grey-backdrop px-3 py-2 text-xs font-medium capitalize leading-none text-black-secondary"
                        >
                            Cancelled
                        </span>
                    )}
                    {dataColumn.textType === TextType.BALANCE && (
                        <span data-type={dataType} className="text-base font-normal capitalize text-black-secondary">
                            {(typeof dataColumn.text === "string" || typeof dataColumn.text === "number") && (
                                <Money currency={dataColumn.currency} amount={dataColumn.text} />
                            )}
                        </span>
                    )}
                    {dataColumn.textType !== TextType.CANCELLED &&
                        dataColumn.textType !== TextType.FAILED &&
                        dataColumn.textType !== TextType.PROCESSING &&
                        dataColumn.subText &&
                        dataColumn.subTextType && (
                            <div
                                data-type={dataType}
                                className={`text-xs font-normal leading-none ${dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_PROCESSING ? "text-warning" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_SUCCESS ? "text-success" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_CANCELLED ? "text-black-secondary" : dataColumn.subTextType === SubTextType.TRANSACTION_STATUS_FAILED ? "text-error" : "text-black-tertiary"}`}
                            >
                                {dataColumn.subTextType === SubTextType.DATE && formatDate(dataColumn.subText as Date)}
                                {dataColumn.subTextType === SubTextType.TIME && formatTime(dataColumn.subText as Date)}
                                {dataColumn.subTextType === SubTextType.DATE_TIME && formatDateAndTime(dataColumn.subText as Date)}
                                {dataColumn.subTextType === SubTextType.AMOUNT_FEE && (
                                    <span>
                                        Fee:{" "}
                                        <Money
                                            currency={dataColumn.currency}
                                            amount={
                                                typeof dataColumn.subText === "string"
                                                    ? dataColumn.subText
                                                    : typeof dataColumn.subText === "number"
                                                      ? dataColumn.subText
                                                      : ""
                                            }
                                        />
                                    </span>
                                )}
                                {dataColumn.subTextType === SubTextType.TRUNCATED_TEXT && (
                                    <span className="">
                                        ••••{typeof dataColumn.subText === "string" ? dataColumn.subText?.toString().slice(-4) : ""}
                                    </span>
                                )}
                                {(dataColumn.subTextType === SubTextType.STRING || dataColumn.subTextType === SubTextType.REACT_NODE) &&
                                    (dataColumn.subText as string | ReactNode)}
                            </div>
                        )}
                </span>
            )}
        </>
    );
}

import { useDispatch, useSelector } from "react-redux";

import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../../redux/rootReducer";
import Money from "../../../../../../components/money";
import PayoutStatusTag from "../Status/PayoutStatusTag";
import { formatDateAndTime } from "../../../../../../utils/formatDate";
import { setIsZambiaPayoutsDetailCardOpen } from "../../../../../../redux/zambia/payouts/zambiaPayoutsSlice";

const DATA_TYPE = "zambia-payout";

function ZambiaLencoPayPayoutsTray(): JSX.Element {
    const dispatch = useDispatch();

    const zambiaPayoutsDetail = useSelector((state: IRootState) => state.zambiaPayouts.zambiaPayoutsDetail);
    const isAddMoneyCollectionRequestDetailCardOpen: boolean = useSelector((state: IRootState) => state.zambiaPayouts.isZambiaPayoutsDetailCardOpen);

    const handleCloseAddMoneyCollectionRequestTray = () => {
        dispatch(setIsZambiaPayoutsDetailCardOpen(false));
    };

    return (
        <>
            <FullPageTray
                active={isAddMoneyCollectionRequestDetailCardOpen}
                dataType={DATA_TYPE}
                handleReset={handleCloseAddMoneyCollectionRequestTray}
                withClickOutside
            >
                <FullPageTrayHeader toggler={handleCloseAddMoneyCollectionRequestTray} dataType={DATA_TYPE}>
                    Payout Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between space-x-8 py-2">
                                <p className="text-2xl font-bold">
                                    <Money amount={zambiaPayoutsDetail?.amount || 0} currency={zambiaPayoutsDetail?.currency} />
                                </p>

                                <PayoutStatusTag transaction={zambiaPayoutsDetail} />
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Date</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {formatDateAndTime(zambiaPayoutsDetail?.createdOn as Date)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Payout Amount</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <Money amount={zambiaPayoutsDetail?.amount || 0} currency={zambiaPayoutsDetail?.currency} />
                                </p>
                            </div>
                            {/* Praise - they are all the same */}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Settled Amount</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <Money amount={zambiaPayoutsDetail?.amount || 0} currency={zambiaPayoutsDetail?.currency} />
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.transactionReference}
                                </p>
                            </div>{" "}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Merchant Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.clientReference}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fees</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <Money amount={zambiaPayoutsDetail?.totalCharges || 0} currency={zambiaPayoutsDetail?.currency} />
                                </p>
                            </div>
                            {(zambiaPayoutsDetail?.totalCharges || 0) > 0 ? (
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Fees Paid By</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {zambiaPayoutsDetail?.isSplitPayment ? "Split Payment" : zambiaPayoutsDetail?.isCharge ? "Self" : "Recipient"}
                                    </p>
                                </div>
                            ) : null}
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Account Name</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.originatingUserAccount?.accountName}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Account Number</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaPayoutsDetail?.originatingUserAccount?.accountNumber}
                                </p>
                            </div>
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            {zambiaPayoutsDetail?.isCardPayment ? (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.cardPayment?.cardDetails?.name}
                                        </p>
                                    </div>{" "}
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.cardPayment?.cardDetails?.panLast4Digits?.replaceAll(/x/gi, "•")}{" "}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {zambiaPayoutsDetail?.destination?.account?.accountName}
                                        </p>
                                    </div>{" "}
                                    {zambiaPayoutsDetail?.destination?.phone ? (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Phone Number</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {zambiaPayoutsDetail?.destination?.phone}
                                            </p>
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default ZambiaLencoPayPayoutsTray;

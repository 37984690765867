import React, { useEffect } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import LencoPayTeamAccessModal from "../Components/Modals/LencoPayTeamAccessModal";
import PageLayout from "../../../../components/layouts/page-layout";
import { ReactComponent as RightAngleBlue } from "../../../../assets/svg/LencoPay/RightAngleBlue.svg";
import { ReactComponent as ShadowedProfile } from "../../../../assets/svg/Button/shadowed-profile.svg";
import apiPayments from "../../../../assets/images/lencopay/terminal-2.png";
import collectPayments from "../../../../assets/images/lencopay/terminal-1.png";
import fastSettlement from "../../../../assets/images/lencopay/terminal-3.png";
import { useAppSelector } from "../../../../redux/hooks";
import useLencoPayDefaultSetupInit from "../hookss/DefaultSetup/useLencoPayDefaultSetupInit";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function GetStarted(): JSX.Element {
    const navigate = useNavigate();
    const [isTeamMemberAccessModalOpen, setIsTeamMemberAccessModalOpen] = useState(false);
    const { handleLencoPayDefaultSetupInit } = useLencoPayDefaultSetupInit();

    const isEnabled = useAppSelector((state) => state.init.main?.meta.lencoPay.isEnabled);

    useEffect(() => {
        if (isEnabled) navigate("/lenco-pay/configuration");
        // initialize the user and team access
        void handleLencoPayDefaultSetupInit();
    }, [isEnabled]);

    return (
        <>
            <LencoPayTeamAccessModal show={isTeamMemberAccessModalOpen} toggle={() => setIsTeamMemberAccessModalOpen((prev) => !prev)} />
            <PageLayout
                pageTitle="Lenco Pay"
                subTitle="Accept and make online payments seamlessly"
                headerButtons={
                    <div className="flex justify-center space-x-3">
                        <ButtonComp
                            color="grey"
                            func={() => setIsTeamMemberAccessModalOpen((prev) => !prev)}
                            type="button"
                            buttonType="secondary"
                            size="md"
                        >
                            <div className="flex items-center justify-center space-x-1">
                                <ShadowedProfile />
                                <span>Lenco Pay Access</span>
                            </div>
                        </ButtonComp>
                    </div>
                }
            >
                <div className="flex h-full w-full flex-col items-center space-y-8 px-6">
                    <div className="flex-col items-center justify-center space-y-2 text-center xl:w-2/5">
                        <p className="text-2xl font-medium">Get started with Lenco Pay</p>
                        <p className="text-sm text-black-secondary">
                            Accept cards and mobile money payments online while you payout to Mobile Money and Zambia bank accounts with ease
                        </p>
                    </div>
                    <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                        <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                            <div className="flex justify-center rounded-lg w-fit-available">
                                <img src={collectPayments} alt="Lenco card light" className="w-full" />
                            </div>
                            <div className="space-y-3">
                                <p className="text-base font-medium">Collect Payments</p>
                                <p className="text-sm text-black-tertiary">Accept local and international cards, and mobile money on your website.</p>
                            </div>
                        </div>

                        <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                            <div className="flex justify-center rounded-lg w-fit-available">
                                <img src={apiPayments} alt="Lenco card dark" className="w-full" />
                            </div>
                            <div className="space-y-3">
                                <p className="text-base font-medium">API Payments</p>
                                <p className="text-sm text-black-tertiary"> integrate the Lenco Pay API to make automated payouts with ease.</p>
                            </div>
                        </div>
                        <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                            <div className="flex justify-center rounded-lg w-fit-available">
                                <img src={fastSettlement} alt="Lenco card dark" className="w-full" />
                            </div>
                            <div className="space-y-3 text-left">
                                <p className="text-base font-medium">Fast Settlement</p>
                                <div>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm text-black-tertiary">Mobile Money:</p>
                                        <p className="text-sm font-bold text-black-secondary">Instant</p>
                                    </div>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm text-black-tertiary">Cards:</p>
                                        <div>
                                            <span className="text-sm font-bold text-black-secondary">T+1 </span>
                                            <span className="text-sm text-black-tertiary">(Next-Day)</span>
                                        </div>
                                    </div>
                                </div>
                                <a href="https://lenco.co/zm/pricing" target="_blank" rel="noreferrer">
                                    <ButtonComp color="blue" type="button" size="sm" buttonType="tertiary">
                                        <div className="flex items-center justify-start space-x-1">
                                            <span>See Pricing</span>
                                            <RightAngleBlue />
                                        </div>
                                    </ButtonComp>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="pb-5">
                        <ButtonComp type="button" size="lg" color="black" buttonType="primary" func={() => navigate("/lenco-pay/configuration")}>
                            <span className="px-5"> Get Started</span>
                        </ButtonComp>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default GetStarted;

import TextClipboard from "../../../../../../../../components/text-clipboard";
import titleCase from "../../../../../../../../hooks/titleCase";

interface AddMoneyCardProps {
    swift?: string;
    medium: string;
    branch?: string;
    isWhiteBg?: boolean;
    accountName: string;
    accountNumber: string;
}

function AddMoneyCard(props: AddMoneyCardProps): JSX.Element {
    return (
        <div
            className={
                `relative box-border flex w-full flex-row items-center justify-between space-x-1 rounded-lg p-8 ` +
                `${props.isWhiteBg ? "bg-white" : " bg-grey-backdrop"}`
            }
        >
            <div className="flex flex-col">
                <p className="flex flex-row whitespace-nowrap pr-8 text-2xl text-black">{titleCase(props.accountNumber || "")}</p>
                <p className="text-sm text-black-tertiary">{props.medium}</p>
                <p className="text-sm text-black-tertiary">{props.accountName}</p>
                {props.branch && <p className="text-sm text-black-tertiary">{props.branch}</p>}
                {props.swift && <p className="text-sm text-black-tertiary">{props.swift}</p>}
            </div>

            <div className="flex flex-col items-center">
                <TextClipboard size="lg" color="grey" copyString={props.accountNumber} />
            </div>
        </div>
    );
}

export default AddMoneyCard;

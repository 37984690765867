import { Snippet, SnippetProps } from "@nextui-org/react";

import GoogleIcon from "../google-icon";

interface Props {
    // iconClassName?: string;
    iconColor?: "blue" | "black";
}

function Clipboard(props: SnippetProps & Props): JSX.Element {
    return (
        <Snippet
            {...props}
            size={props.size}
            hideSymbol={props.hideSymbol || true}
            radius={props.radius || "sm"}
            className="gap-1 !bg-transparent !p-0 !font-helvetica [&>button>div]:contents [&>button]:h-6 [&>button]:w-6 [&>button]:min-w-max [&>button]:bg-transparent"
            classNames={{ pre: "!font-helvetica" }}
            copyIcon={
                <GoogleIcon
                    icon="content_copy"
                    size={props.size === "lg" ? "lg" : props.size === "md" ? "md" : props.size === "sm" ? "sm" : props.size === "xs" ? "xs" : "xs"}
                />
            }
            copyButtonProps={{ variant: "flat", fullWidth: false, className: "!max-w-[unset] !w-max [&>div]:min-w-max [&>div]:h-fit" }}
            fullWidth={props.fullWidth || false}
        />
    );
}

export default Clipboard;

import { useCallback, useEffect } from "react";

import { setSingleTransferPurpose } from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface UseSingleTransferPurposeInterface {
    handlePurposeChange: (_purpose: string) => void;
}

function useSingleTransferPurpose(): UseSingleTransferPurposeInterface {
    const dispatch = useDispatch();

    const payReason = useAppSelector((state) => state.sendMoney.payReason);

    useEffect(() => {
        if (!payReason) return;
        dispatch(setSingleTransferPurpose(payReason));
    }, [payReason]);

    const handlePurposeChange = useCallback((_purpose: string) => {
        dispatch(setSingleTransferPurpose(_purpose));
    }, []);

    return {
        handlePurposeChange,
    };
}

export default useSingleTransferPurpose;

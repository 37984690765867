import { useEffect, useState } from "react";

import AccountInsightSkeleton from "./components/account-insight-skeleton";
import { AccountInsightType } from "../../../Hooks/State/useHomeState.types";
import { DailyAccountBalanceItem } from "../../../Services/homeApi.types";
import GoogleIcon from "../../../../../../components/google-icon";
import Insight from "./components/insight";
import Transaction from "../../../../../../models/transaction";
import { useAppSelector } from "../../../../../../redux/hooks";

interface Props {
    inflow: Transaction[];
    outflow: Transaction[];
    chartData: DailyAccountBalanceItem[];
    isLoading: boolean;
    showAmount: boolean;
    handleShowAmount: () => void;
}

function AccountInsight(props: Props) {
    const accounts = useAppSelector((state) => state.init.main?.companyDetails.accounts);
    const country = useAppSelector((state) => state.init.main?.companyDetails.company.country);

    const [insights, setInsights] = useState<AccountInsightType[]>([]);
    const [activeInsightIndex, setActiveInsightIndex] = useState<number>(1);

    useEffect(() => {
        if (!accounts) return;
        const test = accounts.reduce((acc: AccountInsightType[], curr) => {
            if (!curr.bankAccountCurrency) return acc;

            const insight = acc.some((_) => _.currency.htmlString === curr.bankAccountCurrency?.htmlString);

            if (!insight) {
                return [
                    ...acc,
                    {
                        currency: curr.bankAccountCurrency,
                        totalInflow: props.inflow
                            .filter((_) => _.currency?.htmlString === curr.bankAccountCurrency?.htmlString)
                            .reduce((_acc, _curr) => _acc + _curr.amount, 0),
                        totalOutflow: props.outflow
                            .filter((_) => _.currency?.htmlString === curr.bankAccountCurrency?.htmlString)
                            .reduce((_acc, _curr) => _acc + _curr.amount, 0),
                        totalBalance: curr.balance || 0,
                    },
                ];
            } else {
                return acc.map((_) =>
                    _.currency.htmlString === curr.bankAccountCurrency?.htmlString ? { ..._, totalBalance: _.totalBalance + (curr.balance || 0) } : _
                );
            }
        }, []);
        setInsights(test);
    }, [accounts, props.inflow, props.outflow]);

    return (
        <>
            {props.isLoading || insights.length < 1 ? (
                <AccountInsightSkeleton />
            ) : (
                <div className="relative flex h-full max-h-96 min-h-96 flex-col space-y-2 overflow-hidden rounded-xl bg-white px-8 py-5 text-base shadow">
                    {insights.map(
                        (_, _index) =>
                            _index + 1 === activeInsightIndex && (
                                <Insight
                                    key={_index}
                                    country={country || undefined}
                                    currency={_.currency}
                                    chartData={props.chartData}
                                    showAmount={props.showAmount}
                                    totalInflow={_.totalInflow}
                                    totalBalance={_.totalBalance}
                                    totalOutflow={_.totalOutflow}
                                    handleShowAmount={props.handleShowAmount}
                                />
                            )
                    )}
                    {insights.length > 1 && (
                        <div className="flex w-full items-center justify-center">
                            {insights.map((_, _index) => (
                                <button key={_index} className="p-1" onClick={() => setActiveInsightIndex(_index + 1)}>
                                    <div
                                        className={
                                            "flex h-1.5 w-1.5 cursor-pointer rounded-full outline-none focus:outline-none " +
                                            `${_index + 1 === activeInsightIndex ? "bg-black-secondary" : "bg-black-quin"}`
                                        }
                                    ></div>
                                </button>
                            ))}
                        </div>
                    )}
                    {!!(activeInsightIndex > 1) === true && insights.length > 1 && (
                        <div
                            className="insight-arrow-left absolute left-0 top-0 !mt-0 flex h-full w-10% cursor-pointer items-center justify-center opacity-0 duration-150 hover:opacity-100 [&>div]:mr-6"
                            onClick={() => setActiveInsightIndex((prev) => prev - 1)}
                        >
                            <GoogleIcon icon="chevron_left" size="lg" />
                        </div>
                    )}
                    {activeInsightIndex < insights.length && insights.length > 1 && (
                        <div
                            className="insight-arrow-right absolute right-0 top-0 !mt-0 flex h-full w-10% cursor-pointer items-center justify-center opacity-0 duration-150 hover:opacity-100 [&>div]:ml-6"
                            onClick={() => setActiveInsightIndex((prev) => prev + 1)}
                        >
                            <GoogleIcon icon="chevron_right" size="lg" />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default AccountInsight;

import { TransactionStatus, TransactionType } from "../../../../../models/transaction.constants";

import store from "../../../../../redux/store";

export enum ZambiaPayoutsFilters {
    DATE = "date",
    STATUS = "status",
    ACCOUNT = "account",
}

export const ZambiaPayoutsFiltersArray = [ZambiaPayoutsFilters.DATE, ZambiaPayoutsFilters.STATUS, ZambiaPayoutsFilters.ACCOUNT];

export const ZambiaPayoutsFiltersText = {
    [ZambiaPayoutsFilters.DATE]: "Date",
    [ZambiaPayoutsFilters.STATUS]: "Status",
    [ZambiaPayoutsFilters.ACCOUNT]: "Account",
};

//Status
export const ZambiaPayoutsStatusArray = [
    TransactionStatus.SUCCESS,
    // TransactionStatus.PROCESSING,
    TransactionStatus.FAILED,
    TransactionStatus.CANCELLED,
    TransactionStatus.DECLINED,
];

export const getZambiaPayoutsStatusName = (_status: TransactionStatus): string => {
    switch (_status) {
        case TransactionStatus.FAILED:
            return "Failed";
        case TransactionStatus.DECLINED:
            return "Declined";
        case TransactionStatus.PENDING_APPROVAL:
            return "Pending";
        case TransactionStatus.CANCELLED:
            return "Cancelled";
        case TransactionStatus.PROCESSING:
            return "Processing";
        case TransactionStatus.SUCCESS:
            return "Successful";
        default:
            return "";
    }
};

export const ZambiaPayoutsStatusOptions = ZambiaPayoutsStatusArray.map((_status) => ({
    name: getZambiaPayoutsStatusName(_status),
    value: _status,
}));

//Type
export const ZambiaPayoutsTypeArray = [TransactionType.CREDIT, TransactionType.DEBIT];

export const getZambiaPayoutsTypeName = (_status: TransactionType): string => {
    switch (_status) {
        case TransactionType.CREDIT:
            return "Inflow";
        case TransactionType.DEBIT:
            return "Payout";
        default:
            return "";
    }
};

export const ZambiaPayoutsTypeOptions = ZambiaPayoutsTypeArray.map((_status) => ({
    name: getZambiaPayoutsTypeName(_status),
    value: _status,
}));

//Source
export const ZambiaPayoutsUserAccountIdsArray = store.getState().init.main?.companyDetails?.accounts || [];

export const ZambiaPayoutsUserAccountIdsOptions = ZambiaPayoutsUserAccountIdsArray.map((_userAccount) => ({
    name: _userAccount.accountName,
    value: _userAccount.id,
}));

export const ZambiaPayoutsFiltersOptions: {
    [type in ZambiaPayoutsFilters]:
        | {
              name: string;
              value: TransactionStatus | string;
          }[]
        | undefined;
} = {
    [ZambiaPayoutsFilters.STATUS]: ZambiaPayoutsStatusOptions,
    [ZambiaPayoutsFilters.ACCOUNT]: ZambiaPayoutsUserAccountIdsOptions,
    [ZambiaPayoutsFilters.DATE]: undefined,
};

export const ZambiaPayoutsFiltersData = ZambiaPayoutsFiltersArray.map((_payout) => ({
    name: ZambiaPayoutsFiltersText[_payout],
    value: _payout,
    options: ZambiaPayoutsFiltersOptions[_payout],
}));

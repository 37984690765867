import GoogleIcon from "../../../../../components/google-icon";
import Money from "../../../../../components/money";
import { Num } from "../../../../../utils/math";
import { PaymentsType } from "../../../payments-new/hooks/state/send-money/payments.constants";
import TooltipIcons from "../../../../../components/tooltip";
import UserAccount from "../../../../../models/userAccount";
import titleCase from "../../../../../hooks/titleCase";
import { useNavigate } from "react-router-dom";

interface Props {
    index: number;
    showAmount: boolean;
    accountData: UserAccount;
    arrayLength: number;
}

function AccountCardDetail({ accountData, showAmount, arrayLength, index }: Props): JSX.Element {
    const navigate = useNavigate();

    return (
        <>
            <div
                className={`${index === arrayLength - 1 ? "" : "border-b border-grey-secondary"} flex w-full flex-row items-center justify-between py-4`}
            >
                <div className="flex w-70% flex-row justify-between">
                    <div className="flex flex-col items-center justify-center text-start">
                        <div className="flex flex-row items-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-base text-black-tertiary">
                                {titleCase(accountData?.lencoNameMin || "")}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col text-center">
                        <div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium text-black-secondary">
                            {showAmount ? (
                                <Money
                                    amount={(accountData?.balance || (0 as unknown as Num)).valueOf()}
                                    currency={accountData.bankAccountCurrency && accountData.bankAccountCurrency}
                                />
                            ) : (
                                <div className="flex flex-row items-center justify-center space-x-1.5 pt-2">
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />{" "}
                                    <div className="flex h-2 w-2 rounded-full bg-black-secondary" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="flex w-fit flex-row items-start space-x-6">
                        <TooltipIcons
                            icon={<GoogleIcon icon="send" isOutlined />}
                            message="Send Money"
                            placement="left"
                            onClick={() =>
                                navigate(
                                    {
                                        pathname: "/payments/make/single",
                                        search: `?from=${accountData.id}`,
                                    },
                                    {
                                        state: {
                                            typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                                            from: accountData.id,
                                        },
                                    }
                                )
                            }
                        />
                        <TooltipIcons
                            icon={<GoogleIcon icon="list_alt" isOutlined />}
                            message="Transactions"
                            placement="left"
                            onClick={() => navigate(`/transactions/account/${accountData.id}`)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountCardDetail;

import { useRef, useState } from "react";

import GoogleIcon from "../google-icon";
import useClickOutside from "../../hooks/useClickOutside";

interface SearchBarProps {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    value: string;
    dataType?: string;
    fullWidth?: boolean;
    withWhitBg?: boolean;
    placeholder?: string;
    onChange: (_value: string) => void;
}

function SearchBar(props: SearchBarProps): JSX.Element {
    const { size = "lg" } = props;
    const [active, setActive] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const domNode = useClickOutside(() => {
        setActive(false), (eventTarget: HTMLElement) => eventTarget.dataset.type !== "transaction";
    });

    return (
        <div
            ref={domNode}
            className={
                `relative flex w-full cursor-text items-center justify-start rounded-md border border-solid` +
                `transition-all duration-150 ease-in-out focus:border-black focus:text-blue focus:outline-none` +
                `${props.fullWidth ? "" : " max-w-xl"}` +
                `${props.withWhitBg ? " bg-white" : ""}` +
                `${size === "2xl" || size === "xl" ? " h-10 px-4" : " h-8 px-3"}` +
                `${active ? " border-black-secondary text-black-secondary" : " border-black-quin text-black-quat"}`
            }
            onClick={() => {
                setActive(true);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }}
            onFocus={() => {
                setActive(true);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }}
            data-type={props.dataType}
        >
            <GoogleIcon icon="search" className="text-black" dataType={props.dataType} size="sm" isOutlined />

            <input
                type="text"
                ref={inputRef}
                className="ml-2 w-full bg-transparent text-sm font-normal text-black-secondary antialiased placeholder-black-quat outline-none focus:outline-none"
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value.trim())}
                maxLength={40}
                tabIndex={-1}
                data-type={props.dataType}
            />

            {props.value && (
                <span
                    className="cursor-pointer pr-1 text-sm text-black-secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        inputRef.current?.blur();
                        props.onChange("");
                        setActive(false);
                    }}
                    tabIndex={-1}
                    data-type={props.dataType}
                >
                    Clear
                </span>
            )}
        </div>
    );
}

export default SearchBar;

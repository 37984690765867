import {
    setAirtimePurchaseDebitAccountId,
    setAirtimePurchaseSelectedUserAccount,
} from "../../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";
import { resetSingleTransferRecipient } from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";

interface UseAirtimePurchasePayFromInterface {
    selectedAccountId: string;
    accounts: UserAccount[] | undefined;
    handleSelectAccount: (_accountId: string) => void;
}

function useAirtimePurchasePayFrom(): UseAirtimePurchasePayFromInterface {
    const originatingAccountId = useSelector((state: IRootState) => state.airtimePurchase.airtimePurchaseDetails.debitAccountId);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const payFrom = useSelector((state: IRootState) => state.billPayment.payFrom);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!accounts) return;
        if (payFrom) {
            handleSelectAccount(payFrom);
        } else {
            const accessAccount = accounts.find((_) => doesUserHaveAccessToAccount(_.id));
            accessAccount && handleSelectAccount(accessAccount.id);
        }
    }, [payFrom, accounts]);

    const handleSelectAccount = useCallback((_accountId: string) => {
        const doesAccountExist = accounts?.some((el) => el.id === _accountId);
        if (doesAccountExist) {
            const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
            dispatch(setAirtimePurchaseDebitAccountId(_accountId));
            dispatch(setAirtimePurchaseSelectedUserAccount(userAccount));
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetSingleTransferRecipient());
        }
    }, []);

    return {
        accounts,
        selectedAccountId: originatingAccountId,
        handleSelectAccount,
    };
}

export default useAirtimePurchasePayFrom;

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActivateAccount from "../Modals/ActivateAccountModal";
import { ActivationStatus } from "../../../../../models/userAccount.constants";
import AddFundsCard from "./AddFundsCard";
import CurrencyCode from "../../../../../components/currency-code";
import GoogleIcon from "../../../../../components/google-icon";
import { IRootState } from "../../../../../redux/rootReducer";
import InfoTextButton from "../../../../../components/button/Info-text-button";
import LineDropdown from "../Dropdowns/LineDropdown";
import Money from "../../../../../components/money";
import Tooltip from "../../../../../components/tooltip";
import UserAccount from "../../../../../models/userAccount";
import formatNumber from "../../../../../utils/formatNumber";
import { mainUpdateUserAccountCallback } from "../../../../../redux/init/slice/initSlice";
import useActivateAccountState from "../../Hooks/State/useActivateAccountState";
import { useNavigate } from "react-router-dom";

interface AccountCardProps {
    data: UserAccount;
}
function AccountCard(props: AccountCardProps): JSX.Element {
    const { data } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleReset } = useActivateAccountState();

    const accountType = useSelector((state: IRootState) => state.init.main?.meta.accountTypes.find((t) => t.type === data.type));
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

    const [showActiveAccountModal, setShowActiveAccountModal] = useState<boolean>(false);
    const [individualData, setIndividualData] = useState<UserAccount | null>(null);

    const splitInflowText = data?.splitInflowInstruction
        ? (data?.splitInflowInstruction.ratio || [])
              .map((_) => `${accounts?.find((_acc) => _acc.id === _.userAccountId)?.lencoNameMin} - ${_.percentage}%`)
              .join()
              .replace(",", ", ")
        : "";

    const handleAccountActivationCompleted = useCallback((account: UserAccount) => {
        dispatch(
            mainUpdateUserAccountCallback({
                id: account.id,
                callback: (userAccount: UserAccount) => {
                    userAccount.activationStatus = ActivationStatus.ACTIVATED;
                },
            })
        );
    }, []);

    return (
        <>
            <ActivateAccount
                active={showActiveAccountModal}
                data={individualData}
                toggler={() => setShowActiveAccountModal(false)}
                onCompleteFunc={(account) => handleAccountActivationCompleted(account)}
            />

            {data && (
                <div>
                    <div
                        className={`box-border w-full bg-white shadow ${
                            data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING
                                ? "rounded-b-none rounded-t-lg"
                                : "rounded-lg"
                        } relative`}
                    >
                        <div className="flex flex-col space-y-2 p-5" key={data.id}>
                            <div className="flex h-10 flex-row items-center justify-between">
                                {accountType && (
                                    <div className="bg-black-backdrop rounded-md border border-grey bg-grey-backdrop px-1.5 py-0.5 text-sm font-medium">
                                        <div className="text-black-secondary">{accountType.name}</div>
                                    </div>
                                )}
                                <div className="flex h-full items-center justify-center">
                                    <LineDropdown data={data} />
                                </div>
                            </div>
                            <div className="pt-2">
                                <div className="text-xs font-normal text-black-tertiary">Account Balance</div>
                                <div className="flex flex-row justify-start text-black-secondary">
                                    <div className="text-2xl font-medium">
                                        <CurrencyCode currency={data.bankAccountCurrency} />
                                        {formatNumber(data.balance || 0)}
                                    </div>
                                </div>
                            </div>
                            {data && <AddFundsCard userAccount={data} isCard />}
                            {(data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING) && (
                                <div
                                    onClick={(e) => {
                                        setShowActiveAccountModal(true);
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIndividualData(data);
                                        handleReset();
                                    }}
                                    className="flex items-center justify-end space-x-1 py-2 text-sm font-medium text-blue-secondary"
                                >
                                    <span>Activate Account</span>
                                </div>
                            )}
                            {!(data.activationStatus === ActivationStatus.NOT_ACTIVATED || data.activationStatus === ActivationStatus.ONGOING) && (
                                <div className="flex items-center justify-between py-2 text-sm font-medium text-blue-secondary">
                                    {data.lowBalanceInstruction ||
                                    data.excessBalanceInstruction ||
                                    !data.accountRestrictions.canSendMoneyToAnyAccount ||
                                    data?.splitInflowInstruction ? (
                                        <div className="flex w-full">
                                            <Tooltip
                                                bg="none"
                                                icon={
                                                    <InfoTextButton
                                                        isLoading={false}
                                                        content={<span className="font-normal text-info">About My account</span>}
                                                    />
                                                }
                                                message={
                                                    <div>
                                                        {data.lowBalanceInstruction ? (
                                                            <p>
                                                                Minimum Balance:{" "}
                                                                <Money
                                                                    amount={data.lowBalanceInstruction?.amount || 0}
                                                                    currency={data.bankAccountCurrency}
                                                                />
                                                            </p>
                                                        ) : null}
                                                        {data.excessBalanceInstruction ? (
                                                            <p>
                                                                Excess Balance:{" "}
                                                                <Money
                                                                    amount={data.excessBalanceInstruction?.amount || 0}
                                                                    currency={data.bankAccountCurrency}
                                                                />
                                                            </p>
                                                        ) : null}
                                                        {data?.splitInflowInstruction ? <p>Split Inflow: {splitInflowText}</p> : null}
                                                        {!data.accountRestrictions.canSendMoneyToAnyAccount ? (
                                                            <p>
                                                                Account Restriction:{" "}
                                                                {data.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                                                                    <span>Send Money to Only Specific Accounts</span>
                                                                ) : null}
                                                                {data.accountRestrictions.cannotSendMoney ? <span>Can Not Send Money</span> : null}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                }
                                                size="fit"
                                            />
                                        </div>
                                    ) : null}
                                    <div className="flex w-full items-end justify-end">
                                        <div
                                            className="flex cursor-pointer items-center justify-start space-x-1"
                                            onClick={() => navigate(`/accounts/${data.id}`)}
                                        >
                                            <span>Account Details</span> <GoogleIcon icon="chevron_right" className="text-blue" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountCard;

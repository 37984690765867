import { openAddFundsModal, openZambiaAddFundsModal } from "../../../../redux/accounts/slice/accountsSlice";
import { useDispatch, useSelector } from "react-redux";

import AccountDetailsCard from "../Components/Cards/AccountDetailsCard/Index";
import AccountInsight from "../Components/Cards/account-insight";
import ActivateAccountMessage from "../Components/Messages/ActivateAccountMessage";
import ButtonComp from "../../../../components/button/ButtonComp";
import Currency from "../../../../models/currency";
import GoogleIcon from "../../../../components/google-icon";
import { IRootState } from "../../../../redux/rootReducer";
import LockedAccountDetailsCard from "../Components/Cards/LockedAccountDetailsCard";
import Money from "../../../../components/money";
import PageBanner from "../../../../components/page-banner";
import PageLayout from "../../../../components/layouts/page-layout";
import { ReactComponent as Rewards } from "../../../../assets/svg/Rewards.svg";
import SkeletonElement from "../../../../components/skeleton";
import SplitGif from "../../../../assets/images/settings/split-info.gif";
import TransactionsListCard from "../Components/Cards/transactions-list-card/transactions-list-card";
import { saveSplitBannerSkippedDate } from "../../../../helpers/app-reminders";
import { useCallback } from "react";
import useHomeState from "../Hooks/State/useHomeState";
import { useNavigate } from "react-router-dom";

function DashboardHome(): JSX.Element {
    const {
        dashboardDetailsResponse,
        showPageBanner,
        accountsData,
        inflow,
        outflow,
        pending,
        isLoaded,
        showAmount,
        handleToggleBanner,
        handleToggleOnboardingModal,
    } = useHomeState();

    const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOpenAddFundsModal = useCallback(() => {
        dispatch(isZambia ? openZambiaAddFundsModal() : openAddFundsModal());
    }, [isZambia]);

    return (
        <>
            <PageLayout
                pageTitle={
                    accountsData && isLoaded ? (
                        <span className="text-3xl font-medium capitalize text-black"> Hello {accountsData.user.firstName}</span>
                    ) : (
                        <div className="h-6 w-24">
                            <SkeletonElement box />
                        </div>
                    )
                }
                headerButtons={
                    <div className="bg-white">
                        {dashboardDetailsResponse && accountsData ? (
                            <ButtonComp
                                type="button"
                                color="black"
                                ripple="light"
                                buttonType="secondary"
                                size="md"
                                // func={() => dispatch(isZambia ? openZambiaAddFundsModal() : openAddFundsModal())}
                                func={handleOpenAddFundsModal}
                            >
                                <div className="flex items-center justify-center space-x-2 rounded-lg px-4 py-2">
                                    <GoogleIcon icon="add_circle" isOutlined className="text-blue" />
                                    <span className="text-sm font-medium">Add Money</span>
                                </div>
                            </ButtonComp>
                        ) : (
                            <div className="h-9 w-36">
                                <SkeletonElement box />
                            </div>
                        )}
                    </div>
                }
                isWithShadowContent
            >
                {accountsData && !accountsData.company.accountCreated && <ActivateAccountMessage data={accountsData} />}
                {accountsData && isLoaded && showPageBanner && (
                    <PageBanner
                        image={SplitGif}
                        title="Streamline Incoming Payments"
                        subtitle="Automatically split incoming funds across multiple accounts or recipients. Perfect for managing shared revenue, business income, or automated fund distribution."
                        onToggle={() => {
                            handleToggleBanner();
                            saveSplitBannerSkippedDate();
                        }}
                        buttonCTA="Set Up Split Inflow"
                        imgClassName="!max-w-[285px]"
                        onClick={() => navigate("/settings/account/stage/split-inflow")}
                    />
                )}
                <div className="grid w-full grid-cols-1 gap-5">
                    <div className="grid grid-cols-1 space-y-5 lg:grid-cols-1 xl:grid-cols-2 xl:space-x-5 xl:space-y-0">
                        {accountsData && !accountsData.company.accountCreated && <LockedAccountDetailsCard />}
                        <AccountInsight
                            inflow={inflow}
                            outflow={outflow}
                            chartData={dashboardDetailsResponse?.chart || []}
                            isLoading={!(dashboardDetailsResponse && accountsData)}
                            showAmount={showAmount}
                            handleShowAmount={handleToggleOnboardingModal}
                        />
                        {/* <ChartCard
                            data={dashboardDetailsResponse}
                            handleToggleShowAmount={handleToggleOnboardingModal}
                            showAmount={showAmount}
                            accountsData={accountsData}
                            totalInflow={totalInflow}
                            totalOutflow={totalOutflow}
                        /> */}
                        <AccountDetailsCard showAmount={showAmount} accountsData={accountsData} dashboardDetails={dashboardDetailsResponse} />
                    </div>
                    {/* Rewards section below is hidden */}
                    <div className="hidden w-full items-center justify-between divide-x divide-grey rounded-lg bg-white p-6">
                        {accountsData && isLoaded ? (
                            <>
                                <div className="w-full space-y-4 pr-6">
                                    <p className="text-xl font-medium">Rewards</p>
                                    <div className="flex w-full items-center space-x-12 divide-x divide-grey-secondary">
                                        <div className="w-1/2 space-y-3">
                                            <p className="text-base font-medium text-black-tertiary">Interests</p>
                                            <div className="flex w-full items-center justify-between">
                                                <p className="text-xl font-bold text-black-secondary">
                                                    <Money
                                                        amount={0.0}
                                                        currency={(accountsData?.accounts[0].bankAccountCurrency || "KES") as Currency}
                                                    />
                                                </p>
                                                <p className="cursor-pointer text-base font-medium text-blue">View all</p>
                                            </div>
                                        </div>
                                        <div className="w-1/2 space-y-3 pl-12 text-base font-medium text-black-tertiary">
                                            <p className="text-base font-medium text-black-tertiary">Cashback</p>
                                            <div className="flex w-full items-center justify-between">
                                                <p className="text-xl font-bold text-black-secondary">
                                                    <Money
                                                        amount={0.0}
                                                        currency={(accountsData?.accounts[0].bankAccountCurrency || "KES") as Currency}
                                                    />
                                                </p>
                                                <p className="cursor-pointer text-base font-medium text-blue">View all</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-1/6 items-center justify-center">
                                    <Rewards />
                                </div>
                            </>
                        ) : (
                            <div className="mt-2 h-4 w-20">
                                <SkeletonElement box />
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 space-y-5 xl:grid-cols-3 xl:space-x-5 xl:space-y-0">
                        <TransactionsListCard isLoaded={isLoaded} transactions={inflow} type="inflow" />
                        <TransactionsListCard isLoaded={isLoaded} transactions={outflow} type="payout" />
                        <TransactionsListCard isLoaded={isLoaded} transactions={pending} type="pending-transfer" />
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default DashboardHome;

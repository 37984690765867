import { FilterDateObj, FilterDateType, FilterDateTypes, getFilterCount } from "../../../../../components/filter/filter.constant";
import { FilterOptionSelectType, FilterOptions } from "../../../../../components/Table/table-filter.types";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaSettlementStatus,
    ZambiaSettlementType,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";
import {
    ZambiaCollectionsSettlementsChannelOptions,
    ZambiaCollectionsSettlementsStatusOptions,
    ZambiaCollectionsSettlementsTypeOptions,
} from "./zambiaCollectionsSettlements.constants";
import {
    setIsZambiaCollectionsSettlementsPaginationLoading,
    setZambiaCollectionsSettlementsList,
} from "../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";

import Parsers from "../../../../../utils/parsers";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { ZambiaCollectionsSettlementRequest } from "../../Services/Collections/zambiaCollectionsSettlementApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { zambiaCollectionsSettlement } from "../../Services/Collections/zambiaCollectionsSettlementApi";

function useZambiaCollectionsSettlements() {
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [groupSize, setGroupSize] = useState(0);

    const [filter, setFilter] = useState<ZambiaCollectionsSettlementRequest>({});
    const [dateType, setDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilter, setSelectedFilter] = useState<ZambiaCollectionsSettlementRequest>({});
    const [selectedDateType, setSelectedDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const zambiaCollectionsSettlementsList = useAppSelector((state) => state.zambiaCollectionsSettlements.zambiaCollectionsSettlementsList);
    const isZambiaCollectionsSettlementsPaginationLoading = useAppSelector(
        (state) => state.zambiaCollectionsSettlements.isZambiaCollectionsSettlementsPaginationLoading
    );

    useEffect(() => {
        setSelectedFilterCount(getFilterCount(filter));
    }, [filter]);

    const handleFilteredZambiaCollectionsSettlements = useCallback(async (_data: ZambiaCollectionsSettlementRequest) => {
        try {
            dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(true));
            const res = await zambiaCollectionsSettlement(_data);
            dispatch(setZambiaCollectionsSettlementsList(res.settlements));
            setTotal(res.total);
            setGroupSize(res.groupSize);
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            dispatch(setIsZambiaCollectionsSettlementsPaginationLoading(false));
        }
    }, []);

    const handleApplyFilter = async () => {
        setSelectedFilter(filter);
        setSelectedDateType(dateType);
        await handleFilteredZambiaCollectionsSettlements(filter);
    };

    const handlePaginationRequest = async (_offset: number) => {
        setOffset(_offset);
        await handleFilteredZambiaCollectionsSettlements({ offset });
    };

    const handleClearFilter = useCallback(async () => {
        setOffset(0);
        setFilter({});
        setSelectedFilter({});
        setDateType(FilterDateTypes.ALL_TIME);
        setSelectedDateType(FilterDateTypes.ALL_TIME);
        await handleFilteredZambiaCollectionsSettlements({});
    }, []);

    const filterOption = useMemo(
        (): FilterOptions => ({
            filterOptions: [
                {
                    text: "Date",
                    value:
                        filter.date !== "" && !isNullOrUndefined(filter.date) ? ({ name: dateType, date: filter.date } as FilterDateType) : undefined,
                    selectType: FilterOptionSelectType.DATE_OPTION,
                    selectedValue:
                        selectedFilter.date !== "" || isNullOrUndefined(selectedFilter.date)
                            ? ({ name: selectedDateType, date: selectedFilter.date } as FilterDateType)
                            : undefined,
                    onClick: (_value) => {
                        if (!(typeof _value === "object" && "date" in _value && "name" in _value)) return;
                        setFilter((prev) => ({ ...prev, date: _value.date as FilterDateObj }));
                        setDateType(_value.name as FilterDateTypes);
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, date: "" };
                        handleFilteredZambiaCollectionsSettlements(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Status",
                    value: filter.status,
                    subList: ZambiaCollectionsSettlementsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.status,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaSettlementStatus)) return;
                        setFilter((prev) => ({
                            ...prev,
                            status: prev.status !== _value ? (_value as ZambiaSettlementStatus) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, status: "" };
                        handleFilteredZambiaCollectionsSettlements(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Channel",
                    value: filter.channel,
                    subList: ZambiaCollectionsSettlementsChannelOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.channel,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaCollectionPaymentChannel)) return;
                        setFilter((prev) => ({
                            ...prev,
                            channel: prev.channel !== _value ? (_value as ZambiaCollectionPaymentChannel) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, channel: "" };
                        handleFilteredZambiaCollectionsSettlements(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Settlement Type",
                    value: filter.settlementType,
                    subList: ZambiaCollectionsSettlementsTypeOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.settlementType,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaSettlementType)) return;
                        setFilter((prev) => ({
                            ...prev,
                            source: prev.settlementType !== _value ? (_value as ZambiaSettlementType) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, settlementType: "" };
                        handleFilteredZambiaCollectionsSettlements(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
            ],
        }),
        [filter, selectedFilter, selectedDateType]
    );

    return {
        total,
        offset,
        groupSize,
        filterOption,
        selectedFilterCount,
        zambiaCollectionsSettlementsList,
        isZambiaCollectionsSettlementsPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsSettlements,
    };
}

export default useZambiaCollectionsSettlements;

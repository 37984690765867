import {
    resetAllTerminalTransactionsData,
    setIsTerminalTransactionsDetailCardOpen,
    setSelectedTerminalTransactionsDetails,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback, useEffect, useLayoutEffect } from "react";

import { BasicFinalStatus } from "../../../../../models/posTransaction.constants";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import { TERMINAL_TRANSACTION_DATATYPE } from "../Cards/TerminalTransactionDetailsCard/TerminalTransactionDetailsCard";
import Table from "../../../../../components/Table";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import TerminalTransactionSummary from "../../Elements/Card/TerminalSummary/TerminalTransactionSummary";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { useDispatch } from "react-redux";
import useSpecificTerminalTransactions from "../../Hooks/State/useSpecificTerminalTransactions";

const terminalTransactionsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Terminal Name", pos: 3, headType: TableColumnType.TEXT },
    { text: "Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Amount", pos: 5, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 6, headType: TableColumnType.STATUS },
];

function AllTerminalsTransactions(): JSX.Element {
    const dispatch = useDispatch();

    const {
        total,
        offset,
        groupSize,
        filterOption,
        posTransactions,
        selectedFilterCount,
        isTransactionPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleGetTerminalTransactions,
    } = useSpecificTerminalTransactions();

    // const terminalsTransactions = useAppSelector((state) => state.terminals.transactions);
    // const isTransactionPaginationLoading = useAppSelector((state) => state.terminals.isTransactionPaginationLoading);

    const handleOpenTerminalTransaction = useCallback((transactionId: string) => {
        dispatch(setIsTerminalTransactionsDetailCardOpen(true));
        dispatch(setSelectedTerminalTransactionsDetails(transactionId));
    }, []);

    useLayoutEffect(() => {
        void handleGetTerminalTransactions({ offset: 0 });
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllTerminalTransactionsData());
        };
    }, []);

    return (
        <>
            <TerminalTransactionSummary />
            <div className="relative h-full w-90% min-w-full max-w-screen-2xs sm:w-max lg:h-auto xs:max-w-screen-xs">
                <div className="h-full w-full max-w-screen lg:h-auto">
                    {/* <div className="h-full w-full max-w-screen overflow-x-auto lg:h-auto"> */}
                    <div className="mx-auto h-full w-screen lg:h-auto lg:w-fit">
                        <Table
                            //filter
                            // isFilterLeft
                            isSticky
                            filterOptions={filterOption}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            totalSelectedItems={selectedFilterCount}
                            //pagination
                            total={total}
                            offset={offset}
                            groupSize={groupSize}
                            paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                            isPaginateLoading={isTransactionPaginationLoading}
                            //table
                            heads={terminalTransactionsHeader}
                            dataType={TERMINAL_TRANSACTION_DATATYPE}
                            isLoading={!!isTransactionPaginationLoading}
                            rows={posTransactions?.map((transaction) => ({
                                onRecordClick: () => handleOpenTerminalTransaction(transaction.id),
                                record: [
                                    {
                                        key: "Date",
                                        text: transaction.datetime,
                                        textType: TextType.DATE,
                                        subText: transaction.datetime,
                                        subTextType: SubTextType.TIME,
                                    },
                                    {
                                        key: "From",
                                        text: transaction?.transferIn?.sender || transaction?.cardPayment?.maskedPan,
                                        textType: TextType.STRING,
                                    },
                                    {
                                        key: "Terminal Name",
                                        text: transaction.terminal?.serialNumber || transaction.terminal?.name || "",
                                        textType: TextType.STRING,
                                    },
                                    {
                                        key: "Type",
                                        text: transaction.getType,
                                        textType: TextType.STRING,
                                    },
                                    {
                                        key: "Amount",
                                        text: transaction.transactionAmount,
                                        textType: transaction.status === BasicFinalStatus.FAILED ? TextType.BALANCE_STRIKE : TextType.BALANCE,
                                    },
                                    {
                                        key: "Status",
                                        text: transaction.getStatus,
                                        textType:
                                            transaction.status === BasicFinalStatus.FAILED
                                                ? TextType.TRANSACTION_STATUS_FAILED
                                                : transaction.status === BasicFinalStatus.SUCCESSFUL
                                                  ? TextType.TRANSACTION_STATUS_SUCCESS
                                                  : TextType.BALANCE,
                                    },
                                ],
                            }))}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllTerminalsTransactions;

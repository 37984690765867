// import BankDropdown from "../DropDowns/BankDropdown";
import BankSelect from "../selects/bank-select";
// import BankDropdown from "../DropDowns/BankDropdown";
import Input from "../../../../../components/inputs/Input";
import SimpleModal from "../../../../../components/modal/simple-modal";
// import { TailSpin } from "react-loader-spinner";
import { UseAddNewRecipientResponse } from "../../hooks/state/SavedRecipient/useAddNewRecipient";
import VerifiedAccountCard from "../Cards/verified-account-card";

interface Props {
    addNewRecipientData: UseAddNewRecipientResponse;
}

function AddNewRecipientModal({ addNewRecipientData }: Props): JSX.Element {
    const deviceWidth = window.innerWidth;

    return (
        <SimpleModal
            onSubmit={() => {
                if (addNewRecipientData.bankAccount) {
                    void addNewRecipientData.handleCreateRecipient(addNewRecipientData.bankAccount, addNewRecipientData.alias);
                }
            }}
            active={addNewRecipientData.isModalOpen}
            header="Add a Recipient"
            onClose={addNewRecipientData.closeModal}
            canSubmit={!!addNewRecipientData.bankAccount && !addNewRecipientData.isVerifyingAccount}
            isLoading={addNewRecipientData.isVerifyingAccount}
            errorMessage={addNewRecipientData.errorMessage}
            isSubmitting={addNewRecipientData.isSubmitting}
            confirmText="Add Recipient"
            size={deviceWidth < 640 ? "xs" : "md"}
        >
            <div className="flex w-full flex-col space-y-4">
                <Input placeholder="Account Number" value={addNewRecipientData.accountNumber} onChange={addNewRecipientData.setAccountNumber} />
                <BankSelect
                    label="Bank Name"
                    accountNumber={addNewRecipientData.accountNumber}
                    bankCode={addNewRecipientData.bankCode || ""}
                    onClick={addNewRecipientData.setBankCode}
                />
                {addNewRecipientData.bankAccount?.accountName && <VerifiedAccountCard value={addNewRecipientData.bankAccount?.accountName} />}
                {/* <Input
                placeholder="Account Name"
                value={addNewRecipientData.bankAccount?.accountName || ""}
                isDisabled
                appendOuterIcon={
                    <>
                        {addNewRecipientData.isVerifyingAccount && (
                            <div>
                            </div>
                        )}
                    </>
                }
            /> */}
                <Input placeholder="Alias (Optional)" value={addNewRecipientData.alias} onChange={addNewRecipientData.setAlias} />
            </div>
        </SimpleModal>
    );
}

export default AddNewRecipientModal;

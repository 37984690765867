import GoogleIcon from "../google-icon";
import InflowArrow from "../../assets/svg/dashboard/inflow-arrow.svg";
import OutflowArrow from "../../assets/svg/dashboard/outflow-arrow.svg";
import getInitials from "../../helpers/get-initials";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import titleCase from "../../hooks/titleCase";

interface Props {
    icon?: "outflow" | "inflow" | "cancel" | "none";
    size?: "xs" | "2xs" | "sm" | "md" | "lg";
    title?: React.ReactNode | string;
    color?: "white" | "grey";
    initials?: string | null;
    dataType?: string;
    subtitle?: React.ReactNode | string;
    fullWidth?: boolean;
    customInitials?: React.ReactNode;
    isHideInitials?: boolean;
}

function AvatarDetails(props: Props) {
    const { icon = "none", size = "sm", color = "grey", fullWidth = true, isHideInitials = false } = props;

    const title = typeof props.title === "string" ? props.title : "";

    const initials = props.initials || getInitials(title);

    return (
        <div className={"flex items-center justify-between space-x-2" + `${fullWidth ? " w-full" : " w-max"}`} data-type={props.dataType}>
            {!isHideInitials && (
                <div data-type={props.dataType}>
                    <div
                        className={
                            "relative flex items-center justify-center rounded-md bg-grey-tertiary duration-150" +
                            ` ${color === "white" ? "bg-white" : ""}` +
                            ` ${color === "grey" ? "bg-grey-tertiary" : ""}` +
                            ` ${size === "2xs" ? "h-7 w-7" : ""}` +
                            ` ${size === "xs" ? "h-8 w-8" : ""}` +
                            ` ${size === "sm" ? "h-10 w-10" : ""}` +
                            ` ${size === "md" ? "h-12 w-12" : ""}` +
                            ` ${size === "lg" ? "h-14 w-14" : ""}`
                        }
                        data-type={props.dataType}
                    >
                        {props.customInitials ? (
                            <div className="flex h-full w-full items-center justify-center" data-type={props.dataType}>
                                {props.customInitials}
                            </div>
                        ) : initials ? (
                            <span
                                className={
                                    "uppercase text-black-secondary" +
                                    ` ${size === "2xs" ? "text-[10px]" : ""} ` +
                                    ` ${size === "xs" ? "text-xs" : ""} ` +
                                    ` ${size === "sm" ? "text-sm" : ""}` +
                                    ` ${size === "md" ? "text-base" : ""}` +
                                    ` ${size === "lg" ? "text-lg" : ""}`
                                }
                                data-type={props.dataType}
                            >
                                {initials}
                            </span>
                        ) : (
                            <div className="flex h-full w-full items-center justify-center" data-type={props.dataType}>
                                <GoogleIcon icon="payments" dataType={props.dataType} className="text-black-secondary" size="sm" />
                            </div>
                        )}

                        {icon !== "none" && (
                            <div
                                className={
                                    "absolute rounded-full border-[1.12px] border-white" +
                                    `${size === "lg" ? " -bottom-1 -right-1" : " -right-0.5 bottom-0"}`
                                }
                                data-type={props.dataType}
                            >
                                {icon === "inflow" && (
                                    <img
                                        className={size === "lg" ? "h-3.5 w-3.5" : "h-2 w-2"}
                                        src={InflowArrow}
                                        alt="inflow arrow"
                                        data-type={props.dataType}
                                    />
                                )}
                                {icon === "outflow" && (
                                    <img
                                        className={size === "lg" ? "h-3.5 w-3.5" : "h-2 w-2"}
                                        src={OutflowArrow}
                                        alt="outflow arrow"
                                        data-type={props.dataType}
                                    />
                                )}
                                {icon === "cancel" && (
                                    <GoogleIcon
                                        icon="block"
                                        dataType={props.dataType}
                                        className={`${size === "lg" ? "!text-[14px]" : "!text-[8px]"} text-black-secondary`}
                                        size="xs"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {(props.title || props.subtitle) && (
                <div
                    className={
                        "relative w-full " +
                        ` ${size === "2xs" ? "h-10" : ""} ` +
                        ` ${size === "xs" ? "h-10" : ""} ` +
                        ` ${size === "sm" ? "h-10" : ""}` +
                        ` ${size === "md" ? "h-12" : ""}` +
                        ` ${size === "lg" ? "h-14" : ""}`
                    }
                    data-type={props.dataType}
                >
                    <div
                        className={`absolute left-0 top-0 flex h-full w-full flex-col items-start justify-center ${!(size === "2xs" || size === "xs") ? "space-y-1" : ""}`}
                        data-type={props.dataType}
                    >
                        {typeof props.title === "string" && (
                            <p
                                className={
                                    "max-w-90% truncate text-left font-medium capitalize text-black" +
                                    ` ${size === "2xs" ? "text-sm" : ""} ` +
                                    ` ${size === "xs" ? "text-sm" : ""} ` +
                                    ` ${size === "sm" ? "text-sm" : ""}` +
                                    ` ${size === "md" ? "text-base" : ""}` +
                                    ` ${size === "lg" ? "text-lg" : ""}`
                                }
                                data-type={props.dataType}
                            >
                                {titleCase(props.title || "")}
                            </p>
                        )}
                        {typeof props.title !== "string" && !isNullOrUndefined(props.title) && (
                            <div className="w-full" data-type={props.dataType}>
                                {props.title}
                            </div>
                        )}
                        {typeof props.subtitle === "string" && (
                            <p
                                className={
                                    "w-full max-w-90% truncate text-left text-black-tertiary" +
                                    ` ${size === "2xs" ? "text-[10px]" : ""} ` +
                                    ` ${size === "xs" ? "text-xs" : ""} ` +
                                    ` ${size === "sm" ? "text-[10px]" : ""}` +
                                    ` ${size === "md" ? "text-xs" : ""}` +
                                    ` ${size === "lg" ? "text-sm" : ""}`
                                }
                                data-type={props.dataType}
                            >
                                {props.subtitle}
                            </p>
                        )}
                        {typeof props.subtitle !== "string" && !isNullOrUndefined(props.subtitle) && (
                            <div className="w-full" data-type={props.dataType}>
                                {props.subtitle}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default AvatarDetails;

import { Form, Formik } from "formik";
import { NotificationType, UserRole } from "../../../../../../models/userAccount.constants";
import React, { useCallback, useEffect } from "react";
import useInviteTeamMember, { InviteStage } from "../../../Services/team-members/hooks/useInviteTeamMember";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Checkbox from "../../../../../../components/checkbox";
import CurrencyCode from "../../../../../../components/currency-code";
import FormInput from "../../../../../../components/inputs/FormInput";
import MessageToasts from "../../../../../../components/general/MessageToasts/MessageToasts";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import MoneyInput from "../../../../../../components/inputs/money-input";
import MultiTagCloudSelectDropdown from "../../../../../../components/dropdown/multi-select-dropdown/MultiTagCloudSelectDropdown";
import SingleSelectDropdown from "../../DropDowns/TeamMembers/SingleSelectDropdown";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import { ToastType } from "../../../../../../helpers/AppConstants";
import formatNumber from "../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

interface InviteTeamMemberModalProps {
    active: boolean;
    onClose: () => void;
    onComplete: (item: TeamMemberItem) => void;
}

function InviteTeamMemberModal({ active, onClose, onComplete }: InviteTeamMemberModalProps): JSX.Element {
    const dispatch = useDispatch();

    const handleOnComplete = useCallback((item: TeamMemberItem, message: string) => {
        dispatch(messageTrue({ message: message }));
        onClose();
        onComplete(item);
    }, []);

    const useInviteTeamMemberData = useInviteTeamMember(handleOnComplete, active);

    useEffect(() => {
        if (useInviteTeamMemberData.settingsForm.role === UserRole.NOTIFICATION_ONLY) {
            useInviteTeamMemberData.setNotificationType(NotificationType.TRUNCATED);
        }
    }, [useInviteTeamMemberData.settingsForm.role]);

    useEffect(() => {
        if (useInviteTeamMemberData.settingsForm.notificationType === NotificationType.NONE) {
            useInviteTeamMemberData.setNotificationChannels([]);
        }
    }, [useInviteTeamMemberData.settingsForm.notificationType]);

    const handleOnClose = useCallback(() => {
        onClose();
        useInviteTeamMemberData.formikRef.current?.resetForm();
    }, [useInviteTeamMemberData.formikRef.current]);

    return (
        <Modal size="md" active={active} toggler={handleOnClose}>
            <ModalHeader onClose={handleOnClose}>
                <div className="flex flex-col space-y-4">
                    <h2>Invite a Team Member</h2>
                    <div className="flex space-x-2">
                        <div className="w-5 rounded-lg border-b-4 border-blue" />
                        <div
                            className={`${useInviteTeamMemberData.inviteTeamMemberStage === InviteStage.TEAM_MEMBER_RULES ? "border-blue" : "border-grey"} w-5 rounded-lg border-b-4`}
                        />
                    </div>
                </div>
            </ModalHeader>
            <Formik
                initialValues={useInviteTeamMemberData.initialFormState}
                innerRef={useInviteTeamMemberData.formikRef}
                validationSchema={useInviteTeamMemberData.formValidation}
                onSubmit={(values) => useInviteTeamMemberData.handleFormSubmit(values)}
                onReset={() => {
                    void useInviteTeamMemberData.formikRef.current.setTouched({
                        ...useInviteTeamMemberData.formikRef.current.touched,
                        ["firstName"]: true,
                    });
                }}
                enableReinitialize
                validateOnMount
                validateOnChange
                validateOnBlur
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <ModalBody>
                                <div className="flex flex-col items-start space-y-2 text-base font-normal md:items-center lg:items-center">
                                    {useInviteTeamMemberData.inviteTeamMemberStage === InviteStage.TEAM_MEMBER_DETAILS && (
                                        <>
                                            <div className="flex w-full flex-col space-y-4">
                                                <FormInput type="text" placeholder="First Name" name="firstName" />
                                                <FormInput type="text" placeholder="Last Name" name="lastName" />
                                                <FormInput type="text" placeholder="Phone Number" name="phone" />
                                                <FormInput type="text" placeholder="Email Address" name="email" />
                                            </div>
                                        </>
                                    )}

                                    {useInviteTeamMemberData.inviteTeamMemberStage === InviteStage.TEAM_MEMBER_RULES && (
                                        <>
                                            <div className="flex w-full flex-col items-center justify-start space-y-4">
                                                <MultiTagCloudSelectDropdown
                                                    placeholder="Select Account(s)"
                                                    value={useInviteTeamMemberData.settingsForm.userAccountIds}
                                                    options={useInviteTeamMemberData.options.account}
                                                    onChange={useInviteTeamMemberData.setUserAccountIds}
                                                    active={active}
                                                />
                                                <SingleSelectDropdown
                                                    placeholder="Role"
                                                    options={useInviteTeamMemberData.options.role}
                                                    onChange={useInviteTeamMemberData.setRole}
                                                    active={active}
                                                    fitHeight
                                                />
                                                <SingleSelectDropdown
                                                    placeholder="Notification Type"
                                                    options={useInviteTeamMemberData.options.notificationType}
                                                    onChange={useInviteTeamMemberData.setNotificationType}
                                                    isDisabled={useInviteTeamMemberData.settingsForm.role === UserRole.NOTIFICATION_ONLY}
                                                    value={useInviteTeamMemberData.settingsForm.notificationType}
                                                    active={active}
                                                    fitHeight
                                                />
                                                <MultiTagCloudSelectDropdown
                                                    placeholder="Notification Channel"
                                                    options={useInviteTeamMemberData.options.notificationChannel}
                                                    onChange={useInviteTeamMemberData.setNotificationChannels}
                                                    isDisabled={useInviteTeamMemberData.settingsForm.notificationType === NotificationType.NONE}
                                                    value={useInviteTeamMemberData.settingsForm.notificationChannels}
                                                    active={active}
                                                />
                                                {(useInviteTeamMemberData.settingsForm.role === UserRole.APPROVER ||
                                                    useInviteTeamMemberData.settingsForm.role === UserRole.BOTH) && (
                                                    <div className="w-full">
                                                        <Checkbox
                                                            id="approval-level"
                                                            size="sm"
                                                            text="Set amount based approval access for your team members"
                                                            func={useInviteTeamMemberData.handleToggleApprovalLevel}
                                                            checked={useInviteTeamMemberData.isSettingApprovalLevel}
                                                            truncate={false}
                                                        />
                                                    </div>
                                                )}

                                                {useInviteTeamMemberData.isSettingApprovalLevel && (
                                                    <MoneyInput
                                                        placeholder="Amount"
                                                        icon={<CurrencyCode />}
                                                        value={useInviteTeamMemberData.settingsForm.maxApprovalAmount || ""}
                                                        onChange={(amount) => {
                                                            if (!amount || amount === 0 || Number(amount) === 0) {
                                                                useInviteTeamMemberData.setMaxApprovalAmount("");
                                                                return;
                                                            }
                                                            useInviteTeamMemberData.setMaxApprovalAmount(String(amount));
                                                        }}
                                                        helperText={
                                                            <span>
                                                                {useInviteTeamMemberData.formikRef.current.values.firstName}{" "}
                                                                {useInviteTeamMemberData.formikRef.current.values.lastName} will only be able to
                                                                approve payments below <CurrencyCode />
                                                                {useInviteTeamMemberData.settingsForm.maxApprovalAmount
                                                                    ? formatNumber(useInviteTeamMemberData.settingsForm.maxApprovalAmount)
                                                                    : "0.00"}
                                                            </span>
                                                        }
                                                    />
                                                )}
                                            </div>
                                            {!!useInviteTeamMemberData.error && (
                                                <div className="max-w-md pt-1.5">
                                                    <MessageToasts toastMessage={useInviteTeamMemberData.error || ""} toastType={ToastType.ERROR} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {useInviteTeamMemberData.inviteTeamMemberStage === InviteStage.TEAM_MEMBER_DETAILS && (
                                    <div className="flex w-full flex-col pt-4 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                            <ButtonComp size="lg" color="grey" buttonType="secondary" func={handleOnClose} fullWidth>
                                                Cancel
                                            </ButtonComp>
                                        </div>
                                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                            <ButtonComp
                                                size="lg"
                                                color="black"
                                                buttonType="primary"
                                                disable={!formik.isValid}
                                                func={useInviteTeamMemberData.handleSetStageToTeamMemberRules}
                                                fullWidth
                                            >
                                                Next
                                            </ButtonComp>
                                        </div>
                                    </div>
                                )}
                                {useInviteTeamMemberData.inviteTeamMemberStage === InviteStage.TEAM_MEMBER_RULES && (
                                    <div className="flex w-full flex-col pt-4 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                        <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                            <ButtonComp
                                                size="lg"
                                                buttonType="secondary"
                                                color="grey"
                                                func={useInviteTeamMemberData.handleSetStageToTeamMemberDetails}
                                                fullWidth
                                            >
                                                Back
                                            </ButtonComp>
                                        </div>
                                        <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                            <ButtonComp
                                                size="lg"
                                                type="submit"
                                                color="black"
                                                buttonType="primary"
                                                isLoading={useInviteTeamMemberData.isSubmitting}
                                                disable={
                                                    !useInviteTeamMemberData.isFormValid ||
                                                    (useInviteTeamMemberData.isSettingApprovalLevel &&
                                                        (isNullOrUndefined(useInviteTeamMemberData.settingsForm.maxApprovalAmount) ||
                                                            (!isNullOrUndefined(useInviteTeamMemberData.settingsForm.maxApprovalAmount) &&
                                                                Number(parseFloat(useInviteTeamMemberData.settingsForm.maxApprovalAmount)) < 1) ||
                                                            !useInviteTeamMemberData.settingsForm.maxApprovalAmount))
                                                }
                                                fullWidth
                                            >
                                                Send Invite
                                            </ButtonComp>
                                        </div>
                                    </div>
                                )}
                            </ModalFooter>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

export default InviteTeamMemberModal;

import { Link, To } from "react-router-dom";

import React from "react";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | "fit";
    color?: "red" | "blue" | "green";
    state?: unknown;
    linkTo?: To;
    isLink?: boolean;
    padding?: "sm" | "md" | "lg" | "none";
    children: React.ReactNode;
    dataType?: string;
    isActive?: boolean;
    className?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;

    onClick?: (e: React.MouseEvent) => void;
}

const getClass = (props: Props): string => {
    const classes: string[] = [
        "flex justify-start items-center",
        "w-full text-black-tertiary font-normal antialiased",
        "transition-all ease-in-out duration-300",
        "overflow-ellipsis overflow-hidden whitespace-nowrap ",
    ];

    classes.push(
        props.isDisabled
            ? "cursor-default pointer-events-none opacity-60"
            : props.isReadOnly
              ? "cursor-default pointer-events-none"
              : "cursor-pointer"
    );
    classes.push(
        props.padding === "lg"
            ? `px-6 ${props.size === "fit" ? "py-6" : ""}`
            : props.padding === "md"
              ? `px-4 ${props.size === "fit" ? "py-4" : ""}`
              : props.padding === "sm"
                ? `px-2 ${props.size === "fit" ? "py-2" : ""}`
                : props.padding === "none"
                  ? ""
                  : `px-4 ${props.size === "fit" ? "py-4" : ""}`
    );
    classes.push(
        props.size === "sm"
            ? "h-8"
            : props.size === "md"
              ? "h-10"
              : props.size === "lg"
                ? "h-12"
                : props.size === "xl"
                  ? "h-14"
                  : props.size === "2xl"
                    ? "h-16"
                    : props.size === "fit"
                      ? "h-fit"
                      : "h-14"
    );
    classes.push(
        props.color === "red"
            ? "hover:bg-error-backdrop hover:text-error"
            : props.color === "green"
              ? "hover:bg-success-backdrop hover:text-success"
              : props.color === "blue" || props.isLink
                ? "hover:bg-blue-senary hover:text-blue"
                : "hover:bg-grey-backdrop"
    );
    classes.push(
        props.isActive
            ? props.color === "red"
                ? "bg-error-backdrop text-error"
                : props.color === "green"
                  ? "bg-success-backdrop text-success"
                  : props.color === "blue" || props.isLink
                    ? "bg-blue-senary text-blue"
                    : "bg-grey-backdrop"
            : ""
    );

    classes.push(props.className || "");

    return classes.join(" ");
};

function NewDropdownItem(props: Props): JSX.Element {
    return (
        <>
            {props.isLink && props.linkTo ? (
                <div className="w-full">
                    <Link to={props.linkTo} state={props.state} className="w-full">
                        <div className={getClass(props)} onClick={(e) => props.onClick && props.onClick(e)} data-type={props.dataType || "dropdown"}>
                            {props.children}
                        </div>
                    </Link>
                </div>
            ) : (
                <div
                    className={getClass(props)}
                    onClick={(e) => {
                        if (props.isDisabled) {
                            e.stopPropagation();
                            e.preventDefault();
                        } else {
                            props.onClick && props.onClick(e);
                        }
                    }}
                    data-type={props.dataType || "dropdown"}
                >
                    {props.children}
                </div>
            )}
        </>
    );
}

export default NewDropdownItem;

import { resetBillPaymentData, setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useDispatch, useSelector } from "react-redux";

import AirtimePurchaseHeaders from "./AirtimePurchaseHeaders";
import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import BillPurchase from "../../../../../../models/billPurchase";
import BillVendor from "../../../../../../models/billVendor";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import CurrencyCode from "../../../../../../components/currency-code";
import { IRootState } from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../components/message-toast";
import MoneyInput from "../../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../../components/user-account-dropdown";
import RecentBillPaymentSkeleton from "../../Skeletons/RecentBillPaymentSkeleton";
import RecentContactsElement from "../../Elements/RecentContactsElement";
import VendorDropdown from "../../DropDowns/VendorDropdown";
import { abortBillPayment } from "../../../services/billPayment/billPaymentApi";
import commaSeparator from "../../../../../../utils/formatNumber";
import { resetAllAirtimePurchaseData } from "../../../../../../redux/payments/airtimePurchase/slice/airtimePurchaseSlice";
import useAirtimePurchaseAmount from "../../../hooks/state/BillPayment/AirtimePurchase/useAirtimePurchaseAmount";
import useAirtimePurchasePayFrom from "../../../hooks/state/BillPayment/AirtimePurchase/useAirtimePurchasePayFrom";
import useAirtimePurchasePayTo from "../../../hooks/state/BillPayment/AirtimePurchase/useAirtimePurchasePayTo";
import useAirtimePurchaseProduct from "../../../hooks/state/BillPayment/AirtimePurchase/useAirtimePurchaseProduct";
import { useCallback } from "react";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";

// import SinglePaymentHeaders from "./SinglePaymentHeaders";

function PaymentDetails(): JSX.Element {
    const dispatch = useDispatch();

    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const selectedVendorName = useSelector((state: IRootState) => state.airtimePurchase.selectedVendorName);
    const selectedUserAccount = useSelector((state: IRootState) => state.airtimePurchase.selectedUserAccount);
    const selectedVendorRange = useSelector((state: IRootState) => state.airtimePurchase.selectedVendorRange);
    const recentBillPurchases = useSelector((state: IRootState) => state.billPayment.recentBillPurchases);
    const selectedPayToAccount = useSelector((state: IRootState) => state.airtimePurchase.airtimePurchaseDetails.accountId);
    const airtimePurchaseDetails = useSelector((state: IRootState) => state.airtimePurchase.airtimePurchaseDetails);
    const isRecentBillPurchasesLoading = useSelector((state: IRootState) => state.billPayment.isRecentBillPurchasesLoading);

    const { handleAmountChange } = useAirtimePurchaseAmount();
    const { selectedAccountId, handleSelectAccount, accounts } = useAirtimePurchasePayFrom();
    const { handleOnChange } = useAirtimePurchasePayTo();
    const { handleSelectProduct } = useAirtimePurchaseProduct();

    const handleSelectRecentPurchase = useCallback(
        (_data: BillPurchase) => {
            if (!vendorsList) return;
            handleAmountChange(String(_data.amount));
            handleOnChange(_data.extraData.mobile.phone);
            handleSelectProduct(vendorsList.find((_vendor) => _vendor.id === _data.product.vendor?.id) as BillVendor);
        },
        [vendorsList]
    );

    const handleBack = useCallback(() => {
        dispatch(resetBillPaymentData());
        dispatch(resetAllAirtimePurchaseData());
        abortBillPayment();
    }, []);

    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.REVIEW_PAYMENT));
    }, []);

    return (
        <>
            <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-6">
                <AirtimePurchaseHeaders />
                {!isRecentBillPurchasesLoading && recentBillPurchases && recentBillPurchases.length > 0 && (
                    <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Recent Purchase</p>
                        <div className="flex items-center justify-start space-x-7 overflow-auto">
                            {recentBillPurchases.slice(-3).map((_purchase, index) => (
                                <RecentContactsElement
                                    key={index}
                                    data={_purchase}
                                    selectedAccountId={selectedAccountId}
                                    onClick={handleSelectRecentPurchase}
                                    handleSelectAccount={handleSelectAccount}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {isRecentBillPurchasesLoading && <RecentBillPaymentSkeleton />}

                <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium leading-[100%] text-black-secondary">Select Account</p>
                        <NewUserAccountDropdown
                            value={selectedAccountId || ""}
                            options={accounts || null}
                            onClick={handleSelectAccount}
                            isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                            dropdownSize="xl"
                            showBalance
                            initiatorCanSelect
                        />
                    </div>

                    <div className="flex w-full flex-col space-y-4">
                        <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                        <MoneyInput
                            label="Amount"
                            value={airtimePurchaseDetails.amount || ""}
                            onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                            inputSize="xl"
                        />
                        <Input placeholder="Phone Number" value={selectedPayToAccount} onChange={handleOnChange} inputSize="xl" />
                        <VendorDropdown label="Service Provider" value={selectedVendorName} options={vendorsList} onSelect={handleSelectProduct} />
                    </div>

                    {airtimePurchaseDetails.amount.length > 0 &&
                        !!useMoneyToNumber(airtimePurchaseDetails.amount) &&
                        (useMoneyToNumber(airtimePurchaseDetails.amount) > (selectedVendorRange?.max as number) ||
                            useMoneyToNumber(airtimePurchaseDetails.amount) < (selectedVendorRange?.min as number)) && (
                            <MessageToast
                                message={
                                    <div>
                                        Amount must be between <CurrencyCode />
                                        {commaSeparator(selectedVendorRange?.min)} and <CurrencyCode />
                                        {commaSeparator(selectedVendorRange?.max)}
                                    </div>
                                }
                                type="error"
                                fullWidth
                            />
                        )}
                    {useMoneyToNumber(airtimePurchaseDetails.amount) > (selectedUserAccount?.balance as number) && (
                        <MessageToast
                            message={
                                <div>
                                    You do not have enough funds. Your account balance is <CurrencyCode />
                                    {commaSeparator(selectedUserAccount?.balance as number)}
                                </div>
                            }
                            type="error"
                            fullWidth
                        />
                    )}
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4">
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" size="xl" func={handleBack}>
                        Back
                    </ButtonComp>

                    <ButtonComp
                        color="black"
                        buttonType="primary"
                        size="xl"
                        disable={
                            !!(
                                !selectedUserAccount ||
                                !airtimePurchaseDetails.amount ||
                                !selectedVendorName ||
                                selectedPayToAccount.length < 5 ||
                                useMoneyToNumber(airtimePurchaseDetails.amount) > (selectedVendorRange?.max as number) ||
                                useMoneyToNumber(airtimePurchaseDetails.amount) < (selectedVendorRange?.min as number) ||
                                useMoneyToNumber(airtimePurchaseDetails.amount) === 0 ||
                                useMoneyToNumber(airtimePurchaseDetails.amount) > (selectedUserAccount?.balance as number)
                            )
                        }
                        func={handleNextPage}
                    >
                        Review Payment
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PaymentDetails;

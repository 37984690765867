import GoogleIcon from "../google-icon";
import React from "react";

interface Props {
    children: React.ReactNode;
    subTitle?: string;
    dataType?: string | null;
    withImgSection?: boolean;

    onClose?: () => void;
    toggler?: () => void;
}

function ModalHeader(props: Props): JSX.Element {
    return (
        <>
            <div className="relative flex w-full items-start justify-between space-x-2 font-medium" data-type={props.dataType && props.dataType}>
                <div className={"max-w-full " + `${!props.subTitle ? "self-center" : ""}`} data-type={props.dataType && props.dataType}>
                    {props.children && (
                        <div
                            className="flex flex-row items-start justify-start space-x-2 text-lg text-black"
                            data-type={props.dataType && props.dataType}
                        >
                            <div className="leading-none" data-type={props.dataType && props.dataType}>
                                {props.children}
                            </div>
                        </div>
                    )}
                    {props.subTitle && (
                        <p
                            className="break-words pt-2 text-left text-sm font-normal text-black-tertiary"
                            data-type={props.dataType && props.dataType}
                        >
                            {props.subTitle}
                        </p>
                    )}
                </div>
                {!props.withImgSection && (
                    <div>
                        <button
                            className="flex h-10 w-10 items-center justify-center rounded-md bg-grey-tertiary outline-none transition-all focus:outline-none"
                            onClick={() => {
                                props.onClose && props.onClose();
                                props.toggler && props.toggler();
                            }}
                            data-type={props.dataType && props.dataType}
                        >
                            <GoogleIcon icon="close" size="lg" />
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default ModalHeader;

import { useEffect, useState } from "react";

import TextClipboard from "../../../../../../../components/text-clipboard";
import Transaction from "../../../../../../../models/transaction";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardNIP({ dataType, transaction }: Props): JSX.Element {
    const [clipSuccess, setClipSuccess] = useState(false);

    useEffect(() => {
        if (clipSuccess) {
            setTimeout(() => {
                setClipSuccess(false);
            }, 800);
        } else {
            return;
        }
    }, [clipSuccess]);

    return (
        <>
            {transaction && transaction.nipSessionId && (
                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                    <p className="text-sm text-black-tertiary" data-type={dataType}>
                        NIP Session ID
                    </p>
                    <div className="flex max-w-55% flex-col items-end justify-end space-y-1">
                        <p className="w-full whitespace-normal break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                            {transaction.nipSessionId}
                        </p>
                        <TextClipboard copyString={transaction.nipSessionId} text="Copy ID" color="blue" />
                    </div>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardNIP;

import FullPageTrayFooter from "../../../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import { IRootState } from "../../../../../../../redux/rootReducer";
import NewTransactionIcon from "../../../../../../../components/new-transaction-icon";
import { PaymentsType } from "../../../../../payments-new/hooks/state/send-money/payments.constants";
import Transaction from "../../../../../../../models/transaction";
import UploadInput from "../../../../../../../components/inputs/upload-input";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useTransactionDetailsLencoReceipt from "../Hooks/useTransactionDetailsLencoReceipt";

interface TransactionDetailCardActionsInterface {
    dataType: string;
    transaction: Transaction;
    isUploadingReceipt: boolean;

    handleCancel?: () => void;
    handleAttachFile: () => void;
    handleShowTagTransactionModal: () => void;
    handleUploadAccountingReceipt: (transaction: Transaction, file: File) => Promise<void>;
    handlePreviewAccountingReceipt: (transaction: Transaction) => Promise<void>;
}

function TransactionDetailCardActions({
    dataType,
    transaction,
    isUploadingReceipt,
    handleAttachFile,
    handleShowTagTransactionModal,
    handleUploadAccountingReceipt,
}: TransactionDetailCardActionsInterface): JSX.Element {
    const { isDownloading: isDownloadingLencoReceipt, handleDownloadLencoReceipt } = useTransactionDetailsLencoReceipt();

    const navigate = useNavigate();

    const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

    return (
        <>
            {transaction && transaction.isDebit() && transaction.isSuccess && transaction.destination && transaction.destination.account ? (
                <FullPageTrayFooter size="md" dataType={dataType}>
                    <div
                        className="flex h-full w-full flex-row items-center justify-center space-x-5 text-center text-2xs text-black-tertiary"
                        data-type={dataType}
                    >
                        <div className="flex h-full items-center justify-center space-x-12">
                            {transaction &&
                                transaction.isDebit() &&
                                transaction.isSuccess &&
                                transaction.destination &&
                                transaction.destination.account && (
                                    <NewTransactionIcon
                                        icon="download"
                                        text="Download"
                                        isLoading={isDownloadingLencoReceipt}
                                        dataType={dataType}
                                        onClick={() => transaction && void handleDownloadLencoReceipt(transaction)}
                                    />
                                )}
                            {/* isNigeria && */}
                            {isNigeria &&
                                transaction &&
                                transaction.isDebit() &&
                                transaction.isSuccess &&
                                transaction.destination &&
                                transaction.destination.customerAccount && (
                                    <NewTransactionIcon icon="share" text="Share" dataType={dataType} onClick={handleShowTagTransactionModal} />
                                )}
                            <NewTransactionIcon icon="attach_file" text="Attach Receipt" dataType={dataType} onClick={handleAttachFile} />
                        </div>
                    </div>
                </FullPageTrayFooter>
            ) : transaction && transaction.isCredit() ? (
                <FullPageTrayFooter size="md" dataType={dataType}>
                    <div className="h-full w-full px-7">
                        <UploadInput
                            isLoading={isUploadingReceipt}
                            handleUpload={(_file) => void handleUploadAccountingReceipt(transaction, _file)}
                        />
                    </div>
                </FullPageTrayFooter>
            ) : (
                transaction &&
                transaction.isDebit() &&
                transaction.isFailed && (
                    <FullPageTrayFooter size="md" dataType={dataType}>
                        <NewTransactionIcon
                            icon="refresh"
                            text="Retry"
                            dataType={dataType}
                            onClick={() =>
                                navigate(
                                    {
                                        pathname:
                                            transaction.isInternalFallback || transaction.isInternal
                                                ? "/payments/make/accounts"
                                                : "/payments/make/single",
                                        // search:
                                        //     `?from=${transaction.originatingUserAccount?.id}` +
                                        //     `?to=${transaction.destination?.customerAccount?.id || ""}`,
                                    },
                                    {
                                        state: {
                                            to:
                                                transaction.isInternalFallback || transaction.isInternal
                                                    ? transaction.destination?.userAccount?.id || ""
                                                    : transaction.destination?.customerAccount?.id || "",
                                            from: transaction.originatingUserAccount?.id,
                                            amount: transaction.amount || "",
                                            reason: transaction.description || "",
                                            typeOfTransfer:
                                                transaction.isInternalFallback || transaction.isInternal
                                                    ? PaymentsType.TRANSFER_BETWEEN_ACCOUNT
                                                    : PaymentsType.SINGLE_TRANSFER,
                                        },
                                    }
                                )
                            }
                        />
                    </FullPageTrayFooter>
                )
            )}
        </>
    );
}

export default TransactionDetailCardActions;

//  {
//      transaction.isDebit() && (
//          <>
//              <div
//                  className="flex cursor-pointer flex-col items-center justify-center space-y-2"
//                  data-type={dataType}
//                  onClick={() =>
//                      navigate(
//                          {
//                              pathname: "/payments/make/single",
//                              search: `?to=${transaction.destination?.account?.id || ""}`,
//                          },
//                          {
//                              state: {
//                                  typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
//                                  to: transaction.destination?.account?.id || "",
//                              },
//                          }
//                      )
//                  }
//              >
//                  <TransactionIcon icon={IconType.NEW_PAYMENT} data-type={dataType} />
//                  <p className="text-xs text-black-tertiary" data-type={dataType}>
//                      New Payment
//                  </p>
//              </div>
//          </>
//      );
//  }

import React, { useState } from "react";

import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import UserGroup from "../../../../../models/userGroup";
import useDimension from "../../../../../hooks/useDimension";
import useUserGroups from "../../hooks/use-user-groups";

interface UserGroupDropdownProps {
    onSelect: (selectedUserGroup: UserGroup | null) => void;
}

function UserGroupDropdown(props: UserGroupDropdownProps): JSX.Element {
    const { userGroups } = useUserGroups();
    const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup | null>(null);

    const deviceWidth = useDimension().width;

    return (
        <div className="m-auto w-full sm:w-auto">
            <NewDropdownHead
                content={<p className="w-full min-w-8 max-w-8 truncate text-left">{selectedUserGroup?.name ?? "All Recipient Group"}</p>}
                buttonType="secondary"
                color="grey"
                paddingSize="sm"
                size="md"
                fullWidth={deviceWidth < 640}
            >
                <>
                    {[null, ...userGroups].map((userGroup, index) => {
                        return (
                            <NewDropdownItem
                                key={index}
                                onClick={() => {
                                    setSelectedUserGroup(userGroup);
                                    props.onSelect(userGroup);
                                }}
                                size="md"
                                isDisabled={selectedUserGroup?.id === userGroup?.id}
                            >
                                <p className="w-full max-w-full truncate text-sm capitalize">{userGroup?.name ?? "All"}</p>
                            </NewDropdownItem>
                        );
                    })}
                </>
            </NewDropdownHead>
        </div>
    );
}

export default UserGroupDropdown;

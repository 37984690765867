import React, { CSSProperties, KeyboardEvent, ReactNode, useEffect, useState } from "react";

import { ReactComponent as EmptyXX } from "../../assets/images/empty/empty-xx.svg";
import GoogleIcon from "../google-icon";
import { FixedSizeList as List } from "react-window";
import SearchBar from "../search-bar";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import useClickOutside from "../../hooks/useClickOutside";

interface Props {
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    icon?: string;
    label: string;
    value: string | ReactNode;
    children?: ((_index: number) => React.ReactNode) | React.ReactDOM | Element[] | React.ReactElement | React.ReactElement[];
    withIcon?: boolean;
    dataType?: string;
    fitHeight?: boolean;
    isDisabled?: boolean;
    searchTerm?: string;
    dropdownSize?: "sm" | "md" | "lg" | "xl" | "2xl";
    isSearchable?: boolean;
    clickAndClose?: boolean;
    optionsLength?: number;
    searchPlaceholder?: string;
    dropdownAlwaysDown?: boolean;

    clickOutsideFunc?: () => void;
    handleChangeSearchTerm?: (e: string) => void;
}

function NewSelect({
    size = "lg",
    icon,
    label,
    value = "",
    children,
    dataType,
    withIcon = false,
    fitHeight = false,
    isDisabled = false,
    searchTerm = "",
    isSearchable = false,
    dropdownSize,
    clickAndClose = false,
    optionsLength,
    searchPlaceholder = "Search",
    dropdownAlwaysDown = false,

    clickOutsideFunc = undefined,
    handleChangeSearchTerm = undefined,
}: Props): JSX.Element {
    const [y, setY] = useState<number | null>(null);
    const [active, setActive] = useState<boolean>(false);
    const [hasValue, setHasValue] = useState<boolean>(false);
    const [positionTop, setPositionTop] = useState<boolean>(false);

    const innerHeight = window.innerHeight;

    const searchDropdownHeight =
        dropdownSize === "sm"
            ? 32
            : dropdownSize === "md"
              ? 40
              : dropdownSize === "lg"
                ? 48
                : dropdownSize === "xl"
                  ? 56
                  : dropdownSize === "2xl"
                    ? 64
                    : 56;

    const domNode = useClickOutside(() => {
        setActive(false);
        clickOutsideFunc && clickOutsideFunc();
        handleChangeSearchTerm && handleChangeSearchTerm("");
    });

    useEffect(() => {
        if (domNode.current) {
            setY(domNode.current.getBoundingClientRect().top);
        }
    });

    useEffect(() => {
        if (dropdownAlwaysDown) {
            setPositionTop(false);
        } else {
            if (y) {
                const shouldSetPositionTop = y > innerHeight / 1.65;
                setPositionTop(shouldSetPositionTop);
            }
        }
    }, [innerHeight, y, dropdownAlwaysDown]);

    useEffect(() => {
        setHasValue((typeof value === "string" && value.length > 0) || (typeof value === "object" && !isNullOrUndefined(value)));
    }, [value]);

    const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
        //it triggers by pressing the enter key
        if (event.key === "Enter") {
            setActive((prev) => !prev);
        }
    };

    return (
        <div
            className={`group/input relative flex h-fit w-full flex-col items-center justify-start ` + `${isDisabled ? "pointer-events-none" : ""}`}
            ref={domNode}
            data-type={dataType}
        >
            <div
                className={
                    `group/input relative flex w-full items-center space-x-4 rounded-lg bg-white font-normal outline-none` +
                    ` px-4 capitalize transition-all duration-150 focus:outline-none` +
                    ` cursor-pointer border border-solid bg-transparent text-left text-base leading-relaxed shadow-none lg:hover:border-black-tertiary lg:hover:text-black-secondary lg:focus:border-black-secondary lg:focus:text-black-secondary` +
                    ` ${size === "2xl" ? "h-16" : ""} ` +
                    ` ${size === "xl" ? "h-14" : ""} ` +
                    ` ${size === "lg" ? "h-12" : ""} ` +
                    ` ${size === "md" ? "h-10" : ""} ` +
                    ` ${size === "sm" ? "h-8" : ""} ` +
                    ` ${hasValue ? "max-w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap" : "justify-end"} ` +
                    ` ${hasValue && active ? "border-black" : ""} ` +
                    ` ${hasValue && !active ? "border-black-quin text-black-secondary lg:hover:border-black-tertiary lg:hover:text-black-secondary lg:focus:border-black-secondary lg:focus:text-black-secondary" : ""} ` +
                    ` ${!hasValue && active ? "border-black-secondary text-black-secondary" : ""} ` +
                    ` ${!hasValue && !active ? "border-black-quin text-black-tertiary" : ""} `
                }
                onClick={() => !isDisabled && setActive((prev) => !prev)}
                tabIndex={isDisabled ? -1 : 0}
                onKeyDown={handleKeypress}
                data-type={dataType}
            >
                {value && (
                    <div
                        className={
                            `flex items-center justify-start self-end truncate ${withIcon ? "space-x-2" : ""}` +
                            `${size === "sm" ? " pb-1" : ""}` +
                            `${size === "md" ? " pb-1" : ""}` +
                            `${size === "lg" ? " pb-1" : ""}` +
                            `${size === "xl" ? " pb-1.5" : ""}` +
                            `${size === "2xl" ? " pb-2" : ""}`
                        }
                    >
                        {withIcon && icon && (
                            <div>
                                <img
                                    className={`${dropdownSize === "xl" || dropdownSize === "2xl" ? "h-6 w-6" : "h-5 w-5"}`}
                                    src={icon}
                                    alt={typeof value === "string" ? value : "dropdown" + "icon"}
                                />
                            </div>
                        )}

                        {typeof value === "string" ? (
                            <span
                                className={
                                    `max-w-full self-end overflow-hidden truncate overflow-ellipsis whitespace-nowrap text-base text-black-secondary` +
                                    `${isDisabled ? " pointer-events-none bg-transparent text-black-quat" : ""}`
                                }
                                data-type={dataType}
                            >
                                {value}
                            </span>
                        ) : (
                            <div
                                className={`w-full overflow-auto ` + ` ${isDisabled ? " pointer-events-none bg-transparent text-black-quat" : ""}`}
                                data-type={dataType}
                            >
                                {value}
                            </div>
                        )}
                    </div>
                )}
                <div className={`${active ? "rotate-0" : "rotate-180"} ` + "w-max text-black duration-150"} data-type={dataType}>
                    <GoogleIcon icon="keyboard_arrow_up" dataType={dataType || ""} />
                </div>
            </div>

            {label && (
                <label
                    htmlFor="text"
                    className={
                        `pointer-events-none absolute left-4 z-10 !ml-0 w-full max-w-70% truncate duration-150 group-hover/input:!text-black-tertiary ` +
                        ` ${active ? "top-0 text-2xs text-black-tertiary" : "top-3 text-base"}` +
                        ` ${hasValue ? "!top-0 !text-2xs !text-black-tertiary" : "text-black-quat"}` +
                        ` ${size === "sm" ? "leading-6" : ""}` +
                        ` ${size === "md" ? "leading-6" : ""}` +
                        ` ${size === "lg" ? "leading-6" : ""}` +
                        ` ${size === "xl" ? (active || hasValue ? "leading-8" : "!top-3.5 leading-7") : ""}` +
                        ` ${size === "2xl" ? (active || hasValue ? "leading-10" : "!top-3.5 leading-9") : ""}` +
                        ` ${isDisabled ? "text-black-quat" : ""}`
                    }
                    data-type={dataType}
                >
                    <span className="h-fit truncate">{label}</span>
                </label>
            )}

            <div
                className={
                    `absolute my-2 w-full duration-300 ease-in-out` +
                    `${positionTop ? " bottom-full origin-bottom" : " top-full origin-top"}` +
                    `${active ? " z-40 opacity-100" : " pointer-events-none max-h-0 overflow-hidden opacity-0"}` +
                    `${!fitHeight ? "" : ""} `
                }
                data-type={dataType}
            >
                <div className="w-full pb-4">
                    <div className="h-full max-h-[60vh] w-full overflow-y-auto rounded-lg bg-white shadow-dropdown">
                        {isSearchable && handleChangeSearchTerm && (
                            <div
                                className={
                                    "flex w-full items-center justify-start border-b border-grey-secondary px-4" +
                                    ` ${size === "2xl" ? "h-16" : ""} ` +
                                    ` ${size === "xl" ? "h-14" : ""} ` +
                                    ` ${size === "lg" ? "h-12" : ""} ` +
                                    ` ${size === "md" ? "h-10" : ""} ` +
                                    ` ${size === "sm" ? "h-8" : ""} `
                                }
                                data-type={dataType}
                            >
                                <SearchBar placeholder={searchPlaceholder} value={searchTerm} onChange={handleChangeSearchTerm} size={dropdownSize} />
                            </div>
                        )}
                        {isSearchable && !isNullOrUndefined(optionsLength) && optionsLength > 0 && typeof children === "function" && (
                            <div className="h-full w-full" onClick={() => clickAndClose && setActive((prev) => !prev)} data-type={dataType}>
                                <List
                                    width="100%"
                                    height={
                                        optionsLength * searchDropdownHeight > innerHeight / 2.25
                                            ? innerHeight / 2.25
                                            : optionsLength * searchDropdownHeight
                                    }
                                    itemSize={searchDropdownHeight}
                                    itemCount={optionsLength}
                                    className="w-full [&>div>div:not(:first-child)]:border-t [&>div>div:not(:first-child)]:border-grey-secondary"
                                >
                                    {({ index, style }: { index: number; style: CSSProperties | undefined }) => {
                                        return (
                                            <div className="w-full" style={style} key={index} data-type={dataType}>
                                                {children && children(index)}
                                            </div>
                                        );
                                    }}
                                </List>
                            </div>
                        )}
                        {isSearchable && !isNullOrUndefined(optionsLength) && optionsLength < 1 && (
                            <div className="flex w-full flex-col items-center justify-center space-y-2 py-4" data-type={dataType}>
                                <EmptyXX className="h-10 w-10" data-type={dataType} />
                                <span className="text-sm text-black-tertiary" data-type={dataType}>
                                    Nothing Found
                                </span>
                            </div>
                        )}

                        {!isSearchable && (
                            <div
                                className="w-full [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary"
                                onClick={() => clickAndClose && setActive((prev) => !prev)}
                                data-type={dataType}
                            >
                                {children as React.ReactNode}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewSelect;

import { ReactComponent as Check } from "../../../../../../../../../../../../assets/svg/LencoPay/check.svg";
import { ReactComponent as CheckWhite } from "../../../../../../../../../../../../assets/svg/LencoPay/check-white.svg";
import React from "react";
import { isBackgroundColorDark } from "../../../../../../../../../hookss/ApiAccessKeySettings/calculateTextColor";
interface Props {
    color: string;
    isSelected: boolean;
    isDisabled?: boolean;
    handleSelectColor?: (_color: string) => void;
}

function ColorCircle(props: Props): JSX.Element {
    return (
        <>
            <div
                className={
                    "flex min-w-max items-center justify-center rounded border-0.5 p-1.5 " +
                    `${props.isDisabled ? "pointer-events-none" : ""} ` +
                    `${props.isSelected ? "border-black-quin" : "border-transparent"}`
                }
            >
                <div
                    style={{
                        backgroundColor: props.color,
                    }}
                    className={
                        "flex h-6 w-6 min-w-max items-center justify-center rounded-full " +
                        `${props.handleSelectColor ? "cursor-pointer" : "pointer-events-none"}`
                    }
                    onClick={() => !props.isDisabled && props.handleSelectColor && props.handleSelectColor(props.color)}
                >
                    {props.isSelected && (!isBackgroundColorDark(props.color) ? <Check className="h-5 w-5" /> : <CheckWhite className="h-5 w-5" />)}
                </div>
            </div>
        </>
    );
}

export default ColorCircle;

import { useCallback, useEffect } from "react";

import { setSingleTransferAmount } from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface UseSingleTransferPurposeInterface {
    handleAmountChange: (_amount: string) => void;
}

function useSingleTransferAmount(): UseSingleTransferPurposeInterface {
    const dispatch = useDispatch();

    const payAmount = useAppSelector((state) => state.sendMoney.payAmount);

    useEffect(() => {
        if (!payAmount) return;
        dispatch(setSingleTransferAmount(payAmount));
    }, [payAmount]);

    const handleAmountChange = useCallback((_amount: string) => {
        if (!_amount || Number(_amount) === 0) return;
        dispatch(setSingleTransferAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useSingleTransferAmount;

import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardMain from "../../../../../../models/cardMain";
import { IRootState } from "../../../../../../redux/rootReducer";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
import useCards from "../../../Hooks/State/useCards";
import { useSelector } from "react-redux";

interface ISetPaymentChannel {
    cardData: CardMain;
    isSelectedCardDetailsOpen: boolean;
    handleBack: () => void;
}

function SetSecurity({ handleBack }: ISetPaymentChannel): JSX.Element {
    const { isCardShowCvvLoading, handleCardShowCVV } = useCards();

    const showCVV = useSelector((state: IRootState) => state.cards.showCVV);

    const divRef = useRef<HTMLDivElement | null>(null);

    const [showCVVState, setShowCVVState] = useState(false);

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    useEffect(() => {
        setShowCVVState(showCVV);
    }, [showCVV]);

    const handleRequest = useCallback(async () => {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await handleCardShowCVV({ showCvv: showCVVState });
        handleBack();
    }, [showCVVState, showCVV, handleBack]);

    return (
        <div className="mt-6 w-full space-y-8" data-type="card-active" ref={divRef}>
            <div className="flex flex-col space-y-2">
                <h3 className="text-base font-medium leading-none text-black">Security</h3>
                <p className="text-sm text-black-tertiary">Manage your card&apos;s security</p>
            </div>
            <div className="flex w-full flex-col space-y-12">
                <div className="flex w-full items-center justify-between" data-type="card-active">
                    <div className="flex flex-col space-y-2" data-type="card-active">
                        <p className="text-sm font-medium leading-[100%] text-black" data-type="card-active">
                            Card Verification Value (CVV)
                        </p>
                        <p className="text-xs font-normal leading-[100%] text-black-tertiary" data-type="card-active">
                            Show card CVV on card details
                        </p>
                    </div>
                    <ToggleSwitch isActive={showCVVState} onToggle={() => setShowCVVState((prev) => !prev)} />
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4 pt-4" data-type="card-active">
                    <ButtonComp size="lg" buttonType="secondary" color="grey" onClick={handleBack} dataType="card-active" fullWidth>
                        Cancel
                    </ButtonComp>
                    <ButtonComp
                        size="lg"
                        color="black"
                        dataType="card-active"
                        buttonType="primary"
                        disable={showCVV === showCVVState}
                        isLoading={isCardShowCvvLoading}
                        onClick={handleRequest}
                        fullWidth
                    >
                        Save Changes
                    </ButtonComp>
                </div>
            </div>
        </div>
    );
}

export default SetSecurity;

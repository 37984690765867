import { useCallback, useEffect } from "react";

import { setZambiaSingleTransferAmount } from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface UseSingleTransferPurposeInterface {
    handleAmountChange: (_amount: string) => void;
}

function useSingleTransferAmount(): UseSingleTransferPurposeInterface {
    const dispatch = useDispatch();

    const payAmount = useAppSelector((state) => state.sendMoney.payAmount);

    useEffect(() => {
        if (!payAmount) return;
        dispatch(setZambiaSingleTransferAmount(payAmount));
    }, [payAmount]);

    const handleAmountChange = useCallback((_amount: string) => {
        dispatch(setZambiaSingleTransferAmount(!_amount || Number(_amount) === 0 ? "" : _amount));
    }, []);

    return { handleAmountChange };
}

export default useSingleTransferAmount;

import React, { ReactNode } from "react";

import { DataColumn } from "../Type/DataRow";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDate";
import { formatTime } from "../../../utils/formatDate";
import titleCase from "../../../hooks/titleCase";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function Text({ dataColumn, dataType }: Props) {
    return (
        <>
            <div className={"w-max max-w-[99%] truncate " + `${dataColumn.bgGray ? "rounded-full bg-grey-backdrop px-2 py-1" : ""}`}>
                {dataColumn.textType === TextType.ERROR ? (
                    <p className="text-error" data-type={dataType}>
                        {typeof dataColumn.text === "string" ? dataColumn.text : ""}
                    </p>
                ) : (
                    <p data-type={dataType} className="truncate text-sm font-normal leading-none text-black-secondary">
                        {dataColumn.text
                            ? dataColumn.textType === TextType.DATE
                                ? formatDate(dataColumn.text as Date)
                                : dataColumn.textType === TextType.TIME
                                  ? formatTime(dataColumn.text as Date)
                                  : dataColumn.textType === TextType.DATE_TIME
                                    ? formatDateAndTime(dataColumn.text as Date)
                                    : typeof dataColumn.text === "string"
                                      ? titleCase(dataColumn.text || "")
                                      : (dataColumn.text as string | ReactNode)
                            : "-"}
                    </p>
                )}
            </div>
        </>
    );
}

import Currency from "./currency";
import { GenericObject } from "../helpers/types";
import Parsers from "../utils/parsers";
import { immerable } from "immer";
import titleCase from "../hooks/titleCase";

const startStrings = ["lenco/", "lenco /"];
const endStrings = ["-lco", "- lco"];
export default class BankAccount {
    [immerable] = true;

    constructor(
        public id: string,
        public bankCode: string,
        public bankName: string | null,
        public accountNumber: string,
        public accountName: string,
        public currency: Currency | null,
        public isVirtual: boolean
    ) {}

    static create(obj: GenericObject): BankAccount {
        return new BankAccount(
            Parsers.string(obj.id),
            Parsers.string(obj.bankCode),
            Parsers.nullableString(obj.bankName),
            Parsers.string(obj.accountNumber),
            Parsers.string(obj.accountName),
            Parsers.classObject(obj.currency, Currency),
            Parsers.boolean(obj.isVirtual)
        );
    }

    get isLenco(): boolean {
        return this.bankCode === "lenco-till";
    }

    get formattedLencoAccountName(): string {
        const accName = this.accountName.toLocaleLowerCase();
        let formattedLencoName: string = accName;
        let formattedLencoNameStrings: string[] = [""];
        const startString = startStrings.find((_) => accName.includes(_));
        const endString = endStrings.find((_) => accName.includes(_));

        if (startString) {
            formattedLencoNameStrings = formattedLencoName.split(startString);
            formattedLencoName = (formattedLencoNameStrings[formattedLencoNameStrings.length - 1] || "").trim();
        }
        if (endString) {
            formattedLencoNameStrings = formattedLencoName.split(endString || "");
            formattedLencoName = (formattedLencoNameStrings[formattedLencoNameStrings.length - 2] || "").trim();
        }

        return titleCase(formattedLencoName);
    }
}

// "hhhh bbb- ggg-lco"

//   const formattedName = this.accountName.toLocaleLowerCase().includes("/")
//       ? this.accountName.toLocaleLowerCase().split("/")
//       : this.accountName.toLocaleLowerCase().includes("-")
//         ? this.accountName.toLocaleLowerCase().split("-")
//         : ["", this.accountName];

//   return titleCase(formattedName[formattedName.length - 1].trim() || "");

import { SearchPageItem, listOfPages, zambiaListOfPages } from "../../../../../helpers/Data/SearchBar/listOfPages";
import { useCallback, useEffect, useRef, useState } from "react";

import { AccountsSearchCard } from "./componentss/AccountsSearch";
import Card from "../../../../../models/card";
import { CardsSearchCard } from "./componentss/CardsSearch";
import CustomerAccount from "../../../../../models/customerAccount";
import { Link } from "react-router-dom";
import PagesSearch from "./componentss/PagesSearch";
import SearchBar from "../../../../search-bar";
import Transaction from "../../../../../models/transaction";
import TransactionsSearch from "./componentss/TransactionsSearch";
import { mapToArray } from "../../../../../utils/map";
import { showTransactionDetails } from "../../../../../redux/transaction/slice/transactionSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { useDispatch } from "react-redux";

function DashboardSearchBar(): JSX.Element {
    const dispatch = useDispatch();

    const main = useAppSelector((state) => state.init.main);
    const isZambia = useAppSelector((state) => state.init.main?.companyDetails?.company.isZambia);
    const isNigeria = useAppSelector((state) => state.init.main?.companyDetails?.company.isNigeria);
    const cachedCards = useAppSelector((state) => state.init.main?.companyDetails.cards || []);
    const customerList = useAppSelector((state) => state.customerAccount.customerAccounts);
    const TransactionList = useAppSelector((state) => state.transaction.transactions);

    const [active, setActive] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredCards, setFilteredCards] = useState<Card[]>([]);
    const [filteredPages, setFilteredPages] = useState<SearchPageItem[]>([]);
    const [hasSearchTerm, setHasSearchTerm] = useState<boolean>(false);
    const [cachedAccounts, setCachedAccounts] = useState<CustomerAccount[]>([]);
    const [filteredAccounts, setFilteredAccounts] = useState<CustomerAccount[]>([]);
    const [cachedTransactions, setCachedTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const domNode = useClickOutside(() => {
        setActive(false), (eventTarget: HTMLElement) => eventTarget.dataset.type !== "transaction";
    });

    useEffect(() => {
        if (!customerList) return;
        setCachedAccounts(mapToArray(customerList));
    }, [customerList]);

    useEffect(() => {
        if (!TransactionList) return;
        setCachedTransactions(mapToArray(TransactionList));
    }, [TransactionList]);

    useEffect(() => {
        // setSearchTerm(searchTerm.trim());
        setHasSearchTerm(searchTerm.trim().length > 0);
    }, [searchTerm]);

    useEffect(() => {
        if (main) {
            const uniqueTransactions = new Map<string, Transaction>();

            cachedTransactions
                .filter((item) => {
                    const searchQuery = searchTerm.trim().toLowerCase();
                    if (!searchQuery) {
                        return false;
                    }

                    return (
                        (!!item.userAccount && item.userAccount.accountName.toLowerCase().includes(searchQuery)) ||
                        (!!item.otherAccount && item.otherAccount.accountName.toLowerCase().includes(searchQuery))
                    );
                })
                .forEach((item) => {
                    uniqueTransactions.set(item.id, item);
                });

            setFilteredTransactions(Array.from(uniqueTransactions.values()));
        }
    }, [main, cachedTransactions, searchTerm]);

    useEffect(() => {
        if (main) {
            const uniqueAccounts = new Map<string, CustomerAccount>();
            cachedAccounts
                .filter((item) => {
                    const searchQuery = searchTerm.trim().toLowerCase();
                    if (!searchQuery) {
                        return false;
                    }

                    return item.accountName.toLowerCase().includes(searchQuery) || item.accountNumber.toLowerCase().includes(searchQuery);
                })
                .forEach((item) => {
                    uniqueAccounts.set(item.id, item);
                });
            setFilteredAccounts(Array.from(uniqueAccounts.values()));
        }
    }, [main, cachedAccounts, searchTerm]);

    useEffect(() => {
        if (main) {
            setFilteredCards(
                cachedCards.filter((item) => {
                    const searchQuery = searchTerm.trim().toLowerCase();
                    if (!searchQuery) {
                        return false;
                    }
                    return item.name.toLowerCase().includes(searchTerm) || item.maskedPan.toLowerCase().includes(searchTerm);
                })
            );
        }
    }, [main, cachedCards, searchTerm]);

    useEffect(() => {
        setFilteredPages(
            (isZambia ? zambiaListOfPages : listOfPages).filter((item) => {
                const searchQuery = searchTerm.trim().toLowerCase();
                if (!searchQuery) {
                    return false;
                }
                return item.name.toLowerCase().includes(searchTerm) || item.url.toLowerCase().includes(searchTerm);
            })
        );
    }, [isZambia, searchTerm]);

    const handleShowTransactionDetail = useCallback(
        (t: Transaction) => {
            dispatch(showTransactionDetails(t.id));
        },
        [dispatch]
    );

    return (
        <>
            <div
                ref={domNode}
                className="relative hidden w-full lg:block"
                onClick={() => {
                    setActive(true);
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
                onFocus={() => {
                    setActive(true);
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
            >
                <SearchBar
                    value={searchTerm}
                    onChange={(_value) => setSearchTerm(_value)}
                    placeholder="Search for accounts, transactions, and pages"
                    size="xl"
                    fullWidth
                />

                <div
                    className={
                        `absolute left-0 top-full z-40 mt-2 max-h-120 min-h-full w-full min-w-[540px] overflow-auto rounded-lg bg-white py-4 shadow-search xl:min-w-[unset] ` +
                        `${!active || !hasSearchTerm ? "hidden" : ""}`
                    }
                    tabIndex={-1}
                >
                    {/* //Accounts */}
                    <div className="flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5">
                        <p className="text-sm font-medium text-black-secondary" data-type="transaction">
                            Recipients
                        </p>
                        <div>
                            <p className="cursor-pointer text-xs font-medium text-blue">
                                <Link
                                    to={`/payments/recipients/${searchTerm}`}
                                    onClick={() => {
                                        inputRef.current?.blur();
                                        setSearchTerm("");
                                    }}
                                    tabIndex={active ? 0 : -1}
                                    data-type="transaction"
                                >
                                    Search Recipients
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="accounts-search border-b border-grey-tertiary pb-2.5">
                        {active &&
                            filteredAccounts.slice(0, 5).map((el, index) => (
                                <AccountsSearchCard
                                    key={`search-account-${index}`}
                                    account={el}
                                    onClick={() => {
                                        setActive(false);
                                        setSearchTerm("");
                                        inputRef.current?.blur();
                                    }}
                                    data-type="transaction"
                                />
                            ))}
                    </div>
                    {/* //Cards*/}
                    {isNigeria && (
                        <>
                            <div
                                className="mt-3 flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5"
                                tabIndex={-1}
                                data-type="transaction"
                            >
                                <p className="text-sm font-medium text-black-secondary" data-type="transaction">
                                    Cards
                                </p>
                            </div>
                            <div className="accounts-search border-b border-grey-tertiary pb-2.5" data-type="transaction">
                                {active &&
                                    filteredCards.slice(0, 5).map((el, index) => (
                                        <CardsSearchCard
                                            key={`search-account-${index}`}
                                            card={el}
                                            index={index}
                                            onClick={() => {
                                                setActive(false);

                                                inputRef.current?.blur();
                                                setSearchTerm("");
                                            }}
                                            data-type="transaction"
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                    {/* //Transactions */}
                    <div className="mt-3 flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5">
                        <p className="text-sm font-medium text-black-secondary" data-type="transaction">
                            Transactions
                        </p>
                        <div>
                            <p className="cursor-pointer text-xs font-medium text-blue">
                                <Link
                                    to={`/transactions/search/${searchTerm}`}
                                    onClick={() => {
                                        setActive(false);

                                        inputRef.current?.blur();
                                        setSearchTerm("");
                                    }}
                                    tabIndex={active ? 0 : -1}
                                    data-type="transaction"
                                >
                                    Search Transactions
                                </Link>
                            </p>
                        </div>
                    </div>

                    <div className="accounts-search border-b border-grey-tertiary pb-2.5" data-type="transaction">
                        {active &&
                            filteredTransactions.slice(0, 5).map((el, index) => (
                                <div
                                    onClick={() => {
                                        setActive(false);

                                        inputRef.current?.blur();
                                        setSearchTerm("");
                                    }}
                                    key={`search-transaction-${index}`}
                                    tabIndex={-1}
                                    data-type="transaction"
                                >
                                    <TransactionsSearch
                                        data-type="transaction"
                                        transaction={el}
                                        index={index}
                                        handleShowTransactionDetail={handleShowTransactionDetail}
                                    />
                                </div>
                            ))}
                    </div>

                    {/* //Pages */}
                    <div>
                        <div
                            className="mt-3 flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5"
                            tabIndex={-1}
                            data-type="transaction"
                        >
                            <p className="text-sm font-medium text-black-secondary" data-type="transaction">
                                Pages
                            </p>
                        </div>
                        {active &&
                            filteredPages.slice(0, 5).map((el, index) => (
                                <PagesSearch
                                    key={`search-page-${index}`}
                                    pagesData={el}
                                    index={index}
                                    onClick={() => {
                                        setActive(false);

                                        inputRef.current?.blur();
                                        setSearchTerm("");
                                    }}
                                    data-type="transaction"
                                />
                            ))}
                    </div>

                    <div className="mt-4 px-4 py-2">
                        <p className="cursor-pointer text-xs font-medium text-blue">
                            <Link
                                to={`/transactions/search/${searchTerm}`}
                                onClick={() => {
                                    setActive(false);

                                    inputRef.current?.blur();
                                    setSearchTerm("");
                                }}
                                tabIndex={active ? 0 : -1}
                                data-type="transaction"
                            >
                                View all matches for the term &quot;{searchTerm}
                                &quot; in transactions
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardSearchBar;

import { DataColumn } from "../Type/DataRow";
import titleCase from "../../../hooks/titleCase";

interface Props {
    dataType?: string;
    dataColumn: DataColumn;
}

export default function ImageAndText({ dataColumn, dataType }: Props) {
    return (
        <>
            {dataColumn.img && (
                <div className="flex items-center justify-start space-x-2" data-type={dataType}>
                    <div
                        className="flex h-8 w-8 min-w-[32px] max-w-[32px] items-center justify-center overflow-hidden rounded-md"
                        data-type={dataType}
                    >
                        <img src={dataColumn.img} className="w-full" alt="row image" data-type={dataType} />
                    </div>
                    <p className="w-full truncate text-left font-medium capitalize text-black" data-type={dataType}>
                        {typeof dataColumn.text === "string" ? titleCase(dataColumn.text || "") : ""}
                    </p>
                </div>
            )}
        </>
    );
}

import CardDetails, { CARD_DETAILS_DATATYPE } from "../Components/Cards/CardDetails";
import { CardStatus, CardWalletType, CreateCardRequestBankingAppStatus } from "../../../../models/card.constants";
import {
    closeSelectedCardDetails,
    openSelectedCardDetails,
    resetCards,
    setIsCardAccessModalOpen,
    setIsCardUsageModalOpen,
    setIsCardsFiltering,
    setSelectedCardDetails,
} from "../../../../redux/cards/slice/cardsSlice";
import { useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ActivateCardIcon } from "../../../../assets/svg/Cards/ActivateCard.svg";
import { ReactComponent as BlueCirclePlus } from "../../../../assets/svg/Button/blue-circled-plus.svg";
import ButtonComp from "../../../../components/button/ButtonComp";
import { ReactComponent as CancelCardIcon } from "../../../../assets/svg/Cards/Cancel.svg";
import CardAccessModal from "../Components/Modals/CardAccessModal";
import CardUsageModal from "../Components/Modals/CardUsageModal";
import CreateCardSection from "../Components/Element/CreateCardSection";
import DeactivateCardModal from "../Components/Modals/DeactivateCardModal";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import { ReactComponent as FreezeIcon } from "../../../../assets/svg/Cards/Freeze.svg";
import { IRootState } from "../../../../redux/rootReducer";
import LencoSpinner from "../../../../components/spinner";
import PageLayout from "../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../helpers/AppConstants";
import { ReactComponent as ReassignCardIcon } from "../../../../assets/svg/Cards/ReassignCard.svg";
import RemoveCreateCardModal from "../Components/Modals/RemoveManageCardAccessModal";
import { ReactComponent as RequestDefaultIcon } from "../../../../assets/svg/Cards/request-default-pin.svg";
import { ReactComponent as SecurityIcon } from "../../../../assets/svg/Cards/SecurityIcon.svg";
import { ReactComponent as SendMoneyIcon } from "../../../../assets/svg/Cards/SendMoney.svg";
import { ReactComponent as SetSpendingLimitIcon } from "../../../../assets/svg/Cards/SetSpendingLimit.svg";
import { ReactComponent as ShadowedProfile } from "../../../../assets/svg/Button/shadowed-profile.svg";
import { SubTextType } from "../../../../components/Table/Type/SubTextType";
import Table from "../../../../components/Table";
import { TableColumnType } from "../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../components/Table/Type/TextType";
import { setGlobalActiveDataType } from "../../../../redux/init/slice/initSlice";
import useCardTray from "../Hooks/State/useCardTray";
import useCards from "../Hooks/State/useCards";

const allCardsHeader: TableHead = [
    { text: "Card Name", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Assigned To", pos: 2, headType: TableColumnType.TEXT },
    { text: "Spend Limit", pos: 3, headType: TableColumnType.PROGRESS },
    { text: "Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
    { text: "Available Balance", pos: 4, headType: TableColumnType.AMOUNT },
    { text: "Action", pos: 4, headType: TableColumnType.ACTION },
];

function Cards(): JSX.Element {
    document.title = PageTitle.CARDS_PAGE;

    const {
        startInit,
        cardsTotal,
        cardsOffset,
        filterOption,
        cardInitError,
        cardsGroupSize,
        isCardInitLoading,
        isCardsListLoading,
        selectedFilterCount,
        isFilteredCardsListLoading,
        handleCardInit,
        handleCardsList,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
    } = useCards();

    const {
        onOpenActivateCardSection,
        onOpenReassignCardCardSection,
        onOpenSetPaymentChannelsCardSection,
        onOpenSpendingLimitCardSection,
        onOpenCancelCardSection,
        onSendMoney,
        onFreezeCard,
        onUnfreezeCard,
        onOpenRequestDefaultPINSection,
        onOpenSecurityCardSection,
    } = useCardTray();

    const currentUserId = useSelector((state: IRootState) => state.init.main?.companyDetails.user.id);

    const { onOpenDefaultCardSection } = useCardTray();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const isCardsFiltering = useSelector((state: IRootState) => state.cards.isCardsFiltering);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails?.accounts);
    const user = useSelector((state: IRootState) => state.init.main?.companyDetails.user);
    const canAccessCards = useSelector((state: IRootState) => state.init.canAccessCards);
    const newCardsList = useSelector((state: IRootState) => state.cards.newCards);
    const selectedCard = useSelector((state: IRootState) => state.cards.selectedCardDetails);
    const isCardUsageModalOpen = useSelector((state: IRootState) => state.cards.isCardUsageModalOpen);
    const isCardAccessModalOpen = useSelector((state: IRootState) => state.cards.isCardAccessModalOpen);
    const isSelectedCardDetailsOpen = useSelector((state: IRootState) => state.cards.isSelectedCardDetailsOpen);
    const teamMembersWithManageAccess = useSelector((state: IRootState) => state.cards.teamMembersWithManageAccess);

    const handleCloseCardAccessModal = useCallback(() => {
        dispatch(setIsCardAccessModalOpen(false));
    }, []);

    const handleResetSelectedCard = useCallback(() => {
        dispatch(closeSelectedCardDetails());
    }, []);

    const handleSearch = useCallback(async (_value: string) => {
        await handleCardsList({ query: _value }, true);
    }, []);

    useLayoutEffect(() => {
        if (!canAccessCards) {
            return navigate("/");
        }
        handleCardInit({ offset: 0, assignedToIds: [], cardStatuses: [], query: "" });
        return () => {
            dispatch(setIsCardsFiltering(false));
            dispatch(resetCards());
        };
    }, [canAccessCards]);

    useLayoutEffect(() => {
        if (!isCardInitLoading && location.state?.createCardInstant && teamMembersWithManageAccess.length > 0) {
            dispatch(setIsCardUsageModalOpen(true));
            return navigate("/cards");
        }
    }, [isCardInitLoading, location, teamMembersWithManageAccess]);

    useLayoutEffect(() => {
        if (!location.state?.cardId || newCardsList.length < 1) return;
        const tempSelectedCard = newCardsList.find((_el) => _el.card?.id === location.state.cardId);
        if (tempSelectedCard) {
            onOpenDefaultCardSection();
            dispatch(setSelectedCardDetails(tempSelectedCard.createRequest.id));
            dispatch(openSelectedCardDetails());
            dispatch(setGlobalActiveDataType("card"));
        }
    }, [newCardsList, location]);

    const deviceWidth = window.innerWidth;

    return (
        <>
            <CardAccessModal active={isCardAccessModalOpen} toggler={handleCloseCardAccessModal} />
            <DeactivateCardModal />
            <RemoveCreateCardModal />
            <CardUsageModal active={isCardUsageModalOpen} toggler={() => dispatch(setIsCardUsageModalOpen(false))} />
            <CardDetails isSelectedCardDetailsOpen={isSelectedCardDetailsOpen} selectedCard={selectedCard} handleReset={handleResetSelectedCard} />

            <PageLayout
                pageTitle="Cards"
                subTitle="Expense cards to manage your finance"
                headerButtons={
                    user?.isAdmin ? (
                        <div className="flex w-full flex-col xs:w-max xs:flex-row xs:space-x-4">
                            <div className="flex justify-center space-x-3">
                                {!isCardInitLoading && (
                                    <ButtonComp
                                        color="grey"
                                        func={() => dispatch(setIsCardAccessModalOpen(true))}
                                        type="button"
                                        buttonType="secondary"
                                        size="md"
                                        fullWidth={deviceWidth < 540 ? true : false}
                                    >
                                        <div className="flex items-center justify-center space-x-1">
                                            <ShadowedProfile />
                                            <span>Card Access</span>
                                        </div>
                                    </ButtonComp>
                                )}
                                {!isCardInitLoading && user?.hasManageTerminalAccess && newCardsList?.length > 0 && (
                                    <ButtonComp
                                        color="grey"
                                        type="button"
                                        buttonType="secondary"
                                        size="md"
                                        fullWidth={deviceWidth < 540 ? true : false}
                                        func={() => dispatch(setIsCardUsageModalOpen(true))}
                                    >
                                        <div className="flex items-center justify-center space-x-1">
                                            <BlueCirclePlus /> <span>Create New Card </span>
                                        </div>
                                    </ButtonComp>
                                )}
                            </div>
                        </div>
                    ) : null
                }
            >
                {cardInitError && !isCardInitLoading && (
                    <div className="flex h-full w-full items-center justify-center">
                        <ErrorToast error={cardInitError || ""} isReactNode />
                    </div>
                )}

                {isCardInitLoading && startInit && (
                    <div className="flex h-full w-full items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}

                {/* <CardsFilterSection handleClearAll={handleClearAll} handleFilteredDetails={handleFilteredDetails} /> */}
                {!cardInitError && !isCardInitLoading && !startInit && (
                    <>
                        {isCardsFiltering || newCardsList.length > 0 ? (
                            <div className="relative h-full w-screen min-w-full max-w-screen-2xs sm:w-max lg:h-auto xs:max-w-screen-xs">
                                <div className="h-full w-full max-w-screen overflow-x-auto lg:h-auto">
                                    <div className="mx-auto h-full w-full lg:h-auto lg:w-fit">
                                        <Table
                                            //filter
                                            filterOptions={filterOption}
                                            handleApplyFilter={handleApplyFilter}
                                            handleClearFilter={handleClearFilter}
                                            totalSelectedItems={selectedFilterCount}
                                            //pagination
                                            total={cardsTotal}
                                            offset={cardsOffset}
                                            groupSize={cardsGroupSize}
                                            paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                                            isPaginateLoading={isFilteredCardsListLoading}
                                            //table
                                            addSearch
                                            heads={allCardsHeader}
                                            dataType={CARD_DETAILS_DATATYPE}
                                            isLoading={!!isFilteredCardsListLoading || !!isCardsListLoading}
                                            handleSearch={handleSearch}
                                            rows={newCardsList?.map((obj) => ({
                                                onRecordClick: () => {
                                                    onOpenDefaultCardSection();
                                                    dispatch(setSelectedCardDetails(obj?.createRequest?.id));
                                                    dispatch(openSelectedCardDetails());
                                                    dispatch(setGlobalActiveDataType("card"));
                                                },
                                                record: [
                                                    {
                                                        key: "Card Name",
                                                        text: obj?.card?.name || obj?.createRequest.requestedNameOnCard || "-",
                                                        subText: obj?.card?.maskedPan || "",
                                                        subTextType: SubTextType.TRUNCATED_TEXT,
                                                    },
                                                    {
                                                        key: "Assigned To",
                                                        bgGray: true,
                                                        text:
                                                            obj.card?.assignedToCorporateAccountMember?.fullName ||
                                                            obj.createRequest.assignedTo?.fullName ||
                                                            "-",
                                                    },
                                                    {
                                                        key: "Spend Limit",
                                                        text: obj?.cardSpend?.spent?.toString(),
                                                        textType: obj?.cardSpend?.textType,
                                                        spendingLimit: Number(obj?.cardSpend?.limit) || 0,
                                                    },
                                                    {
                                                        key: "Type",
                                                        text: obj?.card?.cardType || obj?.createRequest.cardRequestType || "-",
                                                    },
                                                    {
                                                        key: "Status",
                                                        text: obj.cardStatusText,
                                                        textType: obj?.cardStatusTableTextType,
                                                    },
                                                    {
                                                        key: "Available Balance",
                                                        text: obj?.balance || "-",
                                                        textType: TextType.BALANCE,
                                                    },
                                                    {
                                                        key: "Action",
                                                        text: "-",
                                                        textType: TextType.MEAT_BALL_MENU,
                                                        isDisabled: !(
                                                            obj.createRequest.status === CreateCardRequestBankingAppStatus.DELIVERED ||
                                                            obj.createRequest.status === CreateCardRequestBankingAppStatus.APPROVED
                                                        ),
                                                        dropDownOptions: [
                                                            {
                                                                icon: <FreezeIcon className="stroke-current" />,
                                                                text: "Unfreeze Card",
                                                                func: () => onUnfreezeCard(obj?.createRequest.id),
                                                                show: obj?.card?.status === CardStatus.FROZEN,
                                                            },
                                                            {
                                                                icon: <ActivateCardIcon className="stroke-current" />,
                                                                text: "Activate Card",
                                                                func: () => onOpenActivateCardSection(obj?.createRequest.id),
                                                                show: obj?.card?.status === CardStatus.UNACTIVATED,
                                                            },
                                                            {
                                                                icon: <SendMoneyIcon className="stroke-current" />,
                                                                text: "Send Money",
                                                                func: () => onSendMoney(obj?.createRequest.id, obj?.card?.id || ""),
                                                                show: !!(
                                                                    obj?.card &&
                                                                    !obj?.card.linkedAccount &&
                                                                    obj?.card.walletType === CardWalletType.INDEPENDENT_WALLET &&
                                                                    obj?.card.isActive &&
                                                                    accounts &&
                                                                    accounts?.length < 2
                                                                ),
                                                            },
                                                            {
                                                                icon: <SetSpendingLimitIcon className="stroke-current" />,
                                                                text: "Set Spending Limit",
                                                                func: () => onOpenSpendingLimitCardSection(obj?.createRequest.id),
                                                                show: !!(
                                                                    obj.card &&
                                                                    (obj.card.status === CardStatus.ACTIVE ||
                                                                        obj.card.status === CardStatus.UNACTIVATED ||
                                                                        obj.card.status === CardStatus.DEACTIVATED ||
                                                                        obj.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                            {
                                                                icon: <ReassignCardIcon className="stroke-current" />,
                                                                text: "Reassign Card",
                                                                func: () => onOpenReassignCardCardSection(obj?.createRequest.id),
                                                                show: !!(
                                                                    obj?.card?.assignedToCorporateAccountMember?.id === currentUserId &&
                                                                    obj.card &&
                                                                    (obj.card.status === CardStatus.ACTIVE ||
                                                                        obj.card.status === CardStatus.UNACTIVATED ||
                                                                        obj.card.status === CardStatus.DEACTIVATED)
                                                                ),
                                                            },
                                                            {
                                                                icon: <FreezeIcon className="stroke-current" />,
                                                                text: "Freeze Card",
                                                                func: () => onFreezeCard(obj?.createRequest.id),
                                                                show: !!(
                                                                    obj?.card &&
                                                                    (obj?.card.status === CardStatus.ACTIVE ||
                                                                        obj?.card.status === CardStatus.DEACTIVATED ||
                                                                        obj?.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                            {
                                                                icon: <ActivateCardIcon className="stroke-current" />,
                                                                text: "Set Payment Channels",
                                                                func: () => onOpenSetPaymentChannelsCardSection(obj?.createRequest.id),
                                                                show: !!(
                                                                    obj?.card &&
                                                                    (obj?.card.status === CardStatus.ACTIVE ||
                                                                        obj?.card.status === CardStatus.UNACTIVATED ||
                                                                        obj?.card.status === CardStatus.DEACTIVATED ||
                                                                        obj?.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                            {
                                                                icon: <RequestDefaultIcon className="stroke-current" />,
                                                                text: "Request Default PIN",
                                                                func: () => onOpenRequestDefaultPINSection(),
                                                                show: !!(
                                                                    obj?.card &&
                                                                    !obj?.card.hasDoneExternalTransaction &&
                                                                    (obj?.card.status === CardStatus.ACTIVE ||
                                                                        obj?.card.status === CardStatus.UNACTIVATED ||
                                                                        obj?.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                            {
                                                                icon: <SecurityIcon className="stroke-current" />,
                                                                text: "Security",
                                                                func: () => onOpenSecurityCardSection(obj?.createRequest.id),
                                                                show: !!(
                                                                    obj?.card &&
                                                                    (obj?.card.status === CardStatus.ACTIVE || obj?.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                            {
                                                                icon: <CancelCardIcon className="stroke-current" />,
                                                                text: "Cancel Card",
                                                                func: () => onOpenCancelCardSection(obj?.createRequest.id),
                                                                isDanger: true,
                                                                show: !!(
                                                                    obj?.card &&
                                                                    (obj?.card.status === CardStatus.ACTIVE ||
                                                                        obj?.card.status === CardStatus.UNACTIVATED ||
                                                                        obj?.card.status === CardStatus.FROZEN)
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {newCardsList.length < 1 && !isCardsFiltering ? <CreateCardSection /> : null}
                    </>
                )}
            </PageLayout>
        </>
    );
}

export default Cards;

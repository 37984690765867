import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import AvatarDetails from "../../../../../../components/avatar-details";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import DefaultSetup from "../../../../../../models/zambia/lencoPay/defaultSetup";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import GoogleIcon from "../../../../../../components/google-icon";
import { IRootState } from "../../../../../../redux/rootReducer";
import MultiSelectDropdown from "../../../../../../components/new-multi-select";
import { updateOtherAccountsAccessIdsDefaultSetupConfiguration } from "../../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { useAppSelector } from "../../../../../../redux/hooks";

const DATA_TYPE = "api-access-key-other-accounts";

interface Props {
    active: boolean;
    settlementAccountId: string;
    toggler: () => void;
}

function DefaultSetupOtherAccountsTray(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const zambiaLencoPayDefaultSetup = useAppSelector((state) => state.zambiaDefaultSetup.defaultSetup || []) as DefaultSetup;

    const accounts = useSelector(
        (state: IRootState) =>
            state.init.main?.companyDetails.accounts
                .filter((_account) => _account.id !== props.settlementAccountId)
                .map((_account) => ({
                    text: _account.lencoNameMin,
                    value: _account.id,
                })) || []
    );

    const formikRef = useRef<FormikProps<{ otherAccountsAccessIds: string[] }> | null>(null);

    const INITIAL_FORM_STATE = {
        otherAccountsAccessIds: zambiaLencoPayDefaultSetup?.otherAccountsAccess?.map((_) => _.id) || [],
    };

    const FORM_VALIDATION = Yup.object().shape({
        otherAccountsAccessIds: Yup.array(Yup.string().min(1).required()).min(1).required(),
    });

    return (
        <>
            <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
                <FullPageTrayHeader subTitle="Select other accounts the API can access" toggler={props.toggler} dataType={DATA_TYPE}>
                    Other Accounts
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <Formik
                        initialValues={INITIAL_FORM_STATE}
                        validationSchema={FORM_VALIDATION}
                        innerRef={formikRef}
                        onSubmit={(values) => {
                            if (values.otherAccountsAccessIds) {
                                dispatch(updateOtherAccountsAccessIdsDefaultSetupConfiguration(values?.otherAccountsAccessIds || []));
                                props.toggler();
                            }
                        }}
                        enableReinitialize
                        validateOnChange
                        validateOnMount
                    >
                        {(formik) => {
                            return (
                                <Form className="relative flex h-full w-full flex-col items-center justify-start space-y-10">
                                    <div className="w-full space-y-6">
                                        <div className="flex w-full items-center">
                                            <MultiSelectDropdown
                                                placeholder="Select Accounts"
                                                value={formik.values.otherAccountsAccessIds}
                                                options={accounts}
                                                onChange={(_value) => formik.getFieldHelpers("otherAccountsAccessIds").setValue(_value)}
                                                // active={active}
                                            />
                                        </div>
                                        {formik.values.otherAccountsAccessIds.map((_account, _index) => {
                                            const acc = (accounts || []).find((_acc) => _acc.value === _account);

                                            return (
                                                <div key={_index} className="flex w-full items-center justify-between">
                                                    <div className="flex items-center justify-start space-x-0.5">
                                                        <AvatarDetails dataType={""} title={acc?.text} size="xs" />
                                                        <p className="text-sm font-medium text-black">{acc?.text}</p>
                                                    </div>
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            formik
                                                                .getFieldHelpers("otherAccountsAccessIds")
                                                                .setValue(
                                                                    formik.values.otherAccountsAccessIds.filter(
                                                                        (_otherAccount) => _otherAccount !== _account
                                                                    )
                                                                );
                                                        }}
                                                    >
                                                        <GoogleIcon icon="delete" size={"xs"} />
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="mt-12 flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-10">
                                        <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                                        </ButtonComp>
                                        <ButtonComp fullWidth type="submit" size="lg" buttonType="primary" color={"black"} disable={!formik.isValid}>
                                            <span className="w-full text-center">Save Changes</span>
                                        </ButtonComp>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default DefaultSetupOtherAccountsTray;

import {
    removeTransferDetailToTransferList,
    setCanApprove,
    setIsReviewingTransferDetail,
    updateTransferDetailToTransferList,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import { useEffect, useLayoutEffect, useState } from "react";

import AccountNumberInput from "../../../../../../../components/account-number-input";
import AccountNumberSelect from "../../../selects/account-number-select";
import BankSelect from "../../../selects/bank-select";
import ButtonComp from "../../../../../../../components/button/ButtonComp";
import CustomerAccount from "../../../../../../../models/customerAccount";
import Fee from "../../../../../../../components/fees/fees";
import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import GoogleIcon from "../../../../../../../components/google-icon";
import Input from "../../../../../../../components/inputs/Input";
import MessageToast from "../../../../../../../components/message-toast";
import MoneyInput from "../../../../../../../components/inputs/money-input";
import NewUserAccountDropdown from "../../../../../../../components/user-account-dropdown";
import SendMoneyErrorCard from "../../../Cards/send-money-error-card";
import UserAccount from "../../../../../../../models/userAccount";
import VerifiedAccountCard from "../../../Cards/verified-account-card";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import titleCase from "../../../../../../../hooks/titleCase";
import { useAppSelector } from "../../../../../../../redux/hooks";
import useBulkTransferAccountNumberAutocomplete from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferAccountNumberAutocomplete";
import useBulkTransferAmount from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferAmount";
import useBulkTransferPayFrom from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferPayFrom";
import useBulkTransferPurpose from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferPurpose";
import useBulkTransferRecipientBankCode from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferRecipientBankCode";
import useBulkTransferVerifyRecipientAccountDetails from "../../../../hooks/state/send-money/BulkTransfer/useBulkTransferVerifyRecipientAccountDetails";
import { useDispatch } from "react-redux";
import { useMoneyToNumber } from "../../../../../../../hooks/useMoneyToNumber";

interface Props {
    active: boolean;
    toggler: () => void;
}

function EditPaymentTray(props: Props) {
    const dispatch = useDispatch();

    const { handleAmountChange } = useBulkTransferAmount();
    const { handlePurposeChange } = useBulkTransferPurpose();
    const { handleSelectBank } = useBulkTransferRecipientBankCode();

    const { accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount, handleResetUserAccountMeta } = useBulkTransferPayFrom();
    const { verifyAccountError, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount } =
        useBulkTransferVerifyRecipientAccountDetails();

    const {
        suggestedRecipients,
        isRecipientAutocompleteLoading,

        // handleSelectRecipient,
        handleAccountNumberChange,
        handleSelectUserAccountRecipient,
        handleSelectCustomerAccountRecipient,
    } = useBulkTransferAccountNumberAutocomplete({ handleSetVerifiedAccount, handleResetVerifiedAccount });

    const tempTransferDetail = useAppSelector((state) => state.bulkTransfer.tempTransferDetail);
    const transferDetailArray = useAppSelector((state) => state.bulkTransfer.transferDetailArray);
    const globalActiveDataType = useAppSelector((state) => state.init.globalActiveDataType);
    const isUpdatingTransferDetail = useAppSelector((state) => state.bulkTransfer.isUpdatingTransferDetail);
    const canVerifyRecipientAccountDetails = useAppSelector((state) => state.bulkTransfer.canVerifyRecipientAccountDetails);

    const [indexOfTransferDetail, setIndexOfTransferDetail] = useState<number | null>(null);

    useEffect(() => {
        return () => {
            handleResetVerifiedAccount();
            handleResetUserAccountMeta();
        };
    }, []);

    useLayoutEffect(() => {
        if (!tempTransferDetail) return;
        const index = transferDetailArray.findIndex((_) => _.key === tempTransferDetail.key);
        setIndexOfTransferDetail(index < 0 ? transferDetailArray.length : index);
    }, [tempTransferDetail, transferDetailArray]);

    useEffect(() => {
        if (tempTransferDetail && currentUserAccountMeta && currentUserAccountMeta.isApprover) {
            if (currentUserAccountMeta.maxApprovalAmount) {
                if (useMoneyToNumber(tempTransferDetail.amount) <= currentUserAccountMeta.maxApprovalAmount) {
                    dispatch(setCanApprove(true));
                    return;
                } else {
                    dispatch(setCanApprove(false));
                    return;
                }
            } else {
                dispatch(setCanApprove(true));
                return;
            }
        } else {
            dispatch(setCanApprove(false));
        }
    }, [tempTransferDetail, currentUserAccountMeta]);

    return (
        <>
            {isUpdatingTransferDetail && tempTransferDetail && (
                <FullPageTray active={props.active} dataType={globalActiveDataType || "transaction"}>
                    <FullPageTrayHeader toggler={props.toggler} dataType={globalActiveDataType || "transaction"}>
                        Bulk Payment
                        <span className="font-normal">{` (${(indexOfTransferDetail || 0) + 1} of ${transferDetailArray.length})`}</span>
                    </FullPageTrayHeader>
                    <FullPageTrayBody dataType={globalActiveDataType || "transaction"}>
                        <div className="flex w-full flex-col space-y-4 pb-6">
                            <div className="flex w-full flex-col space-y-4">
                                <p className="text-sm font-medium text-black-secondary">Select Account</p>
                                <NewUserAccountDropdown
                                    value={selectedAccountId || ""}
                                    options={accounts || null}
                                    onClick={handleSelectAccount}
                                    // isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
                                    dropdownSize="xl"
                                    showBalance
                                    initiatorCanSelect
                                />
                            </div>

                            <div className="flex w-full flex-col space-y-4">
                                <p className="text-sm font-medium text-black-secondary">Payment Details</p>
                                <MoneyInput
                                    label="Amount"
                                    value={tempTransferDetail.amount || ""}
                                    onChange={(_value) => typeof _value === "string" && handleAmountChange(_value)}
                                    fee={
                                        tempTransferDetail.selectedUserAccount?.bankCode !==
                                        tempTransferDetail.recipient?.externalAccount.bankCode ? (
                                            <div className="w-max text-xs text-black-secondary">
                                                Fee: <span className="font-bold">{<Fee value={tempTransferDetail.amount} />}</span>
                                            </div>
                                        ) : undefined
                                    }
                                />
                                <Input placeholder="Purpose" value={tempTransferDetail.purpose} onChange={handlePurposeChange} fullWidth />
                            </div>

                            {currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
                                <SendMoneyErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount || 0} isApprovalError fullWidth />
                            )}
                            {tempTransferDetail.selectedUserAccount &&
                                !isNullOrUndefined(tempTransferDetail?.selectedUserAccount?.balance) &&
                                useMoneyToNumber(tempTransferDetail.amount) > tempTransferDetail?.selectedUserAccount?.balance && (
                                    <SendMoneyErrorCard balance={tempTransferDetail?.selectedUserAccount?.balance} isInsufficientFunds fullWidth />
                                )}
                            {tempTransferDetail.selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
                                <SendMoneyErrorCard canSendMoneyToSpecificAccounts fullWidth />
                            )}

                            {tempTransferDetail.selectedUserAccount?.accountRestrictions.cannotSendMoney ? (
                                <SendMoneyErrorCard canNotSendFromAccount fullWidth />
                            ) : (
                                <div className="flex w-full flex-col space-y-4">
                                    <p className="text-sm font-medium text-black-secondary">Recipient Details</p>
                                    {tempTransferDetail.selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                                        <>
                                            <AccountNumberSelect
                                                onClick={(e) => {
                                                    if (e instanceof UserAccount) {
                                                        handleSelectUserAccountRecipient(e);
                                                    }
                                                    if (e instanceof CustomerAccount) {
                                                        handleSelectCustomerAccountRecipient(e);
                                                    }
                                                }}
                                                options={suggestedRecipients}
                                                value={tempTransferDetail.recipient.externalAccount?.accountNumber}
                                            />
                                            {!verifyAccountError && tempTransferDetail.recipientBankAccount && (
                                                <VerifiedAccountCard value={titleCase(tempTransferDetail.recipientBankAccount.accountName)} />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <AccountNumberInput
                                                data={suggestedRecipients as UserAccount[] | null}
                                                label="Account Number or Name"
                                                onChange={handleAccountNumberChange}
                                                isLoading={isRecipientAutocompleteLoading}
                                                inputValue={tempTransferDetail.recipient.externalAccount?.accountNumber}
                                                onClick={(e) => {
                                                    if (e instanceof UserAccount) {
                                                        handleSelectUserAccountRecipient(e);
                                                    }
                                                    if (e instanceof CustomerAccount) {
                                                        handleSelectCustomerAccountRecipient(e);
                                                    }
                                                }}
                                            />
                                            <BankSelect
                                                bankCode={tempTransferDetail.recipient.externalAccount?.bankCode || ""}
                                                accountNumber={tempTransferDetail.recipient.externalAccount?.accountNumber || ""}
                                                onClick={handleSelectBank}
                                            />
                                            {verifyAccountError && !tempTransferDetail.recipientBankAccount && (
                                                <MessageToast message={verifyAccountError.message} type="error" fullWidth />
                                            )}
                                            {!verifyAccountError && tempTransferDetail.recipientBankAccount && (
                                                <VerifiedAccountCard value={titleCase(tempTransferDetail.recipientBankAccount.accountName)} />
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="!mb-2 !mt-6 h-[1px] min-h-[1px] w-full bg-grey"></div>

                            <div className="flex w-full items-center justify-between space-x-4">
                                <ButtonComp
                                    size="sm"
                                    color="red"
                                    buttonType="tertiary"
                                    paddingSize="2xs"
                                    func={() => {
                                        props.toggler();
                                        dispatch(removeTransferDetailToTransferList(tempTransferDetail.key));
                                        dispatch(messageTrue("Transfer Deleted"));
                                    }}
                                >
                                    <GoogleIcon icon="delete" />
                                    <p className="ml-2">Delete</p>
                                </ButtonComp>
                                <div className="flex w-max items-center justify-center space-x-4">
                                    <ButtonComp
                                        size="lg"
                                        color="grey"
                                        buttonType="secondary"
                                        func={() => {
                                            props.toggler();
                                            dispatch(setIsReviewingTransferDetail());
                                        }}
                                    >
                                        Cancel
                                    </ButtonComp>
                                    <ButtonComp
                                        size="lg"
                                        color="black"
                                        buttonType="primary"
                                        isLoading={canVerifyRecipientAccountDetails && isVerifyAccountLoading}
                                        disable={
                                            !!(
                                                !tempTransferDetail.selectedUserAccount ||
                                                verifyAccountError ||
                                                !tempTransferDetail.recipientBankAccount ||
                                                tempTransferDetail.selectedUserAccount.accountRestrictions.cannotSendMoney ||
                                                !tempTransferDetail.purpose ||
                                                !tempTransferDetail.amount ||
                                                useMoneyToNumber(tempTransferDetail.amount) === 0 ||
                                                useMoneyToNumber(tempTransferDetail.amount) >
                                                    (tempTransferDetail.selectedUserAccount?.balance as number)
                                            )
                                        }
                                        func={() => {
                                            dispatch(updateTransferDetailToTransferList(tempTransferDetail));
                                            dispatch(messageTrue("Transfer Updated"));
                                            dispatch(setIsReviewingTransferDetail());
                                        }}
                                    >
                                        Save
                                    </ButtonComp>
                                </div>
                            </div>
                        </div>
                    </FullPageTrayBody>
                </FullPageTray>
            )}
        </>
    );
}

export default EditPaymentTray;

import { DataColumn } from "../Type/DataRow";
import { ReactComponent as DocumentIcon } from "../svg/grey-curved-document.svg";
import { ReactComponent as EditIcon } from "../svg/edit.svg";
import GoogleIcon from "../../google-icon";
import NewDropdownHead from "../../new-dropdown";
import NewDropdownItem from "../../new-dropdown-item";
import React from "react";
import { ReactComponent as ShareIcon } from "../svg/grey-curved-send.svg";
import { TextType } from "../Type/TextType";
import TooltipIcons from "../../tooltip";
import { ReactComponent as TrashIcon } from "../svg/delete.svg";
import stopEventPropagation from "../utils/stopEventPropagation";

interface Props {
    dataType?: string;
    dataColumn: DataColumn;
}

export default function ColumnAction({ dataColumn, dataType }: Props) {
    const { textType, onEdit, onDelete, onShare, onDocument, dropDownOptions, isDisabled } = dataColumn;
    return (
        <>
            <div
                className={"flex w-full items-center justify-center " + `${isDisabled ? "pointer-events-none" : ""}`}
                onClick={stopEventPropagation}
                data-type={dataType}
            >
                {textType === TextType.STRING && <p data-type={dataType}>{typeof dataColumn.text === "string" ? dataColumn.text : ""}</p>}
                {textType === TextType.EDIT_DELETE && (
                    <span data-type={dataType} className="ml-auto flex w-full cursor-pointer items-center justify-end space-x-4 rounded-full">
                        {onEdit && <EditIcon className="hover:scale-110" onClick={onEdit} />}{" "}
                        {onDelete && <TrashIcon className="hover:scale-110" onClick={onDelete} />}
                    </span>
                )}
                {textType === TextType.SHARE_DOCUMENT && onShare && (
                    <span data-type={dataType} className="ml-auto flex w-full cursor-pointer items-center justify-end space-x-4 rounded-full">
                        {onShare && <ShareIcon className="hover:scale-110" onClick={onShare} />}
                        {onDocument && <DocumentIcon className="hover:scale-110" onClick={onDocument} />}
                    </span>
                )}
                {dataColumn.textType === TextType.TOOLTIP_ICON && dataColumn.onClick && (
                    <TooltipIcons
                        icon={<GoogleIcon icon={dataColumn.icon || ""} />}
                        message={typeof dataColumn.text === "string" ? dataColumn.text : ""}
                        placement="top"
                        dataType={dataType}
                        onClick={dataColumn.onClick}
                    />
                )}
                {textType === TextType.MEAT_BALL_MENU && (
                    <div data-type={dataType} className="w-full">
                        <div data-type={dataType} className="ml-auto flex h-max w-6 cursor-pointer justify-end rounded-full py-1">
                            <NewDropdownHead
                                content={
                                    <div className="flex h-10 w-10 items-center justify-center rounded-md duration-150 hover:bg-grey-backdrop">
                                        <GoogleIcon icon="more_vert" />
                                    </div>
                                }
                                dataType={dataType}
                                buttonType="tertiary"
                                isDisabled={isDisabled}
                                dropdownWidth="max"
                                dropdownPosition="right"
                                isNoArrow
                                clickAndClose
                                isTriggerLower
                            >
                                {dropDownOptions
                                    ?.filter((_) => _.show)
                                    .map((_el, idx) => (
                                        <NewDropdownItem
                                            key={idx}
                                            size="lg"
                                            color={_el?.isDanger ? "red" : "blue"}
                                            linkTo={_el.to}
                                            onClick={_el.func}
                                            dataType={dataType}
                                        >
                                            <span data-type={dataType}>{_el.icon}</span>
                                            <p className="ml-2 text-sm" data-type={dataType}>
                                                {_el.text}
                                            </p>
                                        </NewDropdownItem>
                                    ))}
                            </NewDropdownHead>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

import { SendMoneyOptions, ZambiaSendMoneyOptions } from "./service/send-money-dropdown.constants";
import { closeAddFundsModal, openAddFundsModal } from "../../../../../redux/accounts/slice/accountsSlice";

import NewDropdownHead from "../../../../new-dropdown";
import NewDropdownItem from "../../../../new-dropdown-item";
import { SendMoneyOptionType } from "./service/send-money-dropdown.types";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SendMoneyDropdown(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const main = useAppSelector((state) => state.init.main);
    const isNigeria = useAppSelector((state) => state.init.main?.companyDetails?.company.isNigeria);

    const sendMoneyOptions = (isNigeria ? SendMoneyOptions : ZambiaSendMoneyOptions).filter((_option) =>
        (main?.companyDetails.accounts || []).length < 2 ? _option.type !== SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS : _option
    );

    const handleOpenAddFundsModal = () => {
        dispatch(openAddFundsModal());
    };

    useEffect(() => {
        return () => {
            dispatch(closeAddFundsModal());
        };
    }, []);

    return (
        <>
            <NewDropdownHead
                size="md"
                color="black"
                buttonType="primary"
                paddingSize="sm"
                borderSmall
                dropdownWidth="max"
                dropdownPosition="center"
                content={<p className="text-white">Send Money</p>}
            >
                <>
                    {sendMoneyOptions.map((_option, index) => {
                        return (
                            <NewDropdownItem
                                size="2xl"
                                key={`${index}-${_option.text}`}
                                onClick={() => _option.onClick && _option.onClick(navigate, handleOpenAddFundsModal)}
                            >
                                <div className="flex w-full items-center justify-start space-x-4 text-wrap">
                                    <div>
                                        <img className="h-10 w-10 min-w-[40px]" src={_option.icon} alt={_option.text} />
                                    </div>
                                    <div className="flex flex-col justify-start">
                                        <p className="text-sm font-medium text-black">{_option.text}</p>
                                        <p className="text-xs text-black-tertiary">{_option.subtext}</p>
                                    </div>
                                </div>
                            </NewDropdownItem>
                        );
                    })}
                </>
            </NewDropdownHead>
        </>
    );
}

export default SendMoneyDropdown;

export enum TextType {
    DATE = 1,
    TIME = 2,
    DATE_TIME = 3,
    STRING = 4,
    REACT_NODE = 5,
    FINAL_GAIN = 6,
    FINAL_LOSS = 7,
    PROCESSING = 8,
    FAILED = 9,
    FAILED_STRIKE = 9.5,
    CANCELLED = 10,
    BALANCE = 11,
    BALANCE_STRIKE = 11.5,
    TRANSACTION_STATUS_SUCCESS = 12,
    TRANSACTION_STATUS_DECLINED = 12.5,
    TRANSACTION_STATUS_FAILED = 13,
    TRANSACTION_STATUS_FAILED_STRIKE = 13.5,
    TRANSACTION_STATUS_CANCELLED = 14,
    TRANSACTION_STATUS_PENDING_APPROVAL = 14.5,
    TRANSACTION_STATUS_PENDING_ACTIVATION = 14.6,
    TRANSACTION_STATUS_PROCESSING = 15,
    TRANSACTION_STATUS_PENDING_FRAUD_REVIEW = 15.5,
    TERMINAL_STATUS_ACTIVE = 16,
    TERMINAL_STATUS_REVOKED = 17,
    TERMINAL_REQUEST_STATUS_REQUESTED = 18,
    TERMINAL_REQUEST_STATUS_APPROVED = 19,
    TERMINAL_REQUEST_STATUS_DECLINED = 20,
    TERMINAL_REQUEST_STATUS_TERMINAL_ASSIGNED = 21,
    TERMINAL_REQUEST_STATUS_SHIPPED = 22,
    TERMINAL_REQUEST_STATUS_DELIVERED = 23,
    SINGLE_PAYMENT_CARD_PROGRESS = 24,
    DAILY_ACCUMULATIVE_CARD_PROGRESS = 25,
    WEEKLY_ACCUMULATIVE_CARD_PROGRESS = 26,
    MONTHLY_ACCUMULATIVE_CARD_PROGRESS = 27,

    CARD_REQUEST_STATUS_APPROVED = 28,
    CARD_REQUEST_STATUS_DECLINED = 29,
    CARD_REQUEST_STATUS_PRINTED = 30,
    CARD_REQUEST_STATUS_REQUESTED = 31,
    CARD_REQUEST_STATUS_SHIPPED = 32,
    CARD_REQUEST_STATUS_DELIVERED = 33,
    CARD_STATUS_ACTIVE = 34,
    CARD_STATUS_FROZEN = 35,
    CARD_STATUS_UNACTIVATED = 36,
    CARD_STATUS_EXPIRED = 37,
    CARD_STATUS_DEACTIVATED = 38,

    EDIT_DELETE = 39,
    SHARE_DOCUMENT = 40,
    MEAT_BALL_MENU = 41,

    API_LIVE = 42,
    API_PAUSED = 43,
    API_DEACTIVATED = 44,
    API_DISABLED_BY_LENCO = 45,

    CARD_LOGO = 50,
    LENCO_LOGO = 51,
    TOOLTIP_ICON = 52,
    CANCELLED_ICON = 53,

    ERROR = 10000,
}

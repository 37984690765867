import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";

import ApiAccess from "../../../../../../../../../../models/zambia/lencoPay/apiAccess";
import { ApiAccessSource } from "../../../../../../../../../../models/zambia/lencoPay/apiAccess.constant";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import FullPageTray from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import RadioButton from "../../../../../../../../../../components/radio-button";
import useUpdateZambiaApiAccessKeyCollectionFeeSource from "../../../../../../../hookss/ApiAccessKeySettings/Setup/useUpdateZambiaApiAccessKeyCollectionFeeSource";

const DATA_TYPE = "api-access-key-channels";

interface Props {
    active: boolean;
    selectedApiAccessKey: ApiAccess | null;
    toggler: () => void;
}

function CollectionFeesTray(props: Props): JSX.Element {
    const { isUpdateZambiaApiAccessKeyCollectionFeeSourceLoading, handleUpdateZambiaApiAccessKeyCollectionFeeSource } =
        useUpdateZambiaApiAccessKeyCollectionFeeSource({
            onComplete: () => props.toggler(),
        });
    const formikRef = useRef<FormikProps<{ collectionFeeSource: ApiAccessSource | null }> | null>(null);

    const INITIAL_FORM_STATE = {
        collectionFeeSource: props.selectedApiAccessKey?.collectionFeeSource || null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        collectionFeeSource: Yup.mixed<ApiAccessSource>().oneOf(Object.values(ApiAccessSource)).required(),
    });

    useEffect(() => {
        formikRef.current?.getFieldHelpers("collectionFeeSource").setValue(props.selectedApiAccessKey?.collectionFeeSource || null);
    }, [props.active]);

    return (
        <>
            <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
                <FullPageTrayHeader dataType={DATA_TYPE} subTitle="Select who pays the collection fees" toggler={props.toggler}>
                    Collection Fees
                </FullPageTrayHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={INITIAL_FORM_STATE}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => {
                        // void handleSetApproverLimit(values);
                        if (values.collectionFeeSource) {
                            handleUpdateZambiaApiAccessKeyCollectionFeeSource({
                                id: props.selectedApiAccessKey?.id || "",
                                collectionFeeSource: values.collectionFeeSource,
                            });
                        }
                    }}
                    enableReinitialize
                    validateOnMount
                    validateOnChange
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <FullPageTrayBody dataType={DATA_TYPE}>
                                    <div className="flex w-full flex-col space-y-6">
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-2 rounded-lg border px-4 py-4" +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.MERCHANT && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.MERCHANT
                                                            ? null
                                                            : ApiAccessSource.MERCHANT
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.MERCHANT}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.MERCHANT}
                                            />
                                            <div>
                                                <p className="text-base font-medium">Merchants</p>
                                                <p className="text-sm text-black-tertiary">You will pay the collection fee</p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-2 rounded-lg border px-4 py-4 " +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER
                                                            ? null
                                                            : ApiAccessSource.CUSTOMER
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.CUSTOMER}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.CUSTOMER}
                                            />
                                            <div>
                                                <p className="text-base font-medium">Customers</p>
                                                <p className="text-sm text-black-tertiary">
                                                    The fee will be added to the total amount to be paid by the customer
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "flex min-h-24 cursor-pointer items-center justify-start space-x-4 rounded-lg border px-4 py-4 " +
                                                ` ${formik.values.collectionFeeSource === ApiAccessSource.EITHER && " !border-blue-secondary"} `
                                            }
                                            onClick={() => {
                                                formik
                                                    .getFieldHelpers("collectionFeeSource")
                                                    .setValue(
                                                        formik.values.collectionFeeSource === ApiAccessSource.EITHER ? null : ApiAccessSource.EITHER
                                                    );
                                            }}
                                        >
                                            <RadioButton
                                                id={ApiAccessSource.EITHER}
                                                checked={formik.values.collectionFeeSource === ApiAccessSource.EITHER}
                                            />
                                            <div>
                                                <p className="text-base font-medium">Either</p>
                                                <p className="text-sm text-black-tertiary">
                                                    You will specify who will be debited for the collection fee through the API
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-12 flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-10">
                                        <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                                        </ButtonComp>

                                        <ButtonComp
                                            type="submit"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            isLoading={isUpdateZambiaApiAccessKeyCollectionFeeSourceLoading}
                                            disable={!formik.isValid || !formik.dirty}
                                            size="lg"
                                            fullWidth
                                        >
                                            <span className="w-full text-center">Save Changes</span>
                                        </ButtonComp>
                                    </div>
                                </FullPageTrayBody>
                            </Form>
                        );
                    }}
                </Formik>
            </FullPageTray>
        </>
    );
}

export default CollectionFeesTray;

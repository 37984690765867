import { useCallback, useEffect, useState } from "react";

import Transaction from "../../../../../../../models/transaction";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { tagRecipient } from "../Services/transactionDetailApi";
import { useDispatch } from "react-redux";

export interface UseTransactionDetailsTagRecipientResponse {
    isSent: boolean;
    errorMessage: string;
    isSubmitting: boolean;
    handleTagRecipient: (transaction: Transaction, contact: string) => Promise<void>;
}

function useTransactionDetailsTagRecipient(): UseTransactionDetailsTagRecipientResponse {
    const dispatch = useDispatch();

    const [isSent, setIsSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setIsSent(false);
            setIsSubmitting(false);
        };
    }, []);

    const handleTagRecipient = useCallback(
        async (transaction: Transaction, contact: string) => {
            try {
                setIsSubmitting(true);
                setIsSent(false);
                setErrorMessage("");
                await tagRecipient(transaction.id, contact);
                dispatch(messageTrue("Receipt Shared Successfully"));
                setIsSent(true);
            } catch (err) {
                setErrorMessage(getErrorMessage(err));
                dispatch(errorTrue({ message: getErrorMessage(err) }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [dispatch]
    );

    return {
        isSent,
        errorMessage,
        isSubmitting,
        handleTagRecipient,
    };
}

export default useTransactionDetailsTagRecipient;

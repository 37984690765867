import AvatarDetails from "../../avatar-details";
import { DataColumn } from "../Type/DataRow";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDate";
import { formatTime } from "../../../utils/formatDate";
import isNullOrUndefined from "../utils/isNullOrUndefined";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function LogoTrendFromText({ dataColumn, dataType }: Props) {
    return (
        <AvatarDetails
            dataType={dataType}
            title={
                dataColumn.textType === TextType.DATE
                    ? formatDate(dataColumn.text as Date)
                    : dataColumn.textType === TextType.TIME
                      ? formatTime(dataColumn.text as Date)
                      : dataColumn.textType === TextType.DATE_TIME
                        ? formatDateAndTime(dataColumn.text as Date)
                        : typeof dataColumn.text === "string"
                          ? dataColumn.text
                          : ""
            }
            icon={
                dataColumn.cancelTrend ? "cancel" : !isNullOrUndefined(dataColumn.gainTrend) ? (dataColumn.gainTrend ? "inflow" : "outflow") : "none"
            }
            size="xs"
        />
    );
}

import { isSwitchingBusinessTrue, setCompanyId } from "../../../../../../../redux/init/slice/initSlice";

import AvatarDetails from "../../../../../../avatar-details";
import { CompanyInterface } from "../../../../../../../helpers/types";
import { clearCustomerAccounts } from "../../../../../../../redux/customer-account/customerAccountSlice";
import { resetAllApplicationState } from "../../../../../../../redux/application/applicationSlice";
import { resetTransactionState } from "../../../../../../../redux/transaction/slice/transactionSlice";
import { resetZambiaAllApplicationState } from "../../../../../../../redux/application/zambiaApplicationSlice";
import { resetZambiaCachedCollectionsSettlementsList } from "../../../../../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import { resetZambiaCachedCollectionsTransactionsList } from "../../../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import { resetZambiaCachedPayouts } from "../../../../../../../redux/zambia/payouts/zambiaPayoutsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    company: CompanyInterface;
}

function BusinessDropdownItem(props: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <>
            <div
                className="flex w-full items-center justify-start p-3"
                onClick={() => {
                    navigate(props.company.accountCreated ? "/" : "/application");
                    dispatch(clearCustomerAccounts());
                    dispatch(resetZambiaCachedCollectionsTransactionsList());
                    dispatch(resetZambiaCachedCollectionsSettlementsList());
                    dispatch(resetZambiaCachedPayouts());
                    dispatch(resetTransactionState());
                    dispatch(resetZambiaAllApplicationState());
                    dispatch(resetAllApplicationState());
                    dispatch(setCompanyId(props.company.id));
                    dispatch(isSwitchingBusinessTrue());
                }}
            >
                <AvatarDetails title={props.company.name} subtitle="" />
            </div>
        </>
    );
}

export default BusinessDropdownItem;

import React, { ReactNode, useCallback } from "react";

import { Tooltip as ReactToolTip } from "react-tooltip";

interface Props {
    bg?: "grey" | "none";
    icon: ReactNode;
    size?: "xs" | "sm" | "fit";
    message: string | ReactNode;
    dataType?: string;
    placement?: "top" | "bottom" | "left" | "right";

    onClick?: () => void;
}

function Tooltip({ bg = "grey", size = "sm", dataType = "transaction", placement = "top", icon, message, onClick }: Props): JSX.Element {
    const key = Math.floor(Math.random() * 100000000000000) + 1;

    const handleOnClick = useCallback(
        (e: React.MouseEvent) => {
            if (onClick) {
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }
        },
        [onClick]
    );

    return (
        <div
            className={
                `relative flex items-center justify-center ` +
                `${size === "xs" ? "h-6 w-6" : ""} ` +
                `${size === "sm" ? "h-8 w-8" : ""} ` +
                `${size === "fit" ? "h-fit w-fit" : ""} `
            }
        >
            <a
                data-type={dataType}
                data-tooltip-id={`my-tooltip-` + `${key}`}
                data-tooltip-content={typeof message === "string" ? message : undefined}
                className={
                    `relative flex items-center justify-center rounded-lg outline-none ` +
                    `${bg === "grey" ? "bg-grey-backdrop" : ""} ` +
                    `${size === "xs" ? "h-6 w-6" : ""} ` +
                    `${size === "sm" ? "h-8 w-8" : ""} ` +
                    `${size === "fit" ? "h-fit w-fit" : ""} ` +
                    `${onClick ? "cursor-pointer" : ""} `
                }
                onClick={handleOnClick}
            >
                {icon}
            </a>
            <ReactToolTip id={`my-tooltip-` + `${key}`} data-type={dataType} place={placement} className="z-100">
                {typeof message !== "string" ? message : undefined}
            </ReactToolTip>
        </div>
    );
}

export default Tooltip;

import { useRef, useState } from "react";

import { FileDrop } from "react-file-drop";
import GoogleIcon from "../google-icon";
import LencoSpinner from "../spinner";

interface Props {
    isLoading: boolean;
    handleUpload: (_file: File) => void;
}

function UploadInput(props: Props) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

    return (
        <>
            <div
                className="flex h-full w-full cursor-pointer items-center justify-center rounded-lg border border-dashed border-grey bg-blue-senary"
                onClick={() => {
                    fileInputRef.current && fileInputRef.current.value === "";
                    fileInputRef.current && fileInputRef.current.click();
                }}
            >
                <FileDrop
                    onDrop={(files: FileList | null) => {
                        if (files && files[0].size > 5242880) {
                            return setIsFileSizeExceeded(true);
                        }
                        setIsFileSizeExceeded(false);

                        files && files[0] && props.handleUpload(files[0]);
                    }}
                >
                    <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                        {props.isLoading ? (
                            <LencoSpinner color="black" size="sm" />
                        ) : (
                            <GoogleIcon icon="upload_file" className="text-black-secondary" isOutlined />
                        )}
                        <p className="text-center text-xs font-medium text-black-secondary">Drop file or click here to attach receipt</p>
                        {isFileSizeExceeded && <span className="text-xs text-error">*File exceeded 5MB</span>}
                    </div>
                </FileDrop>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={(event) => {
                    if (event.target.files && event.target.files[0].size > 5242880) {
                        return setIsFileSizeExceeded(true);
                    }
                    setIsFileSizeExceeded(false);
                    const files = event.target.files;
                    if (files && files[0]) {
                        props.handleUpload(files[0]);
                    }
                }}
                accept="image/jpeg, image/png, application/pdf, .pdf"
                multiple
            />
        </>
    );
}

export default UploadInput;

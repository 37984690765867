import { useCallback, useState } from "react";

import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { resendInvitation } from "../team-members.api";
import { useDispatch } from "react-redux";

export interface useReinviteTeamMemberResponse {
    isReinviteTeamMember: boolean;
    handleReinviteTeamMember: (id: string) => Promise<void>;
}

function useReinviteTeamMember(): useReinviteTeamMemberResponse {
    const dispatch = useDispatch();

    const [isReinviteTeamMember, setIsReinviteTeamMember] = useState(false);

    const handleReinviteTeamMember = useCallback(async (id: string) => {
        try {
            setIsReinviteTeamMember(true);
            dispatch(messageTrue("Reinviting team member"));
            await resendInvitation(id);
            dispatch(messageTrue("Team member reinvited"));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            setIsReinviteTeamMember(false);
        }
    }, []);

    return {
        isReinviteTeamMember,
        handleReinviteTeamMember,
    };
}

export default useReinviteTeamMember;

import { ReactComponent as EmptyXX } from "../../../../../../assets/images/empty/empty-xx.svg";
import MemberCardSkeleton from "../../Skeleton/TeamMembers/MemberCardSkeleton";
import SearchBar from "../../../../../../components/search-bar";
import Skeleton from "../../../../../../components/skeleton";
import { TeamMemberItem } from "../../../Services/team-members/team-members.types";
import TeamMemberListItem from "./TeamMemberListItem";

interface TeamMemberListProps {
    filterTerm: string;
    selectedTeamMember: TeamMemberItem | null;
    filteredTeamMembers: TeamMemberItem[];
    isLoadingTeamMembers: boolean;
    handleSetFilterTerm: (_text: string) => void;
    handleSelectTeamMember: (id: string) => void;
}

function TeamMemberList(props: TeamMemberListProps): JSX.Element {
    return (
        <div className="flex h-full max-h-full flex-col items-start justify-start xl:border-r-0.2 xl:pr-8">
            {props.isLoadingTeamMembers || !props.selectedTeamMember ? (
                <>
                    <div className="h-7 w-44">
                        <Skeleton box />
                    </div>
                    <div className="my-4 h-10 w-full">
                        <Skeleton box />
                    </div>
                </>
            ) : (
                <>
                    <h2 className="text-xl font-medium capitalize text-black">All team members</h2>
                    <div className="mt-4 w-full">
                        <SearchBar
                            placeholder="Search for team member"
                            value={props.filterTerm}
                            onChange={props.handleSetFilterTerm}
                            size="xl"
                            withWhitBg
                        />
                    </div>
                </>
            )}
            <div className="relative h-full w-full">
                <div className="absolute left-0 top-0 mt-4 flex h-full w-full flex-col items-center justify-start space-y-6 overflow-hidden overflow-y-auto py-3">
                    {(props.isLoadingTeamMembers || !props.selectedTeamMember) && (
                        <>
                            <MemberCardSkeleton />
                            <MemberCardSkeleton />
                        </>
                    )}

                    {!props.isLoadingTeamMembers &&
                        props.selectedTeamMember &&
                        props.filteredTeamMembers.map((item, index) => (
                            <div className="w-full" key={index}>
                                <TeamMemberListItem
                                    teamMember={item.teamMember}
                                    onClick={() => props.handleSelectTeamMember(item.id)}
                                    isActive={!!props.selectedTeamMember && props.selectedTeamMember.id === item.id}
                                />
                            </div>
                        ))}
                    {!props.isLoadingTeamMembers && props.filteredTeamMembers.length < 1 && props.selectedTeamMember && (
                        <>
                            <div className="flex h-full w-full flex-row items-center justify-center space-x-2 text-sm text-black-tertiary">
                                <EmptyXX className="h-10 w-10" />
                                <span>No Team Member Found</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TeamMemberList;

// import Clipboard from "../clipboard/Clipboard";
import React from "react";
import TextClipboard from "../../text-clipboard";

interface Props {
    text: string;
    size?: "sm" | "md" | "lg";
    fullWidth?: boolean;
    isDisabled?: boolean;
    withClipboard?: boolean;
}

function Text(props: Props): JSX.Element {
    return (
        <>
            <div
                className={
                    "flex items-center justify-start rounded-lg border border-black-quin bg-grey-backdrop " +
                    `${props.fullWidth ? "w-full" : "w-max"} ` +
                    `${props.size === "lg" || !props.size ? "h-12" : ""} ` +
                    `${props.size === "md" ? "h-10" : ""} ` +
                    `${props.size === "sm" ? "h-8" : ""} `
                }
            >
                <div className="relative h-full w-full overflow-x-hidden">
                    <span
                        className={
                            "absolute left-0 top-1/2 w-full max-w-full -translate-y-1/2 transform truncate px-4 text-base " +
                            `${props.isDisabled ? "text-black-quat" : "text-black-secondary"}`
                        }
                    >
                        {props.text}
                    </span>
                </div>

                {!props.isDisabled && props.withClipboard && (
                    <div className="flex h-full w-max items-center justify-center border-l border-black-quin px-4">
                        <div className="w-6">
                            {/* <Clipboard text={props.text} withCopyIcon /> */}
                            <TextClipboard copyString={props.text} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Text;

import CurrencyCode from "../../../../../../components/currency-code";
import { IRootState } from "../../../../../../redux/rootReducer";
import React from "react";
import { ReactComponent as SmallTerminal } from "../../../../../../assets/svg/Terminal/terminals-bg.svg";
import TableSummary from "../../../../../../components/Table/Widgets/TableSummary";
import Terminal from "../../../../../../models/terminal";
import { ReactComponent as TrendDown } from "../../../../../../assets/svg/Terminal/trend-down.svg";
import { ReactComponent as TrendUp } from "../../../../../../assets/svg/Terminal/trend-up.svg";
import { commaSeparator } from "../../../../../../utils/formatNumber";
import titleCase from "../../../../../../hooks/titleCase";
import { useSelector } from "react-redux";

function AllTerminalsSummary(): JSX.Element {
    const terminals = useSelector((state: IRootState) => state.terminals.initTerminals);

    const bestPerformingTerminal: Terminal | null =
        terminals.length > 0
            ? (terminals || []).reduce(function (a: Terminal, b: Terminal) {
                  return Number(a.stats?.successful.volume || 0) > Number(b.stats?.successful.volume || 0) ? a : b;
              })
            : null;
    const leastPerformingTerminal: Terminal | null =
        terminals.length > 0
            ? (terminals || []).reduce(function (a: Terminal, b: Terminal) {
                  return Number(a.stats?.successful.volume || 0) < Number(b.stats?.successful.volume || 0) ? a : b;
              })
            : null;

    const totalTransactionVolume = terminals.reduce(
        (accumulator: number, currentValue: Terminal) => accumulator + (Number(currentValue.stats?.total.volume) || 0),
        0
    );

    return (
        <TableSummary banner={<SmallTerminal className="pt-4" />}>
            <div className="mb-96 flex w-full flex-col items-start space-x-0 space-y-5 px-6 md:mb-0 md:mr-5 md:h-full md:flex-row md:space-x-5 md:space-y-0">
                <div className="h-full w-full whitespace-nowrap border-b-0.5 border-black-quin pb-5 pr-0 md:w-auto md:border-b-0 md:border-r-0.5 md:pb-0 md:pr-5">
                    <div className="flex h-6 items-center justify-start space-x-2">
                        <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Best Performing</p>
                        <TrendUp />
                    </div>
                    <p className="text-base font-medium">
                        {bestPerformingTerminal?.name
                            ? titleCase(bestPerformingTerminal?.name)
                            : titleCase(bestPerformingTerminal?.serialNumber || "-")}
                    </p>
                    <p className="text-xs font-medium text-black-tertiary">
                        Volume: <CurrencyCode />
                        {bestPerformingTerminal?.stats?.successful?.volume
                            ? commaSeparator(Number(bestPerformingTerminal?.stats?.successful?.volume))
                            : "0.00"}
                    </p>
                </div>

                <div className="w-full whitespace-nowrap border-b-0.5 border-black-quin pb-5 pr-0 md:h-full md:w-auto md:border-b-0 md:border-r-0.5 md:pb-0 md:pr-5">
                    <div className="flex h-6 items-center justify-start space-x-2">
                        <p className="whitespace-nowrap text-xs tracking-tighter text-black-tertiary">Least Performing</p>
                        <TrendDown />
                    </div>
                    <p className="text-base font-medium">
                        {leastPerformingTerminal?.name
                            ? titleCase(leastPerformingTerminal?.name)
                            : titleCase(leastPerformingTerminal?.serialNumber || "-")}
                    </p>
                    <p className="text-xs font-medium text-black-tertiary">
                        Volume: <CurrencyCode />
                        {leastPerformingTerminal?.settlementAccount?.balance
                            ? commaSeparator(leastPerformingTerminal?.settlementAccount?.balance)
                            : "0.00"}
                    </p>
                </div>

                <div className="w-full min-w-[111px] border-b-0.5 border-black-quin pb-5 pr-0 md:h-full md:w-auto md:border-b-0 md:border-r-0.5 md:pb-0 md:pr-5">
                    <p className="flex h-6 items-center justify-start whitespace-nowrap text-xs tracking-tighter text-black-tertiary">
                        No. of Terminals
                    </p>
                    <p className="text-base font-medium">{terminals.length}</p>
                </div>

                <div>
                    <p className="flex h-6 items-center justify-start whitespace-nowrap text-xs tracking-tighter text-black-tertiary">
                        Total Transaction Volume
                    </p>

                    <p className="text-base font-medium">
                        <CurrencyCode />
                        {totalTransactionVolume > 0 ? commaSeparator(totalTransactionVolume) : "0.00"}
                    </p>
                </div>
            </div>
        </TableSummary>
    );
}

export default AllTerminalsSummary;

import React from "react";
import Transaction from "../../../../../../../models/transaction";
import TransactionDetailCardApprovedOrDeclined from "./TransactionDetailCardApprovedOrDeclined";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardStatus({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            {((transaction && transaction.isDebit() && (transaction.initiatedBy || transaction.approvedBy || transaction.declinedBy)) ||
                (transaction.isDeclined && (transaction.declinedBy || transaction.declinedOn)) ||
                (transaction.isFailed && transaction.failedReason)) && (
                <div className="flex w-full flex-col items-start justify-start space-y-4 rounded-lg bg-grey-backdrop p-4" data-type={dataType}>
                    <TransactionDetailCardApprovedOrDeclined dataType={dataType} transaction={transaction} />
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardStatus;

// import { TailSpin } from "react-loader-spinner";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import GoogleIcon from "../../../../../../components/google-icon";
import LencoSpinner from "../../../../../../components/spinner";
import MessageToast from "../../../../../../components/message-toast";
import { ReactComponent as SuccessIcon } from "../../../../../../assets/svg/Cards/Success.svg";
import { useState } from "react";

interface Props {
    cardId: string;
    cardPin: string;
    isPrepaidCard: boolean;
    isGetCardPinLoading: boolean;
}

function ActivatedCard({ cardPin, isGetCardPinLoading }: Props): JSX.Element {
    const [showCardPin, setShowCardPin] = useState(true);

    return (
        <div className="mt-4 flex w-full flex-col items-center justify-center">
            <div className="flex w-full items-center space-x-1 py-3">
                <SuccessIcon />
                <h3 className="text-base font-medium leading-none text-black">Card Activated Successfully</h3>
            </div>
            <p className="w-full text-sm font-normal text-black-tertiary">
                Your Default PIN is shown below, you should change your pin at an ATM close to you
            </p>
            {!isGetCardPinLoading ? (
                <div className="w-full">
                    <div className="flex items-center justify-center">
                        <ButtonComp color="black" buttonType="tertiary" onClick={() => setShowCardPin((prev) => !prev)}>
                            <div className="flex items-center justify-center space-x-1">
                                {showCardPin ? (
                                    <>
                                        <p className="text-black-tertiary">Hide Pin</p>
                                        <GoogleIcon icon="visibility_off" className="text-black-tertiary" />
                                    </>
                                ) : (
                                    <>
                                        <p className="text-black-tertiary">Show Pin</p>
                                        <GoogleIcon icon="visibility" className="text-black-tertiary" />
                                    </>
                                )}
                            </div>
                        </ButtonComp>
                    </div>
                    <div className="mx-auto flex w-max flex-row space-x-4 pt-6">
                        {(showCardPin ? cardPin || "0000" : "••••").split("").map((_, _index) => (
                            <div key={_index} className="flex h-16 w-16 items-center justify-center rounded-[5px] bg-grey-tertiary">
                                <p className="text-2xl font-medium text-black">{_}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-6 w-full">
                        <MessageToast
                            type="info"
                            message="If you get an error while changing your PIN, note that the PIN change has been effected and you can use your card for transactions"
                            fullWidth
                        />
                    </div>
                </div>
            ) : (
                <LencoSpinner />
            )}
        </div>
    );
}

export default ActivatedCard;

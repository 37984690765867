import {
    setIsTerminalTransactionsDetailCardOpen,
    setSelectedTerminalTransactionsDetails,
    setSpecificTerminalStage,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback, useEffect } from "react";

import { BasicFinalStatus } from "../../../../../models/posTransaction.constants";
import { SpecificTerminalStageType } from "../../../../../redux/terminals/slice/terminalsSlice.types";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import { TERMINAL_TRANSACTION_DATATYPE } from "../Cards/TerminalTransactionDetailsCard/TerminalTransactionDetailsCard";
import TabButton from "../../../../../components/TabButton";
import Table from "../../../../../components/Table";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useSpecificTerminalTransactions from "../../Hooks/State/useSpecificTerminalTransactions";

const terminalTransactionsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Terminal Name", pos: 3, headType: TableColumnType.TEXT },
    { text: "Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Amount", pos: 5, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 6, headType: TableColumnType.STATUS },
];

function SpecificTerminalTransactions(): JSX.Element {
    const dispatch = useDispatch();
    const {
        total,
        offset,
        groupSize,
        filterOption,
        posTransactions,
        selectedFilterCount,
        isTransactionPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleGetTerminalTransactions,
    } = useSpecificTerminalTransactions();

    // const terminalsTransactions = useAppSelector((state) => state.terminals.transactions);
    const specificallySelectedTerminal = useAppSelector((state) => state.terminals.specificallySelectedTerminal);

    const handleOpenTerminalTransaction = useCallback((transactionId: string) => {
        dispatch(setIsTerminalTransactionsDetailCardOpen(true));
        dispatch(setSelectedTerminalTransactionsDetails(transactionId));
    }, []);

    useEffect(() => {
        if (specificallySelectedTerminal) void handleGetTerminalTransactions({});
    }, [specificallySelectedTerminal]);

    return (
        <div className="relative flex h-full w-full flex-col items-start justify-start">
            <Table
                //filter
                // isFilterLeft
                filterOptions={filterOption}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                totalSelectedItems={selectedFilterCount}
                //pagination
                total={total}
                offset={offset}
                groupSize={groupSize}
                paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                isPaginateLoading={isTransactionPaginationLoading}
                //table
                heads={terminalTransactionsHeader}
                dataType={TERMINAL_TRANSACTION_DATATYPE}
                isLoading={!!isTransactionPaginationLoading}
                rows={posTransactions?.map((transaction) => ({
                    onRecordClick: () => handleOpenTerminalTransaction(transaction.id),
                    record: [
                        {
                            key: "Date",
                            text: transaction.datetime,
                            textType: TextType.DATE,
                            subText: transaction.datetime,
                            subTextType: SubTextType.TIME,
                        },
                        {
                            key: "From",
                            text: transaction?.transferIn?.sender || transaction?.cardPayment?.maskedPan,
                            textType: TextType.STRING,
                        },
                        {
                            key: "Terminal Name",
                            text: transaction.terminal?.serialNumber || transaction.terminal?.name || "",
                            textType: TextType.STRING,
                        },
                        {
                            key: "Type",
                            text: transaction.getType,
                            textType: TextType.STRING,
                        },
                        {
                            key: "Amount",
                            text: transaction.transactionAmount,
                            textType: transaction.status === BasicFinalStatus.FAILED ? TextType.BALANCE_STRIKE : TextType.BALANCE,
                        },
                        {
                            key: "Status",
                            text: transaction.getStatus,
                            textType:
                                transaction.status === BasicFinalStatus.FAILED
                                    ? TextType.TRANSACTION_STATUS_FAILED
                                    : transaction.status === BasicFinalStatus.SUCCESSFUL
                                      ? TextType.TRANSACTION_STATUS_SUCCESS
                                      : TextType.BALANCE,
                        },
                    ],
                }))}
                extraButton={
                    <div className="ml-6 border-l pl-6">
                        <TabButton
                            buttons={[
                                {
                                    text: "Transactions",
                                    onClick: () => dispatch(setSpecificTerminalStage(SpecificTerminalStageType.TERMINAL_TRANSACTIONS)),
                                    isActive: true,
                                },
                                {
                                    text: "Settings",
                                    onClick: () => dispatch(setSpecificTerminalStage(SpecificTerminalStageType.SETTINGS)),
                                    isActive: false,
                                },
                            ]}
                        />
                    </div>
                }
            />
        </div>
    );
}

export default SpecificTerminalTransactions;

import { FilterDateObj, FilterDateTypes } from "../../../components/filter/filter.constant";
import { TransactionStatus, TransactionType } from "../../../models/transaction.constants";

import Transaction from "../../../models/transaction";

export interface TransactionState {
    isTransactionPaginationLoading: boolean;
    transactionPaginationOffset: number;
    isExportTransactionsLoading: boolean;
    isTransactionAccountQuery: boolean;
    isTransactionSearchQuery: boolean;
    isExportZambiaTransactionsTrayOpen: boolean;
    transactionsListTotal: number;
    transactionsListGroupSize: number;
    transactionList: Transaction[] | null;
    isTransactionListEmpty: boolean;
    transactionSearchQuery: string;
    transactionAccountQuery: string;
    transactionDate: FilterDateTypes;
    selectedTransactionDate: FilterDateTypes;
    isCustomModalOpen: boolean;
    transactionFilterState: {
        offset: number;
        date: FilterDateObj;
        transactionTypes: TransactionType[];
        userGroupIds: string[];
        userAccountIds: string[];
        categoryIds: string[];
        cardIds: string[];
        transactionStatuses: TransactionStatus[];
        query: string;
    };
    selectedTransactionFilterState: {
        offset: number;
        date: FilterDateObj;
        transactionTypes: TransactionType[];
        userGroupIds: string[];
        userAccountIds: string[];
        categoryIds: string[];
        cardIds: string[];
        transactionStatuses: TransactionStatus[];
        query: string;
    };
    selectedFiltersCount: number;
    isTriggerUploadFunc: boolean;
    isAdjustTransactionTrayMargin: boolean;
}
export type TransactionFilterRequest = {
    date?: FilterDateObj;
    query?: string;
    offset?: number;
    cardIds?: string[];
    categoryIds?: string[];
    userGroupIds?: string[];
    userAccountIds?: string[];
    transactionTypes?: TransactionType[];
    transactionStatuses?: TransactionStatus[];
};

export type TransactionFilterTypes =
    | { date: FilterDateObj }
    | { userAccountIds: Array<string> }
    | { categoryIds: Array<string> }
    | { userGroupIds?: Array<string> }
    | { cardIds?: Array<string> }
    | { query: string }
    | { offset: number }
    | { transactionTypes?: Array<TransactionType>; transactionStatuses?: Array<TransactionStatus> };

export const transactionTypesList: TransactionType[] = [TransactionType.CREDIT, TransactionType.DEBIT];

export const transactionTypesText: {
    [value in TransactionType]: string;
} = {
    [TransactionType.CREDIT]: "Inflow",
    [TransactionType.DEBIT]: "Payout",
};

export const transactionStatusList: TransactionStatus[] = [
    TransactionStatus.CANCELLED,
    TransactionStatus.DECLINED,
    TransactionStatus.FAILED,
    TransactionStatus.PENDING_APPROVAL,
    TransactionStatus.PENDING_FRAUD_REVIEW,
    TransactionStatus.PROCESSING,
    TransactionStatus.SUCCESS,
];

export const transactionStatusText: {
    [value in TransactionStatus]: string;
} = {
    [TransactionStatus.CANCELLED]: "Cancelled",
    [TransactionStatus.DECLINED]: "Declined",
    [TransactionStatus.FAILED]: "Failed",
    [TransactionStatus.PENDING_APPROVAL]: "Pending Approval",
    [TransactionStatus.PENDING_FRAUD_REVIEW]: "Pending Review",
    [TransactionStatus.PROCESSING]: "Processing",
    [TransactionStatus.SUCCESS]: "Success",
};

import { FilterDateType, FilterDateTypes } from "../../../../components/filter/filter.constant";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "../../../../models/zambia/zambiaCollectionRequest.constant";
import { ZambiaCollectionsTransactionsFilterTypes, ZambiaCollectionsTransactionsState } from "./zambiaCollectionsTransactionsSlice.ts.types";

import ZambiaCollectionRequest from "../../../../models/zambia/zambiaCollectionRequest";

const initialState: ZambiaCollectionsTransactionsState = {
    zambiaCollectionsTransactionsList: null,
    zambiaCollectionsTransactionsDetail: null,
    zambiaCachedCollectionsTransactionsList: [],

    isCustomModalOpen: false,
    isZambiaCollectionsTransactionsListEmpty: false,
    isExportZambiaCollectionsTransactionsLoading: false,
    isExportZambiaCollectionsTransactionsTrayOpen: false,
    isZambiaCollectionsTransactionsDetailCardOpen: false,
    isZambiaCollectionsTransactionsPaginationLoading: false,

    zambiaCollectionsTransactionsListTotal: 0,
    zambiaCollectionsTransactionsListGroupSize: 0,
    zambiaCollectionsTransactionsPaginationOffset: 0,

    zambiaCollectionsTransactionsSearchQuery: "",

    zambiaCollectionsTransactionsDate: FilterDateTypes.ALL_TIME,
    selectedZambiaCollectionsTransactionsDate: FilterDateTypes.ALL_TIME,

    zambiaCollectionsTransactionsFilterState: {
        offset: 0,
        query: "",
        status: [],
        source: [],
        channel: [],
        date: {
            end: "",
            begin: "",
        },
    },
    selectedZambiaCollectionsTransactionsFilterState: {
        offset: 0,
        query: "",
        status: [],
        source: [],
        channel: [],
        date: {
            end: "",
            begin: "",
        },
    },
};

export const zambiaCollectionsTransactionsSlice = createSlice({
    name: "zambiaCollectionsTransactions",
    initialState,
    reducers: {
        setSelectedZambiaCollectionsTransactionsDetails: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<string>) => {
            const collectionRequest = state.zambiaCollectionsTransactionsList?.find((_sub) => _sub.id === action.payload);
            if (collectionRequest) {
                state.zambiaCollectionsTransactionsDetail = collectionRequest;
            }
        },
        setIsZambiaCollectionsTransactionsDetailCardOpen: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isZambiaCollectionsTransactionsDetailCardOpen = action.payload;
        },

        setIsAddMoneyDateModalOpen: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isCustomModalOpen = action.payload;
        },
        setIsExportZambiaCollectionsTransactionsTrayOpen: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isExportZambiaCollectionsTransactionsTrayOpen = action.payload;
        },
        setIsExportZambiaCollectionsTransactionsLoading: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isExportZambiaCollectionsTransactionsLoading = action.payload;
        },

        setIsZambiaCollectionsTransactionsPaginationLoading: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isZambiaCollectionsTransactionsPaginationLoading = action.payload;
        },
        setIsZambiaCollectionsTransactionsListEmpty: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<boolean>) => {
            state.isZambiaCollectionsTransactionsListEmpty = action.payload;
        },
        setZambiaCollectionsTransactionsDate: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<FilterDateType>) => {
            if (action.payload.date.begin && action.payload.date.end) {
                state.zambiaCollectionsTransactionsFilterState = {
                    ...state.zambiaCollectionsTransactionsFilterState,
                    date: {
                        begin: action.payload.date.begin,
                        end: action.payload.date.end,
                    },
                };
                state.zambiaCollectionsTransactionsDate = action.payload.name;
            }
        },
        setZambiaCollectionsTransactionsList: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<ZambiaCollectionRequest[]>) => {
            state.zambiaCollectionsTransactionsList = action.payload;
            state.zambiaCachedCollectionsTransactionsList = [...state.zambiaCachedCollectionsTransactionsList, ...action.payload];
        },
        setZambiaCollectionsTransactionsListTotal: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<number>) => {
            state.zambiaCollectionsTransactionsListTotal = action.payload;
        },
        setZambiaCollectionsTransactionsPaginationOffset: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<number>) => {
            state.zambiaCollectionsTransactionsPaginationOffset = action.payload;
        },
        setZambiaCollectionsTransactionsListGroupSize: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<number>) => {
            state.zambiaCollectionsTransactionsListGroupSize = action.payload;
        },
        setZambiaCollectionsTransactionsFilterState: (
            state: ZambiaCollectionsTransactionsState,
            action: PayloadAction<ZambiaCollectionsTransactionsFilterTypes>
        ) => {
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, ...action.payload };
        },
        setSelectedZambiaCollectionsTransactionsFilterStateInstant: (
            state: ZambiaCollectionsTransactionsState,
            action: PayloadAction<ZambiaCollectionsTransactionsFilterTypes>
        ) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.selectedZambiaCollectionsTransactionsFilterState, ...action.payload };
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, ...action.payload };
        },
        setZambiaCollectionsTransactionsDateFilter: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = {
                ...state.selectedZambiaCollectionsTransactionsFilterState,
                date: {
                    begin: "",
                    end: "",
                },
            };
            state.zambiaCollectionsTransactionsFilterState = {
                ...state.zambiaCollectionsTransactionsFilterState,
                date: {
                    begin: "",
                    end: "",
                },
            };
            state.selectedZambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
            state.zambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
        },
        setSelectedZambiaCollectionsTransactionsFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState };
            state.selectedZambiaCollectionsTransactionsDate = state.zambiaCollectionsTransactionsDate;
        },

        setZambiaCollectionsTransactionsChannelFilter: (
            state: ZambiaCollectionsTransactionsState,
            action: PayloadAction<ZambiaCollectionPaymentChannel>
        ) => {
            state.selectedZambiaCollectionsTransactionsFilterState.channel = state.selectedZambiaCollectionsTransactionsFilterState.channel.filter(
                (_types) => _types !== action.payload
            );
            state.zambiaCollectionsTransactionsFilterState.channel = state.zambiaCollectionsTransactionsFilterState.channel.filter(
                (_types) => _types !== action.payload
            );
        },
        setZambiaCollectionsTransactionsStatusFilter: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<ZambiaCollectionStatus>) => {
            state.selectedZambiaCollectionsTransactionsFilterState.status = state.selectedZambiaCollectionsTransactionsFilterState.status.filter(
                (_status) => _status !== action.payload
            );
            state.zambiaCollectionsTransactionsFilterState.status = state.zambiaCollectionsTransactionsFilterState.status.filter(
                (_status) => _status !== action.payload
            );
        },
        setZambiaCollectionsTransactionsSourceFilter: (state: ZambiaCollectionsTransactionsState, action: PayloadAction<ZambiaCollectionSource>) => {
            state.selectedZambiaCollectionsTransactionsFilterState.source = state.selectedZambiaCollectionsTransactionsFilterState.source.filter(
                (_source) => _source !== action.payload
            );
            state.zambiaCollectionsTransactionsFilterState.source = state.zambiaCollectionsTransactionsFilterState.source.filter(
                (_source) => _source !== action.payload
            );
        },

        resetZambiaCollectionsTransactionsList: (state: ZambiaCollectionsTransactionsState) => {
            state.zambiaCollectionsTransactionsList = null;
        },
        resetZambiaCollectionsTransactionsDateFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = {
                ...state.selectedZambiaCollectionsTransactionsFilterState,
                date: {
                    begin: "",
                    end: "",
                },
            };
            state.zambiaCollectionsTransactionsFilterState = {
                ...state.zambiaCollectionsTransactionsFilterState,
                date: {
                    begin: "",
                    end: "",
                },
            };
            state.selectedZambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
            state.zambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
        },
        resetZambiaCollectionsTransactionsStatusFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.selectedZambiaCollectionsTransactionsFilterState, status: [] };
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, status: [] };
        },
        resetZambiaCollectionsTransactionsSourceFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.selectedZambiaCollectionsTransactionsFilterState, source: [] };
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, source: [] };
        },
        resetZambiaCollectionsTransactionsChannelFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.selectedZambiaCollectionsTransactionsFilterState, channel: [] };
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, channel: [] };
        },
        resetZambiaCollectionsTransactionsQueryFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.selectedZambiaCollectionsTransactionsFilterState = { ...state.selectedZambiaCollectionsTransactionsFilterState, query: "" };
            state.zambiaCollectionsTransactionsFilterState = { ...state.zambiaCollectionsTransactionsFilterState, query: "" };
        },
        resetZambiaCollectionsTransactionsFilterState: (state: ZambiaCollectionsTransactionsState) => {
            state.zambiaCollectionsTransactionsFilterState = {
                query: "",
                offset: 0,
                date: {
                    begin: "",
                    end: "",
                },
                channel: [],
                source: [],
                status: [],
            };
            state.selectedZambiaCollectionsTransactionsFilterState = {
                query: "",
                offset: 0,
                date: {
                    begin: "",
                    end: "",
                },
                channel: [],
                source: [],
                status: [],
            };
        },
        resetAllZambiaCollectionsTransactionsData: (state: ZambiaCollectionsTransactionsState) => {
            state.zambiaCollectionsTransactionsDetail = null;
            state.isZambiaCollectionsTransactionsDetailCardOpen = false;
            state.isExportZambiaCollectionsTransactionsTrayOpen = false;
            state.zambiaCollectionsTransactionsList = null;
            state.zambiaCollectionsTransactionsListTotal = 0;
            state.zambiaCollectionsTransactionsPaginationOffset = 0;
            state.zambiaCollectionsTransactionsListGroupSize = 0;
            state.isExportZambiaCollectionsTransactionsLoading = false;
            state.isZambiaCollectionsTransactionsListEmpty = false;
            state.zambiaCollectionsTransactionsSearchQuery = "";
            state.isZambiaCollectionsTransactionsPaginationLoading = false;
            state.zambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
            state.selectedZambiaCollectionsTransactionsDate = FilterDateTypes.ALL_TIME;
            state.isCustomModalOpen = false;
            state.zambiaCollectionsTransactionsFilterState = {
                query: "",
                status: [],
                offset: 0,
                source: [],
                channel: [],
                date: {
                    begin: "",
                    end: "",
                },
            };
            state.selectedZambiaCollectionsTransactionsFilterState = {
                query: "",
                offset: 0,
                status: [],
                source: [],
                channel: [],
                date: {
                    begin: "",
                    end: "",
                },
            };
        },
        resetZambiaCachedCollectionsTransactionsList: (state: ZambiaCollectionsTransactionsState) => {
            state.zambiaCachedCollectionsTransactionsList = [];
        },
    },
});

export const {
    setIsAddMoneyDateModalOpen,
    setZambiaCollectionsTransactionsDate,
    setZambiaCollectionsTransactionsList,
    setZambiaCollectionsTransactionsListTotal,
    setZambiaCollectionsTransactionsDateFilter,
    setZambiaCollectionsTransactionsChannelFilter,
    setZambiaCollectionsTransactionsFilterState,
    setIsZambiaCollectionsTransactionsListEmpty,
    setZambiaCollectionsTransactionsListGroupSize,
    setSelectedZambiaCollectionsTransactionsFilterState,
    setZambiaCollectionsTransactionsSourceFilter,
    setZambiaCollectionsTransactionsStatusFilter,
    setIsExportZambiaCollectionsTransactionsLoading,
    setSelectedZambiaCollectionsTransactionsDetails,
    setIsZambiaCollectionsTransactionsDetailCardOpen,
    setZambiaCollectionsTransactionsPaginationOffset,
    setIsZambiaCollectionsTransactionsPaginationLoading,
    setSelectedZambiaCollectionsTransactionsFilterStateInstant,
    setIsExportZambiaCollectionsTransactionsTrayOpen,

    resetZambiaCollectionsTransactionsList,
    resetAllZambiaCollectionsTransactionsData,
    resetZambiaCachedCollectionsTransactionsList,
    resetZambiaCollectionsTransactionsFilterState,
    resetZambiaCollectionsTransactionsDateFilterState,
    resetZambiaCollectionsTransactionsQueryFilterState,
    resetZambiaCollectionsTransactionsChannelFilterState,
    resetZambiaCollectionsTransactionsSourceFilterState,
    resetZambiaCollectionsTransactionsStatusFilterState,
} = zambiaCollectionsTransactionsSlice.actions;

export default zambiaCollectionsTransactionsSlice.reducer;

import { Navigate, Routes, useLocation } from "react-router-dom";

import AccountDetails from "./modules/dashboard/Accounts/Pages/IndividualAccount";
import AccountOpening from "./modules/dashboard/applicationn/Pages/AccountOpening";
import AccountSettings from "./modules/dashboard/settings-new/Pages/AccountSettings";
import Accounts from "./modules/dashboard/Accounts/Pages";
import AddMoney from "./modules/dashboard/payments-new/pages/AddMoney";
import AddMoneyHistory from "./modules/dashboard/payments-new/componentss/AddMoney/AddMoneyHistory";
import ApiAccessKeys from "./modules/dashboard/lenco-pay/Pages/ApiAccessKeys";
import Application from "./modules/dashboard/applicationn/Pages/Application";
import BillPayment from "./modules/dashboard/payments-new/pages/BillPayment";
import BusinessType from "./modules/landing/business-type/page";
import Cards from "./modules/dashboard/Cards/Pages";
import CompanyBase from "./modules/landing/company-base/pages/index";
import DashboardHome from "./modules/dashboard/Home copy/Pages";
import DashboardLayout from "./components/layouts/dashboard-layout";
import DirectorForm from "./modules/landing/external-application-forms/director-form";
import EmailVerification from "./modules/landing/email-verification/page";
import ForgotPassword from "./modules/landing/forgot-password/pagess";
import InitWrapper from "./components/init";
import LandingLayout from "./components/layouts/landing-layout";
import Login from "./modules/landing/login/page";
import PendingPayments from "./modules/dashboard/payments-new/pages/PendingPayment";
import Recipients from "./modules/dashboard/payments-new/pages/SavedRecipient";
import ReferenceForm from "./modules/landing/external-application-forms/reference-form/pagess";
import ResetForgotPassword from "./modules/landing/reset-password/pagess";
import { Route } from "react-router-dom";
import Security from "./modules/dashboard/settings-new/Pages/Security";
import SelectTypeOfBusiness from "./modules/dashboard/applicationn/Pages/SelectTypeOfBusiness";
import SendMoney from "./modules/dashboard/payments-new/pages/SendMoney";
import SignUp from "./modules/landing/sign-up/page";
import { Suspense } from "react";
import TeamMembers from "./modules/dashboard/settings-new/Pages/TeamMembers";
import Terminals from "./modules/dashboard/terminals";
import TransactionsPage from "./modules/dashboard/Transactions/Pages";
import WaitList from "./modules/landing/wait-list/page";
import ZambiaCollections from "./modules/dashboard/lenco-pay/Pages/Collections";
import ZambiaConfiguration from "./modules/dashboard/lenco-pay/Pages/Configuration";
import ZambiaLencoPayGetStarted from "./modules/dashboard/lenco-pay/Pages/GetStarted";
import ZambiaPayouts from "./modules/dashboard/lenco-pay/Pages/Payouts";
import { enableMapSet } from "immer";
import { routes } from "./config/routes";
import { useAppSelector } from "./redux/hooks";

enableMapSet();

function App() {
    const location = useLocation();
    const isLoggedIn = useAppSelector((state) => state.init.isLoggedIn);

    return (
        <Suspense
            fallback={
                !location.pathname.includes("/director/") ? isLoggedIn ? <DashboardLayout isLoading /> : <LandingLayout isLoading /> : undefined
            }
        >
            <Routes location={location} key={location.pathname}>
                <Route path="/director/:a/:b" element={<DirectorForm />} />
                <Route path="/director/:a/:b/:section" element={<DirectorForm />} />
                <Route path="/director/:a/:b/:section/:stage" element={<DirectorForm />} />
                <Route path="/referee/:a/:b" element={<ReferenceForm />} />
                <Route element={<LandingLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/invite/:type/:a/:b" element={<Login />} />
                    <Route path="/signup" element={<CompanyBase />} />
                    <Route path="/signup/invite/:type/:a/:b" element={<CompanyBase />} />
                    <Route path="/signup/business-type" element={<BusinessType />} />
                    <Route path="/signup/business-type/invite/:type/:a/:b" element={<BusinessType />} />
                    <Route path="/signup/account" element={<SignUp />} />
                    <Route path="/signup/account/invite/:type/:a/:b" element={<SignUp />} />
                    <Route path="/verify-email/:a/:b/:c" element={<EmailVerification />} />
                    <Route path="/wait-list/:stage" element={<WaitList />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:a/:b/:c" element={<ResetForgotPassword />} />
                    {/* <Route path="/tr/:a/:b" component={ShareReceipt} /> */}
                    {/* {!isLoggedIn && <Route path="/" element={<Navigate to="/login" replace />} />} */}
                </Route>
                <Route path="/" element={<InitWrapper />}>
                    <Route index element={<DashboardHome />} />
                    <Route path="/application" element={<AccountOpening />} />
                    <Route path="/application/:section" element={<Application />} />
                    <Route path="/application/:section/:stage" element={<Application />} />
                    <Route path="/application/registration-status" element={<SelectTypeOfBusiness />} />
                    <Route path="/settings/security" element={<Security />} />
                    <Route path="/payments/pending" element={<PendingPayments />} />
                    <Route path="/settings/account/:accountId" element={<AccountSettings />} />
                    <Route path="/settings/account/stage/:stage" element={<AccountSettings />} />
                    <Route path="/settings/account" element={<AccountSettings />} />
                    <Route path="/settings/team-members" element={<TeamMembers />} />
                    <Route path="/terminals" element={<Terminals />} />
                    <Route path="/lenco-pay/payouts" element={<ZambiaPayouts />} />
                    <Route path="/lenco-pay/payouts/search/:query" element={<ZambiaPayouts />} />
                    <Route path="/lenco-pay/collections" element={<ZambiaCollections />} />
                    <Route path="/lenco-pay/configuration" element={<ZambiaConfiguration />} />
                    <Route path={routes.LENCO_PAY.APIS} element={<ApiAccessKeys />} />
                    <Route path={routes.LENCO_PAY.CONFIGURATION} element={<ZambiaConfiguration />} />
                    <Route path="/lenco-pay/collections/:type" element={<ZambiaCollections />} />
                    <Route path="/lenco-pay/collections/:type/search/:query" element={<ZambiaCollections />} />
                    <Route path="/lenco-pay/get-started" element={<ZambiaLencoPayGetStarted />} />
                    <Route path="/payments/recipients" element={<Recipients />} />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/accounts/:accountId" element={<AccountDetails />} />
                    <Route path="/transactions" element={<TransactionsPage />} />
                    <Route path="/transactions/:type" element={<TransactionsPage />} />
                    <Route path="/transactions/:type/:query" element={<TransactionsPage />} />
                    <Route path="/payments/make" element={<SendMoney />} />
                    <Route path="/payments/make/:type" element={<SendMoney />} />
                    <Route path="/payments/make/:type/:accountId" element={<SendMoney />} />
                    <Route path="/payments/bill/make" element={<BillPayment />} />
                    <Route path="/payments/add-money/make" element={<AddMoney />} />
                    <Route path="/payments/add-money/history" element={<AddMoneyHistory />} />
                    <Route path="/payments/add-money/make/:urlFundingType" element={<AddMoney />} />
                    <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} replace />} />
                </Route>
                <Route path="*" element={<Navigate to={isLoggedIn ? "/" : "/login"} replace />} />
            </Routes>
        </Suspense>
    );
}

export default App;

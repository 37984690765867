import { useDispatch, useSelector } from "react-redux";

import CurrencyCode from "../../../../../../../components/currency-code";
import FullPageTray from "../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import { IRootState } from "../../../../../../../redux/rootReducer";
import React from "react";
import StatusTag from "../Status/CollectionStatusTag";
import { formatDateAndTime } from "../../../../../../../utils/formatDate";
import formatNumber from "../../../../../../../utils/formatNumber";
import { setIsZambiaCollectionsTransactionsDetailCardOpen } from "../../../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import titleCase from "../../../../../../../hooks/titleCase";

const DATA_TYPE = "zambia-collections-transaction";

function ZambiaCollectionsTransaction(): JSX.Element {
    const dispatch = useDispatch();

    const zambiaCollectionsTransactionsDetail = useSelector(
        (state: IRootState) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsDetail
    );
    const isZambiaCollectionsTransactionsDetailCardOpen: boolean = useSelector(
        (state: IRootState) => state.zambiaCollectionsTransactions.isZambiaCollectionsTransactionsDetailCardOpen
    );

    const handleCloseAddMoneyCollectionRequestTray = () => {
        dispatch(setIsZambiaCollectionsTransactionsDetailCardOpen(false));
    };

    const successfulOrLastAttempt = [...(zambiaCollectionsTransactionsDetail?.attempts || [])].sort((a, b) =>
        a.isSuccessful === b.isSuccessful ? 0 : a.isSuccessful ? -1 : 1
    )[0];

    return (
        <>
            {/*<===Terminal Transaction Details Code goes here===>*/}
            {/* {zambiaCollectionsTransactionsDetail && ( */}
            <FullPageTray
                active={isZambiaCollectionsTransactionsDetailCardOpen}
                dataType={DATA_TYPE}
                handleReset={handleCloseAddMoneyCollectionRequestTray}
                withClickOutside
            >
                <FullPageTrayHeader toggler={handleCloseAddMoneyCollectionRequestTray} dataType={DATA_TYPE}>
                    Collection Details
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <div className="mb-10 flex w-full flex-col space-y-4">
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between space-x-8 py-2">
                                <p className="text-2xl font-bold">
                                    <CurrencyCode currency={zambiaCollectionsTransactionsDetail?.currency} />
                                    {formatNumber(zambiaCollectionsTransactionsDetail?.amount || 0)}
                                </p>

                                <div className="">
                                    <StatusTag collection={zambiaCollectionsTransactionsDetail} />
                                </div>
                            </div>
                            {zambiaCollectionsTransactionsDetail?.isFailed && zambiaCollectionsTransactionsDetail?.failedReason && (
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Reason</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-error">
                                        {zambiaCollectionsTransactionsDetail?.failedReason}
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Channel</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {titleCase(successfulOrLastAttempt?.channelText || "")}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Type</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {titleCase(successfulOrLastAttempt?.mobileMoneyCollection?.serviceProviderText || "-")}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Date</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {formatDateAndTime(zambiaCollectionsTransactionsDetail?.createdAt as Date)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {zambiaCollectionsTransactionsDetail?.paymentReference}
                                </p>
                            </div>
                            {zambiaCollectionsTransactionsDetail?.apiRequestPayment?.clientReference && (
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Merchant Reference</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        {zambiaCollectionsTransactionsDetail?.apiRequestPayment?.clientReference}
                                    </p>
                                </div>
                            )}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fees</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode currency={zambiaCollectionsTransactionsDetail?.currency} />
                                    {formatNumber(
                                        successfulOrLastAttempt?.mobileMoneyCollection?.lencoFee ??
                                            (successfulOrLastAttempt?.cardPaymentCollection?.lencoFee || 0)
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            {successfulOrLastAttempt?.isCard ? (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Customer Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {successfulOrLastAttempt?.cardPaymentCollection?.cardDetails?.name}
                                        </p>
                                    </div>{" "}
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {successfulOrLastAttempt?.cardPaymentCollection?.cardDetails?.maskedPan?.replaceAll(/x/gi, "•")}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Customer Name</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {successfulOrLastAttempt?.mobileMoneyCollection?.debitAccountName}
                                        </p>
                                    </div>{" "}
                                    <div className="flex w-full justify-between">
                                        <p className="text-sm font-normal text-black-tertiary">Phone Number</p>
                                        <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                            {successfulOrLastAttempt?.mobileMoneyCollection?.debitPhoneNumber}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>

                        {/* <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Type</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {terminalTransactionDetail?.getType}
                                </p>
                            </div>
                            {terminalTransactionDetail?.type === TransactionType.CARD_PAYMENT && (
                                <>
                                    {terminalTransactionDetail?.cardPayment?.maskedPan && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">PAN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.maskedPan}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.cardPayment?.rrn && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">RRN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.rrn}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.cardPayment?.maskedPan && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">STAN</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.cardPayment.maskedPan}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            {terminalTransactionDetail?.type === TransactionType.TRANSFER_IN && (
                                <>
                                    {terminalTransactionDetail?.transferIn?.sender && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Sender Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.sender}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferIn?.description && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Narration</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.description}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferIn?.sessionId && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Session ID</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferIn?.sessionId}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                            {terminalTransactionDetail?.type === TransactionType.TRANSFER_OUT && (
                                <>
                                    {terminalTransactionDetail?.transferOut?.recipient?.accountName && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Name</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.recipient.accountName}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferOut?.recipient?.bankName && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Bank</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.recipient.bankName}
                                            </p>
                                        </div>
                                    )}
                                    {terminalTransactionDetail?.transferOut?.recipient?.accountNumber && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Recipient Account Number</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {"********" + terminalTransactionDetail?.transferOut?.recipient.accountNumber?.slice(-4) || ""}
                                            </p>
                                        </div>
                                    )}

                                    {terminalTransactionDetail?.transferOut?.sessionId && (
                                        <div className="flex w-full justify-between">
                                            <p className="text-sm font-normal text-black-tertiary">Session ID</p>
                                            <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                                {terminalTransactionDetail?.transferOut?.sessionId}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div> */}

                        {/* <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-tertiary px-4 py-4">
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Settlement Status</p>

                                <div className="">
                                    <TerminalSettlementStatusTag terminal={terminalTransactionDetail} />
                                </div>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Reference</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {terminalTransactionDetail?.reference}
                                </p>
                            </div>

                            {(terminalTransactionDetail?.type === TransactionType.TRANSFER_IN ||
                                terminalTransactionDetail?.type === TransactionType.TRANSFER_OUT) && (
                                <div className="flex w-full justify-between">
                                    <p className="text-sm font-normal text-black-tertiary">Stamp Duty</p>
                                    <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                        <CurrencyCode />
                                        {formatNumber(terminalTransactionDetail?.stampDuty || 0)}
                                    </p>
                                </div>
                            )}
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Fee</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    <CurrencyCode />
                                    {formatNumber(terminalTransactionDetail?.lencoFee || 0)}
                                </p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className="text-sm font-normal text-black-tertiary">Transaction Time</p>
                                <p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary">
                                    {formatDateAndTime(terminalTransactionDetail?.datetime as Date)}
                                </p>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="mb-10 flex w-full flex-col space-y-4">
                        {successfulOrLastAttempt && (
                            <>
                                <div className="border-t border-grey-tertiary" />
                                <div className="flex w-full flex-col space-y-6">
                                    <h6 className="text-sm font-medium">Customer Details</h6>
                                    <div className="flex w-full flex-col space-y-4">
                                        {successfulOrLastAttempt.isCard ? (
                                            <>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Customer Name</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {successfulOrLastAttempt.cardPaymentCollection?.cardDetails?.name}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">PAN</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {successfulOrLastAttempt.cardPaymentCollection?.cardDetails?.maskedPan?.replaceAll(
                                                            /x/gi,
                                                            "•"
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Channel</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {titleCase(successfulOrLastAttempt.channelText || "")}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Card Type</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {titleCase(successfulOrLastAttempt.cardPaymentCollection?.cardDetails?.cardType || "-")}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Customer Name</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {successfulOrLastAttempt.mobileMoneyCollection?.debitAccountName}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Phone Number</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {successfulOrLastAttempt.mobileMoneyCollection?.debitPhoneNumber}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Channel</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {titleCase(successfulOrLastAttempt.channelText || "")}
                                                    </p>
                                                </div>
                                                <div className="flex w-full justify-between">
                                                    <p className="text-sm">Channel Type</p>
                                                    <p className="max-w-55% break-words text-right text-sm font-medium">
                                                        {titleCase(successfulOrLastAttempt.mobileMoneyCollection?.serviceProviderText || "-")}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div> */}
                </FullPageTrayBody>
            </FullPageTray>
            {/* )} */}
        </>
    );
}

export default ZambiaCollectionsTransaction;

import { InviteTeamMemberRequest, TeamMemberAccessLevel, TeamMemberApproverLimitRequest, TeamMemberItem } from "./team-members.types";

import { GenericObject } from "../../../../../helpers/types";
import Parsers from "../../../../../utils/parsers";
import User from "../../../../../models/user";
import UserAccountMeta from "../../../../../models/userAccountMeta";
import { makeRequest } from "../../../../../helpers/request/makeRequest";

export async function listTeamMembers(): Promise<Array<TeamMemberItem>> {
    const res = await makeRequest("/main/settings/team-members");
    if (res instanceof Error) {
        throw res;
    }
    if (!res.members || !Array.isArray(res.members)) {
        throw new Error("An Error Occurred");
    }
    return res.members.map((member: GenericObject) => ({
        id: Parsers.string(member.id),
        teamMember: Parsers.classObjectNonNullable(member.teamMember, User),
        accountsMeta: Parsers.classObjectArray(member.accountMembers, UserAccountMeta),
    }));
}

export async function removeTeamMember(memberId: string): Promise<boolean> {
    const res = await makeRequest("/main/settings/team-members/remove", { memberId });
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.success);
}

export async function inviteTeamMember(request: InviteTeamMemberRequest): Promise<TeamMemberItem> {
    const res = await makeRequest("/main/settings/team-members/add", request);
    if (res instanceof Error) {
        throw res;
    }
    const teamMember = Parsers.classObjectNonNullable(res.teamMember, User);
    return {
        id: teamMember.id,
        teamMember: teamMember,
        accountsMeta: Parsers.classObjectArray(Object.values(res.accountMembers as GenericObject), UserAccountMeta),
    };
}

export async function setApproverLimit(request: TeamMemberApproverLimitRequest): Promise<TeamMemberAccessLevel> {
    const res = await makeRequest("/main/settings/accounts/members/update/approval-limit", request);
    if (res instanceof Error) {
        throw res;
    }
    return {
        accountMember: Parsers.classObjectNonNullable(res.accountMember, UserAccountMeta),
    };
}

export async function resendInvitation(memberId: string): Promise<boolean> {
    const res = await makeRequest("/main/settings/team-members/resend-invitation", { memberId });
    if (res instanceof Error) {
        throw res;
    }
    return Parsers.boolean(res.success);
}

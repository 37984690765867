import React, { ReactNode } from "react";

interface Props {
    children: ReactNode;
    banner?: ReactNode;
}

export default function TableSummary({ children, banner }: Props) {
    return (
        <div className="relative mb-10 flex w-full items-center overflow-hidden">
            <div className="flex h-full w-full items-center justify-between overflow-hidden rounded-xl bg-white md:h-[133px]">
                <div className="h-full w-full flex-1 py-4">
                    <div className="flex h-full w-full flex-1 items-center justify-center border-r-0 border-black-quin py-4 lg:border-r-0.5">
                        <div className="flex h-full w-full flex-col items-center md:flex-row">{children}</div>
                    </div>
                </div>
                {banner && <div className="hidden h-full w-fit items-end justify-center xl:flex xl:px-20">{banner}</div>}
            </div>
        </div>
    );
}

import { useCallback, useEffect } from "react";

import { setTransferBetweenAmount } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface useTransferBetweenPurposeInterface {
    handleAmountChange: (_amount: string) => void;
}

function useTransferBetweenAmount(): useTransferBetweenPurposeInterface {
    const dispatch = useDispatch();

    const payAmount = useAppSelector((state) => state.sendMoney.payAmount);

    useEffect(() => {
        if (!payAmount) return;
        dispatch(setTransferBetweenAmount(payAmount));
    }, [payAmount]);

    const handleAmountChange = useCallback((_amount: string) => {
        if (!_amount || Number(_amount) === 0) return;
        dispatch(setTransferBetweenAmount(_amount));
    }, []);

    return { handleAmountChange };
}

export default useTransferBetweenAmount;

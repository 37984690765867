import ButtonComp from "../button/ButtonComp";
import GoogleIcon from "../google-icon";
import React from "react";

interface Props {
    image: string;
    title: string;
    subtitle: string;
    buttonCTA?: string;
    imgClassName?: string;
    onClick?: () => void;
    onToggle: () => void;
}

function PageBanner(props: Props) {
    return (
        <>
            <div className="relative mb-10 flex min-h-fit w-full items-center justify-between overflow-hidden rounded-lg border bg-white">
                <div className="flex flex-col justify-between gap-6 p-8">
                    <div className="flex flex-col gap-1">
                        <h3 className="text-xl font-medium text-black">{props.title}</h3>
                        <p className="text-sm text-black-secondary">{props.subtitle}</p>
                    </div>
                    {props.onClick && (
                        <ButtonComp buttonType="secondary" size="sm" color="grey" onClick={props.onClick}>
                            {props.buttonCTA}
                        </ButtonComp>
                    )}
                </div>
                <div className="relative hidden h-full w-full min-w-[360px] max-w-[479px] overflow-hidden bg-grey-tertiary sm:block xl:min-w-[unset]">
                    <div className="transform-center absolute left-[50%] top-[50%] flex h-full w-max items-center justify-center">
                        <img src={props.image} className={"w-full max-w-[585px] xl:max-w-[unset] " + `${props.imgClassName || ""}`} alt="accounts" />
                    </div>
                </div>
                <div className="absolute right-4 top-4">
                    <ButtonComp color="black" buttonType="tertiary" onClick={props.onToggle}>
                        <GoogleIcon icon="close" size="lg" />
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default PageBanner;

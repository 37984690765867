import React, { useCallback, useEffect } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import MessageToasts from "../../../../../components/message-toast";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import TextArea from "../../../../../components/text-area";
import { closeEditAccountDescriptionModal } from "../../../../../redux/accounts/slice/accountsSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useEditAccountDescription from "../../Hooks/State/useEditAccountDescription";
import useDimension from "../../../../../hooks/useDimension";

function EditAccountDescriptionModal(): JSX.Element {
    const {
        description,
        handleChangeUserAccountDescription,
        handleEditAccountDescription,
        handleResetAll,
        editAccountDescriptionError,
        editAccountDescriptionResponse,
        isEditAccountDescriptionLoading,
    } = useEditAccountDescription();

    const dispatch = useDispatch();

    const individualAccount = useAppSelector((state) => state.account.individualAccount);
    const isEditAccountDescriptionModalOpen = useAppSelector((state) => state.account.isEditAccountDescriptionModalOpen);

    const deviceWidth = useDimension().width;

    useEffect(() => {
        if (!editAccountDescriptionResponse) return;
        dispatch(closeEditAccountDescriptionModal());
    }, [editAccountDescriptionResponse]);

    useEffect(() => {
        if (!individualAccount || !isEditAccountDescriptionModalOpen) return;
        handleChangeUserAccountDescription(individualAccount.description || "");
    }, [individualAccount, isEditAccountDescriptionModalOpen]);

    useEffect(() => {
        return () => {
            handleResetAll();
        };
    }, [editAccountDescriptionResponse]);

    const handleCloseEditAccountDescriptionModal = useCallback(() => {
        dispatch(closeEditAccountDescriptionModal());
    }, []);

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!individualAccount) return;
            void handleEditAccountDescription({
                userAccountId: individualAccount.id,
                description,
            });
        },
        [individualAccount, description]
    );

    return (
        <>
            <Modal size="sm" active={isEditAccountDescriptionModalOpen} toggler={handleCloseEditAccountDescriptionModal}>
                <ModalHeader onClose={handleCloseEditAccountDescriptionModal}>Edit Description</ModalHeader>
                <form className="w-full" onSubmit={handleSubmit}>
                    <ModalBody>
                        {editAccountDescriptionError && <MessageToasts className="mb-4" message={editAccountDescriptionError || ""} type="error" />}
                        <TextArea
                            value={description ? description : ""}
                            onChangeFunc={handleChangeUserAccountDescription}
                            placeholder="Account Description (optional)"
                            sm
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex w-full flex-col-reverse justify-center gap-4 sm:flex-row sm:justify-end">
                            <ButtonComp
                                size="lg"
                                type="button"
                                ripple="light"
                                buttonType="secondary"
                                color="grey"
                                func={handleCloseEditAccountDescriptionModal}
                                fullWidth={deviceWidth < 640}
                            >
                                <span>Cancel</span>
                            </ButtonComp>
                            <ButtonComp
                                size="lg"
                                type="submit"
                                color="black"
                                ripple="light"
                                buttonType="primary"
                                isLoading={isEditAccountDescriptionLoading}
                                disable={description && description.length < 0 ? true : false}
                                fullWidth={deviceWidth < 640}
                            >
                                <span>Submit</span>
                            </ButtonComp>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
}

export default EditAccountDescriptionModal;

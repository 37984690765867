import React from "react";

interface Props {
    size?: "md" | "lg";
    padding?: "none" | "md";
    children: React.ReactNode;
    dataType: string;
}
function FullPageTrayFooter(props: Props): JSX.Element {
    return (
        <div
            className={
                "flex h-full w-full flex-row items-center justify-center border-t-0.2 " +
                `${props.size === "md" ? "max-h-[76px] py-2 xl:max-h-24" : ""}` +
                `${!props.size || props.size === "lg" ? "max-h-24 py-4 xl:max-h-32" : ""}`
            }
            data-type={props.dataType}
            id="scroller"
        >
            {props.children}
        </div>
    );
}

export default FullPageTrayFooter;

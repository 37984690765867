import { isStorageAvailable } from "../utils/windowStorage";
import moment from "moment";
import store from "../redux/store";

export const storageKey = "lenco-card-reminder";
export const storageSplitKey = "lenco-split-reminder";
export const storageAccountKey = "lenco-account-reminder";
export const storageTransactionKey = "lenco-transaction-reminder";

enum StorageKey {
    CARD = "lenco-card-reminder",
    SPLIT = "lenco-split-reminder",
    ACCOUNT = "lenco-account-reminder",
    TRANSACTION = "lenco-transaction-reminder",
}

function getBannerReminderData(_storageKey = StorageKey.CARD): { [key: string]: string } {
    if (!isStorageAvailable) {
        return {};
    }
    const dataString = window.localStorage.getItem(_storageKey);
    try {
        return dataString === null ? {} : (JSON.parse(dataString) as { [key: string]: string });
    } catch (err) {
        return {};
    }
}

export function saveBannerSkippedDate(_storageKey = StorageKey.CARD): void {
    if (isStorageAvailable) {
        const prev = getBannerReminderData(_storageKey);
        const currentDate = moment().format("YYYY-MM-DD");
        const data = { isBannerSkipped: currentDate, ...prev };
        window.localStorage.setItem(_storageKey, JSON.stringify(data));
    }
}

export function saveSplitBannerSkippedDate(): void {
    saveBannerSkippedDate(StorageKey.SPLIT);
}

export function saveAccountBannerSkippedDate(): void {
    saveBannerSkippedDate(StorageKey.ACCOUNT);
}

export function saveTransactionBannerSkippedDate(): void {
    saveBannerSkippedDate(StorageKey.TRANSACTION);
}

export function saveCardModalSkipped(): void {
    if (isStorageAvailable) {
        const prev = getBannerReminderData();
        const data = { isModalSkipped: true, ...prev };
        window.localStorage.setItem(storageKey, JSON.stringify(data));
    }
}

// enum StorageKey {
//     CARD = "lenco-card-reminder",
//     SPLIT = "lenco-split-reminder",
//     ACCOUNT = "lenco-account-reminder",
//     TRANSACTION = "lenco-transaction-reminder",
// }
export function canShowTransactionBanner(): boolean {
    const skippedData = getBannerReminderData(StorageKey.TRANSACTION);

    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    const hasTransaction = (store.getState().transactions.transactionList?.filter((_) => _.receiptUploaded).length || 0) > 1;

    if (hasTransaction || !isNigeria) {
        return false;
    }

    if (Object.keys(skippedData).length > 0) {
        const isBannerSkipped = skippedData.isBannerSkipped;
        if (isBannerSkipped) {
            const currentDate = moment().format("YYYY-MM-DD");
            const formattedStartDate = moment(isBannerSkipped, "YYYY-MM-DD");
            const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
            const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
            return !!(daysBetweenStart !== 0 && daysBetweenStart % 7 === 0);
        }
        return false;
    } else {
        return true;
    }
}

export function canShowAccountBanner(): boolean {
    const skippedData = getBannerReminderData(StorageKey.ACCOUNT);

    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    const hasSubAccount = (store.getState().init.main?.companyDetails.accounts.length || 0) > 1;
    const isTypeStarter = store.getState().init.main?.companyDetails?.company.isTypeStarter;

    if (hasSubAccount || !isNigeria || isTypeStarter) {
        return false;
    }

    if (Object.keys(skippedData).length > 0) {
        const isBannerSkipped = skippedData.isBannerSkipped;
        if (isBannerSkipped) {
            const currentDate = moment().format("YYYY-MM-DD");
            const formattedStartDate = moment(isBannerSkipped, "YYYY-MM-DD");
            const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
            const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
            return !!(daysBetweenStart !== 0 && daysBetweenStart % 7 === 0);
        }
        return true;
    } else {
        return true;
    }
}

export function canShowSplitBanner(): boolean {
    const skippedData = getBannerReminderData(StorageKey.SPLIT);

    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    const isTypeStarter = store.getState().init.main?.companyDetails?.company.isTypeStarter;
    const hasSplitAccount = store.getState().init.main?.companyDetails.accounts.some((_) => _.balanceInstructions.length > 1) || false;

    if (hasSplitAccount || !isNigeria || isTypeStarter) {
        return false;
    }

    if (Object.keys(skippedData).length > 0) {
        const isBannerSkipped = skippedData.isBannerSkipped;
        if (isBannerSkipped) {
            const currentDate = moment().format("YYYY-MM-DD");
            const formattedStartDate = moment(isBannerSkipped, "YYYY-MM-DD");
            const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
            const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
            return !!(daysBetweenStart !== 0 && daysBetweenStart % 7 === 0);
        }
        return true;
    } else {
        return true;
    }
}

export function canShowCardBanner(): boolean {
    const skippedData = getBannerReminderData();
    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;

    if (hasMadeRequest || !isNigeria) {
        return false;
    }
    if (Object.keys(skippedData).length > 0) {
        const isBannerSkipped = skippedData.isBannerSkipped;
        if (isBannerSkipped) {
            const currentDate = moment().format("YYYY-MM-DD");
            const formattedStartDate = moment(isBannerSkipped, "YYYY-MM-DD");
            const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
            const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
            return !!(daysBetweenStart !== 0 && daysBetweenStart % 7 === 0);
        }
        return true;
    } else {
        return true;
    }
}

export function canShowCardModal(): boolean {
    const skippedData = getBannerReminderData();
    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;
    if (hasMadeRequest || !isNigeria) {
        return false;
    }
    if (Object.keys(skippedData).length > 0) {
        const isModalSkipped = skippedData.isModalSkipped;
        return !isModalSkipped;
    } else {
        return true;
    }
}

export function canShowNewCard(): boolean {
    const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;
    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;

    if (hasMadeRequest || !isNigeria) {
        return false;
    }
    const currentDate = moment().format("YYYY-MM-DD");
    const formattedStartDate = moment("2023-11-13", "YYYY-MM-DD");
    const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");

    const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asMonths();
    return !!(daysBetweenStart < 3);
}

export function canShowNewTerminals(): boolean {
    const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
    if (!isNigeria) {
        return false;
    }
    const currentDate = moment().format("YYYY-MM-DD");
    const formattedStartDate = moment("2023-12-15", "YYYY-MM-DD");
    const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");

    const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asMonths();
    return !!(daysBetweenStart < 1);
}

import { CardStatus, CardWalletType } from "../../../../../models/card.constants";

import { ReactComponent as ActivateCardIcon } from "../../../../../assets/svg/Cards/ActivateCard.svg";
import { ReactComponent as CancelCardIcon } from "../../../../../assets/svg/Cards/Cancel.svg";
import CardMain from "../../../../../models/cardMain";
import { ReactComponent as FreezeIcon } from "../../../../../assets/svg/Cards/Freeze.svg";
import { IRootState } from "../../../../../redux/rootReducer";
import { ReactComponent as LineDropdownIcon } from "../../../../../assets/svg/Cards/HorizontalDropdownIcon.svg";
import NewDropdownHead from "../../../../../components/new-dropdown";
import NewDropdownItem from "../../../../../components/new-dropdown-item";
import { ReactComponent as ReassignCardIcon } from "../../../../../assets/svg/Cards/ReassignCard.svg";
import { ReactComponent as RequestDefaultIcon } from "../../../../../assets/svg/Cards/request-default-pin.svg";
import { ReactComponent as SecurityIcon } from "../../../../../assets/svg/Cards/SecurityIcon.svg";
import { ReactComponent as SendMoneyIcon } from "../../../../../assets/svg/Cards/SendMoney.svg";
import { ReactComponent as SetSpendingLimitIcon } from "../../../../../assets/svg/Cards/SetSpendingLimit.svg";
import { useSelector } from "react-redux";

interface Props {
    data: CardMain;
    isVertical?: boolean;
    isAssignedToUser: boolean;

    onSecurity: () => void;
    onSendMoney: () => void;
    onFreezeCard: () => void;
    onCancelCard: () => void;
    onUnfreezeCard: () => void;
    onActivateCard: () => void;
    onReassignCard: () => void;
    onSpendingLimit: () => void;
    onPaymentChannel: () => void;
    onRequestDefaultPIN: () => void;
}

function CardLineDropdown({
    data,
    isVertical = false,
    isAssignedToUser,

    onSecurity,
    onSendMoney,
    onFreezeCard,
    onCancelCard,
    onUnfreezeCard,
    onActivateCard,
    onReassignCard,
    onSpendingLimit,
    onPaymentChannel,
    onRequestDefaultPIN,
}: Props): JSX.Element {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

    return (
        <>
            <div data-type="card">
                <NewDropdownHead
                    content={
                        <div className="flex h-10 w-10 items-center justify-center rounded-md duration-150 hover:bg-grey-backdrop">
                            <LineDropdownIcon className={"fill-current text-current ease-in-out " + `${isVertical ? "rotate-90 transform" : ""}`} />
                        </div>
                    }
                    dataType="card"
                    buttonType="tertiary"
                    dropdownWidth="max"
                    dropdownPosition="right"
                    isNoArrow
                    clickAndClose
                    isTriggerLower
                >
                    <>
                        {data.card && data.card.status === CardStatus.FROZEN ? (
                            <NewDropdownItem size="lg" onClick={onUnfreezeCard} dataType="card" isLink>
                                <span className="text-xl" data-type="card">
                                    <FreezeIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Unfreeze Card
                                </div>
                            </NewDropdownItem>
                        ) : null}

                        {data.card && data.card.status === CardStatus.UNACTIVATED ? (
                            <NewDropdownItem size="lg" onClick={onActivateCard} isLink>
                                <span className="text-xl" data-type="card">
                                    <ActivateCardIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Activate Card
                                </div>
                            </NewDropdownItem>
                        ) : null}
                        {data.card &&
                        !data.card.linkedAccount &&
                        data.card.walletType === CardWalletType.INDEPENDENT_WALLET &&
                        data.card.isActive &&
                        accounts &&
                        accounts?.length < 2 ? (
                            <NewDropdownItem size="lg" onClick={onSendMoney} isLink>
                                <span className="text-xl" data-type="card">
                                    <SendMoneyIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Send Money
                                </div>
                            </NewDropdownItem>
                        ) : null}

                        {data.card &&
                        (data.card.status === CardStatus.ACTIVE ||
                            data.card.status === CardStatus.UNACTIVATED ||
                            data.card.status === CardStatus.DEACTIVATED ||
                            data.card.status === CardStatus.FROZEN) ? (
                            <NewDropdownItem size="lg" onClick={onSpendingLimit} isLink isReadOnly={data.card.status === CardStatus.DEACTIVATED}>
                                <span className="text-xl" data-type="card">
                                    <SetSpendingLimitIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Set Spending Limit
                                </div>
                            </NewDropdownItem>
                        ) : null}

                        {/* only admins can do this */}
                        {isAssignedToUser &&
                        data.card &&
                        (data.card.status === CardStatus.ACTIVE ||
                            data.card.status === CardStatus.UNACTIVATED ||
                            data.card.status === CardStatus.DEACTIVATED) ? (
                            <NewDropdownItem size="lg" onClick={onReassignCard} isLink isReadOnly={data.card.status === CardStatus.DEACTIVATED}>
                                <span className="text-xl" data-type="card">
                                    <ReassignCardIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Reassign Card
                                </div>
                            </NewDropdownItem>
                        ) : null}

                        {data.card &&
                            (data.card.status === CardStatus.ACTIVE ||
                                data.card.status === CardStatus.DEACTIVATED ||
                                data.card.status !== CardStatus.FROZEN) && (
                                <NewDropdownItem size="lg" onClick={onFreezeCard} isLink isReadOnly={data.card.status === CardStatus.DEACTIVATED}>
                                    <span className="text-xl" data-type="card">
                                        <FreezeIcon className="stroke-current" />
                                    </span>
                                    <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                        Freeze Card
                                    </div>
                                </NewDropdownItem>
                            )}

                        {data.card &&
                            (data.card.status === CardStatus.ACTIVE ||
                                data.card.status === CardStatus.UNACTIVATED ||
                                data.card.status === CardStatus.DEACTIVATED ||
                                data.card.status === CardStatus.FROZEN) && (
                                <NewDropdownItem size="lg" onClick={onPaymentChannel} isLink isReadOnly={data.card.status === CardStatus.DEACTIVATED}>
                                    <span className="text-xl" data-type="card">
                                        <ActivateCardIcon className="stroke-current" />
                                    </span>
                                    <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                        Set Payment Channels
                                    </div>
                                </NewDropdownItem>
                            )}

                        {data.card &&
                        !data.card.hasDoneExternalTransaction &&
                        (data.card.status === CardStatus.ACTIVE ||
                            data.card.status === CardStatus.UNACTIVATED ||
                            data.card.status === CardStatus.FROZEN) ? (
                            <NewDropdownItem size="lg" onClick={onRequestDefaultPIN} isLink>
                                <span className="text-xl" data-type="card">
                                    <SecurityIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Request Default PIN
                                </div>
                            </NewDropdownItem>
                        ) : null}

                        {data.card && (data.card.status === CardStatus.ACTIVE || data.card.status === CardStatus.FROZEN) && (
                            <NewDropdownItem size="lg" onClick={onSecurity} isLink>
                                <span className="text-xl" data-type="card">
                                    <RequestDefaultIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Security
                                </div>
                            </NewDropdownItem>
                        )}

                        {data.card &&
                            (data.card.status === CardStatus.ACTIVE ||
                                data.card.status === CardStatus.UNACTIVATED ||
                                data.card.status === CardStatus.FROZEN) && (
                                <NewDropdownItem size="lg" onClick={onCancelCard} color="red">
                                    <span className="text-xl" data-type="card">
                                        <CancelCardIcon className="stroke-current" />
                                    </span>
                                    <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                        Cancel Card
                                    </div>
                                </NewDropdownItem>
                            )}
                    </>
                </NewDropdownHead>
            </div>
        </>
    );
}

export default CardLineDropdown;

{
    /*

import { CardStatus, CardWalletType } from "../../../../../models/card.constants";
import { ReactComponent as ActivateCardIcon } from "../../../../../assets/svg/Cards/ActivateCard.svg";
import { ReactComponent as SecurityIcon } from "../../../../../assets/svg/Cards/SecurityIcon.svg";
import { ReactComponent as CancelCardIcon } from "../../../../../assets/svg/Cards/Cancel.svg";
import CardMain from "../../../../../models/cardMain";
import { ReactComponent as FreezeIcon } from "../../../../../assets/svg/Cards/Freeze.svg";
import { IRootState } from "../../../../../redux/rootReducer";
import { ReactComponent as LineDropdownIcon } from "../../../../../assets/svg/Cards/HorizontalDropdownIcon.svg";
import React from "react";
import { ReactComponent as ReassignCardIcon } from "../../../../../assets/svg/Cards/ReassignCard.svg";
import { ReactComponent as RequestDefaultIcon } from "../../../../../assets/svg/Cards/request-default-pin.svg";
import { ReactComponent as SendMoneyIcon } from "../../../../../assets/svg/Cards/SendMoney.svg";
import { ReactComponent as SetSpendingLimitIcon } from "../../../../../assets/svg/Cards/SetSpendingLimit.svg";
import { useSelector } from "react-redux";
import DropdownHead from "../../../../../components/dropdown/dropdown-components/dropdown-head";
import DropdownLink from "../../../../../components/dropdown/dropdown-components/dropdown-link";

interface Props {
    data: CardMain;
    isAssignedToUser: boolean;
    isVertical?: boolean;
    onActivateCard: () => void;
    onSpendingLimit: () => void;
    onReassignCard: () => void;
    onPaymentChannel: () => void;
    onCancelCard: () => void;
    onSendMoney: () => void;
    onFreezeCard: () => void;
    onUnfreezeCard: () => void;
    onRequestDefaultPIN: () => void;
    onSecurity: () => void;
}

function CardLineDropdown({
    data,
    isAssignedToUser,
    isVertical = false,
    onActivateCard,
    onSpendingLimit,
    onReassignCard,
    onPaymentChannel,
    onCancelCard,
    onSendMoney,
    onFreezeCard,
    onUnfreezeCard,
    onRequestDefaultPIN,
    onSecurity,
}: Props): JSX.Element {
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

    return (
        <>
            <div data-type="card">
                <DropdownHead
                    placeholder={
                        <LineDropdownIcon className={"fill-current text-current ease-in-out " + `${isVertical ? "rotate-90 transform" : ""}`} />
                    }
                    placement="right"
                    triggerLower
                    clickAndClose
                    icon
                >
                    {data.card && data.card.status === CardStatus.FROZEN ? (
                        <DropdownLink onClick={onUnfreezeCard} isLink>
                            <div className="flex items-center justify-start px-4" data-type="card">
                                <span className="text-xl" data-type="card">
                                    <FreezeIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Unfreeze Card
                                </div>
                            </div>
                        </DropdownLink>
                    ) : null}

                    {data.card && data.card.status === CardStatus.UNACTIVATED ? (
                        <DropdownLink onClick={onActivateCard} isLink>
                            <div className="flex items-center justify-start px-4" data-type="card">
                                <span className="text-xl" data-type="card">
                                    <ActivateCardIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Activate Card
                                </div>
                            </div>
                        </DropdownLink>
                    ) : null}
                    {data.card &&
                    !data.card.linkedAccount &&
                    data.card.walletType === CardWalletType.INDEPENDENT_WALLET &&
                    data.card.isActive &&
                    accounts &&
                    accounts?.length < 2 ? (
                        <DropdownLink onClick={onSendMoney} isLink>
                            <div className="flex items-center justify-start px-4" data-type="card">
                                <span className="text-xl" data-type="card">
                                    <SendMoneyIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Send Money
                                </div>
                            </div>
                        </DropdownLink>
                    ) : null}

                    {data.card &&
                    (data.card.status === CardStatus.ACTIVE ||
                        data.card.status === CardStatus.UNACTIVATED ||
                        data.card.status === CardStatus.DEACTIVATED ||
                        data.card.status === CardStatus.FROZEN) ? (
                        <DropdownLink onClick={onSpendingLimit} isLink isReadOnly={data.card.status === CardStatus.DEACTIVATED}>
                            <div className="flex items-center justify-start px-4" data-type="card">
                                <span className="text-xl" data-type="card">
                                    <SetSpendingLimitIcon className="stroke-current" />
                                </span>
                                <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
                                    Set Spending Limit
                                </div>
                            </div>
                        </DropdownLink>
                    ) : null}

                    {/* only admins can do this */
}
//                     {isAssignedToUser &&
//                     data.card &&
//                     (data.card.status === CardStatus.ACTIVE ||
//                         data.card.status === CardStatus.UNACTIVATED ||
//                         data.card.status === CardStatus.DEACTIVATED) ? (
//                         <DropdownLink onClick={onReassignCard} isLink noHover={data.card.status === CardStatus.DEACTIVATED}>
//                             <div className="flex items-center justify-start px-4" data-type="card">
//                                 <span className="text-xl" data-type="card">
//                                     <ReassignCardIcon className="stroke-current" />
//                                 </span>
//                                 <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
//                                     Reassign Card
//                                 </div>
//                             </div>
//                         </DropdownLink>
//                     ) : null}

//                     {data.card &&
//                         (data.card.status === CardStatus.ACTIVE ||
//                             data.card.status === CardStatus.DEACTIVATED ||
//                             data.card.status !== CardStatus.FROZEN) && (
//                             <DropdownLink onClick={onFreezeCard} isLink noHover={data.card.status === CardStatus.DEACTIVATED}>
//                                 <div className="flex items-center justify-start px-4" data-type="card">
//                                     <span className="text-xl" data-type="card">
//                                         <FreezeIcon className="stroke-current" />
//                                     </span>
//                                     <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
//                                         Freeze Card
//                                     </div>
//                                 </div>
//                             </DropdownLink>
//                         )}

//                     {data.card &&
//                         (data.card.status === CardStatus.ACTIVE ||
//                             data.card.status === CardStatus.UNACTIVATED ||
//                             data.card.status === CardStatus.DEACTIVATED ||
//                             data.card.status === CardStatus.FROZEN) && (
//                             <DropdownLink onClick={onPaymentChannel} isLink noHover={data.card.status === CardStatus.DEACTIVATED}>
//                                 <div className="flex items-center justify-start px-4" data-type="card">
//                                     <span className="text-xl" data-type="card">
//                                         <ActivateCardIcon className="stroke-current" />
//                                     </span>
//                                     <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
//                                         Set Payment Channels
//                                     </div>
//                                 </div>
//                             </DropdownLink>
//                         )}

//                     {data.card &&
//                     !data.card.hasDoneExternalTransaction &&
//                     (data.card.status === CardStatus.ACTIVE ||
//                         data.card.status === CardStatus.UNACTIVATED ||
//                         data.card.status === CardStatus.FROZEN) ? (
//                         <DropdownLink onClick={onRequestDefaultPIN} isLink>
//                             <div className="flex items-center justify-start px-4" data-type="card">
//                                 <span className="text-xl" data-type="card">
//                                     <RequestDefaultIcon className="stroke-current" />
//                                 </span>
//                                 <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
//                                     Request Default PIN
//                                 </div>
//                             </div>
//                         </DropdownLink>
//                     ) : null}

//                     {data.card && (data.card.status === CardStatus.ACTIVE || data.card.status === CardStatus.FROZEN) && (
//                         <DropdownLink onClick={onSecurity} isLink>
//                             <div className="flex items-center justify-start px-4" data-type="card">
//                                 <span className="text-xl" data-type="card">
//                                     <SecurityIcon className="stroke-current" />
//                                 </span>
//                                 <div className="ml-4 inline items-center justify-center text-sm" data-type="card">
//                                     Security
//                                 </div>
//                             </div>
//                         </DropdownLink>
//                     )}

//                     {data.card &&
//                         (data.card.status === CardStatus.ACTIVE ||
//                             data.card.status === CardStatus.UNACTIVATED ||
//                             data.card.status === CardStatus.FROZEN) && (
//                             <DropdownLink onClick={onCancelCard} redHover>
//
//                                     <span className="text-xl" data-type="card">
//                                         <CancelCardIcon className="stroke-current text-error" />
//                                     </span>
//                                     <div className="ml-4 inline items-center justify-center text-sm text-error" data-type="card">
//                                         Cancel Card
//                                     </div>
//                                 </div>
//                             </DropdownLink>
//                         )}
//                 </DropdownHead>
//             </div>
//         </>
//     );
// }

// export default CardLineDropdown;

import { FilterDateType, FilterDateTypes } from "../../../filter/filter.constant";
import { FilterOptionSelectType, FilterOptionSubList, TableFilterProps } from "../../table-filter.types";
import React, { useEffect, useRef, useState } from "react";
import { resetAllFilterData, resetFilter } from "../../../../redux/filter/filterSlice";

import ButtonComp from "../../../button/ButtonComp";
import CustomDatePickerModal from "../CustomDatePickerModal";
import DateTag from "../../../filter/Tags/DateTag";
import FilterRow from "../../../filter/Row/FilterRow";
import GenericFilterTag from "../../../filter/Tags/GenericTag";
import { GenericObject } from "../../../../helpers/types";
import GoogleIcon from "../../../google-icon";
import NewDropdownHead from "../../../new-dropdown";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { useDispatch } from "react-redux";

export default function TableFilter<T extends string | number | GenericObject, U extends FilterOptionSubList>(props: TableFilterProps<T, U>) {
    const dispatch = useDispatch();

    const domRef = useRef<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [activeRowIndex, setActiveRowIndex] = useState<number>(0);
    const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);

    const handleClearAll = () => {
        props.handleClearFilter();
        dispatch(resetFilter());
        setIsOpen(false);
        //one second later reset it
        setTimeout(() => dispatch(resetAllFilterData()), 1000);
    };

    const handleAdditionalCheck = (target: HTMLElement): boolean => {
        return !domRef.current?.contains(target);
    };

    const handleApplyFilter = () => {
        setIsOpen(false);
        props.handleApplyFilter && props.handleApplyFilter();
    };

    useEffect(() => {
        return () => {
            dispatch(resetAllFilterData());
        };
    }, []);

    return (
        <div className="flex flex-col md:flex-row md:justify-between" ref={domRef}>
            <CustomDatePickerModal
                toggle={() => setIsCustomDateOpen(false)}
                active={isCustomDateOpen}
                filterFunc={(_value) => {
                    const dateObj = props.options.find((_) => _.selectType === FilterOptionSelectType.DATE_OPTION);
                    dateObj && dateObj.onClick && dateObj.onClick(_value);
                }}
            />
            <div className="-mt-2 flex flex-col md:flex-row md:justify-between">
                {props.isFilterLeft && props.options.some((_) => !isNullOrUndefined(_.selectedValue)) ? (
                    <div className="flex h-fit w-full flex-row flex-wrap items-end justify-end pl-2">
                        {props.options.map((_, _index) =>
                            _.selectedValue ? (
                                <React.Fragment key={_index}>
                                    {_.selectType === FilterOptionSelectType.DATE_OPTION && typeof _.selectedValue === "object" ? (
                                        <DateTag
                                            endDate={(_.selectedValue as FilterDateType).date?.end || ""}
                                            startDate={(_.selectedValue as FilterDateType).date?.begin || ""}
                                            selectedFilterDate={(_.selectedValue as FilterDateType).name}
                                            handleCancelSelection={() => _.onCancel && _.onCancel()}
                                        />
                                    ) : (
                                        <GenericFilterTag
                                            title={_.text}
                                            values={Array.isArray(_.selectedValue) ? _.selectedValue : [_.selectedValue]}
                                            onCancel={() => _.onCancel && _.onCancel()}
                                            onIndividualCancel={_.onIndividualCancel}
                                            options={_.subList?.map((_userAccount) => ({
                                                text: _userAccount.text || "",
                                                value: _userAccount.value || "",
                                            }))}
                                        />
                                    )}
                                </React.Fragment>
                            ) : null
                        )}
                    </div>
                ) : null}

                <div className="pt-2">
                    <NewDropdownHead
                        content={
                            <div className="flex items-center justify-start space-x-2">
                                <span className="text-sm font-normal">{props.totalSelectedItems < 1 ? "No Filter" : "Filter"}</span>
                                <GoogleIcon icon="filter_list" className="-mr-1" />
                            </div>
                        }
                        size="md"
                        color="grey"
                        buttonType="secondary"
                        paddingSize="xs"
                        dropdownWidth="max"
                        isActive={isOpen}
                        handleOpen={() => setIsOpen(true)}
                        handleClose={() => setIsOpen(false)}
                        handleAdditionalCheck={handleAdditionalCheck}
                        isNoArrow
                        isFreeFormDropdown
                    >
                        <div
                            className={
                                "absolute -top-12 z-10 mt-[1px] " +
                                `${props.isFilterLeft ? "pr-1.5" : "pl-1.5"} ` +
                                `${props.totalSelectedItems > 0 ? `${props.isFilterLeft ? "right-0" : "left-20"}` : `${props.isFilterLeft ? "right-[0px]" : "left-[102px]"}`}`
                            }
                        >
                            <div className="w-64 rounded-lg bg-white pb-2.5 shadow-dropdown">
                                <div className="flex h-10 w-full flex-row items-center justify-between px-4">
                                    <p className="text-base font-medium text-black-secondary">Filter by</p>
                                    <ButtonComp
                                        buttonType="tertiary"
                                        color="blue"
                                        size="sm"
                                        disable={props.totalSelectedItems < 1}
                                        func={handleClearAll}
                                    >
                                        <span className="text-xs font-medium">Clear all</span>
                                    </ButtonComp>
                                </div>
                                {/* Filter Menu */}
                                <div className="w-full border-t border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                                    {props.options.map((_filter, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {_filter.selectType === FilterOptionSelectType.DATE_OPTION ? (
                                                    <FilterRow
                                                        date={
                                                            !isNullOrUndefined(_filter.value)
                                                                ? typeof _filter.value === "object" && "name" in _filter.value
                                                                    ? (_filter.value.name as FilterDateTypes)
                                                                    : (_filter.value as FilterDateTypes)
                                                                : undefined
                                                        }
                                                        icon={_filter.icon}
                                                        title={_filter.text}
                                                        isLeft={props.isFilterLeft}
                                                        isHover={activeRowIndex === index + 1}
                                                        onHover={() => setActiveRowIndex(index + 1)}
                                                        dataType={`${_filter.text.replace(" ", "-").toLocaleLowerCase()}` + "id"}
                                                        handleSetDate={_filter.onClick}
                                                        handleOpenDateModal={() => setIsCustomDateOpen(true)}
                                                        isDate
                                                    />
                                                ) : (
                                                    <FilterRow
                                                        icon={_filter.icon}
                                                        title={_filter.text}
                                                        options={_filter.subList?.map((_subList) => ({
                                                            text: _subList.text,
                                                            value: _subList.value as T,
                                                        }))}
                                                        isLeft={props.isFilterLeft}
                                                        isHover={activeRowIndex === index + 1}
                                                        isRadio={_filter.selectType === FilterOptionSelectType.RADIO_OPTION}
                                                        onHover={() => setActiveRowIndex(index + 1)}
                                                        dataType={`${_filter.text.replace(" ", "-").toLocaleLowerCase()}` + "id"}
                                                        filteredList={_filter.value}
                                                        handleFilterState={_filter.onClick}
                                                    />
                                                )}
                                            </React.Fragment>
                                        );
                                    })}

                                    {/* Apply Button */}
                                    <div
                                        className={`${props.totalSelectedItems > 0 ? "w-full justify-between" : "w-full justify-end"} flex items-center px-4 pt-2`}
                                    >
                                        {props.totalSelectedItems > 0 ? (
                                            <div className="text-xs text-black-tertiary">
                                                {props.totalSelectedItems} Filter{props.totalSelectedItems > 1 && "s"} Selected
                                            </div>
                                        ) : null}
                                        <ButtonComp
                                            size="sm"
                                            type="button"
                                            color="black"
                                            ripple="light"
                                            buttonType="primary"
                                            func={handleApplyFilter}
                                            disable={props.totalSelectedItems < 1}
                                            borderSmall
                                        >
                                            <span className="text-xs">Apply Filter</span>
                                        </ButtonComp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NewDropdownHead>
                </div>

                {!props.isFilterLeft ? (
                    <div className="flex h-fit w-full flex-row flex-wrap pl-2">
                        {props.options.map((_, _index) =>
                            _.selectedValue ? (
                                <React.Fragment key={_index}>
                                    {_.selectType === FilterOptionSelectType.DATE_OPTION && typeof _.selectedValue === "object" ? (
                                        <DateTag
                                            endDate={(_.selectedValue as FilterDateType).date?.end || ""}
                                            startDate={(_.selectedValue as FilterDateType).date?.begin || ""}
                                            selectedFilterDate={(_.selectedValue as FilterDateType).name}
                                            handleCancelSelection={() => _.onCancel && _.onCancel()}
                                        />
                                    ) : (
                                        <GenericFilterTag
                                            title={_.text}
                                            values={Array.isArray(_.selectedValue) ? _.selectedValue : [_.selectedValue]}
                                            onCancel={() => _.onCancel && _.onCancel()}
                                            onIndividualCancel={_.onIndividualCancel}
                                            options={_.subList?.map((_userAccount) => ({
                                                text: _userAccount.text || "",
                                                value: _userAccount.value || "",
                                            }))}
                                        />
                                    )}
                                </React.Fragment>
                            ) : null
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

import AvatarDetails from "../../../../../avatar-details";
import CustomerAccount from "../../../../../../models/customerAccount";
import GoogleIcon from "../../../../../google-icon";
import { Link } from "react-router-dom";
import { PaymentsType } from "../../../../../../modules/dashboard/payments-new/hooks/state/send-money/payments.constants";
import TooltipIcons from "../../../../../tooltip";
import getInitials from "../../../../../../helpers/get-initials";
import titleCase from "../../../../../../hooks/titleCase";

interface AccountsSearchCardProps {
    account: CustomerAccount;
    onClick: (e: React.MouseEvent) => void;
}

export function AccountsSearchCard({ account, onClick }: AccountsSearchCardProps): JSX.Element {
    return (
        <>
            <div className="flex flex-row items-center justify-between space-x-4 px-4 pb-2.5 pt-2.5" data-type="transaction">
                <div className="flex w-full items-center justify-start space-x-2" data-type="transaction">
                    <AvatarDetails
                        initials={getInitials(account.accountName)}
                        title={
                            <div className="flex w-full flex-row items-center justify-start space-x-2">
                                <span className="w-max whitespace-nowrap text-xs font-medium">{titleCase(account.accountName)}</span>
                                <div className="flex w-full items-center justify-start space-x-2 divide-x-1">
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {account.accountNumber}
                                    </p>
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap pl-2 text-xs capitalize text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {account.bankName}
                                    </p>
                                </div>
                            </div>
                        }
                        dataType="transaction"
                        size="xs"
                    />
                </div>
                <div className="pl-6" data-type="transaction">
                    <Link
                        to={{
                            pathname: "/payments/make/single",
                            search: `?to=${account.id}`,
                        }}
                        state={{
                            typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
                            to: account.id,
                        }}
                        onClick={onClick}
                        tabIndex={-1}
                        data-type="transaction"
                    >
                        <TooltipIcons icon={<GoogleIcon icon="send" size="xs" />} message="Send Money" placement="right" dataType="transaction" />
                    </Link>
                </div>
            </div>
        </>
    );
}

import {
    resetZambiaSingleTransferRecipient,
    setSelectedZambiaUserAccount,
    setZambiaSingleTransferOriginatingAccount,
} from "../../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../../models/userAccount";
import UserAccountMeta from "../../../../../../../../models/userAccountMeta";
import doesUserHaveAccessToAccount from "../../../../../../../../helpers/doesUserHaveAccessToAccount";

interface useSingleTransferPayFromInterface {
    accounts: UserAccount[] | undefined;
    selectedAccountId: string;
    currentUserAccountMeta: UserAccountMeta | null;

    handleSelectAccount: (_accountId: string) => void;
}

function useSingleTransferPayFrom(): useSingleTransferPayFromInterface {
    const dispatch = useDispatch();

    const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
    const originatingAccountId = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails.originatingAccountId);

    const [currentUserAccountMeta, setCurrentUserAccountMeta] = useState<UserAccountMeta | null>(null);

    useEffect(() => {
        if (!accounts) return;
        if (payFrom) {
            handleSelectAccount(payFrom);
        } else {
            const accessAccount = accounts.find((_) => doesUserHaveAccessToAccount(_.id));
            accessAccount && handleSelectAccount(accessAccount.id);
        }
    }, [payFrom, accounts]);

    const handleSelectAccount = useCallback((_accountId: string) => {
        const doesAccountExist = accounts?.some((el) => el.id === _accountId);
        if (doesAccountExist) {
            const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
            const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
            setCurrentUserAccountMeta(userAccountMeta || null);
            dispatch(setZambiaSingleTransferOriginatingAccount(_accountId));
            dispatch(setSelectedZambiaUserAccount(userAccount));
            if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetZambiaSingleTransferRecipient());
        }
    }, []);

    return {
        accounts,
        selectedAccountId: originatingAccountId,
        currentUserAccountMeta,
        handleSelectAccount,
    };
}

export default useSingleTransferPayFrom;

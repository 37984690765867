import { IRootState } from "../../../../../../../redux/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import ManagerDescription from "./accessDescription/ManagerDescription";
import ViewerDescription from "./accessDescription/ViewerDescription";
import TeamMemberAccessLevel from "./TeamMemberAccessLevel";
import NoAccessDescription from "./accessDescription/NoAccessDescription";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";

interface TeamMemberDetailsFeatureCardsProps {
    isSelf: boolean;
    isLoading?: boolean;
    featureMeta: UserAccountMeta;
    userAccountNames: Map<string, string>;
    updateAccountMeta: (featureMeta: UserAccountMeta) => void;
}

function TeamMemberDetailsFeatureCards({ featureMeta, updateAccountMeta }: TeamMemberDetailsFeatureCardsProps): JSX.Element {
    featureMeta;
    updateAccountMeta;

    const featureAccessLevels = useSelector((state: IRootState) => state.teamMemberSettings.featureAccessLevels);

    return (
        <>
            {featureMeta &&
                featureAccessLevels.map((feature, featureIndex) => (
                    <div key={feature.id} className="space-y-4 rounded-2xl border border-grey bg-white">
                        <div className="p-6">
                            <div className="flex h-fit w-full flex-col items-start justify-start border-grey-secondary sm:space-y-0 sm:border-none">
                                <div className="pb-4">
                                    <p className="text-sm text-black-secondary">Feature</p>
                                    <span className="text-sm font-bold capitalize text-black-secondary">{feature.name}</span>
                                </div>

                                <div className="pb-4">
                                    <p className="text-sm text-black-secondary">Access Level</p>
                                    <TeamMemberAccessLevel
                                        featureMeta={featureMeta}
                                        updateAccountMeta={updateAccountMeta}
                                        canUpdate={!featureMeta.isAdmin && featureIndex !== 2}
                                        featureIndex={featureIndex}
                                        id={feature.id}
                                    />
                                </div>

                                <div className="item-start flex h-full w-full flex-col justify-start border-b-0.2 border-grey-secondary sm:border-none">
                                    <p className="text-sm text-black-secondary">Description</p>
                                    {feature.access === 0 && <ManagerDescription name={feature.name} />}
                                    {feature.access === 1 && <ViewerDescription />}
                                    {feature.access === 2 && <NoAccessDescription />}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}

export default TeamMemberDetailsFeatureCards;

import { DataColumn, TableRecord } from "./Type/DataRow";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import ColumnAction from "./Element/ColumnAction";
import { ColumnInfoType } from "./Type/ColumnInfoType";
import { ReactComponent as EmptyX } from "./svg/EmptyX.svg";
import { FilterOptions } from "./table-filter.types";
import ImageAndText from "./Element/ImageAndText";
import LogoFromText from "./Element/LogoFromText";
import LogoSubTextFromText from "./Element/LogoSubTextFromText";
import LogoSubTextTrendFromText from "./Element/LogoSubTextTrendFromText";
import LogoTrendFromText from "./Element/LogoTrendFromText";
import MessageToasts from "../general/MessageToasts/MessageToasts";
import PendingApproval from "./Element/PendingApproval";
import SearchBar from "../search-bar";
import { TableColumnType } from "./Type/TableColumnType";
import TableFilter from "./components/TableFilter";
import { TableHead } from "./Type/HeadRow";
import TablePagination from "./components/TablePagination";
import TableSkeleton from "./components/TableSkeleton";
import Text from "./Element/Text";
import TextWithSubtext from "./Element/TextWithSubtext";
import { ToastType } from "../../helpers/app-constants";
import TransactionAmount from "./Element/TransactionAmount";
import TransactionAmountWithSubtext from "./Element/TransactionAmountWithSubtext";
import TransactionProgress from "./Element/TransactionProgress";
import TransactionStatus from "./Element/TransactionStatus";
import TrendLogo from "./Element/TrendLogo";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import stopEventPropagation from "./utils/stopEventPropagation";
import useDimension from "../../hooks/useDimension";

interface ITable {
    isFilterLeft?: boolean;
    heads: TableHead;
    rows: TableRecord;
    isLoading?: boolean;
    isMaxHeight?: boolean;
    isTableLoading?: boolean;
    noRecordText?: string | ReactNode;
    hideHeader?: boolean;
    hideBorder?: boolean;
    offset?: number;
    total?: number;
    dataType?: string;
    groupSize?: number;
    errorMessage?: string | null;
    paginateFunction?: (pageNumber: number, pageOffset: number) => void;
    isPaginateLoading?: boolean;
    bg_transparent?: boolean;
    rowSize?: "md" | "base";
    addFilter?: boolean;
    filter?: FilterOptions;
    filterOptions?: FilterOptions;
    handleSearch?: (_value: string) => void;
    handleClearFilter?: () => void;
    handleApplyFilter?: () => void;
    addSearch?: boolean;
    addExtraButton?: boolean;
    extraButton?: ReactNode;
    totalSelectedItems?: number;
    isSticky?: boolean;
}

function Table({
    heads,
    rows,
    offset,
    total,
    isSticky,
    groupSize,
    isFilterLeft,
    paginateFunction,
    isPaginateLoading,
    dataType,
    errorMessage,
    noRecordText = "You have no record",
    isLoading = false,
    isTableLoading = false,
    hideHeader = false,
    hideBorder = false,
    bg_transparent = false,
    rowSize = "base",
    // addFilter = false,
    filterOptions,
    filter,
    addSearch = false,
    handleSearch,
    totalSelectedItems = 0,
    handleClearFilter,
    handleApplyFilter,
    extraButton,
}: ITable) {
    const [search, setSearch] = useState("");
    const { width } = useDimension();

    const handleChangeSearchTerm = useCallback((_value: string) => {
        setSearch(_value);
        handleSearch && handleSearch(_value);
    }, []);

    const [sortedTableHead, setSortedTableHead] = useState<TableHead>([]);

    const getHeadColumnInfo = useCallback(
        (infoType: ColumnInfoType, dataColumn: DataColumn) => {
            if (infoType === ColumnInfoType.INDEX)
                return sortedTableHead.findIndex((_el) => _el.text?.toString().toLowerCase().includes(dataColumn.key?.toLowerCase()));
            if (infoType === ColumnInfoType.DATATYPE)
                return (
                    sortedTableHead?.find((_el) => _el.text?.toString().toLowerCase().includes(dataColumn.key?.toLowerCase()))?.headType ||
                    TableColumnType.TEXT
                );
            return TableColumnType.TEXT;
        },
        [sortedTableHead]
    );

    useEffect(() => {
        if (heads.length && heads[0].pos !== undefined)
            return setSortedTableHead(
                heads?.sort((a, b) => (a?.pos || 1) - (b?.pos || 1))?.map((_el) => ({ ..._el, headType: _el.headType || TableColumnType.TEXT }))
            );
        return setSortedTableHead(heads?.map((_el) => ({ ..._el, headType: _el.headType || TableColumnType.TEXT })));
    }, [heads]);

    return (
        <>
            {(filter || filterOptions || addSearch) && (
                <div className="flex w-full justify-between space-x-4 pb-10 sm:space-x-0">
                    {isFilterLeft && extraButton ? extraButton : null}
                    {(filter || filterOptions) && handleClearFilter && !isNullOrUndefined(totalSelectedItems) && (
                        <TableFilter
                            options={filter?.filterOptions || filterOptions?.filterOptions || []}
                            isFilterLeft={isFilterLeft}
                            handleApplyFilter={filter?.handleApplyFilter || handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            totalSelectedItems={totalSelectedItems}
                        />
                    )}
                    {addSearch && handleSearch && (
                        <div className="relative flex w-full max-w-xs items-center justify-start" tabIndex={-1}>
                            <SearchBar size="xl" placeholder="Search" value={search} onChange={handleChangeSearchTerm} withWhitBg />
                        </div>
                    )}
                    {!isFilterLeft && extraButton ? extraButton : null}
                </div>
            )}

            <div className="relative flex h-full w-full flex-1 flex-shrink flex-grow border-0">
                <div className={`relative flex h-auto max-h-full w-full flex-1 rounded-lg border-0 ` + `${!isSticky ? "overflow-hidden" : ""}`}>
                    {isTableLoading && (
                        <div className="absolute left-0 top-12 z-50 w-full">
                            <BarLoader color="#5466F9" className="!w-full" />
                        </div>
                    )}

                    {/* <div className="w-full max-w-screen overflow-x-auto md:hidden"> */}
                    {/* <div
                        className={
                            `h-max max-h-full w-full rounded-lg ${!bg_transparent && "bg-white"} border-grey ${!hideBorder && "border-0.2"}` +
                            ` ${rows?.length > 5 && !isSticky && "overflow-y-auto"}`
                        }
                    > */}
                    {/* <div
                        className={
                            `h-max max-h-full w-full max-w-screen overflow-x-auto rounded-lg ${!bg_transparent && "bg-white"}
                            border-grey ${!hideBorder && "border-0.2"}` +
                            ` ${rows?.length > 5 && !isSticky && "overflow-y-auto"}`
                        }
                    > */}
                    <div className={"absolute left-0 top-0 h-full w-full md:relative " + `${width < 767 ? "overflow-scroll" : ""}`}>
                        <div
                            className={
                                `h-max max-h-full w-full min-w-[720px] overflow-x-auto rounded-lg md:mx-auto md:min-w-[unset] md:overflow-x-visible ${!bg_transparent && "bg-white"} border-grey ${!hideBorder && "border-0.2"}` +
                                ` ${rows?.length > 5 && !isSticky && "overflow-y-auto"}`
                            }
                        >
                            {!errorMessage && (
                                <table className="relative min-w-full table-fixed border-0 lg:w-full">
                                    {/* <table className="relative w-full table-fixed border-0"> */}
                                    {!hideHeader && (
                                        <>
                                            <thead className="sticky z-10 h-12 w-full overflow-x-scroll text-left text-sm text-black">
                                                <tr className="relative w-full">
                                                    {sortedTableHead?.map((head, idx) => (
                                                        <th
                                                            key={idx}
                                                            className={
                                                                `sticky overflow-hidden text-ellipsis whitespace-nowrap text-nowrap border-b-0.2 border-t-0 border-black-quin ${!bg_transparent && "bg-grey-tertiary"} px-6 py-3 font-medium ` +
                                                                `${isSticky ? "top-0" : ""}`
                                                            }
                                                            // style={{
                                                            //     width: head.headType === TableColumnType.ACTION ? "80px" : head?.width || "1fr",
                                                            // }} // Ensures each column takes up equal space
                                                            style={{
                                                                width:
                                                                    head.headType === TableColumnType.ACTION
                                                                        ? typeof head.text === "string" && head.text.toLocaleLowerCase() === "action"
                                                                            ? "80px"
                                                                            : head?.width || "1fr"
                                                                        : head?.width || "1fr",
                                                            }} // Ensures each column takes up equal space
                                                            scope="col"
                                                        >
                                                            {head.headType === TableColumnType.ACTION
                                                                ? typeof head.text === "string"
                                                                    ? head.text.toLocaleLowerCase() === "action"
                                                                        ? ""
                                                                        : head.text
                                                                    : head.text
                                                                : head.text}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            {/* table masking to be done */}
                                            {/* <thead className="absolute left-0 top-0 z-10 h-12 w-full
overflow-x-scroll text-left text-sm text-black">
                                            <tr className="relative flex h-12 w-full">
                                                {sortedTableHead?.map((head, idx) => (
                                                    <th
                                                        key={idx}
                                                        className={
                                                            `sticky overflow-hidden text-ellipsis whitespace-nowrap text-nowrap
                                                             border-b-0.2 border-t-0 border-black-quin ${!bg_transparent && "bg-pink-100"}
                                                             px-6 py-3 font-medium ` +
                                                            `${isSticky ? "top-0" : ""}`
                                                        }
                                                        style={{ width: head.headType === TableColumnType.ACTION ? "80px" : head?.width || "1fr" }}
                                                         // Ensures each column takes up equal space
                                                        scope="col"
                                                    >
                                                        {head.headType !== TableColumnType.ACTION && head.text}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead> */}
                                        </>
                                    )}

                                    <tbody className={`${!bg_transparent && "bg-white"} w-full`}>
                                        {!isLoading
                                            ? rows?.map((row, idy) => (
                                                  <tr
                                                      onClick={row.onRecordClick}
                                                      key={idy}
                                                      className={`relative w-full cursor-pointer whitespace-nowrap text-sm hover:bg-grey-backdrop`}
                                                      data-type={dataType}
                                                  >
                                                      {row.record
                                                          ?.sort((a, b) => {
                                                              return (
                                                                  getHeadColumnInfo(ColumnInfoType.INDEX, a) -
                                                                  getHeadColumnInfo(ColumnInfoType.INDEX, b)
                                                              );
                                                          })
                                                          ?.slice(0, sortedTableHead.length)
                                                          ?.map((recordItem, idx) => {
                                                              const columnInfoType = getHeadColumnInfo(ColumnInfoType.DATATYPE, recordItem);
                                                              return (
                                                                  <td
                                                                      data-type={dataType}
                                                                      onClick={
                                                                          columnInfoType === (TableColumnType.PENDING_APPROVAL as number) ||
                                                                          columnInfoType === (TableColumnType.ACTION as number)
                                                                              ? stopEventPropagation
                                                                              : undefined
                                                                      }
                                                                      key={idx}
                                                                      className={
                                                                          `h-full w-full min-w-10 ${
                                                                              columnInfoType !== (TableColumnType.ACTION as number) &&
                                                                              "overflow-hidden"
                                                                          } text-ellipsis whitespace-nowrap ${
                                                                              recordItem.leftAlign
                                                                                  ? "text-start"
                                                                                  : recordItem.rightAlign
                                                                                    ? "pr-0 text-end"
                                                                                    : "px-6 xl:pr-10 2xl:pr-16"
                                                                          } ${
                                                                              rowSize === "md" ? "py-2" : "py-4"
                                                                          } font-normal text-black-secondary xl:max-w-xs ${idx + 1 === heads.length && ""} ` +
                                                                          `${rows.length !== idy + 1 ? "border-b border-grey-secondary" : ""}`
                                                                      }
                                                                  >
                                                                      {columnInfoType === (TableColumnType.PROGRESS as number) && (
                                                                          <TransactionProgress dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.PENDING_APPROVAL as number) && (
                                                                          <PendingApproval dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.TEXT as number) && (
                                                                          <Text dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.TEXT_WITH_SUBTEXT as number) && (
                                                                          <TextWithSubtext dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.STATUS as number) && (
                                                                          <TransactionStatus dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.AMOUNT as number) && (
                                                                          <TransactionAmount dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.AMOUNT_WITH_SUBTEXT as number) && (
                                                                          <TransactionAmountWithSubtext dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.TREND_LOGO as number) && (
                                                                          <TrendLogo dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.IMG_AND_TEXT as number) && (
                                                                          <ImageAndText dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.LOGO_FROM_TEXT as number) && (
                                                                          <LogoFromText dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.LOGO_AND_TREND_FROM_TEXT as number) && (
                                                                          <LogoTrendFromText dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType ===
                                                                          (TableColumnType.LOGO_TREND_SUBTEXT_FROM_TEXT as number) && (
                                                                          <LogoSubTextTrendFromText dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.LOGO_SUBTEXT_FROM_TEXT as number) && (
                                                                          <LogoSubTextFromText dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.ACTION as number) && (
                                                                          <ColumnAction dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                      {columnInfoType === (TableColumnType.IMG_AND_TEXT as number) && (
                                                                          <ColumnAction dataType={dataType} dataColumn={recordItem} />
                                                                      )}
                                                                  </td>
                                                              );
                                                          })}
                                                  </tr>
                                              ))
                                            : null}
                                        {(isPaginateLoading || isLoading) && <TableSkeleton totalColumns={heads.length} />}
                                    </tbody>
                                </table>
                            )}
                            {!isPaginateLoading && !isLoading && !rows.length && (
                                <div
                                    className={`flex min-h-40 w-full flex-col items-center justify-center space-y-2 ${rowSize === "base" ? "py-16" : "py-8"} text-center text-sm text-black-secondary`}
                                >
                                    <EmptyX className="h-16 w-16" />
                                    <span className="text-black-tertiary">{noRecordText}</span>
                                </div>
                            )}
                            {errorMessage && (
                                <div className="flex h-full w-full items-center justify-center">
                                    <div className="w-max">
                                        <MessageToasts toastMessage={errorMessage || ""} toastType={ToastType.ERROR} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <TablePagination
                            offset={offset || 0}
                            total={total || 0}
                            groupSize={groupSize || 0}
                            onSelect={(page, _offSet) => paginateFunction?.(page, _offSet)}
                            isLoading={!!isPaginateLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Table;

interface Props {
    icon: string;
    size?: "xs" | "sm" | "md" | "lg";
    dataType?: string;
    isFilled?: boolean;
    className?: string;
    isOutlined?: boolean;
}
function GoogleIcon(props: Props) {
    const { size = "md" } = props;
    return (
        <div>
            <div
                className={
                    "flex select-none items-center justify-center overflow-hidden leading-[100%]" +
                    ` ${size === "xs" ? "[&>span]:text-base" : ""}` +
                    ` ${size === "sm" ? "[&>span]:text-lg" : ""}` +
                    ` ${size === "md" ? "[&>span]:text-xl" : ""}` +
                    ` ${size === "lg" ? "max-w-[24px] [&>span]:text-2xl" : " max-w-[20px]"}`
                }
                data-type={props.dataType}
            >
                <span
                    className={
                        `!leading-[100%] ${props.isOutlined ? "material-symbols-outlined" : "material-symbols-rounded"}` +
                        ` ${props.isFilled ? "icon-fill" : ""}` +
                        ` ${props.className}`
                    }
                    data-type={props.dataType}
                >
                    {props.icon}
                </span>
            </div>
        </div>
    );
}

export default GoogleIcon;

import React from "react";
interface Props {
    children: React.ReactNode;
    dataType?: string | null;
}

function ModalFooter(props: Props): JSX.Element {
    return (
        <>
            <div
                className="flex h-fit w-full items-center justify-center gap-4 pt-6 sm:flex-row sm:justify-end"
                data-type={props.dataType && props.dataType}
            >
                {props.children}
            </div>
        </>
    );
}

export default ModalFooter;

import { formatDate, formatTime } from "../../../../../../utils/formatDate";

import AvatarDetails from "../../../../../avatar-details";
import Money from "../../../../../money";
import Transaction from "../../../../../../models/transaction";
import getInitials from "../../../../../../helpers/get-initials";

interface TransactionsSearchProps {
    index: number;
    transaction: Transaction;
    handleShowTransactionDetail: (transaction: Transaction) => void;
}

function TransactionsSearch({ index, transaction, handleShowTransactionDetail }: TransactionsSearchProps): JSX.Element {
    return (
        <div key={index} onClick={() => handleShowTransactionDetail(transaction)} data-type="transaction" className="w-full">
            <div
                className="flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
                data-type="transaction"
            >
                <div className="flex w-full items-center justify-start space-x-2" data-type="transaction">
                    <AvatarDetails
                        initials={getInitials(transaction.source || "")}
                        icon={transaction.isCredit() ? "inflow" : "outflow"}
                        title={
                            <div className="flex w-full flex-row items-center justify-start space-x-2">
                                <div className="relative h-full max-w-[10rem]">
                                    <span className="absolute left-0 top-1/2 w-full max-w-full -translate-y-1/2 transform truncate text-xs font-medium">
                                        {transaction.source || ""}
                                    </span>
                                    <span className="pointer-events-none whitespace-nowrap text-xs font-medium opacity-0">
                                        {transaction.source || ""}
                                    </span>
                                </div>
                                {/* <div className="w-fit max-w-[10rem] truncate">
                                    <span className="text-xs font-medium">{transaction.source || ""}</span>
                                    <span className="text-xs font-medium">{transaction.source || ""}</span>
                                </div> */}
                                <div className="flex w-full items-center justify-start space-x-2 divide-x-1">
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {transaction.createdOn && formatDate(transaction.createdOn)}
                                    </p>
                                    <p
                                        className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap pl-2 text-xs text-black-tertiary"
                                        data-type="transaction"
                                    >
                                        {transaction.createdOn && formatTime(transaction.createdOn)}
                                    </p>
                                </div>
                            </div>
                        }
                        dataType="transaction"
                        size="xs"
                    />
                </div>
                <div className="pl-6" data-type="transaction">
                    <Money
                        amount={transaction.amount}
                        currency={transaction.currency}
                        positive={transaction.isCredit()}
                        negative={transaction.isDebit()}
                        deleted={transaction.isFailed}
                        className={`text-sm ${
                            transaction.isDeclined || transaction.isProcessing
                                ? "text-warning"
                                : `${transaction.isCredit() && transaction.isSuccess ? "text-success" : "text-black-secondary"} `
                        }`}
                        dataType="transaction"
                    />
                </div>
            </div>
        </div>
    );
}

export default TransactionsSearch;

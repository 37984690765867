import React from "react";
import useDimension from "../../../../../../../../hooks/useDimension";

interface IProps {
    name: string;
}

function ManagerDescription({ name }: IProps): JSX.Element {
    const deviceWidth = useDimension().width;

    return (
        <div className="item-start flex w-full flex-col justify-start space-y-2">
            <div className="relative flex w-[260px] flex-col items-start justify-center space-y-0 lg:space-y-2">
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">Full Access</div>
                </div>
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>
                    <div className="whitespace-nowrap text-xs text-black-secondary">
                        {name === "POS Terminal"
                            ? "Can request POS Terminal"
                            : name === "Cards"
                              ? "Can create and assign cards to other team members"
                              : "Can request SoundBox device"}
                    </div>
                </div>
                <div className="relative inline-flex items-center space-x-2">
                    <span className="material-symbols-outlined text-base text-blue">check</span>

                    {name === "POS Terminal" ? (
                        <p className="whitespace-nowrap text-xs text-black-secondary">
                            Can view and manage POS {deviceWidth < 640 && <br />} Terminal devices
                        </p>
                    ) : name === "Cards" ? (
                        <p className="whitespace-nowrap text-xs text-black-secondary">Can view and manage corporate cards </p>
                    ) : (
                        <p className="whitespace-nowrap text-xs text-black-secondary">Can view and manage SoundBox devices</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManagerDescription;

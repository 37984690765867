import React from "react";
import SkeletonElement from "../../../../../../../components/skeleton/SkeletonElement";

function AccountInsightSkeleton() {
    return (
        <>
            <div className="relative flex h-full max-h-96 min-h-96 flex-col space-y-2 overflow-hidden rounded-xl bg-white px-8 py-5 text-base shadow">
                <div className="mb-6 flex h-20 w-full items-start justify-between">
                    <div className="w-full space-y-6">
                        <div className="w-full space-y-4">
                            <div className="relative pt-1">
                                <div className="absolute right-2 flex w-full flex-col items-end justify-end">
                                    <div className="mt-1 flex w-35% items-end justify-end">
                                        <SkeletonElement title />
                                    </div>
                                </div>
                                <div className="h-6 w-44">
                                    <SkeletonElement box />
                                </div>
                            </div>

                            <div className="relative flex w-lg items-center justify-start">
                                <div className="flex flex-row items-end">
                                    <div className="mt-2 h-4 w-20">
                                        <SkeletonElement box />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex h-full max-h-72 w-full items-center justify-center">
                    <SkeletonElement box />
                </div>
            </div>
        </>
    );
}

export default AccountInsightSkeleton;

import { ActivateCardFormDTO, CardSectionEnum, ICardRequestStatusUpdate } from "../../../Services/cardsApi.types";
import React, { useCallback } from "react";
import {
    addToActiveOpenedCardActivationForms,
    removeFromActiveOpenedCardActivationForms,
    setActiveCardTraySections,
} from "../../../../../../redux/cards/slice/cardsSlice";
import { useDispatch, useSelector } from "react-redux";

import ActivateCard from "./ActivateCardForm";
import CardRequestStatus from "./CardRequestStatus";
import { IRootState } from "../../../../../../redux/rootReducer";
import useCardTray from "../../../Hooks/State/useCardTray";

function CardRequestStatusUpdate({ data, isActivateCardLoading, isSelectedCardDetailsOpen, onActivateCard }: ICardRequestStatusUpdate): JSX.Element {
    const dispatch = useDispatch();

    const { onOpenDefaultCardSection } = useCardTray();

    const { activeOpenedCardActivationForm } = useSelector((state: IRootState) => state.cards);

    const isActivating = activeOpenedCardActivationForm?.includes(data?.createRequest?.id || "");

    const handleOpenActivationForm = useCallback((cardRequestId: string) => {
        dispatch(setActiveCardTraySections([CardSectionEnum.CREDIT_CARD_SECTION, CardSectionEnum.CARD_REQUEST_STATUS_UPDATE_SECTION]));
        dispatch(addToActiveOpenedCardActivationForms(cardRequestId));
    }, []);

    const handleCancelActivationForm = useCallback((cardRequestId: string) => {
        onOpenDefaultCardSection();
        dispatch(removeFromActiveOpenedCardActivationForms(cardRequestId));
    }, []);

    const handleCardActivation = useCallback(
        (input: ActivateCardFormDTO) => {
            if (data) return onActivateCard({ createRequestId: data.createRequest.id, ...input });
        },
        [data, onActivateCard]
    );

    return (
        <>
            <div
                className={"flex w-full justify-center pb-6 pt-6 " + `${!isActivating ? "border-b border-grey-secondary" : ""}`}
                data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}
            >
                {data && data.createRequest ? (
                    !isActivating ? (
                        <CardRequestStatus
                            walletType={data.createRequest.walletType}
                            deliveryPhone={data.createRequest.deliveryPhone || ""}
                            deliveryAddress={data.createRequest.deliveryAddress || ""}
                            cardRequestStatus={data.createRequest.status}
                            handleActivateCard={() => handleOpenActivationForm(data.createRequest.id)}
                        />
                    ) : (
                        <ActivateCard
                            onCancel={() => handleCancelActivationForm(data?.createRequest?.id)}
                            onCardActivation={handleCardActivation}
                            isActivateCardLoading={isActivateCardLoading}
                        />
                    )
                ) : null}
            </div>
        </>
    );
}
export default CardRequestStatusUpdate;

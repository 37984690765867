import AllTerminalsSummary from "../../Elements/Card/TerminalSummary/AllTerminalsSummary";
import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../components/Table";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import Terminal from "../../../../../models/terminal";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { setAllTerminalStageToSpecificTerminal } from "../../../../../redux/terminals/slice/terminalsSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCallback } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import useTerminals from "../../Hooks/State/useTerminals";

const allTerminalHeader: TableHead = [
    { text: "Terminal Name", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "Settlement Account", pos: 2, headType: TableColumnType.TEXT },
    { text: "Transaction Volume", pos: 3, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
];

function AllTerminals(): JSX.Element {
    const dispatch = useDispatch();
    const terminals = useAppSelector((state) => state.terminals.terminals);
    const terminalListTotal = useAppSelector((state) => state.terminals.terminalListTotal);
    const isPaginationLoading = useAppSelector((state) => state.terminals.isTerminalPaginationLoading);
    const terminalListGroupSize = useAppSelector((state) => state.terminals.terminalListGroupSize);
    const terminalPaginationOffset = useAppSelector((state) => state.terminals.terminalPaginationOffset);

    const { handleTerminalList, isTerminalListLoading } = useTerminals();

    const onSelectTerminalRow = useCallback((selectedTerminal: Terminal) => {
        dispatch(setAllTerminalStageToSpecificTerminal(selectedTerminal));
    }, []);

    const debounceTerminalList = useCallback(useDebounce(handleTerminalList, 1000), []);
    const handleSearch = useCallback((_value: string) => {
        void debounceTerminalList({ query: _value });
    }, []);

    return (
        <>
            <AllTerminalsSummary />
            <div className="relative h-full w-90% min-w-full max-w-screen-2xs sm:w-max lg:h-auto xs:max-w-screen-xs">
                <div className="h-full w-full max-w-screen lg:h-auto">
                    <div className="mx-auto h-full w-screen lg:h-auto lg:w-fit">
                        <Table
                            isSticky
                            addSearch
                            handleSearch={handleSearch}
                            heads={allTerminalHeader}
                            isPaginateLoading={isPaginationLoading}
                            groupSize={terminalListGroupSize}
                            offset={terminalPaginationOffset}
                            total={terminalListTotal}
                            isLoading={!!isTerminalListLoading}
                            rows={terminals?.map((terminal) => ({
                                onRecordClick: () => onSelectTerminalRow(terminal),
                                record: [
                                    {
                                        key: "Terminal Name",
                                        text: terminal.name || "-",
                                        subText: terminal.serialNumber,
                                        subTextType: SubTextType.STRING,
                                    },
                                    {
                                        key: "Settlement Account",
                                        text: terminal.settlementAccount?.accountName,
                                    },
                                    {
                                        key: "Transaction Volume",
                                        text: terminal?.stats?.total?.volume || "",
                                        textType: TextType.BALANCE,
                                    },
                                    { key: "Status", text: terminal.status, textType: terminal.getTableStatusType() },
                                ],
                            }))}
                            paginateFunction={(_, pageOffset) => handleTerminalList({ offset: pageOffset })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllTerminals;

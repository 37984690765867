import React, { useEffect, useState } from "react";
import { setFeatureAccessLevels, setSelectedMember } from "../../../../../../../redux/settings/teamMembers/slice/teamMembersSettingsSlice";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../../../redux/rootReducer";
import TeamMemberDetailsFeatureCards from "./TeamMemberDetailsFeatureCards";
import TeamMemberDetailsFeatureRow from "./TeamMemberDetailsFeatureRow";
import { TeamMemberItem } from "../../../../Services/team-members/team-members.types";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import TeamMembersCardContainer from "../TeamMembersCardsContainer";
import useTeamMemberAccessLevelRole from "../../../../Services/team-members/hooks/useTeamMemberAccessLevelRole";

function TeamMemberFeatureDetails(props: {
    selectedTeamMember: TeamMemberItem | null;
    updateAccountMeta: (accountMeta: UserAccountMeta) => void;
    removeAccountMetaFromTeamMember: { (accountMetaToRemove: UserAccountMeta): void; (accountMetaToRemove: UserAccountMeta): void };
    userAccountNames: Map<string, string>;
    isSelf: boolean;
}): JSX.Element {
    const selectedMember = useSelector((state: IRootState) => state.teamMemberSettings.selectedMember);
    const featureAccessLevelsArray = useSelector((state: IRootState) => state.teamMemberSettings.featureAccessLevelsArray);

    const [accessLevels, setAccessLevels] = useState<{ id: number; name: string; access: number }[]>([]);
    const [featureDetails, setFeatureDetails] = useState<UserAccountMeta | undefined>(undefined);

    const { handleDefaultFetchAccessLevels } = useTeamMemberAccessLevelRole(props.updateAccountMeta);

    const dispatch = useDispatch();

    useEffect(() => {
        async function handleDefault() {
            await handleDefaultFetchAccessLevels();
        }
        handleDefault();
    }, []);

    useEffect(() => {
        dispatch(setSelectedMember(props.selectedTeamMember));
    }, [props.selectedTeamMember]);

    useEffect(() => {
        const data = featureAccessLevelsArray.find((_data) => selectedMember?.id === _data.memberId);
        setAccessLevels([
            {
                id: 0,
                name: "POS Terminal",
                access: data ? data.pos : 2,
            },
            {
                id: 1,
                name: "Cards",
                access: data ? data.cards : 2,
            },
            {
                id: 2,
                name: "Soundbox",
                access: selectedMember?.teamMember.isAdmin ? 0 : 2,
            },
        ]);
    }, [selectedMember, featureAccessLevelsArray]);

    useEffect(() => {
        dispatch(setFeatureAccessLevels(accessLevels));
    }, [accessLevels]);

    useEffect(() => {
        props.selectedTeamMember && setFeatureDetails(props.selectedTeamMember.accountsMeta[0]);
    }, [props.selectedTeamMember]);

    return (
        <div className="max-h-fit-available mt-5 flex h-full w-full flex-col items-start justify-start">
            <div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
                <div className="relative flex h-full w-full flex-1">
                    {/* <div className="absolute flex h-full max-h-full w-full flex-1 overflow-auto"> */}
                    <div className="flex h-full max-h-full w-full flex-1">
                        <div className="left-0 top-0 h-fit w-full rounded-custom border border-grey">
                            <table className="hidden w-full xl:table">
                                <thead className="relative z-10 overflow-x-scroll rounded-bl-2xl border-b border-grey-secondary text-left text-sm font-medium text-black-secondary">
                                    <tr className="relative">
                                        <th className="overflow-ellipsis whitespace-nowrap p-3" scope="col">
                                            Feature
                                        </th>
                                        <th className="whitespace-nowrap px-4 pr-4 text-left" scope="col">
                                            Access Level
                                        </th>
                                        <th className="whitespace-nowrap px-4 text-left" scope="col">
                                            Description
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {featureDetails && props.selectedTeamMember && (
                                        <TeamMemberDetailsFeatureRow
                                            key={featureDetails.userAccountId}
                                            featureMeta={featureDetails}
                                            updateAccountMeta={props.updateAccountMeta}
                                            userAccountNames={props.userAccountNames}
                                            isSelf={props.isSelf}
                                        />
                                    )}
                                </tbody>
                            </table>
                            {props.selectedTeamMember && (
                                <div className="block w-full py-4 xl:hidden">
                                    <TeamMembersCardContainer>
                                        {featureDetails && props.selectedTeamMember && (
                                            <TeamMemberDetailsFeatureCards
                                                key={featureDetails.userAccountId}
                                                featureMeta={featureDetails}
                                                updateAccountMeta={props.updateAccountMeta}
                                                userAccountNames={props.userAccountNames}
                                                isSelf={props.isSelf}
                                            />
                                        )}
                                    </TeamMembersCardContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMemberFeatureDetails;

import { formatDate, formatTime } from "../../../../../../../utils/formatDate";

import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardTime({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            {transaction.singleDatetime && (
                <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                    <p className="text-sm text-black-tertiary" data-type={dataType}>
                        Time
                    </p>
                    <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                        {formatTime(transaction.singleDatetime)} {formatDate(transaction.singleDatetime)}
                    </p>
                </div>
            )}
        </>
    );
}

export default TransactionDetailCardTime;

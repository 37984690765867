import { IRootState } from "../../../../../../../redux/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import ManagerDescription from "./accessDescription/ManagerDescription";
import ViewerDescription from "./accessDescription/ViewerDescription";
import NoAccessDescription from "./accessDescription/NoAccessDescription";
import TeamMemberAccessLevel from "./TeamMemberAccessLevel";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";

function SectionTitle(props: { title: string; className?: string }): JSX.Element {
    return <h1 className={`w-full text-xs text-black sm:hidden ${props.className || ""}`}>{props.title}:</h1>;
}

interface TeamMemberDetailsFeatureRowProps {
    isSelf: boolean;
    isLoading?: boolean;
    featureMeta: UserAccountMeta;
    userAccountNames: Map<string, string>;
    updateAccountMeta: (featureMeta: UserAccountMeta) => void;
}

function TeamMemberDetailsFeatureRow({ featureMeta, updateAccountMeta }: TeamMemberDetailsFeatureRowProps): JSX.Element {
    featureMeta;
    updateAccountMeta;

    const featureAccessLevels = useSelector((state: IRootState) => state.teamMemberSettings.featureAccessLevels);

    return (
        <>
            {featureMeta &&
                featureAccessLevels
                    .filter((feature, index) => index <= 1)
                    .map((feature, featureIndex) => (
                        <tr key={feature.id} className="border-b border-grey-secondary">
                            <td className="px-4 align-baseline">
                                <div className="flex h-full w-full flex-grow flex-col items-start justify-start space-y-2 border-grey-secondary pb-2 sm:space-y-0 sm:border-none">
                                    <SectionTitle title="Feature" />
                                    <div className="flex w-full flex-col items-stretch justify-between">
                                        <span className="text-sm capitalize text-black-secondary">{feature.name}</span>
                                    </div>
                                </div>
                            </td>

                            <td className="px-4 align-baseline">
                                <div className="flex h-full w-full flex-col space-y-2 border-b-0.2 border-grey-secondary pb-2 pt-4 sm:space-y-0 sm:border-none">
                                    <SectionTitle title="Access Level" />
                                    <TeamMemberAccessLevel
                                        featureMeta={featureMeta}
                                        updateAccountMeta={updateAccountMeta}
                                        canUpdate={!featureMeta.isAdmin && featureIndex !== 2}
                                        featureIndex={featureIndex}
                                        id={feature.id}
                                    />
                                </div>
                            </td>

                            <td className="px-4 pb-5 align-baseline">
                                <div className="item-start flex h-full w-full flex-col justify-start border-b-0.2 border-grey-secondary pb-2 pt-4 sm:border-none">
                                    <SectionTitle title="Notification Rule" className="mb-2 mt-2 sm:mb-0 sm:mt-0" />
                                    {feature.access === 0 && <ManagerDescription name={feature.name} />}
                                    {feature.access === 1 && <ViewerDescription />}
                                    {feature.access === 2 && <NoAccessDescription />}
                                </div>
                            </td>
                        </tr>
                    ))}
        </>
    );
}

export default TeamMemberDetailsFeatureRow;

import AvatarDetails from "../../avatar-details";
import { DataColumn } from "../Type/DataRow";
// import GoogleIcon from "../../google-icon";
import { SubTextType } from "../Type/SubTextType";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDate";
import { formatTime } from "../../../utils/formatDate";
import isNullOrUndefined from "../utils/isNullOrUndefined";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function LogoSubTextTrendFromText({ dataColumn, dataType }: Props) {
    return (
        <AvatarDetails
            dataType={dataType}
            title={
                dataColumn.textType === TextType.DATE
                    ? formatDate(dataColumn.text as Date)
                    : dataColumn.textType === TextType.TIME
                      ? formatTime(dataColumn.text as Date)
                      : dataColumn.textType === TextType.DATE_TIME
                        ? formatDateAndTime(dataColumn.text as Date)
                        : typeof dataColumn.text === "string"
                          ? dataColumn.text
                          : ""
            }
            subtitle={
                dataColumn.subTextType === SubTextType.DATE ? (
                    formatDate(dataColumn.subText as Date)
                ) : dataColumn.subTextType === SubTextType.TIME ? (
                    formatTime(dataColumn.subText as Date)
                ) : dataColumn.subTextType === SubTextType.DATE_TIME ? (
                    formatDateAndTime(dataColumn.subText as Date)
                ) : typeof dataColumn.subText === "string" ? (
                    dataColumn.subText
                ) : dataColumn.subTextType === SubTextType.TRANSACTION_PENDING_APPROVAL ? (
                    <span data-type={dataType} className="rounded px-3 py-1 text-xs font-medium capitalize leading-none text-warning">
                        Pending Approval
                    </span>
                ) : (
                    ""
                )
            }
            icon={
                dataColumn.cancelTrend ? "cancel" : !isNullOrUndefined(dataColumn.gainTrend) ? (dataColumn.gainTrend ? "inflow" : "outflow") : "none"
            }
            // customInitials={
            //     dataColumn.cancelTrend ? (
            //         <GoogleIcon icon="block" dataType={dataType} className={`text-[8px] text-black-secondary`} size="xs" />
            //     ) : undefined
            // }
            size="xs"
        />
    );
}

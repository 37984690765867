import { FilterDateType, FilterDateTypes } from "../filter.constant";

import DateRows from "./DateRows";
import GenericFilterRow from "./GenericFilterRow";
import GoogleIcon from "../../google-icon";
import NewDropdownItem from "../../new-dropdown-item";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { useState } from "react";

// import { useState } from "react";

interface Props<T extends { name?: string; text?: string; value: U }, U> {
    icon?: string;
    title: string;
    isLeft?: boolean;
    options?: T[];
    isRadio?: boolean;
    dataType?: string;
    filteredList?: U[] | U;
    isHover?: boolean;
    onHover?: () => void;
    handleFilterState?: (_value: U) => void;

    date?: FilterDateTypes;
    isDate?: boolean;
    handleSetDate?: (_data: FilterDateType) => void;
    handleFilterDate?: () => void;
    handleOpenDateModal?: () => void;
}

function FilterRow<T extends { name?: string; text?: string; value: U }, U>(props: Props<T, U>): JSX.Element {
    const [isHover, setIsHover] = useState(false);

    return (
        <>
            <div className="relative w-full" onClick={props.onHover}>
                <div className="w-full" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                    <NewDropdownItem size="md" isActive={isHover || props.isHover}>
                        <div className={"flex w-full flex-row items-center justify-between " + `${props.isHover ? "text-black" : ""}`}>
                            <div className="flex items-center justify-center space-x-2">
                                {props.icon && <GoogleIcon icon={props.icon} isFilled={props.isHover} />}
                                <span className="text-sm font-medium">{props.title}</span>
                            </div>
                            {(isHover || props.isHover) && <GoogleIcon icon="chevron_right" />}
                        </div>
                    </NewDropdownItem>
                </div>

                <div
                    className={
                        `absolute top-0 ` +
                        `${!props.isLeft ? "left-full pl-[5px]" : "right-full pr-[5px]"} ` +
                        `${!props.isHover ? "hidden" : "flex"}`
                    }
                >
                    <div className="w-56 flex-col overflow-hidden rounded-lg bg-white shadow-dropdown">
                        {props.isDate && props.handleSetDate && props.handleOpenDateModal && (
                            <DateRows
                                filterDate={props.date}
                                handleFilterDate={props.handleFilterDate}
                                handleSetFilterDate={props.handleSetDate}
                                handleOpenDateModal={props.handleOpenDateModal}
                            />
                        )}
                        {!props.isDate && props.options && props.handleFilterState && (
                            <GenericFilterRow
                                name={props.title}
                                isRadio={props.isRadio}
                                options={props.options}
                                dataType={props.dataType}
                                filteredList={props.filteredList}
                                handleFilterState={props.handleFilterState}
                                isUncontrolled={isNullOrUndefined(props.filteredList)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterRow;

import BillPurchase from "../../../../models/billPurchase";
import { BillPurchaseStatusType } from "../../../../models/billPurchase.constant";
import { DataColumn } from "../../../../components/Table/Type/DataRow";
import { DropdownItem } from "../../../../helpers/types";
import { SubTextType } from "../../../../components/Table/Type/SubTextType";
import { TableColumnType } from "../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../components/Table/Type/TextType";

export enum BillPaymentType {
    AIRTIME_TRANSFER = "airtime",
    DATA_TRANSFER = "data",
    CABLE_TV_TRANSFER = "cableTV",
    ELECTRICITY_TRANSFER = "electricity",
    TYPE_OF_BILL_TRANSFER = "typeOfBillPayment",
}
export enum StageType {
    INITIAL = 1,
    PAYMENT_DETAILS = 2,
    REVIEW_PAYMENT = 3,
    MAKE_PAYMENT = 4,
    PROCESS_PAYMENT = 5,
}

export const AirtimeTransferSections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Make an airtime purchase",
        subtext: "Purchase airtime for your mobile phone",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your payment",
        subtext: "Confirm the airtime transaction details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize payment",
        subtext: "Give approval to this transaction",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const DataSections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Make a data purchase",
        subtext: "Purchase data for your mobile phone",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your payment",
        subtext: "Confirm the transaction details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize payment",
        subtext: "Give approval to this transaction",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const CableTVSections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Make a bulk transfer",
        subtext: "Send money to multiple recipients or sub-accounts all at once",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your payments",
        subtext: "Confirm the transaction details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize payments",
        subtext: "Give approval to these transactions",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const ElectricitySections: {
    [value in StageType]: DropdownItem<StageType>;
} = {
    [StageType.INITIAL]: {
        value: StageType.INITIAL,
        text: "",
        subtext: "",
    },
    [StageType.PAYMENT_DETAILS]: {
        value: StageType.PAYMENT_DETAILS,
        text: "Make a bulk transfer",
        subtext: "Send money to multiple recipients or sub-accounts all at once",
    },
    [StageType.REVIEW_PAYMENT]: {
        value: StageType.REVIEW_PAYMENT,
        text: "Review your payments",
        subtext: "Confirm the transaction details",
    },
    [StageType.MAKE_PAYMENT]: {
        value: StageType.MAKE_PAYMENT,
        text: "Authorize payments",
        subtext: "Give approval to these transactions",
    },
    [StageType.PROCESS_PAYMENT]: {
        value: StageType.PROCESS_PAYMENT,
        text: "",
        subtext: "",
    },
};

export const TableStatusTextType: {
    [value in BillPurchaseStatusType]: TextType;
} = {
    [BillPurchaseStatusType.SUCCESS]: TextType.TRANSACTION_STATUS_SUCCESS,
    [BillPurchaseStatusType.PENDING]: TextType.TRANSACTION_STATUS_PROCESSING,
    [BillPurchaseStatusType.FAILED]: TextType.TRANSACTION_STATUS_FAILED,
};

export const BillPurchaseHeader: TableHead = [
    { text: "From", headType: TableColumnType.LOGO_TREND_SUBTEXT_FROM_TEXT },
    { text: "Category", headType: TableColumnType.TEXT },
    { text: "Vendor", headType: TableColumnType.IMG_AND_TEXT },
    { text: "Product", headType: TableColumnType.TEXT },
    { text: "Amount", headType: TableColumnType.AMOUNT_WITH_SUBTEXT },
    { text: "Status", headType: TableColumnType.STATUS },
];

export const BillPurchaseRow: (_bill: BillPurchase) => Array<DataColumn> = (_bill: BillPurchase) => {
    return [
        {
            key: "Account",
            text: _bill.debitAccount.lencoNameMin,
            textType: TextType.STRING,
            subText: _bill.datetime,
            gainTrend: false,
            subTextType: SubTextType.DATE_TIME,
        },
        {
            key: "Category",
            text: _bill.getCategory,
        },
        {
            key: "Vendor",
            img: _bill.vendor.logoUrl,
            text: _bill.vendor.name,
        },
        {
            key: "Product",
            text: _bill.product.name,
        },
        {
            key: "Amount",
            text: _bill.amount,
            textType: TextType.BALANCE,
            // subText: _bill.,
            // subTextType: SubTextType.AMOUNT_FEE,
        },
        {
            key: "Status",
            text: _bill.statusText,
            textType: TableStatusTextType[_bill.status],
        },
    ];
};

import { useCallback, useState } from "react";

import { AddZambiaApiAccessKeyWhitelistIPAddressRequest } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { addZambiaApiAccessKeyWhitelistIPAddress } from "../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { setUpdateZambiaApiAccessKey } from "../../../../../redux/zambia/lencoPay/apiAccessKeys/apiAccessKeysSlice";
import { useDispatch } from "react-redux";

export interface UseAddZambiaApiAccessKeyWhitelistIPAddressInterface {
    isAddZambiaApiAccessKeyWhitelistIPAddressLoading: boolean;
    addZambiaApiAccessKeyWhitelistIPAddressErrorMessage: string;
    handleAddZambiaApiAccessKeyWhitelistIPAddress: (_data: AddZambiaApiAccessKeyWhitelistIPAddressRequest) => void;
}

interface Props {
    onComplete: () => void;
}

function useAddZambiaApiAccessKeyWhitelistIPAddress(props: Props): UseAddZambiaApiAccessKeyWhitelistIPAddressInterface {
    const dispatch = useDispatch();
    const [isAddZambiaApiAccessKeyWhitelistIPAddressLoading, setIsAddZambiaApiAccessKeyWhitelistIPAddressLoading] = useState(false);
    const [addZambiaApiAccessKeyWhitelistIPAddressErrorMessage, setAddZambiaApiAccessKeyWhitelistIPAddressErrorMessage] = useState<string>("");

    const handleAddZambiaApiAccessKeyWhitelistIPAddress = useCallback(
        async (_data: AddZambiaApiAccessKeyWhitelistIPAddressRequest) => {
            try {
                setIsAddZambiaApiAccessKeyWhitelistIPAddressLoading(true);
                setAddZambiaApiAccessKeyWhitelistIPAddressErrorMessage("");
                const res = await addZambiaApiAccessKeyWhitelistIPAddress(_data);
                dispatch(setUpdateZambiaApiAccessKey(res.apiAccessKey));
                dispatch(messageTrue("API access key whitelist IP added successfully."));
                props.onComplete();
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddZambiaApiAccessKeyWhitelistIPAddressErrorMessage(errorMessage);
            } finally {
                setIsAddZambiaApiAccessKeyWhitelistIPAddressLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isAddZambiaApiAccessKeyWhitelistIPAddressLoading,
        addZambiaApiAccessKeyWhitelistIPAddressErrorMessage,
        handleAddZambiaApiAccessKeyWhitelistIPAddress,
    };
}

export default useAddZambiaApiAccessKeyWhitelistIPAddress;

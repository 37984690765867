import * as Yup from "yup";

import { FieldArray, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";

import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import FormInput from "../../../../../../../../../../components/inputs/FormInput";
import FullPageTray from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import GoogleIcon from "../../../../../../../../../../components/google-icon";
import useAddZambiaApiAccessKeyWhitelistIPAddress from "../../../../../../../hookss/ApiAccessKeySettings/useAddZambiaApiAccessKeyWhitelistIPAddress";

const DATA_TYPE = "whitelisted-IP-addresses";

interface Props {
    active: boolean;
    apiAccessKeyId: string;
    toggler: () => void;
}

function AddWhitelistIpAddressTray(props: Props): JSX.Element {
    const { isAddZambiaApiAccessKeyWhitelistIPAddressLoading, handleAddZambiaApiAccessKeyWhitelistIPAddress } =
        useAddZambiaApiAccessKeyWhitelistIPAddress({
            onComplete: props.toggler,
        });

    const formikRef = useRef<FormikProps<{ ips: string[] }> | null>(null);

    const initialValues: { ips: string[] } = {
        ips: [""],
    };
    const formValidation = Yup.object().shape({
        ips: Yup.array().of(Yup.string().min(7)).min(1).required(),
    });

    useEffect(() => {
        if (!props.active) {
            formikRef.current?.getFieldHelpers("ips").setValue([""]);
        }
    }, [props.active]);

    return (
        <>
            <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
                <FullPageTrayHeader toggler={props.toggler} dataType={DATA_TYPE}>
                    Whitelist IP Address
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <Formik
                        initialValues={initialValues}
                        innerRef={formikRef}
                        validationSchema={formValidation}
                        onSubmit={(values) => {
                            handleAddZambiaApiAccessKeyWhitelistIPAddress({ ...values, id: props.apiAccessKeyId });
                        }}
                        enableReinitialize
                        validateOnChange
                        validateOnMount
                    >
                        {(formik) => {
                            return (
                                <Form className="w-full items-center justify-start space-y-20 pb-8">
                                    <FieldArray name="ips">
                                        {(arrayFunc) => (
                                            <div className="flex w-full flex-col items-start justify-start space-y-4">
                                                {formik &&
                                                    formik?.values?.ips?.map?.((form, index) => (
                                                        <div className="flex w-full flex-col items-start justify-start" key={index}>
                                                            <div className="flex w-full flex-col items-start space-y-1 text-base font-normal">
                                                                <div className={`w-full ` + `${index === 0 ? "pb-2" : ""}`}>
                                                                    <FormInput
                                                                        inputSize={"lg"}
                                                                        type="text"
                                                                        value={form}
                                                                        name={`ips.[${index}]`}
                                                                        placeholder="IP Address"
                                                                    />
                                                                </div>
                                                                {index > 0 && (
                                                                    <div
                                                                        className={
                                                                            `relative flex w-full items-center ` +
                                                                            `${formik?.values?.ips?.length - 1 === index ? "justify-between" : "justify-end"} `
                                                                        }
                                                                    >
                                                                        {formik?.values?.ips.length - 1 === index && (
                                                                            <div className="relative flex w-full items-center justify-start">
                                                                                <ButtonComp
                                                                                    type="button"
                                                                                    buttonType="tertiary"
                                                                                    color="blue"
                                                                                    size="sm"
                                                                                    func={() => arrayFunc?.push("")}
                                                                                >
                                                                                    <GoogleIcon
                                                                                        icon="add_circle"
                                                                                        className="stroke-current"
                                                                                        size="md"
                                                                                    />{" "}
                                                                                    <span className="ml-1">Add Another IP </span>
                                                                                </ButtonComp>
                                                                            </div>
                                                                        )}
                                                                        {index >= 1 && (
                                                                            <ButtonComp
                                                                                type="button"
                                                                                color="red"
                                                                                buttonType="tertiary"
                                                                                size="sm"
                                                                                func={() => arrayFunc?.remove?.(index)}
                                                                            >
                                                                                <span>Remove</span>
                                                                            </ButtonComp>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {formik?.values?.ips?.length < 2 && (
                                                                <div className="relative flex w-full items-center justify-start">
                                                                    <ButtonComp
                                                                        type="button"
                                                                        buttonType="tertiary"
                                                                        color="blue"
                                                                        size="sm"
                                                                        func={() => arrayFunc?.push("")}
                                                                    >
                                                                        <GoogleIcon icon="add_circle" className="stroke-current" size="xs" />{" "}
                                                                        <span className="ml-1">Add Another IP</span>
                                                                    </ButtonComp>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </FieldArray>

                                    <div className="flex w-full flex-row items-center justify-center space-x-4 pt-3">
                                        <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                            Cancel
                                        </ButtonComp>

                                        <ButtonComp
                                            fullWidth
                                            type="submit"
                                            size="lg"
                                            buttonType="primary"
                                            disable={!!formik.values.ips.some((ip) => ip.length < 7)}
                                            isLoading={isAddZambiaApiAccessKeyWhitelistIPAddressLoading}
                                            color={"black"}
                                        >
                                            Whitelist IP
                                        </ButtonComp>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default AddWhitelistIpAddressTray;

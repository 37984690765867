import React, { useEffect, useState } from "react";
import TerminalRequestTray, { TERMINAL_REQUEST_TRAY_DATATYPE } from "./Trays/TerminalRequestTray";

import { SubTextType } from "../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../components/Table";
import { TableColumnType } from "../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../components/Table/Type/HeadRow";
import TerminalRequest from "../../../../../models/terminalRequest";
import TerminalRequestStatusCards from "../Cards/TerminalRequestStatusCards";
import { TextType } from "../../../../../components/Table/Type/TextType";
import { resetAllTerminalRequestData } from "../../../../../redux/terminals/slice/terminalsSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useTerminalRequests from "../../Hooks/State/useTerminalRequests";

const terminalRequestsHeader: TableHead = [
    { text: "Request Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "No of Terminal", pos: 2, headType: TableColumnType.TEXT },
    { text: "Purchase Type", pos: 3, headType: TableColumnType.TEXT },
    { text: "Requested By", pos: 4, headType: TableColumnType.TEXT },
    { text: "Status", pos: 5, headType: TableColumnType.STATUS },
];

function TerminalRequests(): JSX.Element {
    const dispatch = useDispatch();

    const { handleFilteredTerminalRequests } = useTerminalRequests();

    const isLoading = useAppSelector((state) => state.terminals.isTerminalRequestsPaginationLoading);
    const requestedTerminals = useAppSelector((state) => state.terminals.requestedTerminals);
    const terminalRequestsListTotal = useAppSelector((state) => state.terminals.terminalRequestsListTotal);
    const terminalRequestsListGroupSize = useAppSelector((state) => state.terminals.terminalRequestsListGroupSize);
    const terminalRequestsPaginationOffset = useAppSelector((state) => state.terminals.terminalRequestsPaginationOffset);

    const [selectedRequestedTerminal, setSelectedRequestedTerminal] = useState<TerminalRequest | null>(null);

    useEffect(() => {
        void handleFilteredTerminalRequests({ offset: 0 });
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllTerminalRequestData());
        };
    }, []);

    return (
        <>
            <TerminalRequestTray
                active={!!selectedRequestedTerminal}
                terminalRequest={selectedRequestedTerminal}
                toggle={() => setSelectedRequestedTerminal(null)}
            />

            <TerminalRequestStatusCards />

            <div className="relative h-full w-90% min-w-full max-w-screen-2xs sm:w-max lg:h-auto xs:max-w-screen-xs">
                <div className="h-full w-full max-w-screen lg:h-auto">
                    <div className="mx-auto h-full w-full lg:h-auto lg:w-fit">
                        <Table
                            isSticky
                            dataType={TERMINAL_REQUEST_TRAY_DATATYPE}
                            heads={terminalRequestsHeader}
                            isPaginateLoading={isLoading}
                            groupSize={terminalRequestsListGroupSize}
                            offset={terminalRequestsPaginationOffset}
                            total={terminalRequestsListTotal}
                            isLoading={!!isLoading}
                            rows={requestedTerminals?.map((request) => ({
                                onRecordClick: () => setSelectedRequestedTerminal(request),
                                record: [
                                    {
                                        key: "Request Date",
                                        text: request.requestedAt,
                                        textType: TextType.DATE,
                                        subText: request.requestedAt,
                                        subTextType: SubTextType.TIME,
                                    },
                                    {
                                        key: "No of Terminal",
                                        text: request.numUnits,
                                        textType: TextType.STRING,
                                    },
                                    {
                                        key: "Purchase Type",
                                        text: request?.getPurchaseText,
                                        textType: TextType.STRING,
                                    },
                                    { key: "Requested By", text: request.requestByFullName },
                                    { key: "Status", text: request.status, textType: request.requestTableStatus },
                                ],
                            }))}
                            // paginateFunction={(_, pageOffset) => handleTerminalList({ offset: pageOffset })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TerminalRequests;

import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
    dataType: string;
    transaction: Transaction;
}

function TransactionDetailCardChannel({ dataType, transaction }: Props): JSX.Element {
    return (
        <>
            <div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
                <p className="text-sm text-black-tertiary" data-type={dataType}>
                    Channel
                </p>
                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
                    {transaction.cardPayment?.getChannelName || ""}
                </p>
            </div>
        </>
    );
}

export default TransactionDetailCardChannel;

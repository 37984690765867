import { TransactionFilter, TransactionsUrlParams } from "../Hooks/State/useTransactionsState.type";
import { canShowTransactionBanner, saveTransactionBannerSkippedDate } from "../../../../helpers/app-reminders";
import {
    resetAllTransactionData,
    setSelectedTransactionFilterStateInstant,
    setTransactionPaginationOffset,
} from "../../../../redux/transactions/slice/transactionsSlice";
import { useEffect, useRef, useState } from "react";

import EditTransactionAliasModal from "../Components/Modal/EditTransactionAliasModal";
import ExportZambiaTransactionsTray from "../Components/Tray/ExportZambiaTransactionsTray";
import PageBanner from "../../../../components/page-banner";
import PageLayout from "../../../../components/layouts/page-layout";
import Parsers from "../../../../utils/parsers";
import PreviewModal from "../../applicationn/components/modals/preview-modal";
import { SingleTransactionTableHead } from "../Components/TransactionTable/TableData/headerData";
import Table from "../../../../components/Table";
import { TableRecord } from "../../../../components/Table/Type/DataRow";
import { TextType } from "../../../../components/Table/Type/TextType";
import Transaction from "../../../../models/transaction";
import TransactionFilterSection from "../Components/Sections/TransactionFilterSection";
import { TransactionType } from "../../../../models/transaction.constants";
import TransactionsGif from "../../../../assets/images/transactions/transactions-info.gif";
import { produce } from "immer";
import { setGlobalActiveDataType } from "../../../../redux/init/slice/initSlice";
import { setIsEditTransactionAliasModal } from "../../../../redux/accounts/slice/accountsSlice";
import { showTransactionDetails } from "../../../../redux/transaction/slice/transactionSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { useDispatch } from "react-redux";
import useFilterTransactionsState from "../Hooks/State/useFilterTransactionsState";
import { useParams } from "react-router";
import useTransactionDetailsAccountingReceipt from "../Components/Cards/TransactionDetailCard/Hooks/useTransactionDetailsAccountingReceipt";

function TransactionsPage(): JSX.Element {
    const dispatch = useDispatch();

    const { handleFilteredDetails, handleGetTransactions, handleClearAll } = useFilterTransactionsState();
    const { receiptBlob, isPreviewModalOpen, handleClosePreviewModal, handleUploadAccountingReceipt, handlePreviewAccountingReceipt } =
        useTransactionDetailsAccountingReceipt();

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [showPageBanner, setShowPageBanner] = useState<boolean>(false);
    const [selectedInputTrans, setSelectedInputTrans] = useState<Transaction | null>(null);

    const isZambia = useAppSelector((state) => state.init.main?.companyDetails?.company.isZambia);
    const mainInitLoading = useAppSelector((state) => state.init.mainInitLoading);
    const transactionsList = useAppSelector((state) => state.transactions.transactionList);
    const isPaginationLoading = useAppSelector((state) => state.transactions.isTransactionPaginationLoading);
    const transactionsListTotal = useAppSelector((state) => state.transactions.transactionsListTotal);
    const transactionFilterState = useAppSelector((state) => state.transactions.transactionFilterState);
    const transactionsListGroupSize = useAppSelector((state) => state.transactions.transactionsListGroupSize);
    const isEditTransactionAliasModal = useAppSelector((state) => state.account.isEditTransactionAliasModal);
    const transactionPaginationOffset = useAppSelector((state) => state.transactions.transactionPaginationOffset);

    const { type, query } = useParams<TransactionsUrlParams>();

    const queryParams = new URLSearchParams(location.search);
    const typeParam = Parsers.nullableNumberEnum(Number(queryParams.get("type")), TransactionType);
    const accountParam: string | undefined = Parsers.string(queryParams.get("account"));

    useEffect(() => {
        if (mainInitLoading) return;

        window.history.replaceState({}, "Transactions", "/transactions");

        setShowPageBanner(canShowTransactionBanner());

        if (typeParam || accountParam) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = {
                    ...draft,
                    transactionTypes: typeParam ? [typeParam] : [],
                    userAccountIds: accountParam ? [accountParam] : [],
                    offset: 0,
                });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        } else {
            if (!type && !query) {
                const updatedList = produce(transactionFilterState, (draft) => {
                    return (draft = { ...draft, offset: 0 });
                });
                dispatch(setSelectedTransactionFilterStateInstant(updatedList));
                void handleGetTransactions(updatedList);
                return;
            }
            if (type === TransactionFilter.INFLOW) {
                const updatedList = produce(transactionFilterState, (draft) => {
                    return (draft = { ...draft, transactionTypes: [TransactionType.CREDIT], offset: 0 });
                });
                dispatch(setSelectedTransactionFilterStateInstant(updatedList));
                void handleGetTransactions(updatedList);
                return;
            }
            if (type === TransactionFilter.PAYOUT) {
                const updatedList = produce(transactionFilterState, (draft) => {
                    return (draft = { ...draft, transactionTypes: [TransactionType.DEBIT], offset: 0 });
                });
                dispatch(setSelectedTransactionFilterStateInstant(updatedList));
                void handleGetTransactions(updatedList);
                return;
            }

            if (query && type === TransactionFilter.ACCOUNT) {
                const updatedList = produce(transactionFilterState, (draft) => {
                    return (draft = { ...draft, userAccountIds: [query], offset: 0 });
                });
                dispatch(setSelectedTransactionFilterStateInstant(updatedList));
                void handleGetTransactions(updatedList);
                return;
            }

            if (query && type === TransactionFilter.CARDS) {
                const updatedList = produce(transactionFilterState, (draft) => {
                    return (draft = { ...draft, cardIds: [query], offset: 0 });
                });
                dispatch(setSelectedTransactionFilterStateInstant(updatedList));
                void handleGetTransactions(updatedList);
                return;
            }

            if (type === TransactionFilter.SEARCH && query) {
                const initialState = {
                    offset: 0,
                    date: { begin: "", end: "" },
                    transactionTypes: [],
                    userGroupIds: [],
                    userAccountIds: [],
                    categoryIds: [],
                    transactionStatuses: [],
                    cardIds: [],
                    query: "",
                };

                dispatch(setSelectedTransactionFilterStateInstant({ ...initialState, query }));
                void handleGetTransactions({ ...initialState, query });
                return;
            }
        }
    }, [type, typeParam, query, accountParam, mainInitLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetAllTransactionData());
        };
    }, []);

    const handlePaginationRequest = (offset: number) => {
        const updatedList = produce(transactionFilterState, (draft) => {
            return (draft = { ...draft, offset });
        });
        dispatch(setSelectedTransactionFilterStateInstant(updatedList));
        dispatch(setTransactionPaginationOffset(offset));
        handleFilteredDetails({ offset });
    };

    const handleCloseIsEditTransactionAliasModal = () => {
        dispatch(setIsEditTransactionAliasModal(false));
    };

    const onSelectFile = (file: File) => {
        selectedInputTrans && void handleUploadAccountingReceipt(selectedInputTrans, file);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setSelectedInputTrans(null);
    };

    return (
        <>
            {isZambia && <ExportZambiaTransactionsTray />}

            <EditTransactionAliasModal active={isEditTransactionAliasModal} toggler={handleCloseIsEditTransactionAliasModal} />

            <PreviewModal active={isPreviewModalOpen} data={receiptBlob} toggler={handleClosePreviewModal} title="Transaction Attachment" />

            <PageLayout pageTitle="Transactions" subTitle="List of transactions made on your Lenco accounts" isFullPageScroll>
                {!isPaginationLoading && showPageBanner && (
                    <PageBanner
                        image={TransactionsGif}
                        title="Add Context to Your Transactions"
                        subtitle="Attach receipts and add comments to your transactions for better tracking and organization. Make your transaction history more detailed and meaningful."
                        imgClassName="!max-w-[285px]"
                        onToggle={() => {
                            setShowPageBanner(false);
                            saveTransactionBannerSkippedDate();
                        }}
                    />
                )}

                <div className="flex w-full flex-col items-start justify-start space-y-2 pb-10">
                    <TransactionFilterSection handleClearAll={handleClearAll} handleFilteredDetails={handleFilteredDetails} />
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={(event) => {
                        if (event.target.files && event.target.files.length > 0) {
                            onSelectFile(event.target.files[0]);
                        }
                    }}
                    accept="image/jpeg, image/png, application/pdf, .pdf"
                />

                <Table
                    isPaginateLoading={isPaginationLoading}
                    isLoading={isPaginationLoading}
                    heads={SingleTransactionTableHead}
                    dataType="transaction"
                    total={transactionsListTotal}
                    offset={transactionPaginationOffset}
                    groupSize={transactionsListGroupSize}
                    paginateFunction={(_page, _offset) => handlePaginationRequest(_offset)}
                    isSticky
                    rows={
                        (transactionsList || []).map?.((trans) => {
                            return {
                                onRecordClick: () => {
                                    dispatch(setGlobalActiveDataType("transaction"));
                                    dispatch(showTransactionDetails(trans.id));
                                },
                                record: [
                                    {
                                        key: "To/From",
                                        text: trans.isDebit()
                                            ? trans.destination?.userAccount?.lencoNameMin ||
                                              trans.destination?.customerAccount?.bankAccount?.accountName ||
                                              trans.destination?.card?.name ||
                                              trans.destination?.narration
                                            : trans.origination?.userAccount?.lencoNameMin ||
                                              trans.origination?.customerAccount?.bankAccount?.accountName ||
                                              trans.origination?.card?.name ||
                                              trans.origination?.narration,
                                        textType:
                                            // trans.isInternalFallback || trans.isInternal
                                            //     ? TextType.LENCO_LOGO
                                            //     :
                                            trans.isCardPayment || !!trans.origination?.card ? TextType.CARD_LOGO : undefined,
                                        subText: trans.singleDatetime,
                                        subTextType: TextType.DATE_TIME,
                                        gainTrend: trans.isCredit(),
                                        cancelTrend: trans.isCancelled || trans.isFailed,
                                    },
                                    {
                                        key: "Type",
                                        text: trans.isDebit()
                                            ? trans.isCardPayment || !!trans.origination?.card
                                                ? "Card Debit"
                                                : "Payout"
                                            : trans.isCardPayment || !!trans.origination?.card
                                              ? "Card Credit"
                                              : trans.isReversal
                                                ? "Reversal Inflow"
                                                : "Inflow",
                                        gainTrend: trans.isCredit(),
                                    },
                                    {
                                        key: "Amount",
                                        currency: trans.currency,
                                        text: trans.amount,
                                        textType: trans.isDebit() ? TextType.FINAL_LOSS : TextType.FINAL_GAIN,
                                    },
                                    {
                                        key: "Balance",
                                        currency: trans.currency,
                                        text: trans.postTransactionBalance,
                                        textType: trans.isSuccess
                                            ? trans.postTransactionBalance
                                                ? TextType.BALANCE
                                                : TextType.BALANCE
                                            : trans.isFailed
                                              ? TextType.TRANSACTION_STATUS_FAILED
                                              : trans.isPendingApproval
                                                ? TextType.TRANSACTION_STATUS_PENDING_APPROVAL
                                                : trans.isDeclined
                                                  ? TextType.TRANSACTION_STATUS_DECLINED
                                                  : trans.isCancelled
                                                    ? TextType.TRANSACTION_STATUS_CANCELLED
                                                    : trans.isActivationPending
                                                      ? TextType.TRANSACTION_STATUS_PENDING_ACTIVATION
                                                      : trans.isProcessing
                                                        ? TextType.TRANSACTION_STATUS_PROCESSING
                                                        : TextType.BALANCE,
                                    },
                                    {
                                        key: "Attachment",
                                        icon: trans.receiptUploaded ? "visibility" : "attach_file_add",
                                        text: trans.isFailed ? "-" : trans.receiptUploaded ? "View Receipt" : "Attach Receipt",
                                        textType: trans.isFailed ? TextType.STRING : TextType.TOOLTIP_ICON,
                                        onClick: () => {
                                            if (trans.receiptUploaded) {
                                                trans && void handlePreviewAccountingReceipt(trans);
                                            } else {
                                                setSelectedInputTrans(trans);
                                                fileInputRef.current && fileInputRef.current.click();
                                            }
                                        },
                                    },
                                ],
                            };
                        }) as TableRecord
                    }
                />
            </PageLayout>
        </>
    );
}

export default TransactionsPage;

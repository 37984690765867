import {
    GrantUserLencoTerminalAccessRequest,
    RemoveLencoTerminalUserAccessDTO,
    TerminalListDTO,
    UpdateTerminalSettingsDto,
} from "../../Services/terminalsApi.types";
import {
    addTerminalManageAccess,
    addTerminalViewerAccess,
    listTerminals,
    removeTerminalManageAccess,
    removeTerminalViewerAccess,
    terminalsInit,
    updateTerminalSettings,
} from "../../Services/terminalsApi";
import {
    setAllTerminalStageToSpecificTerminal,
    setInitTerminalDetails,
    setRequestedTerminals,
    setTeamMembers,
    setTerminalListGroupSize,
    setTerminalListTotal,
    setTerminalRequestsListGroupSize,
    setTerminalRequestsListTotal,
    setTerminalSettings,
    setTerminals,
    setUpdateTerminalsList,
    updateTerminalManageAccessList,
    updateTerminalViewAccessList,
} from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback, useState } from "react";

import { AppToastType } from "../../../../../redux/app-toast/app-toast-slice.types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { listTeamMembers } from "../../../settings-new/Services/team-members/team-members.api";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useDispatch } from "react-redux";

export default function useTerminals() {
    const dispatch = useDispatch();

    const [terminalInitError, setTerminalInitError] = useState<string | null>(null);
    const [isTerminalInitLoading, setIsTerminalInitLoading] = useState<boolean>(false);
    const [AddManagerAccessError, setAddManagerAccessError] = useState<string | null>(null);
    const [isAddManagerAccessDone, setIsAddManagerAccessDone] = useState<boolean>(false);
    const [isAddManagerAccessLoading, setIsAddManagerAccessLoading] = useState<boolean>(false);
    const [AddViewerAccessError, setAddViewerAccessError] = useState<string | null>(null);
    const [isAddViewerAccessDone, setIsAddViewerAccessDone] = useState<boolean>(false);
    const [isAddViewerAccessLoading, setIsAddViewerAccessLoading] = useState<boolean>(false);
    const [removeViewerAccessError, setRemoveViewerAccessError] = useState<string | null>(null);
    const [isRemoveViewerAccessDone, setIsRemoveViewerAccessDone] = useState<boolean>(false);
    const [isRemoveViewerAccessLoading, setIsRemoveViewerAccessLoading] = useState<boolean>(false);
    const [removeManageAccessError, setRemoveManageAccessError] = useState<string | null>(null);
    const [isRemoveManageAccessDone, setIsRemoveManageAccessDone] = useState<boolean>(false);
    const [isRemoveManageAccessLoading, setIsRemoveManageAccessLoading] = useState<boolean>(false);
    const [terminalListError, setTerminalListError] = useState<string | null>(null);
    const [isTerminalListLoading, setIsTerminalListLoading] = useState<boolean>(false);
    const [updateTerminalSettingsError, setUpdateTerminalSettingsError] = useState<string | null>(null);
    const [isUpdateTerminalSettingsDone, setIsUpdateTerminalSettingsDone] = useState<boolean>(false);
    const [isUpdateTerminalSettingsLoading, setUpdateTerminalSettingsLoading] = useState<boolean>(false);

    const handleUpdateTerminalSettings = useCallback(async (data: UpdateTerminalSettingsDto) => {
        try {
            setUpdateTerminalSettingsError(null);
            setUpdateTerminalSettingsLoading(true);
            setIsUpdateTerminalSettingsDone(false);
            const res = await updateTerminalSettings(data);
            dispatch(setAllTerminalStageToSpecificTerminal(res.terminal));
            dispatch(setUpdateTerminalsList(res.terminal));
            setIsUpdateTerminalSettingsDone(true);
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setUpdateTerminalSettingsError(errorMessage);
        } finally {
            setUpdateTerminalSettingsLoading(false);
        }
    }, []);

    const handleTerminalInit = useCallback(async () => {
        try {
            setTerminalInitError(null);
            setIsTerminalInitLoading(true);
            // dispatch();
            const res = await terminalsInit();
            const usersRes = await listTeamMembers();
            const users = usersRes.map((_el) => _el.teamMember);
            dispatch(setTeamMembers(users));
            dispatch(setInitTerminalDetails(res));
            dispatch(setTerminalListTotal(res.terminalsData.total));
            dispatch(setTerminalRequestsListTotal(res.terminalRequestsData.total));
            dispatch(setTerminalListGroupSize(res.terminalsData.groupSize));
            dispatch(setTerminalRequestsListGroupSize(res.terminalRequestsData.groupSize));
            dispatch(setTerminals(res.terminalsData.terminals));
            dispatch(setRequestedTerminals(res.terminalRequestsData.terminalRequests));
            dispatch(setTerminalSettings(res.settings));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTerminalInitError(errorMessage);
        } finally {
            setIsTerminalInitLoading(false);
        }
    }, []);

    const handleTerminalList = async (data: TerminalListDTO) => {
        try {
            setTerminalListError(null);
            setIsTerminalListLoading(true);
            const res = await listTerminals(data);
            dispatch(setTerminalListTotal(res.total));
            dispatch(setTerminalListGroupSize(res.groupSize));
            dispatch(setTerminals(res.terminals));
            setIsTerminalListLoading(false);
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                setIsTerminalListLoading(false);
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTerminalListError(errorMessage);
            setIsTerminalListLoading(false);
        }
    };

    const handleAddManagerAccess = useCallback(
        async (_data: GrantUserLencoTerminalAccessRequest) => {
            try {
                setIsAddManagerAccessDone(false);
                setAddManagerAccessError(null);
                setIsAddManagerAccessLoading(true);
                const res = await addTerminalManageAccess({ memberIds: _data.memberIds });
                dispatch(updateTerminalManageAccessList(res.teamMembersWithManageAccess));
                dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted Manager Access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddManagerAccessError(errorMessage);
            } finally {
                setIsAddManagerAccessLoading(false);
                setIsAddManagerAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleAddViewerAccess = useCallback(
        async (_data: GrantUserLencoTerminalAccessRequest) => {
            try {
                setIsAddViewerAccessDone(false);
                setAddViewerAccessError(null);
                setIsAddViewerAccessLoading(true);
                const res = await addTerminalViewerAccess({ memberIds: _data.memberIds });
                dispatch(updateTerminalViewAccessList(res.teamMembersWithViewAccess));
                dispatch(messageTrue({ message: `Team member${_data.memberIds.length > 1 ? "s" : ""} granted View Access` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setAddViewerAccessError(errorMessage);
            } finally {
                setIsAddViewerAccessLoading(false);
                setIsAddViewerAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleRemoveViewerAccess = useCallback(
        async (_data: RemoveLencoTerminalUserAccessDTO) => {
            try {
                setIsRemoveViewerAccessDone(false);
                setRemoveViewerAccessError(null);
                setIsRemoveViewerAccessLoading(true);
                const res = await removeTerminalViewerAccess({ memberId: _data.memberId });
                dispatch(updateTerminalViewAccessList(res.teamMembersWithViewAccess));

                dispatch(messageTrue({ message: `Team member's Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveViewerAccessError(errorMessage);
            } finally {
                setIsRemoveViewerAccessLoading(false);
                setIsRemoveViewerAccessDone(true);
            }
        },
        [dispatch]
    );

    const handleRemoveManageAccess = useCallback(
        async (_data: RemoveLencoTerminalUserAccessDTO) => {
            try {
                setIsRemoveManageAccessDone(false);
                setRemoveManageAccessError(null);
                setIsRemoveManageAccessLoading(true);
                const res = await removeTerminalManageAccess({ memberId: _data.memberId });
                dispatch(updateTerminalManageAccessList(res.teamMembersWithManageAccess));
                // dispatch(messageTrue({ message: `Team member's Access Removed` }));
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                // dispatch(messageTrue({ message: errorMessage, type: AppToastType.ERROR }));
                setRemoveManageAccessError(errorMessage);
            } finally {
                setIsRemoveManageAccessLoading(false);
                setIsRemoveManageAccessDone(true);
            }
        },
        [dispatch]
    );

    return {
        handleTerminalInit,
        handleAddManagerAccess,
        handleAddViewerAccess,
        handleRemoveViewerAccess,
        handleRemoveManageAccess,
        handleTerminalList,
        handleUpdateTerminalSettings,
        terminalInitError,
        isTerminalInitLoading,
        AddManagerAccessError,
        isAddManagerAccessDone,
        isAddManagerAccessLoading,
        AddViewerAccessError,
        isAddViewerAccessDone,
        isAddViewerAccessLoading,
        removeViewerAccessError,
        isRemoveViewerAccessDone,
        isRemoveViewerAccessLoading,
        removeManageAccessError,
        isRemoveManageAccessDone,
        isRemoveManageAccessLoading,
        terminalListError,
        isTerminalListLoading,
        updateTerminalSettingsError,
        isUpdateTerminalSettingsDone,
        isUpdateTerminalSettingsLoading,
    };
}

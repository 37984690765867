import InflowArrow from "../../../../../../assets/svg/dashboard/inflow-arrow.svg";
import OutflowArrow from "../../../../../../assets/svg/dashboard/outflow-arrow.svg";

interface Props {
    img: string | undefined;
    type?: "outflow" | "inflow" | "none";
    size?: "xs" | "2xs" | "sm" | "md" | "lg";
}

function VendorBadge(props: Props) {
    const { type = "outflow", size = "lg" } = props;

    return (
        <div className="relative">
            <div
                className={
                    "overflow-hidden rounded-md border border-grey " +
                    ` ${size === "2xs" ? "h-7 w-7" : ""}` +
                    ` ${size === "xs" ? "h-8 w-8" : ""}` +
                    ` ${size === "sm" ? "h-10 w-10" : ""}` +
                    ` ${size === "md" ? "h-12 w-12" : ""}` +
                    ` ${size === "lg" ? "h-14 w-14" : ""}`
                }
            >
                <img className="h-full w-full object-contain" src={props.img} alt="bill vendor" />
            </div>
            <div
                className={"absolute rounded-full border-[1.12px] border-white" + `${size === "lg" ? " -bottom-1 -right-1" : " -right-0.5 bottom-0"}`}
            >
                {type === "inflow" && <img className={size === "lg" ? "h-3.5 w-3.5" : "h-2 w-2"} src={InflowArrow} alt="inflow arrow" />}
                {type === "outflow" && <img className={size === "lg" ? "h-3.5 w-3.5" : "h-2 w-2"} src={OutflowArrow} alt="outflow arrow" />}
            </div>
        </div>
    );
}

export default VendorBadge;

import { ReactNode, useEffect, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import GoogleIcon from "../google-icon";
import Tooltip from "../tooltip";

interface Props {
    text?: string;
    size?: "xs" | "sm" | "md" | "lg";
    color?: "blue" | "black" | "grey";
    children?: ReactNode;
    copyString: string;
    isContentRight?: boolean;
}

function TextClipboard(props: Props) {
    const { size = "md", color = "black", isContentRight = false } = props;
    const [clipSuccess, setClipSuccess] = useState(false);

    useEffect(() => {
        if (clipSuccess) {
            setTimeout(() => {
                setClipSuccess(false);
            }, 800);
        } else {
            return;
        }
    }, [clipSuccess]);

    return (
        <>
            <div className="w-max">
                <CopyToClipboard text={props.copyString || ""} onCopy={() => setClipSuccess(true)}>
                    <div className="flex cursor-pointer items-center justify-center">
                        {!isContentRight ? (
                            props.children ? (
                                props.children
                            ) : (
                                <span
                                    className={
                                        "ml-1 font-medium " +
                                        ` ${size === "xs" ? "text-xs" : ""}` +
                                        ` ${size === "sm" ? "text-sm" : ""}` +
                                        ` ${size === "md" ? "text-sm" : ""}` +
                                        ` ${size === "lg" ? "text-base" : ""}` +
                                        ` ${color === "blue" ? "text-blue" : ""}` +
                                        ` ${color === "black" ? "text-black" : ""}`
                                    }
                                >
                                    {props.text}
                                </span>
                            )
                        ) : null}
                        <div className="relative flex h-fit w-fit items-center justify-center">
                            <Tooltip
                                icon={
                                    <GoogleIcon
                                        icon="content_copy"
                                        className={
                                            `${clipSuccess ? "opacity-0" : "opacity-100 duration-200"} ` +
                                            `${color === "black" ? "!text-black" : color === "grey" ? "!text-black-tertiary" : "!text-blue"}`
                                        }
                                        size={props.size}
                                        isOutlined
                                    />
                                }
                                bg="none"
                                size="fit"
                                message={!(props.children || props.text) ? "Copy" : undefined}
                            />
                            <div className={"absolute left-0 top-0 duration-150 " + `${clipSuccess ? "scale-100 opacity-100" : "scale-0 opacity-0"}`}>
                                <GoogleIcon
                                    icon="check"
                                    className={color === "black" ? "!text-black" : color === "grey" ? "!text-black-tertiary" : "!text-blue"}
                                    size={props.size}
                                    isOutlined
                                />
                            </div>
                        </div>
                        {isContentRight ? (
                            props.children ? (
                                props.children
                            ) : (
                                <span
                                    className={
                                        "ml-1 font-medium " +
                                        ` ${size === "xs" ? "text-xs" : ""}` +
                                        ` ${size === "sm" ? "text-sm" : ""}` +
                                        ` ${size === "md" ? "text-sm" : ""}` +
                                        ` ${size === "lg" ? "text-base" : ""}` +
                                        ` ${color === "blue" ? "text-blue" : ""}` +
                                        ` ${color === "black" ? "text-black" : ""}`
                                    }
                                >
                                    {props.text}
                                </span>
                            )
                        ) : null}
                    </div>
                </CopyToClipboard>
            </div>
        </>
    );
}

export default TextClipboard;

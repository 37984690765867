import { useCallback, useEffect } from "react";

import { setTransferBetweenPurpose } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface useTransferBetweenPurposeInterface {
    handlePurposeChange: (_purpose: string) => void;
}

function useTransferBetweenPurpose(): useTransferBetweenPurposeInterface {
    const dispatch = useDispatch();

    const payReason = useAppSelector((state) => state.sendMoney.payReason);

    useEffect(() => {
        if (!payReason) return;
        dispatch(setTransferBetweenPurpose(payReason));
    }, [payReason]);

    const handlePurposeChange = useCallback((_purpose: string) => {
        dispatch(setTransferBetweenPurpose(_purpose));
    }, []);

    return {
        handlePurposeChange,
    };
}

export default useTransferBetweenPurpose;

import { BasicFinalStatus, TransactionType } from "../../../../../models/posTransaction.constants";
import { FilterDateObj, FilterDateType, FilterDateTypes, getFilterCount } from "../../../../../components/filter/filter.constant";
import { FilterOptionSelectType, FilterOptions } from "../../../../../components/Table/table-filter.types";
import { getTerminalTransactionStatusName, getTerminalTransactionTypeName } from "../../Services/terminals.constants";
import { setIsTerminalTransactionsPaginationLoading, setTerminalTransactionsList } from "../../../../../redux/terminals/slice/terminalsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";

import Parsers from "../../../../../utils/parsers";
import PosTransaction from "../../../../../models/posTransaction";
import { PosTransactionsListRequest } from "../../Services/terminalsApi.types";
// import { DateObj } from "../../Types";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { terminalTransactionsList } from "../../Services/terminalsApi";
import { useAppSelector } from "../../../../../redux/hooks";
// import { TableFilterOptionsInterface } from "../../../../../components/Table";
import { useDispatch } from "react-redux";

function useSpecificTerminalTransactions() {
    const dispatch = useDispatch();

    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [groupSize, setGroupSize] = useState(0);

    const [posTransactions, setPosTransactions] = useState<PosTransaction[]>([]);

    const [filter, setFilter] = useState<PosTransactionsListRequest>({});
    const [dateType, setDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilter, setSelectedFilter] = useState<PosTransactionsListRequest>({});
    const [selectedDateType, setSelectedDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const terminals = useAppSelector((state) => state.terminals.terminals);
    const isTransactionPaginationLoading = useAppSelector((state) => state.terminals.isTransactionPaginationLoading);

    const specificallySelectedTerminal = useAppSelector((state) => state.terminals.specificallySelectedTerminal);
    // const selectedTransactionFilterState = useAppSelector((state) => state.terminals.selectedTransactionFilterState);

    // const handleFilteredTerminalTransactions = useCallback(
    //     <T>(data: T) => {
    //         const updatedList = produce(selectedTransactionFilterState, (draft) => {
    //             return (draft = { ...draft, ...data, terminalId: specificallySelectedTerminal?.id || "" });
    //         });
    //         dispatch(setSelectedTerminalTransactionFilterState());
    //         dispatch(setTerminalTransactionsFilterState(updatedList as TerminalTransactionFilterTypes));
    //         dispatch(setIsTerminalTransactionsPaginationLoading(true));
    //         void handleGetTerminalTransactions(updatedList as TerminalTransactionFilterTypes);
    //     },
    //     [selectedTransactionFilterState, specificallySelectedTerminal]
    // );

    useEffect(() => {
        setSelectedFilterCount(getFilterCount(filter));
    }, [filter]);

    const handleGetTerminalTransactions = useCallback(
        async (_data: PosTransactionsListRequest) => {
            try {
                dispatch(setIsTerminalTransactionsPaginationLoading(true));
                const res = await terminalTransactionsList({ ..._data, terminalId: specificallySelectedTerminal?.id || _data.terminalId });
                dispatch(setTerminalTransactionsList(res.transactions));
                setPosTransactions(res.transactions);
                setTotal(res.total);
                setGroupSize(res.groupSize);
            } catch (err) {
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                dispatch(setIsTerminalTransactionsPaginationLoading(false));
            }
        },
        [specificallySelectedTerminal]
    );

    const handleApplyFilter = async () => {
        setSelectedFilter(filter);
        setSelectedDateType(dateType);
        await handleGetTerminalTransactions(filter);
    };

    const handlePaginationRequest = async (_offset: number) => {
        setOffset(_offset);
        await handleGetTerminalTransactions({ offset });
    };

    const handleClearFilter = useCallback(async () => {
        setOffset(0);
        setFilter({});
        setSelectedFilter({});
        setDateType(FilterDateTypes.ALL_TIME);
        setSelectedDateType(FilterDateTypes.ALL_TIME);
        await handleGetTerminalTransactions({});
    }, []);

    // const handleClearFilter = useCallback(() => {
    //     setFilter({
    //         types: [],
    //         statuses: [],
    //         terminalId: specificallySelectedTerminal?.id,
    //     });
    //     handleGetTerminalTransactions({
    //         types: [],
    //         statuses: [],
    //         terminalId: specificallySelectedTerminal?.id,
    //     });
    // }, [filter]);

    // offset?: number;
    // endDate?: string;
    // startDate?: string;
    // query?: string;

    // types?: number[];
    // statuses?: number[];
    // terminalId?: string;

    const filterOption: FilterOptions = useMemo(
        () => ({
            filterOptions: [
                {
                    text: "Date",
                    value:
                        filter.date !== "" && !isNullOrUndefined(filter.date) ? ({ name: dateType, date: filter.date } as FilterDateType) : undefined,
                    selectType: FilterOptionSelectType.DATE_OPTION,
                    selectedValue:
                        selectedFilter.date !== "" || isNullOrUndefined(selectedFilter.date)
                            ? ({ name: selectedDateType, date: selectedFilter.date } as FilterDateType)
                            : undefined,
                    onClick: (_value) => {
                        if (!(typeof _value === "object" && "date" in _value && "name" in _value)) return;
                        setFilter((prev) => ({
                            ...prev,
                            date: _value.date as FilterDateObj,
                            endDate: String((_value.date as FilterDateObj).end) || undefined,
                            startDate: String((_value.date as FilterDateObj).begin) || undefined,
                        }));
                        setDateType(_value.name as FilterDateTypes);
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, date: "", endDate: "", startDate: "" };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Type",
                    value: filter.types,
                    subList: [
                        { text: getTerminalTransactionTypeName(TransactionType.CARD_PAYMENT), value: TransactionType.CARD_PAYMENT },
                        { text: getTerminalTransactionTypeName(TransactionType.TRANSFER_IN), value: TransactionType.TRANSFER_IN },
                        { text: getTerminalTransactionTypeName(TransactionType.TRANSFER_OUT), value: TransactionType.TRANSFER_OUT },
                    ],
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.types,
                    onClick: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, TransactionType);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            types: prev.types
                                ? prev.types?.includes(filterValue)
                                    ? prev.types.filter((_) => _ !== filterValue)
                                    : [...prev.types, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, types: [] };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, TransactionType);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            types: filter?.types?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Status",
                    value: filter.statuses,
                    subList: [
                        { text: getTerminalTransactionStatusName(BasicFinalStatus.SUCCESSFUL), value: BasicFinalStatus.SUCCESSFUL },
                        { text: getTerminalTransactionStatusName(BasicFinalStatus.FAILED), value: BasicFinalStatus.FAILED },
                    ],
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.statuses,
                    onClick: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, BasicFinalStatus);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            statuses: prev.statuses
                                ? prev.statuses?.includes(filterValue)
                                    ? prev.statuses.filter((_) => _ !== filterValue)
                                    : [...prev.statuses, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, statuses: [] };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, BasicFinalStatus);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            statuses: filter?.statuses?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Terminal",
                    value: filter.terminalId,
                    subList: terminals.map((_) => ({ text: _.serialNumber || _.name || "", value: _.id })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.terminalId,
                    onClick: (_value) => {
                        const filterValue = Parsers.string(_value);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            terminalId: prev.terminalId !== filterValue ? filterValue : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, terminalId: "" };
                        handleGetTerminalTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
            ],
        }),
        [filter, terminals, selectedFilter, selectedDateType]
    );

    // const handlePaginationRequest = useCallback((offset: number) => {
    //     dispatch(setTerminalTransactionsPaginationOffset(offset));
    //     handleFilteredTerminalTransactions({ offset });
    // }, []);

    // const handleClearAll = useCallback(() => {
    //     dispatch(resetTerminalTransactionsFilterState());
    //     dispatch(resetTerminalTransactionsDateFilterState());
    //     void handleGetTerminalTransactions({
    //         query: "",
    //         offset: 0,
    //         types: [],
    //         statuses: [],
    //         terminalId: specificallySelectedTerminal?.id || "",
    //         startDate: "",
    //         endDate: "",
    //     });
    // }, [specificallySelectedTerminal]);

    return {
        //
        total,
        offset,
        groupSize,
        filterOption,
        posTransactions,
        selectedFilterCount,
        isTransactionPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleGetTerminalTransactions,
    };
}

export default useSpecificTerminalTransactions;

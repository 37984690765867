import { AccountInitState, MainInitState } from "../../../../../redux/init/slice/initSlice.types";
import { abortHomeTransfer, dashboardDetails } from "../../Services/homeApi";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardDetailsResponse } from "../../Services/homeApi.types";
import { IRootState } from "../../../../../redux/rootReducer";
import { PageTitle } from "../../../../../helpers/AppConstants";
import Transaction from "../../../../../models/transaction";
import { UseHomeStateInterface } from "./useHomeState.types";
import { canShowSplitBanner } from "../../../../../helpers/app-reminders";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { setMultipleTransactions } from "../../../../../redux/transaction/slice/transactionSlice";

const useHomeState = (): UseHomeStateInterface => {
    document.title = PageTitle.DASHBOARD_PAGE;
    window.history.replaceState({}, "Home", "/");

    const dispatch = useDispatch();
    const main = useSelector<IRootState, MainInitState | null>((state) => state.init.main || null);
    const account = useSelector<IRootState, AccountInitState | null>((state) => state.init.account);
    const companyId = useSelector<IRootState, string | null>((state) => state.init.selectedCompanyId);

    const [inflow, setInflow] = useState<Transaction[]>([]);
    const [outflow, setOutFlow] = useState<Transaction[]>([]);
    const [pending, setPending] = useState<Transaction[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [showAmount, setShowAmount] = useState<boolean>(false);
    const [showPageBanner, setShowPageBanner] = useState<boolean>(false);
    const [dashboardDetailsResponse, setDashboardDetailsResponse] = useState<DashboardDetailsResponse | null>(null);

    const handleToggleOnboardingModal = () => {
        setShowAmount((prev) => !prev);
    };
    const handleToggleBanner = () => {
        setShowPageBanner(false);
    };

    const handleDashboardDetails = useCallback(async () => {
        try {
            const res = await dashboardDetails();
            setDashboardDetailsResponse(res);
            dispatch(setMultipleTransactions([...res.pending.transactions, ...res.recent.credit, ...res.recent.debit]));
            setInflow(res.recent.credit);
            setOutFlow(res.recent.debit);
            setPending(res.pending.transactions);
            setIsLoaded(true);
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!main || companyId === "" || !companyId || companyId.length < 5 || !account) return;
        const selectedCompany = account.companies.find((c) => c.id === companyId);
        if (selectedCompany && selectedCompany.accountCreated) {
            setShowPageBanner(canShowSplitBanner());
            void handleDashboardDetails();
        }
    }, [main, companyId, handleDashboardDetails]);

    useEffect(() => {
        return () => {
            abortHomeTransfer();
            setDashboardDetailsResponse(null);
            setInflow([]);
            setOutFlow([]);
            setPending([]);
        };
    }, []);

    return {
        main,
        inflow,
        outflow,
        pending,
        isLoaded,
        showAmount,
        accountsData: main?.companyDetails || null,
        showPageBanner,
        dashboardDetailsResponse,

        handleToggleBanner,
        handleToggleOnboardingModal,
    };
};

export default useHomeState;

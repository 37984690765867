import { TeamMemberItem, TeamMembersFeature } from "../team-members.types";
import {
    addManageCardRole,
    addManageTerminalRole,
    addViewCardRole,
    addViewTerminalRole,
    noAccessCardRole,
    noAccessTerminalRole,
} from "../../account-settings/account-settings.api";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FeatureAccessLevelsArrayType } from "./../../../../../../redux/settings/teamMembers/slice/teamMembersSettingsSlice.types";
import { GenericObject } from "../../../../../../helpers/types";
import { IRootState } from "../../../../../../redux/rootReducer";
import User from "../../../../../../models/user";
// import UserFeatureMeta from "../../../../../../models/userFeatureMeta";
import { UserRole } from "../../../../../../models/userAccount.constants";
import { errorTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../utils/getErrorMessage";
import { listTeamMembers } from "../team-members.api";
import { messageTrue } from "../../../../../../redux/app-toast/app-toast-slice";
import { updateFeatureAccessLevelsArray } from "../../../../../../redux/settings/teamMembers/slice/teamMembersSettingsSlice";
import UserAccountMeta from "../../../../../../models/userAccountMeta";

export interface UseTeamMemberAccessLevelRoleResponse {
    isSubmitting: boolean;
    handleUpdateAccessLevel: (accountMeta: UserAccountMeta, role: UserRole, feature: number) => Promise<void>;
    handleDefaultFetchAccessLevels: () => Promise<void>;
}

export const checkRes = async (res: GenericObject | unknown) => {
    const response = (await res) as TeamMembersFeature;
    return response;
};

// function useTeamMemberAccessLevelRole(updateAccountMeta: (accountMeta: UserFeatureMeta) => void): UseTeamMemberAccessLevelRoleResponse {
function useTeamMemberAccessLevelRole(updateAccountMeta: (accountMeta: UserAccountMeta) => void): UseTeamMemberAccessLevelRoleResponse {
    const featureAccessLevelsArray: FeatureAccessLevelsArrayType[] = useSelector(
        (state: IRootState) => state.teamMemberSettings.featureAccessLevelsArray
    );

    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleDefaultFetchAccessLevels = async () => {
        const res = await listTeamMembers();
        const allMembers: User[] | any[] = new Array(1).fill(res.map((item: TeamMemberItem) => item.teamMember));

        if (allMembers) {
            const membersData: FeatureAccessLevelsArrayType[] = allMembers[0].map((member: User) => ({
                memberId: member.id,
                pos: member.hasManageTerminalAccess ? 0 : member.hasViewTerminalAccess ? 1 : 2,
                cards: member.hasManageCardAccess ? 0 : member.hasViewCardAccess ? 1 : 2,
            }));

            dispatch(updateFeatureAccessLevelsArray(membersData));
        }
    };

    const handleMemberAccessRole = (role: number, feature: number, accountId: string) => {
        const updatedRoleArray =
            feature === 0
                ? featureAccessLevelsArray.map((member) => {
                      return member.memberId === accountId ? { ...member, pos: role } : member;
                  })
                : featureAccessLevelsArray.map((member) => {
                      return member.memberId === accountId ? { ...member, cards: role } : member;
                  });

        dispatch(updateFeatureAccessLevelsArray(updatedRoleArray));
    };

    const handleUpdateAccessLevel = useCallback(
        async (accountMeta: UserAccountMeta, role: number, feature: number) => {
            setIsSubmitting(true);

            const accountId = accountMeta.id;

            try {
                // POS Terminal
                if (role === 0 && feature === 0) {
                    await addManageTerminalRole(accountMeta.id);
                    handleMemberAccessRole(0, 0, accountId);
                }
                if (role === 1 && feature === 0) {
                    await addViewTerminalRole(accountMeta.id);
                    handleMemberAccessRole(1, 0, accountId);
                }
                if (role === 2 && feature === 0) {
                    await noAccessTerminalRole(accountMeta.id);
                    handleMemberAccessRole(2, 0, accountId);
                }

                // Corporate Cards
                if (role === 0 && feature === 1) {
                    await addManageCardRole(accountMeta.id);
                    handleMemberAccessRole(0, 1, accountId);
                }

                if (role === 1 && feature === 1) {
                    await addViewCardRole(accountMeta.id);
                    handleMemberAccessRole(1, 1, accountId);
                }
                if (role === 2 && feature === 1) {
                    await noAccessCardRole(accountMeta.id);
                    handleMemberAccessRole(2, 1, accountId);
                }
                await handleDefaultFetchAccessLevels();
                dispatch(messageTrue("Team Member Access Level Updated"));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
            } finally {
                setIsSubmitting(false);
            }
        },
        [addManageTerminalRole, addViewTerminalRole, noAccessTerminalRole, addManageCardRole, addViewCardRole, noAccessCardRole, updateAccountMeta]
    );

    return {
        isSubmitting,
        handleUpdateAccessLevel,
        handleDefaultFetchAccessLevels,
    };
}

export default useTeamMemberAccessLevelRole;

export enum PingTime {
    //10 mins
    MAIN_APP_IDLE = 10,
    //30mins
    APPLICATION_APP_IDLE = 30,
}

export type PingResponse = {
    success: boolean;
    message: string;
};

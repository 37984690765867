import AddFundsCard from "./AddFundsCard";
import { IRootState } from "../../../../../redux/rootReducer";
import IndividualAccountsDetailsCardSkeleton from "./IndividualAccountsDetailsCardSkeleton";
import InfoTextButton from "../../../../../components/button/Info-text-button";
import LineDropdown from "../Dropdowns/LineDropdown";
import Money from "../../../../../components/money";
import TooltipIcons from "../../../../../components/tooltip";
import { useSelector } from "react-redux";

interface Props {
    isLoading: boolean;
}
function IndividualAccountsDetailsCard(props: Props): JSX.Element {
    const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);
    const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);
    const individualAccount = useSelector((state: IRootState) => state.account.individualAccount);
    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const accountType = useSelector((state: IRootState) => state.init.main?.meta.accountTypes.find((t) => t.type === individualAccount?.type));

    const splitInflowText = individualAccount?.splitInflowInstruction
        ? (individualAccount?.splitInflowInstruction.ratio || [])
              .map((_) => `${accounts?.find((_acc) => _acc.id === _.userAccountId)?.lencoNameMin} - ${_.percentage}%`)
              .join()
              .replace(",", ", ")
        : "";

    return (
        <>
            {props.isLoading ? (
                <IndividualAccountsDetailsCardSkeleton />
            ) : individualAccount ? (
                <div className="h-full w-full space-y-6 rounded-lg bg-white px-5 py-6 leading-normal shadow">
                    <div className="flex h-10 flex-row items-center justify-between">
                        {accountType && (
                            <div className="bg-black-backdrop rounded-md border border-grey bg-grey-backdrop px-1.5 py-0.5 text-sm font-medium">
                                <div className="text-black-secondary">{accountType.name}</div>
                            </div>
                        )}
                        <div className="flex h-full items-center justify-center">
                            {individualAccount.lowBalanceInstruction ||
                            individualAccount.excessBalanceInstruction ||
                            !individualAccount.accountRestrictions.canSendMoneyToAnyAccount ||
                            individualAccount?.splitInflowInstruction ? (
                                <TooltipIcons
                                    bg="none"
                                    icon={
                                        <InfoTextButton isLoading={false} content={<span className="font-normal text-info">Account Policy</span>} />
                                    }
                                    message={
                                        <div>
                                            {individualAccount.lowBalanceInstruction ? (
                                                <p>
                                                    Minimum Balance:{" "}
                                                    <Money
                                                        amount={individualAccount.lowBalanceInstruction?.amount || 0}
                                                        currency={individualAccount.bankAccountCurrency}
                                                    />
                                                </p>
                                            ) : null}
                                            {individualAccount.excessBalanceInstruction ? (
                                                <p>
                                                    Excess Balance:{" "}
                                                    <Money
                                                        amount={individualAccount.excessBalanceInstruction?.amount || 0}
                                                        currency={individualAccount.bankAccountCurrency}
                                                    />
                                                </p>
                                            ) : null}
                                            {individualAccount?.splitInflowInstruction ? <p>Split Inflow: {splitInflowText}</p> : null}
                                            {!individualAccount.accountRestrictions.canSendMoneyToAnyAccount ? (
                                                <p>
                                                    Account Restriction:{" "}
                                                    {individualAccount.accountRestrictions.canSendMoneyToSpecificAccounts ? (
                                                        <span>Send Money to Only Specific Accounts</span>
                                                    ) : null}
                                                    {individualAccount.accountRestrictions.cannotSendMoney ? <span>Can Not Send Money</span> : null}
                                                </p>
                                            ) : null}
                                        </div>
                                    }
                                    size="fit"
                                />
                            ) : null}

                            <LineDropdown data={individualAccount} />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center justify-start space-y-5">
                        <AddFundsCard userAccount={individualAccount} isCard />
                        <div className="flex w-full flex-col rounded-xl bg-grey-backdrop px-4">
                            {isNigeria && (
                                <div className="flex w-full flex-col items-center justify-between border-b border-grey-secondary py-4 xs:flex-row">
                                    <span className="text-base font-normal text-black-tertiary">Bank Address</span>
                                    <span className="max-w-55% text-right text-base font-medium text-black-secondary">
                                        12 Rev Ogunbiyi street, GRA, Ikeja, Lagos, Ikeja 100271, Lagos{" "}
                                    </span>
                                </div>
                            )}
                            {/* {isZambia && (
                                <div className="flex w-full flex-col items-center justify-between border-b border-grey-secondary py-4 xs:flex-row">
                                    <span className="text-base font-normal text-black-tertiary">Bank Address</span>
                                    <span className="max-w-50% text-right text-base font-medium text-black-secondary">
                                        Plot 724, Adetokunbo Ademola Street, Victoria Island, Lagos.
                                    </span>
                                </div>
                            )} */}
                            <div className="flex w-full flex-col items-center justify-between border-b border-grey-secondary py-4 xs:flex-row">
                                <span className="text-base font-normal text-black-tertiary">
                                    {isZambia ? "Payout Limit" : "Transfer limit"} (Daily)
                                </span>
                                <span className="max-w-50% text-base font-medium text-black-secondary">
                                    <Money amount={individualAccount.nipDailyTransferLimit} currency={individualAccount.bankAccount?.currency} />
                                </span>
                            </div>
                            <div className="flex w-full flex-col items-center justify-between py-4 xs:flex-row">
                                <span className="text-base font-normal text-black-tertiary">Team Banking</span>
                                <span className="max-w-50% text-base font-medium text-black-secondary">Unlimited</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default IndividualAccountsDetailsCard;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../../../../redux/rootReducer";
import { setFeatureAccesslevel, updateFeatureAccess } from "../../../../../../../redux/settings/teamMembers/slice/teamMembersSettingsSlice";
import { accessLevelText } from "../../../../Services/team-members/team-members.constants";
import TeamMemberAccessLevelDropdown from "./TeamMemberAccessLevelDropdown";
import useTeamMemberAccessLevelRole from "../../../../Services/team-members/hooks/useTeamMemberAccessLevelRole";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";

interface TeamMemberRoleProps {
    featureMeta: UserAccountMeta;
    updateAccountMeta: (featureMeta: UserAccountMeta) => void;
    canUpdate: boolean;
    featureIndex: number;
    id: number;
}

function TeamMemberAccessLevel({ featureMeta, canUpdate, featureIndex, updateAccountMeta }: TeamMemberRoleProps): JSX.Element {
    const { isSubmitting, handleUpdateAccessLevel } = useTeamMemberAccessLevelRole(updateAccountMeta);
    const featureAccessLevels = useSelector((state: IRootState) => state.teamMemberSettings.featureAccessLevels);

    const dispatch = useDispatch();

    const handleDropDownItemSelect = (featureNumber: number, roleNumber: number) => {
        if (featureMeta.isAdmin) return;
        dispatch(setFeatureAccesslevel(roleNumber));
        dispatch(updateFeatureAccess({ featureNumber: featureNumber, level: roleNumber }));
    };

    return (
        <>
            <div className="flex flex-row items-start justify-start space-x-2 space-y-0 sm:flex-col sm:space-x-0 sm:space-y-2">
                <TeamMemberAccessLevelDropdown
                    canUpdate={canUpdate}
                    text={accessLevelText[featureAccessLevels[featureIndex].access].text}
                    currentValue={accessLevelText[featureAccessLevels[featureIndex].access].value}
                    isSubmitting={isSubmitting}
                    onDropdownItemSelect={async (role: number) => {
                        handleDropDownItemSelect(featureIndex, role);
                        return await handleUpdateAccessLevel(featureMeta, role, featureIndex);
                    }}
                    options={accessLevelText.map((role: any, idx) => ({
                        value: idx,
                        text: accessLevelText[idx].text,
                    }))}
                    // roleType
                />
            </div>
        </>
    );
}

export default TeamMemberAccessLevel;

import React, { useEffect, useState } from "react";

import FullPageTrayFooter from "../../../../../../../components/Trays/FullPageTray/FullPageTrayFooter";
import StandAlonePendingApproval from "../../../../../payments-new/componentss/Elements/PendingApproval/StandAlonePendingApproval";
import Transaction from "../../../../../../../models/transaction";
import { useAppSelector } from "../../../../../../../redux/hooks";

interface TransactionsDetailCardPendingApprovalActionsInterface {
    isAdmin: boolean;
    dataType: string;
    transaction: Transaction;
    handleUpdateDeclinedTransaction: () => void;
    handleTogglePendingApprovalShowModal: () => void;
}

function TransactionsDetailCardPendingApprovalActions({
    isAdmin,
    dataType,
    transaction,
    handleUpdateDeclinedTransaction,
    handleTogglePendingApprovalShowModal,
}: TransactionsDetailCardPendingApprovalActionsInterface): JSX.Element {
    const userAccountMeta = useAppSelector((state) => state.init.main?.companyDetails.userAccountsMeta);

    const [transactionDetail, setTransactionDetail] = useState<Transaction | null>(null);

    const currentUserAccountMeta = userAccountMeta?.find((_el) => _el.userAccountId === transaction?.origination?.userAccount?.id);

    useEffect(() => {
        if (!transaction) return;
        setTransactionDetail(transaction);
    }, [transaction]);

    return (
        <>
            {transactionDetail &&
                isAdmin &&
                currentUserAccountMeta?.canApprove() &&
                transactionDetail.destination &&
                transactionDetail.isPendingApproval &&
                (transactionDetail.destination.customerAccount ||
                    transactionDetail.destination.userAccount ||
                    transactionDetail.destination.card) && (
                    <FullPageTrayFooter size="md" dataType={dataType}>
                        <StandAlonePendingApproval
                            transaction={transactionDetail}
                            handleShowModal={handleTogglePendingApprovalShowModal}
                            handleUpdateState={handleUpdateDeclinedTransaction}
                        />
                    </FullPageTrayFooter>
                )}
        </>
    );
}

export default TransactionsDetailCardPendingApprovalActions;

import CreateAccountRequest from "../../../models/createAccountRequest";
import Transaction from "../../../models/transaction";
import UserAccount from "../../../models/userAccount";
import { getAccountSummaryResponse } from "../../../modules/dashboard/Accounts/Services/accounts-api.types";

export enum AccountModalStageType {
    INITIAL = 0,
    DETAILS = 1,
    ACTIVATE = 2,
}

export enum ZambiaAddMoneyModalSections {
    ACCOUNT_AND_METHOD = 1,
    FUNDING = 2,
}

export enum ZambiaFundingMethod {
    BANK_TRANSFER = 1,
    MOBILE_MONEY = 2,
    CARD_PAYMENT = 3,
}

export const ZambiaFundingMethods = [
    {
        id: ZambiaFundingMethod.MOBILE_MONEY,
        name: "Mobile Money",
        icon: "",
        // icon: MobileMoney as string,
    },
    {
        id: ZambiaFundingMethod.BANK_TRANSFER,
        name: "Bank Transfer",
        icon: "",
        // icon: BankTransfer as string,
    },
];

export interface AccountState {
    individualAccount: UserAccount | null;
    individualAccountSummary: getAccountSummaryResponse | null;

    accounts: string[];
    subAccountName: string;
    subAccountDescription: string;
    subAccountModalStage: AccountModalStageType;

    isEditTransactionAliasModal: boolean;
    isAddFundsModalOpen: boolean;
    isZambiaAddFundsModalOpen: boolean;
    isAboutMyAccountModalOpen: boolean;
    isEditAccountDescriptionModalOpen: boolean;
    transactionData: Transaction | null;

    pendingAccounts: CreateAccountRequest[];

    listOfSubAccountsToBeActivated: string[];
    listOfSubAccountsToBeCreated: CreateAccountObj[];
    pendingSubAccountsToBeActivatedList: CreateAccountRequest[];
}

export type CreateAccountObj = {
    name: string;
    description: string;
    canDelete: boolean;
    index: number;
    isLoading: boolean;
};

import React, { useCallback, useEffect } from "react";
import {
    resetAllZambiaCollectionsTransactionsData,
    setIsExportZambiaCollectionsTransactionsTrayOpen,
    setIsZambiaCollectionsTransactionsDetailCardOpen,
    setSelectedZambiaCollectionsTransactionsDetails,
    setSelectedZambiaCollectionsTransactionsFilterStateInstant,
} from "../../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import ExportZambiaCollectionsTransactionsTray from "./Tray/ExportZambiaCollectionsTransactionsTray";
import GoogleIcon from "../../../../../../components/google-icon";
import { SubTextType } from "../../../../../../components/Table/Type/SubTextType";
import Table from "../../../../../../components/Table";
import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";
import { TextType } from "../../../../../../components/Table/Type/TextType";
import { ZambiaCollectionsStageType } from "../../../../../../redux/zambia/collections/collectionsSlice.types";
import ZambiaCollectionsTransactionTray from "./Tray/ZambiaCollectionsTransactionTray";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import useZambiaCollectionsTransactions from "../../../hookss/Collections/useZambiaCollectionsTransactions";

const COLLECTION_TRANSACTION_DATATYPE = "zambia-collections-transaction";

export type ZambiaCollectionTransactionsParams = {
    type: string;
    query: string;
};

const allCollectionTransactionsHeader: TableHead = [
    { text: "Date", pos: 1, headType: TableColumnType.TEXT_WITH_SUBTEXT },
    { text: "From", pos: 2, headType: TableColumnType.TEXT },
    { text: "Channel", pos: 3, headType: TableColumnType.TEXT },
    { text: "Channel Type", pos: 4, headType: TableColumnType.TEXT },
    { text: "Amount", pos: 4, headType: TableColumnType.AMOUNT },
    { text: "Status", pos: 4, headType: TableColumnType.STATUS },
];

function ZambiaCollectionTransactions(): JSX.Element {
    const dispatch = useDispatch();
    const { type, query } = useParams<ZambiaCollectionTransactionsParams>();

    const {
        total,
        offset,
        groupSize,
        filterOption,
        selectedFilterCount,
        zambiaCollectionsTransactionsList,
        isZambiaCollectionsTransactionsPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsTransactions,
    } = useZambiaCollectionsTransactions();

    useEffect(() => {
        if (!type || type !== ZambiaCollectionsStageType.TRANSACTION) return;
        if (query) {
            dispatch(setSelectedZambiaCollectionsTransactionsFilterStateInstant({ offset: 0, query: query || "" }));
        }
        void handleFilteredZambiaCollectionsTransactions({ offset: 0, query: query || "" });
    }, [query]);

    const onExport = useCallback(() => {
        dispatch(setIsExportZambiaCollectionsTransactionsTrayOpen(true));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetAllZambiaCollectionsTransactionsData());
        };
    }, []);

    return (
        <>
            <ZambiaCollectionsTransactionTray />
            <ExportZambiaCollectionsTransactionsTray />

            <div className="relative mt-4 flex h-full w-full flex-col items-start justify-start">
                <Table
                    //filter
                    filterOptions={filterOption}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    totalSelectedItems={selectedFilterCount}
                    //pagination
                    total={total}
                    offset={offset}
                    groupSize={groupSize}
                    paginateFunction={(_, _offset) => handlePaginationRequest(_offset)}
                    isPaginateLoading={isZambiaCollectionsTransactionsPaginationLoading}
                    //table
                    isSticky
                    heads={allCollectionTransactionsHeader}
                    dataType={COLLECTION_TRANSACTION_DATATYPE}
                    isLoading={!!isZambiaCollectionsTransactionsPaginationLoading}
                    rows={
                        zambiaCollectionsTransactionsList?.map((obj) => {
                            const result = produce(obj?.attempts, (draft) => {
                                draft?.sort?.((a, b) => (a.isSuccessful === b?.isSuccessful ? 0 : a?.isSuccessful ? -1 : 1))?.[0];
                            });
                            const _trans = result[0];

                            return {
                                onRecordClick: () => {
                                    dispatch(setIsZambiaCollectionsTransactionsDetailCardOpen(true));
                                    dispatch(setSelectedZambiaCollectionsTransactionsDetails(obj.id || ""));
                                },
                                record: [
                                    {
                                        key: "Date",
                                        text: obj?.createdAt,
                                        textType: TextType.DATE,
                                        subText: obj?.createdAt,
                                        subTextType: SubTextType.TIME,
                                    },
                                    {
                                        key: "From",
                                        text:
                                            _trans.mobileMoneyCollection?.debitAccountName || _trans.cardPaymentCollection?.cardDetails?.name || "-",
                                    },
                                    {
                                        key: "channel",
                                        text: _trans.channelText || "-",
                                    },
                                    {
                                        key: "Channel Type",
                                        text:
                                            _trans.cardPaymentCollection?.cardDetails?.cardType ||
                                            _trans.mobileMoneyCollection?.serviceProviderText ||
                                            "-",
                                    },
                                    {
                                        key: "Amount",
                                        text: obj.amount,
                                        textType: TextType.BALANCE,
                                        currency: obj.currency,
                                    },
                                    {
                                        key: "Status",
                                        text: obj.statusText,
                                        textType: obj?.isSuccessful
                                            ? TextType.TRANSACTION_STATUS_SUCCESS
                                            : obj?.isPending
                                              ? TextType.TRANSACTION_STATUS_PROCESSING
                                              : obj?.isExpired
                                                ? TextType.CARD_STATUS_EXPIRED
                                                : obj?.isCancelled
                                                  ? TextType.TRANSACTION_STATUS_CANCELLED
                                                  : TextType.TRANSACTION_STATUS_FAILED,
                                    },
                                ],
                            };
                        }) || []
                    }
                    extraButton={
                        <ButtonComp size="md" paddingSize="xs" color="grey" buttonType="secondary" func={onExport}>
                            <GoogleIcon icon="download" size="sm" className="-ml-1 text-black-tertiary" />
                            <span className="ml-2 text-sm font-normal text-black-tertiary">Export</span>
                        </ButtonComp>
                    }
                />
            </div>
        </>
    );
}

export default ZambiaCollectionTransactions;

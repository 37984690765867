import { CardShowCVVRequest, CardsListRequest } from "../../Services/cardsApi.types";
import { CardStatus, CardWalletType } from "../../../../../models/card.constants";
import { CardStatusArray, CardStatusName, CardWalletArray, CardWalletName } from "../../Services/cards.constants";
import { FilterOptionSelectType, FilterOptions } from "../../../../../components/Table/table-filter.types";
import { cardInit, cardShowCVV, cardsGeneralSettings, cardsList, teamMembersWithManageAccess } from "../../Services/cardsApi";
import { errorTrue, messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import {
    setCardUsers,
    setIsCardsFiltering,
    setNewCards,
    setShowCVV,
    setTeamMembersWithManageAccess,
    setTeamMembersWithViewAccess,
} from "../../../../../redux/cards/slice/cardsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootState } from "../../../../../redux/rootReducer";
import Parsers from "../../../../../utils/parsers";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import { getFilterCount } from "../../../../../components/filter/filter.constant";
import { listTeamMembers } from "../../../settings-new/Services/team-members/team-members.api";

const useCards = () => {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState<CardsListRequest>({});
    const [selectedFilter, setSelectedFilter] = useState<CardsListRequest>({});

    const [cardsTotal, setCardsTotal] = useState(0);
    const [cardsOffset, setCardsOffset] = useState(0);
    const [cardsGroupSize, setCardsGroupSize] = useState(0);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const membersWithManageCardAccess = useSelector((state: IRootState) => state.cards.teamMembersWithManageAccess);
    // const selectedCardsFilterState = useSelector((state: IRootState) => state.cards.selectedCardsFilterState);

    const [cardInitError, setCardInitError] = useState<string | null>(null);
    const [isCardInitLoading, setIsCardInitLoading] = useState<boolean>(false);

    const [cardsListError, setCardsListError] = useState<string | null>(null);
    const [isCardsListLoading, setIsCardsListLoading] = useState<boolean>(false);
    const [isFilteredCardsListLoading, setIsFilteredCardsListLoading] = useState<boolean>(false);

    const [cardsGeneralSettingsError, setCardsGeneralSettingsError] = useState<string | null>(null);
    const [isCardsGeneralSettingsLoading, setIsCardsGeneralSettingsLoading] = useState<boolean>(false);

    const [cardShowCvvError, setCardShowCvvError] = useState<string | null>(null);
    const [isCardShowCvvLoading, setIsCardShowCvvLoading] = useState<boolean>(false);

    const [teamMembersWithManageAccessError, setTeamMembersWithManageAccessError] = useState<string | null>(null);
    const [isTeamMembersWithManageAccessLoading, setIsTeamMembersWithManageAccessLoading] = useState(false);

    const [teamMembersError, setTeamMembersError] = useState<string | null>(null);
    const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);

    const [startInit, setStartInit] = useState(true);

    useEffect(() => {
        setSelectedFilterCount(getFilterCount(filter));
    }, [filter]);

    const handleCardInit = useCallback(async (data?: CardsListRequest) => {
        try {
            setCardInitError(null);
            setIsCardInitLoading(true);
            const res = await cardInit(data);
            const usersRes = await listTeamMembers();
            const users = usersRes.map((_el) => _el.teamMember);
            dispatch(setCardUsers(users));
            dispatch(setNewCards(res.cardsData));
            setCardsTotal(res.total);
            setCardsGroupSize(res.groupSize);
            dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
            dispatch(setTeamMembersWithViewAccess(res.teamMembersWithViewAccess));
            dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setCardInitError(errorMessage);
        } finally {
            setIsCardInitLoading(false);
            setStartInit(false);
        }
    }, []);

    const handleCardGeneralSettings = useCallback(async () => {
        try {
            setIsCardsGeneralSettingsLoading(true);
            setCardsGeneralSettingsError(null);
            const res = await cardsGeneralSettings();
            dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setCardsGeneralSettingsError(errorMessage);
        } finally {
            setIsCardsGeneralSettingsLoading(false);
        }
    }, [dispatch]);

    const handleCardShowCVV = useCallback(
        async (_data: CardShowCVVRequest) => {
            try {
                setIsCardShowCvvLoading(true);
                setCardShowCvvError(null);
                const res = await cardShowCVV(_data);
                dispatch(setShowCVV(!!res?.ngnPhysical?.showCvv));
                dispatch(messageTrue({ message: "Cards Security updated successfully" }));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                dispatch(errorTrue({ message: errorMessage }));
                setCardsGeneralSettingsError(errorMessage);
            } finally {
                setIsCardShowCvvLoading(false);
            }
        },
        [dispatch]
    );

    const handleGetTeamMembers = useCallback(async () => {
        try {
            setTeamMembersError(null);
            setIsLoadingTeamMembers(true);
            const res = await listTeamMembers();
            const users = res.map((_el) => _el.teamMember);
            dispatch(setCardUsers(users));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTeamMembersError(errorMessage);
        } finally {
            setIsLoadingTeamMembers(false);
        }
    }, [dispatch]);

    const handleGetTeamMembersWithManageAccess = useCallback(async () => {
        try {
            setTeamMembersWithManageAccessError(null);
            setIsTeamMembersWithManageAccessLoading(true);
            const res = await teamMembersWithManageAccess();
            dispatch(setTeamMembersWithManageAccess(res.teamMembersWithManageAccess));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            dispatch(errorTrue({ message: errorMessage }));
            setTeamMembersWithManageAccessError(errorMessage);
        } finally {
            setIsTeamMembersWithManageAccessLoading(false);
        }
    }, [dispatch]);

    const handleCardsList = useCallback(
        async (_data: CardsListRequest, _isFilter: boolean = false) => {
            try {
                setCardsListError(null);
                dispatch(setIsCardsFiltering(_isFilter));
                !_isFilter ? setIsCardsListLoading(true) : setIsFilteredCardsListLoading(true);
                const res = await cardsList(_data);
                const usersRes = await listTeamMembers();
                const users = usersRes.map((_el) => _el.teamMember);
                dispatch(setCardUsers(users));
                dispatch(setNewCards(res.cardsData));
                setCardsTotal(res.total);
                setCardsGroupSize(res.groupSize);
                if (!_isFilter) {
                    dispatch(setIsCardsFiltering(res.cardsData.length > 0));
                }
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                if (err instanceof RequestCancelledError) {
                    return; // do nothing
                }
                dispatch(errorTrue({ message: errorMessage }));
                setCardsListError(errorMessage);
            } finally {
                !_isFilter ? setIsCardsListLoading(false) : setIsFilteredCardsListLoading(false);
            }
        },
        [dispatch]
    );

    const filterOption: FilterOptions = useMemo(
        () => ({
            filterOptions: [
                {
                    text: "Assigned To",
                    value: filter.assignedToIds,
                    subList: membersWithManageCardAccess?.map((_member) => ({
                        text: _member.name,
                        value: _member.id,
                    })),
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.assignedToIds,
                    onClick: (_value) => {
                        const filterValue = Parsers.string(_value);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            assignedToIds: prev.assignedToIds
                                ? prev.assignedToIds?.includes(filterValue)
                                    ? prev.assignedToIds.filter((_) => _ !== filterValue)
                                    : [...prev.assignedToIds, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, assignedToIds: [] };
                        handleCardsList(updatedFilter, true);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.string(_value);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            assignedToIds: filter?.assignedToIds?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleCardsList(updatedFilter, true);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Status",
                    value: filter.cardStatuses,
                    subList: CardStatusArray?.map((status) => ({
                        text: CardStatusName[status],
                        value: status,
                    })),
                    selectType: FilterOptionSelectType.CHECKBOX_OPTION,
                    selectedValue: selectedFilter.cardStatuses,
                    onClick: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, CardStatus);
                        if (!filterValue) return;
                        setFilter((prev) => ({
                            ...prev,
                            cardStatuses: prev.cardStatuses
                                ? prev.cardStatuses?.includes(filterValue)
                                    ? prev.cardStatuses.filter((_) => _ !== filterValue)
                                    : [...prev.cardStatuses, filterValue]
                                : [filterValue],
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, cardStatuses: [] };
                        handleCardsList(updatedFilter, true);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                    onIndividualCancel: (_value) => {
                        const filterValue = Parsers.nullableNumberEnum(_value, CardStatus);
                        if (!filterValue) return;
                        const updatedFilter = {
                            ...filter,
                            cardStatuses: filter?.cardStatuses?.filter((_el) => _el !== filterValue) || [],
                        };
                        handleCardsList(updatedFilter, true);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Type",
                    value: filter.walletType,
                    subList: CardWalletArray?.map((type) => ({
                        text: CardWalletName[type],
                        value: type,
                    })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.walletType,
                    onClick: (_value) => {
                        if (!Parsers.nullableNumberEnum(_value, CardWalletType)) return;
                        setFilter((prev) => ({
                            ...prev,
                            walletType: prev.walletType !== _value ? (_value as CardWalletType) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, walletType: "" };
                        handleCardsList(updatedFilter, true);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
            ],
        }),
        [filter, selectedFilter, membersWithManageCardAccess]
    );

    const handleApplyFilter = async () => {
        dispatch(setIsCardsFiltering(true));
        setSelectedFilter(filter);
        await handleCardsList(filter, true);
    };

    const handlePaginationRequest = async (_offset: number) => {
        setCardsOffset(_offset);
        await handleCardsList({ offset: cardsOffset });
    };

    const handleClearFilter = useCallback(async () => {
        setFilter({});
        setCardsOffset(0);
        setSelectedFilter({});
        await handleCardsList({});
    }, []);

    return {
        cardsTotal,
        cardsOffset,
        cardsListError,
        cardsGroupSize,
        teamMembersError,
        isCardsListLoading,
        isLoadingTeamMembers,
        isFilteredCardsListLoading,
        teamMembersWithManageAccessError,
        isTeamMembersWithManageAccessLoading,
        cardsGeneralSettingsError,
        isCardsGeneralSettingsLoading,
        cardShowCvvError,
        isCardShowCvvLoading,
        cardInitError,
        isCardInitLoading,
        filter,
        filterOption,
        startInit,
        selectedFilterCount,
        handleCardsList,
        handleGetTeamMembers,
        handleApplyFilter,
        handlePaginationRequest,
        handleGetTeamMembersWithManageAccess,
        handleCardGeneralSettings,
        handleCardShowCVV,
        handleCardInit,
        handleClearFilter,
    };
};

export default useCards;

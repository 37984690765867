import { FilterDateObj, FilterDateType, FilterDateTypes, getFilterCount } from "../../../../../components/filter/filter.constant";
import { FilterOptionSelectType, FilterOptions } from "../../../../../components/Table/table-filter.types";
import {
    ZambiaCollectionPaymentChannel,
    ZambiaCollectionSource,
    ZambiaCollectionStatus,
} from "../../../../../models/zambia/zambiaCollectionRequest.constant";
import {
    ZambiaCollectionsTransactionsChannelOptions,
    ZambiaCollectionsTransactionsSourceOptions,
    ZambiaCollectionsTransactionsStatusOptions,
} from "./zambiaCollectionsTransactions.constants";
import {
    setIsZambiaCollectionsTransactionsPaginationLoading,
    setZambiaCollectionsTransactionsList,
} from "../../../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import { useCallback, useEffect, useMemo, useState } from "react";

import Parsers from "../../../../../utils/parsers";
import { RequestCancelledError } from "../../../../../helpers/request/requestErrors";
import { ZambiaCollectionTransactionsParams } from "../../Components/Collections/Transactions";
import { ZambiaCollectionsTransactionsRequest } from "../../Services/Collections/zambiaCollectionsTransactionsApi.types";
import { errorTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { useAppSelector } from "../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { zambiaCollectionsTransactions } from "../../Services/Collections/zambiaCollectionsTransactionsApi";

function useZambiaCollectionsTransactions() {
    const dispatch = useDispatch();

    const { query } = useParams<ZambiaCollectionTransactionsParams>();

    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [groupSize, setGroupSize] = useState(0);

    const [filter, setFilter] = useState<ZambiaCollectionsTransactionsRequest>({});
    const [dateType, setDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilter, setSelectedFilter] = useState<ZambiaCollectionsTransactionsRequest>({});
    const [selectedDateType, setSelectedDateType] = useState<FilterDateTypes>(FilterDateTypes.ALL_TIME);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const zambiaCollectionsTransactionsList = useAppSelector((state) => state.zambiaCollectionsTransactions.zambiaCollectionsTransactionsList);
    const isZambiaCollectionsTransactionsPaginationLoading = useAppSelector(
        (state) => state.zambiaCollectionsTransactions.isZambiaCollectionsTransactionsPaginationLoading
    );

    useEffect(() => {
        setSelectedFilterCount(getFilterCount(filter));
    }, [filter]);

    useEffect(() => {
        if (query) {
            setFilter({ query: query || "" });
            setSelectedFilter({ query: query || "" });
        }
    }, [query]);

    const handleFilteredZambiaCollectionsTransactions = useCallback(async (_data: ZambiaCollectionsTransactionsRequest) => {
        try {
            dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(true));
            const res = await zambiaCollectionsTransactions(_data);
            dispatch(setZambiaCollectionsTransactionsList(res.collections));
            setTotal(res.total);
            setGroupSize(res.groupSize);
        } catch (err) {
            if (err instanceof RequestCancelledError) {
                return; // do nothing
            }
            const errorMessage = getErrorMessage(err);
            dispatch(errorTrue({ message: errorMessage }));
        } finally {
            dispatch(setIsZambiaCollectionsTransactionsPaginationLoading(false));
        }
    }, []);

    const handleApplyFilter = async () => {
        setSelectedFilter(filter);
        setSelectedDateType(dateType);
        await handleFilteredZambiaCollectionsTransactions(filter);
    };

    const handlePaginationRequest = async (_offset: number) => {
        setOffset(_offset);
        await handleFilteredZambiaCollectionsTransactions({ offset });
    };

    const handleClearFilter = useCallback(async () => {
        setOffset(0);
        setFilter({});
        setSelectedFilter({});
        setDateType(FilterDateTypes.ALL_TIME);
        setSelectedDateType(FilterDateTypes.ALL_TIME);
        await handleFilteredZambiaCollectionsTransactions({});
    }, []);

    const filterOption = useMemo(
        (): FilterOptions => ({
            filterOptions: [
                {
                    text: "Date",
                    value:
                        filter.date !== "" && !isNullOrUndefined(filter.date) ? ({ name: dateType, date: filter.date } as FilterDateType) : undefined,
                    selectType: FilterOptionSelectType.DATE_OPTION,
                    selectedValue:
                        selectedFilter.date !== "" || isNullOrUndefined(selectedFilter.date)
                            ? ({ name: selectedDateType, date: selectedFilter.date } as FilterDateType)
                            : undefined,
                    onClick: (_value) => {
                        if (!(typeof _value === "object" && "date" in _value && "name" in _value)) return;
                        setFilter((prev) => ({ ...prev, date: _value.date as FilterDateObj }));
                        setDateType(_value.name as FilterDateTypes);
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, date: "" };
                        handleFilteredZambiaCollectionsTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Status",
                    value: filter.status,
                    subList: ZambiaCollectionsTransactionsStatusOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.status,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaCollectionStatus)) return;
                        setFilter((prev) => ({
                            ...prev,
                            status: prev.status !== _value ? (_value as ZambiaCollectionStatus) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, status: "" };
                        handleFilteredZambiaCollectionsTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Channel",
                    value: filter.channel,
                    subList: ZambiaCollectionsTransactionsChannelOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.channel,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaCollectionPaymentChannel)) return;
                        setFilter((prev) => ({
                            ...prev,
                            channel: prev.channel !== _value ? (_value as ZambiaCollectionPaymentChannel) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, channel: "" };
                        handleFilteredZambiaCollectionsTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
                {
                    text: "Source",
                    value: filter.source,
                    subList: ZambiaCollectionsTransactionsSourceOptions.map((_) => ({ text: _.name, value: _.value })),
                    selectType: FilterOptionSelectType.RADIO_OPTION,
                    selectedValue: selectedFilter.source,
                    onClick: (_value) => {
                        if (!Parsers.nullableEnum(_value, ZambiaCollectionSource)) return;
                        setFilter((prev) => ({
                            ...prev,
                            source: prev.source !== _value ? (_value as ZambiaCollectionSource) : "",
                        }));
                    },
                    onCancel: () => {
                        const updatedFilter = { ...filter, source: "" };
                        handleFilteredZambiaCollectionsTransactions(updatedFilter);
                        setFilter(updatedFilter);
                        setSelectedFilter(updatedFilter);
                    },
                },
            ],
        }),
        [filter, selectedFilter, selectedDateType]
    );

    return {
        total,
        offset,
        groupSize,
        filterOption,
        selectedFilterCount,
        zambiaCollectionsTransactionsList,
        isZambiaCollectionsTransactionsPaginationLoading,
        handleApplyFilter,
        handleClearFilter,
        handlePaginationRequest,
        handleFilteredZambiaCollectionsTransactions,
    };
}

export default useZambiaCollectionsTransactions;

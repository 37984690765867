import { useDispatch, useSelector } from "react-redux";

import AirtimePurchaseHeaders from "./AirtimePurchaseHeaders";
import { BillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice.types";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../redux/rootReducer";
import MessageToast from "../../../../../../components/message-toast";
import Money from "../../../../../../components/money";
import VendorBadge from "../vendor-badge";
import { setBillPaymentStage } from "../../../../../../redux/payments/billPayment/slice/billPaymentSlice";
import { useCallback } from "react";

function ReviewPayment(): JSX.Element {
    const dispatch = useDispatch();

    const vendorsList = useSelector((state: IRootState) => state.billPayment.vendorsList);
    const selectedVendorName = useSelector((state: IRootState) => state.airtimePurchase.selectedVendorName);
    const selectedUserAccount = useSelector((state: IRootState) => state.airtimePurchase.selectedUserAccount);
    const airtimePurchaseDetails = useSelector((state: IRootState) => state.airtimePurchase.airtimePurchaseDetails);

    const handleBack = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.PAYMENT_DETAILS));
    }, []);
    const handleNextPage = useCallback(() => {
        dispatch(setBillPaymentStage(BillPaymentStage.MAKE_PAYMENT));
    }, []);

    return (
        <>
            {airtimePurchaseDetails && selectedUserAccount && (
                <>
                    <div className="mx-auto flex w-full max-w-xl flex-col items-start justify-center space-y-9">
                        <AirtimePurchaseHeaders />
                        <div className="flex w-full flex-col space-y-6">
                            <div className="flex w-full flex-col space-y-4 rounded-lg bg-white p-6">
                                <div className="flex flex-col items-center justify-center space-y-3 pb-6">
                                    <VendorBadge
                                        img={
                                            vendorsList?.find((_) => _.name?.toLocaleLowerCase() === selectedVendorName?.toLocaleLowerCase())
                                                ?.logoUrl || undefined
                                        }
                                        type="outflow"
                                    />
                                    <p className="text-sm font-normal !leading-[100%] text-black-tertiary">You&apos;re buying</p>
                                    <p className="text-[32px] font-medium !leading-[100%] text-black">
                                        <Money amount={airtimePurchaseDetails.amount} />
                                    </p>
                                    <p className="text-sm font-normal !leading-[100%] text-black-tertiary">{selectedVendorName} airtime</p>
                                </div>
                                <div className="flex w-full flex-col space-y-3">
                                    <p className="text-sm font-medium leading-[100%] text-black-tertiary">From</p>
                                    <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                        <div className="flex w-full flex-row justify-between">
                                            <span className="text-sm text-black-tertiary">Account Name</span>
                                            <p className="text-right text-sm font-medium text-black-secondary">
                                                {selectedUserAccount && selectedUserAccount?.bankAccount?.accountName}
                                            </p>
                                        </div>
                                        <div className="flex w-full flex-row justify-between">
                                            <span className="text-sm text-black-tertiary">Account Number</span>
                                            <p className="text-right text-sm font-medium text-black-secondary">
                                                {selectedUserAccount && selectedUserAccount?.bankAccount?.accountNumber}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full flex-col space-y-3">
                                    <p className="text-sm font-medium leading-[100%] text-black-tertiary">To</p>
                                    <div className="flex w-full flex-col space-y-3 rounded-lg bg-grey-backdrop p-4">
                                        <div className="flex w-full flex-row justify-between">
                                            <span className="text-sm text-black-tertiary">Provider</span>
                                            <p className="text-right text-sm font-medium text-black-secondary">{selectedVendorName}</p>
                                        </div>
                                        <div className="flex w-full flex-row justify-between">
                                            <span className="text-sm text-black-tertiary">Phone Number</span>
                                            <p className="text-right text-sm font-medium text-black-secondary">{airtimePurchaseDetails.accountId}</p>
                                        </div>
                                        <div className="flex w-full flex-row justify-between">
                                            <span className="text-sm text-black-tertiary">Package</span>
                                            <p className="text-right text-sm font-medium text-black-secondary">Airtime Topup</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MessageToast
                                type="security"
                                message="Ensure you verify that the recipient is genuine as payments can not be reversed after approval."
                                fullWidth
                            />
                        </div>
                        <div className="flex w-full flex-row items-center justify-center space-x-4">
                            <ButtonComp size="xl" color="grey" ripple="light" buttonType="secondary" func={handleBack}>
                                Back
                            </ButtonComp>
                            <ButtonComp size="xl" color="black" ripple="light" buttonType="primary" func={handleNextPage}>
                                Make Payment
                            </ButtonComp>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ReviewPayment;

export default function getInitials(name: string) {
    const formattedStr = name
        .replace(/\d+/g, "")
        .replace(/[^a-zA-Z0-9\s\\/]/g, "")
        .trim()
        .toLocaleLowerCase();

    const secondString = formattedStr.includes("/")
        ? formattedStr.split("/").map((_) => _.trim())[1]
        : formattedStr.includes("lenco")
          ? formattedStr.split("lenco").map((_) => _.trim())[1]
          : formattedStr;
    const secondStringArr = secondString.includes(" ") ? secondString.toLocaleLowerCase().split(" ") : [secondString];

    const secondStringInitials =
        secondStringArr.length < 2
            ? secondStringArr[0].length > 1
                ? (secondStringArr[0] || "").slice(0, 2)
                : null
            : `${(secondStringArr[0] || "").slice(0, 1)}${(secondStringArr[1] || "").slice(0, 1)}`;

    return secondStringInitials;
}

// const formattedName = name.toLocaleLowerCase().includes("/")
//     ? name.toLocaleLowerCase().split("/")
//     : name.toLocaleLowerCase().includes("-")
//       ? name.toLocaleLowerCase().split("-")
//       : name.toLocaleLowerCase().includes("lenco(")
//         ? name.toLocaleLowerCase().split("lenco(")
//         : ["", name];
// const purifiedName = formattedName[formattedName.length - 1].trim();
// const spaceIndex = (purifiedName || "").indexOf(" ");

// return spaceIndex === -1
//     ? (purifiedName || "").slice(0, 2)
//     : `${(purifiedName || "").slice(0, 1)}${(purifiedName || "").slice(spaceIndex + 1, spaceIndex + 2)}`;

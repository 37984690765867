import ButtonComp from "../../../../../components/button/ButtonComp";
import CardCost from "../../../../../assets/svg/Cards/card-cost.svg";
import CardDark from "../../../../../assets/svg/Cards/CardDark.svg";
import CardLight from "../../../../../assets/svg/Cards/CardLight.svg";
import CurrencyCode from "../../../../../components/currency-code";
import { ReactComponent as Fees } from "../../../../../assets/svg/Cards/fees.svg";
import GoogleIcon from "../../../../../components/google-icon";
import { ReactComponent as Mark } from "../../../../../assets/svg/Cards/mark.svg";
import { ReactComponent as Perks } from "../../../../../assets/svg/Cards/perks.svg";
import { setIsCardUsageModalOpen } from "../../../../../redux/cards/slice/cardsSlice";
import { useDispatch } from "react-redux";

function CreateCardSection(): JSX.Element {
    const dispatch = useDispatch();

    // const isStarterAccount = useAppSelector((state) => state.init.main?.companyDetails.company.type === CorporateAccountType.STARTER);

    const handleGetStarted = () => {
        dispatch(setIsCardUsageModalOpen(true));
    };

    return (
        <>
            <div className="flex h-full w-full flex-col items-center space-y-8 px-6">
                <div className="flex-col items-center justify-center space-y-2 text-center">
                    <p className="text-2xl font-medium">Get started with Lenco Cards</p>

                    <div className="flex items-center justify-center space-x-2 rounded-lg border border-info-quin bg-info-backdrop px-4 py-2 text-xs">
                        <div className="self-start pt-1 md:self-center md:pt-0">
                            <GoogleIcon icon="info" />
                        </div>

                        <p className="text-sm text-info">
                            You need a minimum balance of <CurrencyCode />
                            2,000 to request your first <span className="font-bold">FREE</span> card
                        </p>
                    </div>
                </div>
                <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                    <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                        <div className="flex justify-center rounded-lg w-fit-available">
                            <img src={CardLight} alt="Lenco card light" className="w-full" />
                        </div>
                        <div className="space-y-3">
                            <p className="text-base font-medium">Physical Cards</p>
                            <p className="text-sm text-black-tertiary">
                                Set spend limits and manage your business expenses with Lenco Business Debit Cards
                            </p>
                        </div>
                    </div>

                    <div className="space-y-8 rounded-lg border border-grey bg-white p-6 lg:w-1/3">
                        <div className="flex justify-center rounded-lg w-fit-available">
                            <img src={CardDark} alt="Lenco card dark" className="w-full" />
                        </div>
                        <div className="space-y-3">
                            <div className="flex space-x-3">
                                <div className="flex items-center">
                                    <p className="text-base font-medium">Virtual Cards</p>
                                </div>
                                <div className="flex items-center justify-center bg-warning-backdrop px-2 py-1">
                                    <p className="text-xs font-medium text-warning">Coming Soon</p>
                                </div>
                            </div>
                            <p className="text-sm text-black-tertiary">Make online purchases and subscriptions securely with Lenco Virtual Cards</p>
                        </div>
                    </div>
                    <div className="space-y-8 rounded-lg border border-grey bg-white p-6 text-xs lg:w-1/3">
                        <div className="flex w-full flex-col space-y-2 rounded-lg border border-grey-tertiary bg-grey-backdrop p-3">
                            <div className="flex items-center justify-start">
                                <Perks className="h-4 w-4" />
                                <h3 className="ml-2 font-medium text-black">Perks</h3>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <div className="flex items-start justify-start space-x-2">
                                    <div>
                                        <Mark className="mt-1 h-3 w-3" />
                                    </div>
                                    <p className="text-black-tertiary">Assign to team members</p>
                                </div>
                                <div className="flex items-start justify-start space-x-2">
                                    <div>
                                        <Mark className="mt-1 h-3 w-3" />
                                    </div>
                                    <p className="text-black-tertiary">Set daily, weekly or monthly spend limit</p>
                                </div>
                                <div className="flex items-start justify-start space-x-2">
                                    <div>
                                        <Mark className="mt-1 h-3 w-3" />
                                    </div>
                                    <p className="text-black-tertiary">Freeze and unfreeze card anytime</p>
                                </div>
                                <div className="flex items-start justify-start space-x-2">
                                    <div>
                                        <Mark className="mt-1 h-3 w-3" />
                                    </div>
                                    <p className="text-black-tertiary">Categorize and manage expenses seamlessly</p>
                                </div>
                                <div className="flex items-start justify-start space-x-2">
                                    <div>
                                        <Mark className="mt-1 h-3 w-3" />
                                    </div>
                                    <p className="text-black-tertiary">Set channels where the card can be used</p>
                                </div>
                            </div>{" "}
                            <div className="flex w-full flex-col space-y-2">
                                <div className="flex items-center justify-start">
                                    {/* <CardCost /> */}
                                    <img src={CardCost} className="h-4 w-4" alt="cost card icon"></img>
                                    <h3 className="ml-2 font-medium text-black">Card Cost</h3>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <div className="flex items-start justify-start space-x-2">
                                        <div>
                                            <Mark className="mt-1 h-3 w-3" />
                                        </div>
                                        <p className="text-black-tertiary">First card is free (Pro and Prime customers only).</p>
                                    </div>
                                    <div className="flex items-start justify-start space-x-2">
                                        <div>
                                            <Mark className="mt-1 h-3 w-3" />
                                        </div>
                                        <p className="text-black-tertiary">
                                            Subsequent cards cost <span className="text-black-secondary">₦1,000 each</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col space-y-2">
                                <div className="flex items-center justify-start">
                                    <Fees className="h-4 w-4" />
                                    <h3 className="ml-2 font-medium text-black">Fees</h3>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <div className="flex items-start justify-start space-x-2">
                                        <div>
                                            <Mark className="mt-1 h-3 w-3" />
                                        </div>
                                        <div className="flex flex-col items-start justify-start space-y-0.5">
                                            <p className="text-black-tertiary">
                                                Monthly maintenance fee of <span className="text-black-secondary">₦300</span>
                                            </p>
                                            <p className="text-xs text-black-tertiary">Fees only apply for months card was used</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pb-5">
                    <ButtonComp buttonType="primary" color="black" onClick={handleGetStarted}>
                        Get Started
                    </ButtonComp>
                </div>
            </div>
        </>
    );
}

export default CreateCardSection;

import { Dispatch, SetStateAction } from "react";

import AccountSettingsActionButtons from "../AccountSettingsActionButtons";
import GoogleIcon from "../../../../../../components/google-icon";
import Input from "../../../../../../components/inputs/Input";
import { SplitInflowRatio } from "../../../Services/account-settings/account-settings.types";
import UserAccount from "../../../../../../models/userAccount";
// import UserAccountDropdown from "../../../../payments-new/componentss/DropDowns/UserAccountDropdown";
import UserAccountSelect from "../../../../../../components/user-account-select";
import useEditSplitInflowInstruction from "../../../Services/account-settings/hooks/useEditSplitInflowInstruction";

interface Props {
    account: UserAccount;
    canCancel: boolean;
    accountsMap: Map<string | number, UserAccount>;
    isDisableCancel: boolean;
    handleCancel: () => void;
    handleUpdateSplitInflowInstruction: (ratio: SplitInflowRatio[], setIsSubmitting: Dispatch<SetStateAction<boolean>>) => Promise<void>;
}

function EditSplitInflowInstruction({
    account,
    accountsMap,
    canCancel,
    isDisableCancel,
    handleCancel,
    handleUpdateSplitInflowInstruction,
}: Props): JSX.Element {
    const {
        form,
        isFormValid,
        isSubmitting,
        totalPercentage,
        setNumParts,
        setRatio,

        removeRatioItem,
        handleSubmit,
        getAccountOptions,
    } = useEditSplitInflowInstruction(account, accountsMap, handleUpdateSplitInflowInstruction);

    const handleAddAccount = () => {
        const addOne = form.ratio.length + 1;
        setNumParts(addOne);
    };

    const handleChangeRatio = (index: number, value: string) => {
        const numberPattern = /^(\d+(\.\d{1,2})?)|$/; // allow only numbers and up to 2 decimal places, or empty string
        if (numberPattern.test(value)) {
            setRatio(index, undefined, Number(value));
        }
    };

    return (
        <>
            <form className="w-full" onSubmit={handleSubmit}>
                <div className="mx-auto w-full max-w-md space-y-12">
                    <div className="w-full space-y-5">
                        {form.ratio.map((ratioItem, index) => (
                            <div className="grid grid-cols-2 place-content-center place-items-center gap-4" key={`ratio-form-${index}`}>
                                <div className="flex w-full flex-shrink">
                                    {form.ratio[index].userAccountId === account.id ? (
                                        <Input
                                            inputSize="sm"
                                            label={`Account ${index + 1}`}
                                            value={account.lencoNameMin}
                                            disabled
                                            onChange={() => {
                                                /* do nothing */
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <UserAccountSelect
                                                label={`Account ${index + 1}`}
                                                value={form.ratio[index].userAccountId}
                                                options={getAccountOptions(form.ratio[index].userAccountId)}
                                                onClick={(value) => {
                                                    if (value) {
                                                        setRatio(index, value, undefined);
                                                    }
                                                }}
                                                size="lg"
                                            />
                                        </>
                                    )}
                                </div>

                                <div className="flex w-full min-w-7 items-center justify-between space-x-4">
                                    {/* <div className={index > 0 && form.ratio.length > 2 ? "w-4/5" : "w-full"}> */}
                                    <div className="w-4/5">
                                        <Input
                                            inputSize="sm"
                                            label="Percentage"
                                            iconType="percentage"
                                            value={form.ratio[index].percentage}
                                            // type="number"
                                            min={0}
                                            max={100}
                                            step={0.01}
                                            onChange={(value) => handleChangeRatio(index, value)}
                                            disabled={isSubmitting}
                                        />
                                    </div>

                                    {index > 0 && form.ratio.length > 2 && (
                                        <div
                                            className="cursor-pointer stroke-current text-right text-black-tertiary duration-150 hover:text-error-tertiary"
                                            onClick={() => removeRatioItem(index)}
                                        >
                                            <GoogleIcon icon="delete" isOutlined />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-between pr-10">
                            <div className="flex w-full cursor-pointer items-center space-x-3 text-blue" onClick={handleAddAccount}>
                                <span className="material-symbols-outlined text-xl">add_circle</span>
                                <p className="text-base font-medium">Add Account</p>
                            </div>
                            <div className="flex w-4/5 items-center justify-end space-x-4">
                                {totalPercentage > 0 && (
                                    <span className={`font-medium ${totalPercentage !== 100 ? "text-error" : "text-success"}`}>
                                        Total: {totalPercentage}%
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <AccountSettingsActionButtons
                        isSubmitting={isSubmitting}
                        handleCancel={handleCancel}
                        canCancel={canCancel}
                        isDisableCancel={isDisableCancel}
                        isFormValid={isFormValid}
                        formLength={form.ratio.length}
                    />
                </div>
            </form>
        </>
    );
}

export default EditSplitInflowInstruction;

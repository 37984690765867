import GoogleIcon from "../../../../../../components/google-icon";
import { MobileTeamMemberListPageState } from "../../../Services/team-members/team-members.constants";
import React from "react";

interface TeamMemberListMobileBackProps {
    isMobileLayout: boolean;
    mobileLayoutPageState: MobileTeamMemberListPageState;
    goBack: () => void;
}

function TeamMemberListMobileBack({ isMobileLayout, mobileLayoutPageState, goBack }: TeamMemberListMobileBackProps): JSX.Element {
    return (
        <div
            className={
                "w-full items-start justify-start border-b-0.2 border-grey-secondary py-3 " +
                `${isMobileLayout && mobileLayoutPageState === MobileTeamMemberListPageState.DETAILS ? "hidden sm:flex xl:hidden" : "hidden"}`
            }
        >
            <div className="flex cursor-pointer flex-row items-center justify-start space-x-2 text-lg" onClick={goBack}>
                <GoogleIcon icon="keyboard_backspace" size="lg" />
            </div>
        </div>
    );
}

export default TeamMemberListMobileBack;

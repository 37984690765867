import { GenericObject } from "../../helpers/types";

export enum FilterDateTypes {
    LAST_30_DAYS = "Last 30 days",
    LAST_7_DAYS = "Last 7 days",
    THIS_MONTH = "This Month",
    LAST_MONTH = "Last Month",
    THIS_YEAR = "This year",
    ALL_TIME = "All time",
    CUSTOM = "Custom Range",
    TODAY = "Today",
}

export type FilterDateObj = {
    begin: Date | null | string;
    end: Date | null | string;
};

export type FilterDateType = {
    name: FilterDateTypes;
    date: FilterDateObj;
};

export const getFilterCount = (_data: GenericObject) => {
    const arr = Object.values(_data).filter((item) => Array.isArray(item) || Object.keys(item as GenericObject).length > 1);

    const cleanFilterState = arr.filter((item) => {
        const count = Array.isArray(item)
            ? item.length > 0
            : Object.values(item as GenericObject).length && (Object.values(item as GenericObject)[0] as number[] | string[]).length > 0;
        return count;
    });

    const counter = 0;

    const countFilter = cleanFilterState.map((item) => {
        const count = Array.isArray(item)
            ? counter + item.length
            : Object.values(item as GenericObject).length && (Object.values(item as GenericObject)[0] as number[] | string[]).length > 0
              ? counter + 1
              : counter;
        return count;
    });

    const sumCountFilter = countFilter.reduce((a, b) => {
        return a + b;
    }, 0);

    return sumCountFilter;
};

// const dateOptions = [
// {
// name: FilterDateTypes.LAST_30_DAYS,
// date: {begin: last30Days, end: currentDate},
// },
// {
// name: FilterDateTypes.THIS_MONTH,
// date: {begin: firstDayOfMonth, end: currentDate},
// },
// {
// name: FilterDateTypes.LAST_MONTH,
// date: {begin: firstDayOfLastMonth, end: lastDayOfLastMonth},
// },
// {
// name: FilterDateTypes.THIS_YEAR,
// date: {begin: firstDayOfYear, end: currentDate},
// },
// {
// name: FilterDateTypes.ALL_TIME,
// date: {begin: firstDate, end: currentDate},
// },
// {
// name: FilterDateTypes.CUSTOM,
// date: {begin: "", end: currentDate},
// },
// ];

// export enum FilterDateTypes {
//     LAST_30_DAYS = "Last 30 days",
//     THIS_MONTH = "This Month",
//     LAST_MONTH = "Last Month",
//     THIS_YEAR = "This year",
//     ALL_TIME = "All time",
//     CUSTOM = "Custom",
// }

// export type FilterDateObj = {
//     begin: Date | null | string;
//     end: Date | null | string;
// };

// export type FilterDateType = {
//     name: FilterDateTypes;
//     date: FilterDateObj;
// };

// // const dateOptions = [
// // {
// // name: FilterDateTypes.LAST_30_DAYS,
// // date: {begin: last30Days, end: currentDate},
// // },
// // {
// // name: FilterDateTypes.THIS_MONTH,
// // date: {begin: firstDayOfMonth, end: currentDate},
// // },
// // {
// // name: FilterDateTypes.LAST_MONTH,
// // date: {begin: firstDayOfLastMonth, end: lastDayOfLastMonth},
// // },
// // {
// // name: FilterDateTypes.THIS_YEAR,
// // date: {begin: firstDayOfYear, end: currentDate},
// // },
// // {
// // name: FilterDateTypes.ALL_TIME,
// // date: {begin: firstDate, end: currentDate},
// // },
// // {
// // name: FilterDateTypes.CUSTOM,
// // date: {begin: "", end: currentDate},
// // },
// // ];

import React, { ReactElement } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import User from "../../../../../models/user";

interface Props {
    active: boolean;
    teamMember: User;
    isRemoving: boolean;
    backFunc: () => void;
    handleRemoveTeamMember: () => void;
}

function RemoveTeamMemberModal(props: Props): ReactElement {
    return (
        <>
            <Modal size="md" active={props.active} toggler={props.backFunc}>
                <ModalHeader onClose={props.backFunc}>Remove From The Team</ModalHeader>
                <ModalBody>
                    <p className="text-left text-base font-normal">
                        Are you sure you want to remove <span className="font-medium capitalize">{props.teamMember.name}</span> from your team?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp size="lg" color="grey" buttonType="secondary" func={() => props.backFunc()}>
                        Back
                    </ButtonComp>
                    <ButtonComp size="lg" color="red" isLoading={props.isRemoving} buttonType="primary" func={() => props.handleRemoveTeamMember()}>
                        Delete
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default RemoveTeamMemberModal;

import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import { GetCardPinRequest } from "../../../Services/cardsApi.types";
import GoogleIcon from "../../../../../../components/google-icon";
import LencoSpinner from "../../../../../../components/spinner";
import MessageToast from "../../../../../../components/message-toast";

interface Props {
    cardId: string;
    cardPin: string;
    isPrepaidCard: boolean;
    isGetCardPinLoading: boolean;
    handleGetCardPinCard: (_data: GetCardPinRequest) => void;
}

function RequestDefaultPIN({ cardId, cardPin, isGetCardPinLoading, handleGetCardPinCard }: Props): JSX.Element {
    const [showCardPin, setShowCardPin] = useState(true);

    useEffect(() => {
        if (!cardPin) handleGetCardPinCard({ createRequestId: cardId });
    }, [cardPin]);

    return (
        <div className="mt-4 flex w-full flex-col items-center justify-center">
            {!isGetCardPinLoading ? (
                <div className="w-full">
                    <div className="flex items-center justify-center">
                        <ButtonComp color="black" buttonType="tertiary" onClick={() => setShowCardPin((prev) => !prev)}>
                            <div className="flex items-center justify-center space-x-1">
                                {showCardPin ? (
                                    <>
                                        <p className="text-black-tertiary">Hide Pin</p>
                                        <GoogleIcon icon="visibility_off" className="text-black-tertiary" />
                                    </>
                                ) : (
                                    <>
                                        <p className="text-black-tertiary">Show Pin</p>
                                        <GoogleIcon icon="visibility" className="text-black-tertiary" />
                                    </>
                                )}
                            </div>
                        </ButtonComp>
                    </div>
                    <div className="mx-auto flex w-max flex-row space-x-4 pt-6">
                        {(showCardPin ? cardPin || "0000" : "••••").split("").map((_, _index) => (
                            <div key={_index} className="flex h-16 w-16 items-center justify-center rounded-[5px] bg-grey-tertiary">
                                <p className="text-2xl font-medium text-black">{_}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-6 w-full">
                        <MessageToast
                            type="info"
                            message="If you get an error while changing your PIN, note that the PIN change has been effected and you can use your card for transactions"
                            fullWidth
                        />
                    </div>
                </div>
            ) : (
                <LencoSpinner />
            )}
        </div>
    );
}

export default RequestDefaultPIN;

import { SpinnerColor, SpinnerSize } from "../spinner/spinner.contant";

import { ButtonCompProps } from "./ButtonComp";
import { ButtonProps } from "@nextui-org/react";

export type LencoButtonProps = {
    isText?: boolean;
    dataType?: string | null;
    noTabIndex?: boolean;
    buttonColor?: "black" | "blue" | "grey" | "red" | "transparent";
    buttonSize?: "md" | "lg" | "xl";

    onClick?: (e: React.MouseEvent) => void;
    // size?: ButtonSize;
    // type?: ButtonSubmitType;
    // children: React.ReactNode;
    // fullWidth?: boolean;
    // className?: string;
    // isLoading?: boolean | null;
    // isDisabled?: boolean;
    // buttonType?: ButtonType;
    // borderSmall?: boolean;
};

export type ButtonColor = "black" | "blue" | "grey" | "dark-grey" | "white" | "red" | "transparent";
export type ButtonType = "primary" | "secondary" | "tertiary";
export type ButtonSize = "sm" | "md" | "lg";
export type ButtonSubmitType = "button" | "submit" | "reset";

const primaryColors = {
    red: "text-white bg-error hover:bg-error ",
    blue: "text-white bg-blue hover:bg-blue-hover focus:bg-blue-focused active:bg-blue-hover",
    grey: "text-black-tertiary bg-grey-tertiary border border-transparent focus:border-black",
    black: "text-white bg-black hover:bg-black-hover focus:bg-black-hover active:bg-black-hover",
    transparent: "text-black-secondary hover:text-black focus:outline-none",
    white: "",
    "dark-grey": "",
};

const primaryDisabledColors = {
    red: "text-white bg-error-quat hover:bg-error-tertiary",
    blue: "text-white bg-blue-quat",
    grey: "text-black-quat bg-grey-backdrop",
    black: "text-white bg-black-quat",
    white: "",
    transparent: "",
    "dark-grey": "",
};

const secondaryColors = {
    red: "text-error bg-white border-error",
    blue: "text-blue bg-white border-blue hover:border-blue-hover hover:bg-blue-senary hover:text-blue-hover focus:border-blue-focused focus:bg-blue-senary focus:text-blue-focused",
    grey: "text-black-tertiary bg-white border-black-quat hover:border-black-tertiary hover:text-black-tertiary focus:border-black-tertiary focus:text-black-tertiary",
    black: "text-black bg-white border-black hover:border-black-hover hover:text-black-hover focus:border-black-focused focus:text-black-focused",
    white: "",
    "dark-grey":
        "text-black-tertiary !bg-grey-backdrop border-black-quat hover:border-black-tertiary hover:text-black-tertiary focus:border-black-tertiary focus:text-black-tertiary",
    transparent: "",
};
const secondaryDisabledColors = {
    red: "text-error-quat border-error-quat",
    blue: "text-blue-quat border-blue-quat",
    grey: "text-black-quat border-black-quin",
    black: "text-black-quat border-black-quat",
    white: "",
    "dark-grey": "text-black-quat bg-grey-backdrop",
    transparent: "",
};
const tertiaryColors = {
    red: "text-error",
    blue: "text-blue hover:text-blue-hover focus:text-blue-focused",
    grey: "text-black-quat hover:text-black-tertiary focus:text-black-tertiary",
    black: "text-black hover:text-black-hover focus:text-black-focused",
    white: "",
    "dark-grey": "",
    transparent: "",
};

const tertiaryDisabledColors = {
    red: "",
    blue: "text-blue-quin",
    grey: "text-black-quat",
    black: "text-black-quat",
    white: "",
    "dark-grey": "",
    transparent: "",
};

const flatColors = {
    red: "text-error bg-error-backdrop",
    blue: "text-blue bg-blue-senary",
    grey: "text-black-tertiary bg-grey-backdrop",
    black: "text-black-secondary bg-black-quat",
    white: "text-blue bg-white",
    "dark-grey": "text-black bg-grey",
    transparent: "",
};

const flatDisabledColors = {
    red: "text-error-tertiary bg-error-backdrop",
    blue: "text-blue-tertiary bg-blue-backdrop",
    grey: "text-black-tertiary bg-grey-tertiary",
    black: "text-black-tertiary bg-grey-tertiary",
    white: "text-blue-tertiary bg-white",
    "dark-grey": "text-black-tertiary bg-grey-tertiary",
    transparent: "",
};

export const getDefaultButton = (): {
    size: "sm" | "md" | "lg" | undefined;
    color: undefined;
    radius: "sm" | "md" | "lg" | undefined;
    className: string;
} => {
    return {
        size: undefined,
        color: undefined,
        radius: "sm",
        className: getButtonClass({ variant: "solid" }),
    };
};

export const getLoaderColor = (
    _type: "solid" | "bordered" | "light" | "flat" | "faded" | "shadow" | "ghost" | undefined,
    _color: string | undefined
): SpinnerColor => {
    const colors: string[] = [];

    if (_type === "solid") {
        return "white";
    }

    if (_type === "bordered" || _type === "light") {
        if (_color === "blue") {
            return "blue";
        } else if (_color === "black") {
            colors.push("tailwind-spinner-black");
            return "black";
        } else {
            colors.push("tailwind-spinner-error");
            return "red";
        }
    }

    return "white";
};

export const getLoaderSize = (_size: "sm" | "md" | "lg" | undefined): SpinnerSize => {
    return _size === "lg" ? "md" : _size === "md" ? "md" : "sm";
    // height.push(_size === "sm" ? "[&>div]:h-4 [&>div]:w-4" : "[&>div]:h-6 [&>div]:w-6");
};

export const getLoaderHeight = (_size: "sm" | "md" | "lg" | undefined): string => {
    const height: string[] = [];

    height.push(_size === "lg" ? "h-4" : "h-6");

    return height.join(" ");
};

export const getLoaderWidth = (_size: "sm" | "md" | "lg" | undefined): string => {
    const width: number[] = [];

    if (_size === "sm") {
        width.push(20);
    } else if (_size === "lg") {
        width.push(30);
    } else {
        width.push(20);
    }

    return width.join(" ");
};

export const getButtonClass = (props: ButtonProps & LencoButtonProps): string => {
    const classes: string[] = ["rounded-md overflow-hidden aria-expanded:!scale-[1] data-[pressed=true]:!scale-[1] "];

    //color
    if (props.isDisabled) {
        classes.push("pointer-events-none");
        if (props.variant === "solid" || !props.variant) {
            classes.push(primaryDisabledColors[props.buttonColor || "black"]);
        } else if (props.variant === "bordered") {
            classes.push("bg-transparent border border-solid shadow-none");
            classes.push(secondaryDisabledColors[props.buttonColor || "black"]);
        } else if (props.variant === "light") {
            classes.push(tertiaryDisabledColors[props.buttonColor || "black"]);
        }
    } else {
        if (props.variant === "solid" || !props.variant) {
            classes.push(primaryColors[props.buttonColor || "black"]);
        } else if (props.variant === "bordered") {
            classes.push("bg-transparent border border-solid shadow-none");
            classes.push(secondaryColors[props.buttonColor || "black"]);
        } else if (props.variant === "light") {
            classes.push(tertiaryColors[props.buttonColor || "black"]);
        }
    }

    if (props.variant === "light") {
        classes.push("!h-fit !min-w-[unset] !px-[unset] !py-[unset] hover:!bg-[unset] !rounded-none");
    } else {
        classes.push(props.buttonSize === "xl" ? "!h-14" : props.buttonSize === "lg" ? "!h-12" : props.buttonSize === "md" ? "!h-10" : "!h-12");
    }
    classes.push(props.fullWidth ? "!w-full" : "!w-fit");

    return classes.join(" ");
};

export const getClass = (props: ButtonCompProps): string => {
    const classes: string[] = [
        "flex items-center justify-center relative w-full",
        "uppercase whitespace-nowrap capitalize font-normal outline-none focus:outline-none focus:shadow-none",
        "transition-all duration-300",
    ];

    if (props.disable) {
        if (props.buttonType === "primary") {
            classes.push(primaryDisabledColors[props.color]);
        } else if (props.buttonType === "secondary") {
            classes.push("bg-transparent border border-solid shadow-none");
            classes.push(secondaryDisabledColors[props.color]);
        } else if (props.buttonType === "tertiary") {
            classes.push(tertiaryDisabledColors[props.color]);
        } else if (props.buttonType === "flat") {
            classes.push(flatDisabledColors[props.color]);
        }
    } else {
        if (props.buttonType === "primary") {
            classes.push(primaryColors[props.color]);
        } else if (props.buttonType === "secondary") {
            classes.push("bg-transparent border border-solid shadow-none");
            classes.push(secondaryColors[props.color]);
        } else if (props.buttonType === "tertiary") {
            classes.push(tertiaryColors[props.color]);
        } else if (props.buttonType === "flat") {
            classes.push(flatColors[props.color]);
        }
    }

    if (props.buttonType !== "tertiary") {
        if (props.size === "sm") {
            classes.push("h-8 px-4 text-sm");
        } else if (props.size === "md") {
            classes.push("h-10 px-6 text-sm");
        } else if (props.size === "lg") {
            classes.push("h-12 px-8 text-sm");
        } else if (props.size === "xl") {
            classes.push("h-14 px-10 text-md");
        } else if (props.size === "2xl") {
            classes.push("h-16 px-10 text-md");
        } else {
            classes.push("h-14 px-10 text-md");
        }
    } else {
        if (props.size === "sm") {
            classes.push("text-sm");
        } else if (props.size === "md") {
            classes.push("text-sm");
        } else if (props.size === "xl") {
            classes.push("text-md");
        } else {
            classes.push("text-md");
        }

        if (props.isLoading) {
            if (props.size === "sm") {
                classes.push("h-4 w-4");
            } else if (props.size === "lg") {
                classes.push("h-8 w-8");
            } else {
                classes.push("h-6 w-6");
            }
        }
    }

    //  if (props.size === "sm") {
    //      classes.push("h-8 px-4 text-sm");
    //  } else if (props.size === "md") {
    //      classes.push("h-10 px-6 text-sm");
    //  } else if (props.size === "xl") {
    //      classes.push("h-14 px-10 text-md");
    //  } else {
    //      classes.push("h-12 px-8 text-md");
    //  }
    classes.push(
        props.paddingSize
            ? props.paddingSize === "2xs"
                ? "!px-2"
                : props.paddingSize === "xs"
                  ? "!px-3"
                  : props.paddingSize === "sm"
                    ? "!px-4"
                    : props.paddingSize === "md"
                      ? "!px-6"
                      : props.paddingSize === "lg"
                        ? "!px-10"
                        : ""
            : props.buttonType !== "tertiary"
              ? ""
              : //   ? "!px-4"
                ""
    );

    if (props.buttonType !== "tertiary") {
        classes.push("overflow-hidden");
    }

    classes.push(props.fullWidth ? "w-full" : "w-max");

    if (props.borderSmall) {
        classes.push("rounded-md");
    } else if (props.buttonType === "tertiary") {
        classes.push("");
    } else if (props.fullBorder) {
        classes.push("rounded-full");
    } else if (props.buttonType === "flat") {
        classes.push("rounded-[4px]");
    } else if (props.size === "lg" || props.size === "xl" || props.size === "2xl") {
        classes.push("rounded-lg");
    } else {
        classes.push("rounded-md");
    }
    if (props.className) {
        classes.push(props.className);
    }

    return classes.join(" ");
};

export const getRippleColor = (props: ButtonCompProps): string => {
    if (props.color === "transparent") {
        return props.ripple === "light" ? "#F9F9FB" : "#BBC3FD";
    } else if (props.color === "red") {
        return "#ED9CAC";
    } else if (props.color === "black") {
        return props.ripple === "light" ? "#A8A9AC" : "#8D9093";
    } else if (props.color === "grey" || props.color === "dark-grey") {
        return "#B8BAC6";
    } else {
        return props.ripple === "light" ? "#2E3A9E" : "#BBC3FD";
    }
};

export const getSpinnerColor = (_type: "primary" | "secondary" | "tertiary" | "flat" = "primary", _color: ButtonColor): SpinnerColor => {
    if (_type === "primary") {
        if (_color === "black" || _color === "blue" || _color === "red") {
            return "white";
        } else {
            return "black";
        }
    } else if (_type === "secondary" || _type === "flat" || _type === "tertiary") {
        if (_color === "black") {
            return "black";
        } else if (_color === "blue") {
            return "blue";
        } else if (_color === "red") {
            return "red";
        } else if (_color === "white") {
            return "blue";
        } else if (_color === "grey") {
            return "black";
        } else {
            return "black";
        }
    }

    return "white";
};

import { FilterDateType, FilterDateTypes } from "../../../../../../../../components/filter/filter.constant";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ZambiaCollectionPaymentChannel, ZambiaCollectionStatus } from "../../../../../../../../models/zambia/zambiaCollectionRequest.constant";
import {
    ZambiaCollectionRequestChannelOptions,
    ZambiaCollectionRequestFilters,
    ZambiaCollectionRequestFiltersData,
    ZambiaCollectionRequestStatusOptions,
} from "../../../../../hooks/state/AddMoney/addMoney.constants";
import {
    resetAddMoneyCollectionRequestDateFilterState,
    resetAddMoneyCollectionRequestFilterState,
    resetPreAddMoneyCollectionRequestFilterState,
    setAddMoneyCollectionRequestDate,
    setAddMoneyCollectionRequestFilterState,
    setIsAddMoneyDateModalOpen,
} from "../../../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice";
import { useDispatch, useSelector } from "react-redux";

import { AddMoneyCollectionRequestFilterTypes } from "../../../../../../../../redux/payments/zambia/addMoneyCollectionRequest/slice/addMoneyCollectionRequestSlice.types";
import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import DateModal from "../../../../../../../../components/modal/date-modal";
import FilterRow from "../../../../../../../../components/filter/Row/FilterRow";
import GoogleIcon from "../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import NewDropdownHead from "../../../../../../../../components/new-dropdown";
import isNullOrUndefined from "../../../../../../../../utils/isNullOrUndefined";

interface Props {
    handleClearAll?: () => void;
    handleFilteredDetails?: (_data: AddMoneyCollectionRequestFilterTypes) => void;
}
function FilterDropdown(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const filteredAddMoneyCollectionRequestDate = useSelector((state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestDate);
    const filteredAddMoneyCollectionRequestStatusList = useSelector(
        (state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestFilterState.status
    );
    const filteredAddMoneyCollectionRequestChannelList = useSelector(
        (state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestFilterState.channel
    );

    const addMoneyCollectionRequestFilterState = useSelector((state: IRootState) => state.addMoneyHistory.addMoneyCollectionRequestFilterState);
    // const selectedAddMoneyCollectionRequestDate = useSelector((state: IRootState) => state.addMoneyHistory.selectedAddMoneyCollectionRequestDate);
    const selectedAddMoneyCollectionRequestFilterState = useSelector(
        (state: IRootState) => state.addMoneyHistory.selectedAddMoneyCollectionRequestFilterState
    );
    const isCustomAddMoneyCollectionRequestDateModalOpen = useSelector((state: IRootState) => state.addMoneyHistory.isCustomModalOpen);

    const domRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<ZambiaCollectionRequestFilters | null>(null);

    const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);

    useEffect(() => {
        setSelectedFiltersCount(handleCleanFilterState());
    }, [addMoneyCollectionRequestFilterState]);

    const handleCleanFilterState = () => {
        const arr = Object.values(addMoneyCollectionRequestFilterState).filter(
            (item) => !isNullOrUndefined(item) && (Array.isArray(item) || Object.keys(item).length > 1)
        );

        const cleanFilterState = arr.filter((item) => {
            const count = Array.isArray(item) ? item.length > 0 : Object.values(item).length && Object.values(item)[0].length > 0;
            return count;
        });

        const counter = 0;

        const countFilter = cleanFilterState.map((item) => {
            const count = Array.isArray(item)
                ? counter + item.length
                : Object.values(item).length && Object.values(item)[0].length > 0
                  ? counter + 1
                  : counter;
            return count;
        });

        const sumCountFilter = countFilter.reduce((a, b) => {
            return a + b;
        }, 0);

        return sumCountFilter;
    };

    const handleApplyFilter = useCallback(() => {
        setIsOpen(false);
        props.handleFilteredDetails && props.handleFilteredDetails(addMoneyCollectionRequestFilterState);
    }, [addMoneyCollectionRequestFilterState]);

    const handleClearAll = useCallback(() => {
        setIsOpen(false);
        if (
            selectedAddMoneyCollectionRequestFilterState.query.length > 0 ||
            selectedAddMoneyCollectionRequestFilterState.channel.length > 0 ||
            selectedAddMoneyCollectionRequestFilterState.status.length > 0 ||
            filteredAddMoneyCollectionRequestDate !== FilterDateTypes.ALL_TIME
        ) {
            props.handleClearAll && props.handleClearAll();
            dispatch(resetAddMoneyCollectionRequestFilterState());
        }
        dispatch(resetPreAddMoneyCollectionRequestFilterState());
    }, []);

    const handleOpenFilter = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setIsOpen(false);
        dispatch(setAddMoneyCollectionRequestFilterState(selectedAddMoneyCollectionRequestFilterState));
    }, [selectedAddMoneyCollectionRequestFilterState]);

    const handleToggleModal = useCallback(() => {
        dispatch(setIsAddMoneyDateModalOpen(false));
    }, []);

    const handleSelectDate = useCallback((_data: FilterDateType) => {
        dispatch(
            setAddMoneyCollectionRequestDate({
                name: FilterDateTypes.CUSTOM,
                date: { begin: _data.date.begin as string, end: _data.date.end as string },
            })
        );
    }, []);

    const handleFilterAddMoneyCollectionRequestStatus = useCallback(
        (_status: ZambiaCollectionStatus) => {
            const updatedList = [
                ...(filteredAddMoneyCollectionRequestStatusList.includes(_status)
                    ? filteredAddMoneyCollectionRequestStatusList.filter((_el) => _el !== _status)
                    : [_status]),
            ];
            dispatch(setAddMoneyCollectionRequestFilterState({ status: updatedList }));
        },
        [filteredAddMoneyCollectionRequestStatusList]
    );

    const handleFilterAddMoneyCollectionRequestChannel = useCallback(
        (_channel: ZambiaCollectionPaymentChannel) => {
            // const updatedList = produce(filteredAddMoneyCollectionRequestChannelList, (draft) => {
            // if (draft.includes(_channel)) {
            // return draft.filter((_el) => _el !== _channel);
            // } else {
            // // draft.push(_channel);
            // draft.filter((_el) => _el === _channel);
            // }
            // });
            const updatedList = [
                ...(filteredAddMoneyCollectionRequestChannelList.includes(_channel)
                    ? filteredAddMoneyCollectionRequestChannelList.filter((_el) => _el !== _channel)
                    : [_channel]),
            ];
            dispatch(setAddMoneyCollectionRequestFilterState({ channel: updatedList }));
        },
        [filteredAddMoneyCollectionRequestChannelList]
    );

    const handleFilterAddMoneyCollectionRequestDate = () => {
        dispatch(resetAddMoneyCollectionRequestDateFilterState());
    };

    const handleOpenDateModal = () => {
        dispatch(setIsAddMoneyDateModalOpen(true));
    };

    const handleSetAddMoneyCollectionRequestDate = (_data: FilterDateType) => {
        dispatch(setAddMoneyCollectionRequestDate(_data));
    };

    const handleAdditionalCheck = useCallback((target: HTMLElement): boolean => {
        return !domRef.current?.contains(target);
    }, []);

    return (
        <div className="w-full" ref={domRef}>
            <DateModal
                isActive={isCustomAddMoneyCollectionRequestDateModalOpen}
                dataType="add-money"
                handleToggleModal={handleToggleModal}
                handleSelectDate={handleSelectDate}
            />
            <NewDropdownHead
                content={
                    <div className="flex items-center justify-start space-x-2">
                        <span className="text-sm font-normal">Filter</span>
                        <GoogleIcon icon="filter_list" className="-mr-1" />
                    </div>
                }
                size="md"
                color="grey"
                buttonType="secondary"
                paddingSize="xs"
                dropdownWidth="max"
                isActive={isOpen}
                handleOpen={handleOpenFilter}
                handleClose={handleCloseFilter}
                handleAdditionalCheck={handleAdditionalCheck}
                isNoArrow
                isFreeFormDropdown
            >
                <div className="absolute -top-12 left-20 z-10 mt-[1px] pl-1.5">
                    <div className="w-64 rounded-lg bg-white pb-2.5 shadow-dropdown">
                        <div className="flex h-10 w-full flex-row items-center justify-between px-4">
                            <p className="text-base font-medium text-black-secondary">Filter by</p>
                            <ButtonComp
                                buttonType="tertiary"
                                color="blue"
                                size="sm"
                                disable={
                                    !(
                                        addMoneyCollectionRequestFilterState.query.length > 0 ||
                                        addMoneyCollectionRequestFilterState.channel.length > 0 ||
                                        addMoneyCollectionRequestFilterState.status.length > 0 ||
                                        selectedAddMoneyCollectionRequestFilterState.query.length > 0 ||
                                        selectedAddMoneyCollectionRequestFilterState.channel.length > 0 ||
                                        selectedAddMoneyCollectionRequestFilterState.status.length > 0 ||
                                        filteredAddMoneyCollectionRequestDate !== FilterDateTypes.ALL_TIME
                                    )
                                }
                                func={handleClearAll}
                            >
                                <span className="text-xs font-medium">Clear all</span>
                            </ButtonComp>
                        </div>

                        {/* Filter Menu */}
                        <div className="w-full border-t border-grey-secondary [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-grey-secondary">
                            {ZambiaCollectionRequestFiltersData.map((_filter, index) => {
                                // Typescript Hack
                                // Todo ===> Find better solution
                                return (
                                    <React.Fragment key={index}>
                                        {_filter.value === ZambiaCollectionRequestFilters.DATE && (
                                            <FilterRow
                                                date={filteredAddMoneyCollectionRequestDate}
                                                title={_filter.text}
                                                dataType="add-money"
                                                isHover={activeRow === ZambiaCollectionRequestFilters.DATE}
                                                onHover={() => setActiveRow(ZambiaCollectionRequestFilters.DATE)}
                                                handleSetDate={handleSetAddMoneyCollectionRequestDate}
                                                handleFilterDate={handleFilterAddMoneyCollectionRequestDate}
                                                handleOpenDateModal={handleOpenDateModal}
                                                isDate
                                            />
                                        )}

                                        {_filter.value === ZambiaCollectionRequestFilters.CHANNEL && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={ZambiaCollectionRequestChannelOptions}
                                                dataType="add-money"
                                                isHover={activeRow === ZambiaCollectionRequestFilters.CHANNEL}
                                                onHover={() => setActiveRow(ZambiaCollectionRequestFilters.CHANNEL)}
                                                filteredList={filteredAddMoneyCollectionRequestChannelList}
                                                handleFilterState={handleFilterAddMoneyCollectionRequestChannel}
                                                isRadio
                                            />
                                        )}
                                        {_filter.value === ZambiaCollectionRequestFilters.STATUS && (
                                            <FilterRow
                                                title={_filter.text}
                                                options={ZambiaCollectionRequestStatusOptions}
                                                dataType="add-money"
                                                isHover={activeRow === ZambiaCollectionRequestFilters.STATUS}
                                                onHover={() => setActiveRow(ZambiaCollectionRequestFilters.STATUS)}
                                                filteredList={filteredAddMoneyCollectionRequestStatusList}
                                                handleFilterState={handleFilterAddMoneyCollectionRequestStatus}
                                                isRadio
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}

                            {/* Apply Button */}
                            <div
                                className={`${selectedFiltersCount > 0 ? "w-full justify-between" : "w-full justify-end"} flex items-center px-4 pt-2`}
                            >
                                {selectedFiltersCount > 0 ? (
                                    <p className="text-xs text-black-tertiary">
                                        {selectedFiltersCount} Filter{selectedFiltersCount > 1 && "s"} Selected
                                    </p>
                                ) : null}
                                <ButtonComp
                                    size="sm"
                                    type="button"
                                    color="black"
                                    ripple="light"
                                    buttonType="primary"
                                    func={handleApplyFilter}
                                    disable={selectedFiltersCount < 1}
                                    borderSmall
                                >
                                    <span className="text-xs">Apply Filter</span>
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </div>
            </NewDropdownHead>
        </div>
    );
}

export default FilterDropdown;
